const types = [
  // { label: 'AUTHENTICATION', value: 'AUTHENTICATION' },
  { label: 'MARKETING', value: 'MARKETING' },
  { label: 'UTILITY', value: 'UTILITY' },

  /*
  // old types
  { label: 'ACCOUNT UPDATE', value: 'ACCOUNT_UPDATE' },
  { label: 'PAYMENT UPDATE', value: 'PAYMENT_UPDATE' },
  { label: 'PERSONAL FINANCE UPDATE', value: 'PERSONAL_FINANCE_UPDATE' },
  { label: 'SHIPPING UPDATE', value: 'SHIPPING_UPDATE' },
  { label: 'RESERVATION UPDATE', value: 'RESERVATION_UPDATE' },
  { label: 'ISSUE RESOLUTION', value: 'ISSUE_RESOLUTION' },
  { label: 'APPOINTMENT UPDATE', value: 'APPOINTMENT_UPDATE' },
  { label: 'TRANSPORTATION UPDATE', value: 'TRANSPORTATION_UPDATE' },
  { label: 'TICKET UPDATE', value: 'TICKET_UPDATE' },
  { label: 'ALERT UPDATE', value: 'ALERT_UPDATE' },
  { label: 'AUTO REPLY', value: 'AUTO_REPLY' },
  { label: 'TRANSACTIONAL', value: 'TRANSACTIONAL' },
  { label: 'MARKETING', value: 'MARKETING' },
  { label: 'OTP', value: 'OTP' },
  */
];

export default types;
