<template>
  <div class="view-box columns bg-light">
    <settings-header
      button-route="new"
      :icon="icon"
      :header-title="$t(headerTitle)"
      :button-text="$t(headerButtonText)"
      :show-back-button="showBackButton"
      :back-url="backUrl"
      :show-new-button="showNewButton"
      :button-disabled="buttonDisabled"
    />
    <keep-alive v-if="keepAlive">
      <router-view></router-view>
    </keep-alive>
    <router-view v-else></router-view>
  </div>
</template>

<script>
/* eslint no-console: 0 */
import SettingsHeader from './SettingsHeader';

export default {
  components: {
    SettingsHeader,
  },
  props: {
    headerTitle: { type: String, default: '' },
    headerButtonText: { type: String, default: '' },
    icon: { type: String, default: '' },
    keepAlive: {
      type: Boolean,
      default: true,
    },
    newButtonRoutes: {
      type: Array,
      default: () => [],
    },
    showBackButton: {
      type: Boolean,
      default: false,
    },
    backUrl: {
      type: [String, Object],
      default: '',
    },
  },
  data() {
    return {
      userInboxesDetails: {},
      buttonDisabled: false,
    };
  },
  computed: {
    currentPage() {
      return this.$store.state.route.name;
    },
    showNewButton() {
      return this.newButtonRoutes.length !== 0 && !this.showBackButton;
    },
  },
  async mounted() {
    await this.updateDisabledButton();
  },
  async updated() {
    await this.updateDisabledButton();
  },
  methods: {
    async updateDisabledButton() {
      if (this.headerButtonText === 'SETTINGS.INBOXES.NEW_INBOX') {
        this.userInboxesDetails = await this.$store.dispatch(
          'accounts/fetchAccountInboxes'
        );
        this.buttonDisabled = this.userInboxesDetails.inbox_limit_reached;
      } else {
        this.buttonDisabled = false;
      }
    },
  },
};
</script>
