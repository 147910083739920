<template>
  <div class="column content-box">
    <div
      style="display: flex; padding: 8px;
      padding-left: 24px;
      padding-right: 36px;
      color:grey;
      align-items: center;      
      "
    >
      <input
        v-model="node.data.customName"
        type="text"
        style="margin:0; margin-right:5px;text-transform: uppercase;"
        :placeholder="node.title"
        class="inputTitle"
      />
    </div>
    <form class="row" @submit.prevent>
      <div class="medium-12 columns">
        <div style="display:block;align-items: center; margin-bottom: 1.6em;">
          <label style="width:100%">
            {{ $t('FLOW.STRIPE_NODE_FORM.AMOUNT.LABEL') }}
            <input
              v-model="amount"
              @change="onAmountChange"
              type="number"
              :placeholder="
                $t('FLOW.STRIPE_NODE_FORM.AMOUNT.PLACEHOLDER')
              "
            />
          </label>
        </div>
        <div style="display:block;align-items: center; margin-bottom: 1.6em;">
          <label style="width:100%">
            {{ $t('FLOW.STRIPE_NODE_FORM.CURRENCY.LABEL') }}
            <input
              v-model="currency"
              @change="onCurrencyChange"
              class="w3-input"
              type="text"
              style="margin:0"
              :placeholder="$t('FLOW.STRIPE_NODE_FORM.CURRENCY.PLACEHOLDER')"
            />
          </label>
        </div>
        <div style="display:block;align-items: center; margin-bottom: 1.6em;">
          <label style="width:100%" >
            {{ $t('FLOW.STRIPE_NODE_FORM.SUCCESS_CALLBACK.LABEL') }}
              <!-- @blur="$v.successUrl.$touch" -->
            <input
              v-model="successUrl"
              @change="onSuccessUrlChange"
              class="w3-input"
              type="text"
              style="margin:0"
              :placeholder="$t('FLOW.STRIPE_NODE_FORM.SUCCESS_CALLBACK.PLACEHOLDER')"
            />
          </label>
          <!-- <span
            v-if="$v.successUrl.$error"
            class="message error-msg"
          >{{ $t('FLOW.STRIPE_NODE_FORM.SUCCESS_CALLBACK.ERROR_MESSAGE') }}</span> -->
        </div>
        <div style="display:block;align-items: center; margin-bottom: 1.6em;">
          <label style="width:100%">
            {{ $t('FLOW.STRIPE_NODE_FORM.CANCEL_CALLBACK.LABEL') }}
            <input
              v-model="cancelUrl"
              @change="onCancelUrlChange"
              class="w3-input"
              type="text"
              style="margin:0"
              :placeholder="$t('FLOW.STRIPE_NODE_FORM.CANCEL_CALLBACK.PLACEHOLDER')"
            />
          </label>
          <!-- <span
            v-if="$v.cancelUrl.$error"
            class="message error-msg"
          >{{ $t('FLOW.STRIPE_NODE_FORM.SUCCESS_CALLBACK.ERROR_MESSAGE') }}</span> -->
        </div>
        <div style="display:block;align-items: center; margin-bottom: 1.6em;">
          <label class="custom-checkbox-container">
            {{ $t('FLOW.STRIPE_NODE_FORM.DISCOUNT.LABEL') }}
            <input
              id="discount"
              v-model="discount"
              @change="onDiscountChange"
              type="checkbox"
            />
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
      <div class="modal-footer">
        <woot-button @click.prevent="checkValidation">{{ $t('FLOW.DONE') }}</woot-button>
        <!-- <woot-button variant="clear" @click.prevent="onClose">{{ $t('FLOW.CANCEL') }}</woot-button> -->
      </div>
    </form>
  </div>
</template>

<script>
import { url } from 'vuelidate/lib/validators';
import alertMixin from 'shared/mixins/alertMixin';
import { Node } from '../../vue-diagram-editor/src';
import FluentIcon from '../../../../../../../shared/components/FluentIcon/Index.vue';

export default {
  name: 'StripeNode',
  components: {
    FluentIcon,
  },
  mixins: [alertMixin],
  props: {
    node: {
      type: Node,
      required: true,
    },
    dref: {
      required: true,
    },
    botid: {
      required: true,
    },
    profiles: {
      required: true,
    },
  },
  data() {
    return {
      allGood: true,
      amount:
        this.node.data.contents.length > 0
          ? this.node.data.contents[0].amount
          : null,
      successUrl:
        this.node.data.contents.length > 0
          ? this.node.data.contents[0].successUrl
          : '',
      cancelUrl:
        this.node.data.contents.length > 0
          ? this.node.data.contents[0].cancelUrl
          : '',
      currency:
        this.node.data.contents.length > 0
          ? this.node.data.contents[0].currency
          : '',
      discount:
        this.node.data.contents.length > 0
          ? this.node.data.contents[0].discount
          : false,
    };
  },
  // validations: {
  //   successUrl: {
  //     url,
  //   },
  //   cancelUrl: {
  //     url,
  //   },
  // },
  computed: {
    commonPostfix() {
      return this.node.id.split('-')[1];
    },
  },

  methods: {
    checkValidation() {
      console.log(this.node, '>>> StripeNode.vue <<<', 'line-157');
      this.$emit('all-done');
    },
    onAmountChange() {
      let prevData = {};
      prevData = Object.assign(prevData, this.node.data);
      prevData.contents = [{ ...prevData.contents[0], amount: this.amount }];
      this.dref.updateNode({
        id: this.node.id,
        name: 'data',
        value: prevData,
      });
    },
    onCurrencyChange() {
      let prevData = {};
      prevData = Object.assign(prevData, this.node.data);
      prevData.contents = [
        { ...prevData.contents[0], currency: this.currency },
      ];
      this.dref.updateNode({
        id: this.node.id,
        name: 'data',
        value: prevData,
      });
    },
    onSuccessUrlChange() {
      let prevData = {};

      prevData = Object.assign(prevData, this.node.data);
      prevData.contents = [
        { ...prevData.contents[0], successUrl: this.successUrl },
      ];

      this.dref.updateNode({
        id: this.node.id,
        name: 'data',
        value: prevData,
      });
    },
    onCancelUrlChange() {
      let prevData = {};
      prevData = Object.assign(prevData, this.node.data);
      prevData.contents = [
        { ...prevData.contents[0], cancelUrl: this.cancelUrl },
      ];
      this.dref.updateNode({
        id: this.node.id,
        name: 'data',
        value: prevData,
      });
    },
    onDiscountChange() {
      let prevData = {};
      prevData = Object.assign(prevData, this.node.data);
      prevData.contents = [
        { ...prevData.contents[0], discount: this.discount },
      ];
      this.dref.updateNode({
        id: this.node.id,
        name: 'data',
        value: prevData,
      });
    },
  },
};
</script>

<style scoped>
.error {
  background: var(--r-50);
}
.error-msg {
  color: var(--r-500);
  margin-top: 2px;
}
</style>