import ApiClient from './ApiClient';

class EmailTemplateAPI extends ApiClient {
  constructor() {
    super('', { accountScoped: true });
  }

  getCategories() {
    return axios.get(`${this.url}email_templates/categories`);
  }

  getTemplates(payload) {
    if (payload) {
      return axios.get(`${this.url}email_templates/search?${payload}`);
    } else {
      return axios.get(`${this.url}email_templates`);
    }
  }

  getTemplateDetail(templateId) {
    return axios.get(`${this.url}email_templates/search?id=${templateId}`);
  }

  addTemplate(payload) {
    return axios.post(`${this.url}email_templates`, payload);
  }

  editTemplate(payload) {
    return axios.patch(`${this.url}email_templates`, payload);
  }

  deleteTemplate(templateId) {
    return axios.delete(`${this.url}email_templates?id=${templateId}`);
  }

  addAttchment(payload) {
    return axios.post(`${this.url}email_attachments`, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }

  getAttchments(payload) {
    console.log("payload", payload);
    if (payload) {
      return axios.get(`${this.url}email_attachments/search?${payload}`);
    } else {
      return axios.get(`${this.url}email_attachments`);
    }
  }

  deleteAttchments(attchmentId) {
    return axios.delete(`${this.url}email_attachments?id=${attchmentId}`)
  }
}

export default new EmailTemplateAPI();
