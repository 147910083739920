import Cookies from 'js-cookie';
import ContactAPI from '../api/contacts';
import AuthApi from '../api/auth';
import InboxesAPI from '../api/inboxes';
import UserAttributeAPI from "../api/userAttributes";
import {DEFAULT_REDIRECT_URL} from "../constants";
import AccountAPI from '../api/account';
import CannedResponseAPI from '../api/cannedResponse';
import AttributeAPI from '../api/attributes';
import CalendarApi from '../api/calendar';
import AutomationAPI from '../api/automation';
import FlowAPI from "../../dashboard/routes/dashboard/settings/bot/FlowApi";
import { modifyBotNodesByUserQuestions } from './poupAutomationHelpers/LeadQualificationHelper';

export const instructSteps = {
    inboxCreateSteps:{
        path :'whatsAppInbox',
        inboxAdd: 1,
        inboxAddUrl:"",
        inboxAgentAdd: 2,
        inboxAgentAddUrl:"",
        inboxFinish: 3,
        inboxFinishUrl:"",
    },
    schedulingSteps:{
        path: "scheduling",
        localStorageSkipKey: 'isScheduleSkip',
        clickCardStep: 1,
        selectInboxIdStep:2,
        connectGoogleAccount:3,
        createdBotandAppointment: 4, // last step
    },
    leadQualificationSteps:{
        path: "lead",
        localStorageSkipKey: 'isLeadQualificationSkip',
        clickCardStep: 1,
        selectInboxIdStep:2,
        template_msg:3,
        successFulMsgStep:4,
    },
}



export const getUserAttributes = () =>{
    const user = AuthApi.getCurrentUser();
    const cookieUser = Cookies.getJSON('user');
    if (user) {
        const dataLayerAttributes = window.dataLayer.find(item => item.type === "onboard_attributes_layer") || {};
        const userAttributes = user.custom_attributes || {};
        const combinedAttributes = {...userAttributes,step:user.step,...dataLayerAttributes};
        // console.log({dataLayerAttributes,userAttributes,combinedAttributes,cookieUser});
        return combinedAttributes;
    }else{
        return {}
    }
}
export const updateUserAttributes = async(attributeKey,attributeValue) =>{

    // call API to update user.customattribute in database
    try {
        const {data,error} = await UserAttributeAPI.updateCustomAttribute(attributeKey,attributeValue);

        const customAttributes = {[attributeKey]:attributeValue};
        const existingDataLayerItem = window.dataLayer.find(item => item.type === "onboard_attributes_layer");

        if (existingDataLayerItem) {
            Object.assign(existingDataLayerItem, customAttributes);
        } else {
            window.dataLayer.push({ type: "onboard_attributes_layer", ...customAttributes });
        }
        return {isError: false, data}
    } catch (error) {
        console.log(error);
        return {isError: true,errMsg:error.message};
    }
}
export const incrementUserStepAttributes = async(stepValue) =>{
    // call API to update user.customattribute in database
    try {
        const {data,error} = await UserAttributeAPI.incrementStepAttribute(stepValue);
        // console.log(data,error,"Increment Step result");

        const customAttributes = {step:stepValue};
        const existingDataLayerItem = window.dataLayer.find(item => item.type === "onboard_attributes_layer");

        if (existingDataLayerItem) {
            Object.assign(existingDataLayerItem, customAttributes);
        } else {
            window.dataLayer.push({ type: "onboard_attributes_layer", ...customAttributes });
        }
        return {isError: false, data}
    } catch (error) {
        console.log(error);
        return {isError: true,errMsg:error.message};
    }
}



export const popupHelpers = {
    setSkipInStorage(skipProperty='isScheduleSkip',skipValue=true){
        localStorage.setItem(skipProperty,skipValue.toString());
    },
    getSkipFromStorage(skipProperty){
        return localStorage.getItem(skipProperty);
    },
    deleteSkipStorage(){
        localStorage.removeItem(instructSteps.schedulingSteps.localStorageSkipKey);
    },
    randomNumber(min=100,max=99999){
        return Math.floor(Math.random()*(max-min)) + min;
    },
    sleep(ms) {
        return new Promise(resolve => {
          setTimeout(() => {
            resolve();
          }, ms);
        });
    },
    isSchedulePopupOpen(path,step,skipLocalStorageKey){
        const user = AuthApi.getCurrentUser();
        const attributes = getUserAttributes();
        let isStep =  parseInt(user.step) === parseInt(step);
        if (attributes.hasOwnProperty('step')) {
           isStep = parseInt(attributes.step) === parseInt(step);
        }
        // console.log('FinaL Attributes',attributes,isStep,user,{path,step,skipLocalStorageKey});
        const isPath =  attributes.path === path;
        const isPaiduser =  user.plan_id !== 16;
        const isOnboardLimitPositive =  user.onboard_limit > 0;
        const isNotAppsumoUser = user.appsumo !== true;
        // const isNotSkipedOnce = this.getSkipFromStorage(skipLocalStorageKey) !== 'true' && !attributes.skip;
        const isNotSkipedOnce = attributes.skip !== 'true';

        // console.log(user,{isStep,isPath,isPaiduser,isOnboardLimitPositive,isNotAppsumoUser,isNotSkipedOnce},{step,path},attributes);

        return isStep && isPath && isPaiduser && isOnboardLimitPositive && isNotAppsumoUser && isNotSkipedOnce;
    },
    async navigateToPage(){
        // check all condition and navigate to desired route
        const user = AuthApi.getCurrentUser();
        // const InboxResponse = await InboxesAPI.get();
        // const inboxesList = InboxResponse.data?.payload || [];

        const {schedulingSteps,leadQualificationSteps} = instructSteps;
        const baseRoute = `${DEFAULT_REDIRECT_URL}accounts/${user.account_id}`;
        const schedulingStepAndRoutes = [
            {
                step: schedulingSteps.clickCardStep,
                url: `${baseRoute}/dashboard`
            },
            {
                step: schedulingSteps.selectInboxIdStep,
                url: `${baseRoute}/profile/settings`  // redirect for next step
            },
            {
                step: schedulingSteps.connectGoogleAccount,
                url: `${baseRoute}/calendar/list`
            },
        ];
        const leadQualificationStepAndRoutes = [
            {
                step: leadQualificationSteps.clickCardStep,
                url: `${baseRoute}/dashboard`
            },
            {
                step: leadQualificationSteps.selectInboxIdStep,
                url: `${baseRoute}/settings/canned-response/list`
            },
        ];

        for (const stepAndRoute of [...schedulingStepAndRoutes,...leadQualificationStepAndRoutes]) {
            // console.log(this.isSchedulePopupOpen(schedulingSteps.path, stepAndRoute.step, schedulingSteps.localStorageSkipKey),stepAndRoute);
            if (this.isSchedulePopupOpen(schedulingSteps.path, stepAndRoute.step, schedulingSteps.localStorageSkipKey)) {
              return { isInstructPopupOpen: true, url: stepAndRoute.url };
            }
        }

        return { isInstructPopupOpen: false, url: `${baseRoute}/dashboard` };
    },
    openNewTab(url){
        // console.log("Opening | ",url);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.target = '_blank';
        anchor.click();
    },
    delay(ms){
        return new Promise(resolve=>setTimeout(resolve,ms));
    },
    async saveAtPeasySdkContactAttributes(email,name,contactAttributes){
        // const user =  Cookies.getJSON('user') || {};
        if (email) {
            window.$peasy.setUser(email,{
                email,
                name,
            })
        }
        await this.delay(2000);
        if (contactAttributes) {
            window.$peasy.setCustomAttributes({
                ...contactAttributes
                // invite_date: new Date().toISOString().split('T')[0],
            });
        }
        console.log("saved invite_date at setCustomAttributes = ",window.$peasy.user);
    },
}


export const popupApiHelper = {
    // add template message
    async addTemplateMessage({short_code,content,inbox_id,file,buttonsData,language,templateType}) {
        try {
            let data = { short_code, content, inbox_id, file, buttonsData, language,  templateType,};
            let formData = new FormData();
            Object.entries(data).forEach(([key, value]) => {
                if (key === 'buttonsData') {
                    formData.append(key, JSON.stringify(value));
                } else {
                    formData.append(key, value);
                }
            });
            const response = await AccountAPI.createTemplateMsg(formData);
            return {isError:false,data:response.data};
            /*
            await this.$store
                .dispatch('accounts/createTemplateMessage', formData)
                .then(() => {
                    this.showAlert(this.$t('TEMPLATE_MSG.ADD.API.SUCCESS_MESSAGE'));
                })
                .catch(error => {
                    this.showAlert(error.response.data.message);
            });
            */
        } catch (error) {
            console.log(error,error.response);
            return {isError:true,error:{message: error.response.data.message}};
        }
    },

    // delete template message list
    async deleteTemplateMessageList(template_msg_id_list){
        try {
            for(const msgId of template_msg_id_list){
                await CannedResponseAPI.delete(msgId);
            }
            return;
        } catch (error) {
            console.log(error);
            return;
        }
    },

    // add custom attributes part
    async addCustomAttributes({attribute_display_name,attribute_description,attribute_model,attribute_display_type,attribute_key,attribute_values}) {
        try {
            let bodyData = {attribute_display_name,attribute_description,attribute_model,attribute_display_type,attribute_key,attribute_values};


            const response = await AttributeAPI.create(bodyData);
            return {isError:false,data:response.data};
        } catch (error) {
            console.log(error,error.response);
            return {isError:true,error:{message: error.response.data.message}};
        }
    },

    // delete  custom attribute list
    async deleteCustomAttributeList(attribute_id_list){
        try {
            for(const attributeId of attribute_id_list){
                await AttributeAPI.delete(attributeId);
            }
            return;
        } catch (error) {
            console.log(error);
            return;
        }
    },

    async createBotIdAndExportFlow(botName,bot_template){
        try {
            const user = AuthApi.getCurrentUser();
            const createFlowResponse = await FlowAPI.createFlow(user.id,botName,bot_template,user.account_id);

            // Get the bot_id of the currently created bot
            const botList = await FlowAPI.getBotsByUserId(user.id,true,user.account_id);
            const new_bot_id = botList.find(bot => bot.name === botName)?.botid;
            if (!new_bot_id) {
                return {isError:true,error:{message: 'Failed to create bot'}};
            }

            // Update the bot flowdata to export
            const flowData = await FlowAPI.getFlowById(new_bot_id, user.id);
            const saveFlowResponse = await FlowAPI.exportFlow(new_bot_id,flowData?.data);

            // if (new_bot_id) {
            //     this.undoEndPointList.push({
            //         type: "flowbot",
            //         id: new_bot_id,
            //     });
            // }

            return {isError:false, data:{id:new_bot_id}};
        } catch (error) {
            console.log(error,error.response);
            return {isError:true,error:{message: error.response.data.message}};
        }
    },
    async deleteBotIdList(bot_id_list){
        try {
            for(const botId of bot_id_list){
                await FlowAPI.deleteFlow(botId);
            }
            return;
        } catch (error) {
            console.log(error);
            return;
        }
    },

    modifyBotFlowByQuestionAttribute(mainData, questionAttributes,meetingUniqueLink) {
       const data = {...mainData};
       try {
            // Helper function to update a specific node's content or attribute
            function updateNode(nodeName, actionType,newValue) {
                for (const nodeId in data.nodes) {
                    const node = data.nodes[nodeId];
                    if (node.data && node.data.customName === nodeName) {
                        if (actionType === 'attribute_change') {
                            // node attribute value change
                            node.data.contents.forEach((content) => {
                                if (content.customAttr) {
                                    content.customAttr = newValue;
                                }
                            });
                        }else if (actionType === 'question_change') {
                            node.data.contents[0].value = newValue;
                        }
                    }
                }
            }

            // Update question1 and its corresponding attributes
            updateNode('question1', 'question_change', questionAttributes.question1);
            updateNode('action1', 'attribute_change',  questionAttributes.attribute1);
            updateNode('prequerynode', 'attribute_change', questionAttributes.attribute1);

            // Update question2 and its corresponding attributes
            updateNode('question2', 'question_change',  questionAttributes.question2);
            updateNode('action2', 'attribute_change', questionAttributes.attribute2);
            updateNode('condition2', 'attribute_change', questionAttributes.attribute2,);

            // Update question3 and its corresponding attributes
            updateNode('question3', 'question_change', questionAttributes.question3);
            updateNode('lastaction', 'attribute_change',  questionAttributes.attribute3);
            updateNode('lastcondition', 'attribute_change', questionAttributes.attribute3);

            // update appointment url
            const newAppointmentMessage = `Perfect, please book your preferred appointment time slot here: https://schedule.peasy.ai/book/user/{{session.agent.id}}/${meetingUniqueLink}`;
            updateNode('SET-APPOINTMENT-TIME', 'question_change', newAppointmentMessage);

           return {isError: false,data}

       } catch (error) {
           console.log(error);
           return {isError: true,message:"failed to modify flow"}
       }
    },

    /*
    // create automations
    async createAutomation(automationObj){
        try {
            const response = await AutomationAPI.create(automationObj);
        } catch (error) {
            console.log(error);
            return {isError: true, message: error.response.data.message}
        }
    },
    */

    // create calander schedule meeting
    // await CalendarApi.createMeetingSession(payload);
    async createScheduleAppointmentMeeting(payload){
        try {
            const response = await CalendarApi.createMeetingSession(payload);
            return {isError:false,data:response.data};
        } catch (error) {
            console.log(error,error.response);
            return {isError:true,error:{message: error.response.data.message}};
        }
    },

    // check google account connected
    async getGoogleCalander(){
        try {
            let getCalendarResponse = await CalendarApi.getCalendar();
            if (getCalendarResponse?.data?.enabled === true) {
                // this.primaryCalendar = getCalendarResponse.data;
                return {
                    isError:false,
                    data: getCalendarResponse.data,
                    isConnected: getCalendarResponse.data.calendar_user_id ? true : false,
                }
            }else{
                return {
                    isError:false,
                    data: getCalendarResponse.data,
                    isConnected : false,
                }
            }
        } catch (error) {
            console.log(error,error.response);
            return {isError:true,error:{message: 'Failed to check google calander'}};
        }
    },


    // lead qualification helpers
    async modifyLeadQualificationBotFlow(data,questionList,leadCardOption){
        try {
            console.log({data,questionList,leadCardOption},'modifyDataFFLoLed');
            const result = modifyBotNodesByUserQuestions(data,questionList,leadCardOption)
            if (result) {
                return {isError:false,data:result}
            }else{
                return {isError:true,error:"failed to modify bot"}
            }
        } catch (error) {
            console.log(error);
            return {isError:true,error:error.message}
        }
    },

}




export const attributeHelpers ={
    // onboarding class
    onboardClassValues:{
        peasy_sale:{
            signUp: 1,
            createInbox: 2,
            embadedSignUpOrActivatedWABA: 3,
            createBroadcast: 4,
        },
        peasy_marketing:{ // for react app
            signUpWithSpecialLink: 1,
            topupWithContact_ps_reg_linkChat: 2,
            publishCampaign: 3,
        },
    },
    async update_onboard_class_value(user,onboardClassValue){
        try {
            // must be plan_id=20 and have email
            if ((user.plan_id !== 20) || !user.email) {
                console.log("not a grant user or email",user);
                return
            }

            // set the user first
            window.$peasy.setUser(user.email,{
                email: user.email,
                name: user.name,
            })
            await popupHelpers.delay(2000);
            // triger the event
            console.log("Before onboarding_class Event call with ",onboardClassValue, user);
            window.$peasy.triggerEvent({
                event_name:"increment_class",
                event_params:{
                    onboarding_class: onboardClassValue,
                    product:"PM"
                }
            });
            console.log("After onboarding_class Event call with ",onboardClassValue, user);
        } catch (error) {
            console.log(error);
        }
    },
}






export const demoAutoSteps = {
    whatsAppInbox:{
        stepName:'step_inbox',
        path:'whatsapp_inbox',
        actions:{
            inboxAdd:{
                open_step:1,
                next_setp:2,
            },
            inboxAgentAdd:{
                open_step:2,
                next_setp:3,
            },
            // inboxEnableAutoAssign:{
            //     open_step:3,
            //     next_setp:4,
            // },
            inboxFinish:{
                open_step:3,
                next_setp:4,
            },
        },
    },
    lead:{
        stepName:'step_lead',
        path:'lead',
        actions:{
            whatappInboxAdd:{
                open_step:1,
                next_setp:2,
            },
            inboxAgentAdd:{
                open_step:2,
                next_setp:3,
            },
            inboxEnableAutoAssign:{
                open_step:3,
                next_setp:4,
            },
            createTeam:{
                open_step:4,
                next_setp:5,
            }
        },
    },
    schedule:{
        stepName:'step_schedule',
        path:'schedule',
        actions:{
            whatappInboxAdd:{
                open_step:1,
                next_setp:2,
            },
            inboxAgentAdd:{
                open_step:2,
                next_setp:3,
            },
            inboxEnableAutoAssign:{
                open_step:3,
                next_setp:4,
            },
            addTemplateMsg:{
                open_step:4,
                next_setp:5,
            },
            addCustomAttributes:{
                open_step:5,
                next_setp:6,
            },
            googleCalanderAdd:{
                open_step:1,
                next_setp:2,
            },
        },
    },
}


export const getContactAttributes = (email) =>{
    try {
        const existingDataLayerItem = window.dataLayer.find(item => item.type === "onboard_layer");
        return existingDataLayerItem || {};
        /*
        const {data:contactData} = await ContactAPI.search(email);
        // const {data:contactData} = await axios.get(`/api/v1/accounts/${mainAccountId}/contacts/search?include_contact_inboxes=false&page=1&sort=name&q=${email}`);
        console.log("contactData",contactData);
        if (contactData) {
            const contactList = contactData?.payload || [];
            console.log(contactList,"contactList for contact attributes");
            const customAttributes = contactList.find(el =>el.email === email)?.custom_attributes;
            console.log(customAttributes,"Found customAttributes");
            return customAttributes || {};
        }
        return {};
        */
    } catch (error) {
        console.log(error);
        return {};
    }

}

export const updateDemoOnBoardDataLayer = (customAttributes = {}) => {
    const existingDataLayerItem = window.dataLayer.find(item => item.type === "onboard_layer");

    if (existingDataLayerItem) {
    // Update the existing data object's properties without replacing it entirely
        Object.assign(existingDataLayerItem, customAttributes);
    } else {
    // If no existing item with type "onboard_layer", push a new data object
        window.dataLayer.push({ type: "onboard_layer", ...customAttributes });
    }
};
// {user_id,isNewSignup:true,current_step:'pure_new_signup,business_type,select_plan,catalog_item,QR_code',}
export const SIGNUP_FLOW = {
    store_name:"signup_flow",
    FLOWS:{
        business_type:"business_type",
        select_plan:"select_plan",
        catalog_item:"catalog_item",
        QR_code:"QR_code",
    },
    setFlow:(payload)=>{
        // payload = {account_id,isNewSignup,current_step}
        localStorage.setItem(SIGNUP_FLOW.store_name,JSON.stringify(payload))
    },
    getFlow:()=>{
        const flow = localStorage.getItem(SIGNUP_FLOW.store_name);
        return flow ? JSON.parse(flow) : null;
    },
    clearFlow:()=>{
        localStorage.removeItem(SIGNUP_FLOW.store_name);
    },
}