<template>
  <div class="column content-box">
    <div
      style="display: flex; padding: 8px;
      padding-left: 24px;
      padding-right: 36px;
      color:grey;
      align-items: center;
      "
    >
      <input
        v-model="node.data.customName"
        type="text"
        style="margin:0; margin-right:5px;text-transform: uppercase;"
        :placeholder="node.title"
        class="inputTitle"
      />
    </div>
    <form class="row" @submit.prevent>
      <div class="medium-12 columns">
        <div style="display:block;align-items: center; margin-bottom: 1.6em;">
          <label style="width:100%">
            {{ $t('FLOW.QUESTION_TITLE') }}
            <input
              v-model="questionTitle"
              @change="onQuestionTitleChange"
              :class="{error:$v.questionTitle.$error || !$v.questionTitle.maxLength}"
              class="w3-input"
              type="text"
              style="margin:0"
              :placeholder="$t('FLOW.PLACEHOLDERS.TITLE')"
            />
          </label>
          <span
            class="error-msg"
            v-if="$v.questionTitle.$error"
          >{{ !$v.questionTitle.maxLength ? $t('FLOW.QUESTION_TITLE_MAXLENGTH_ERROR') : $t('FLOW.QUESTION_TITLE_ERROR') }}</span>
        </div>
        <div style="display:block;align-items: center; margin-bottom: 1.6em;">
          <label style="width:100%">
            {{ $t('FLOW.QUESTION_SUBTITLE') }}
            <input
              v-model="questionSubTitle"
              @change="onQuestionSubTitleChange"
              :class="{error:$v.questionSubTitle.$error}"
              class="w3-input"
              type="text"
              style="margin:0"
              :placeholder="$t('FLOW.PLACEHOLDERS.SUBTITLE')"
            />
          </label>
          <span
            class="error-msg"
            v-if="$v.questionSubTitle.$error"
          >{{ $t('FLOW.QUESTION_SUBTITLE_ERROR') }}</span>
        </div>
        <div style="display:flex;align-items: center; margin-bottom: 0em;">
          <label style="width:100%">
            Type
            <select
              class="filter__question"
              v-model="node.data.subNodes[`condition-${commonPostfix}`].data.contents[1].attrType"
            >
              <option v-for="(item, index) in attrTypes" :key="index" :value="item">{{ item.name }}</option>
            </select>
          </label>
        </div>
        <div style="display:block;align-items: center; margin-bottom: 0em;">
          <label style="width:100%">
            Attribute Name
            <select
              v-if="node.data.subNodes[`condition-${commonPostfix}`].data.contents[1].attrType.id == 'standard'"
              v-model="node.data.subNodes[`condition-${commonPostfix}`].data.contents[1].attr"
              class="filter__question"
            >
              <option
                v-for="(item, index) in contactAttributes"
                :key="index"
                :value="item"
              >{{ item.name }}</option>
            </select>
            <input
              v-if="node.data.subNodes[`condition-${commonPostfix}`].data.contents[1].attrType.id == 'custom'"
              v-model="customAttr"
              @change="onCustomAttrChange"
              :class="{error:$v.customAttr.$error || !$v.customAttr.maxLength}"
              class="answer--text-input"
              type="text"
              style="margin:0;margin-right: 5px;"
              :placeholder="$t('FLOW.PLACEHOLDERS.CUSTOM_ATTRIBUTE')"
            />
          </label>
          <span
            class="error-msg"
            v-if="$v.customAttr.$error"
          >{{ !$v.customAttr.maxLength ? $t('FLOW.CUSTOM_AATR_MAXLENGTH_ERROR') : $t('FLOW.CUSTOM_AATR_ERROR') }}</span>
        </div>
        <div style="display:flex;align-items: center; margin-bottom: 0em;">
          <label style="width:100%">
            {{ $t('FLOW.ACTION_BUTTONS') }}
            <div
              style=" background-color:#f6f6f6;  min-height: 100px;border-radius: 10px;margin-bottom: 12px; padding: 12px;"
            >
              <div v-for="(singleActionButton, index) in actionButtons" :key="index">
                <div style="display:flex;align-items: center; align-content: center;">
                  <input
                    v-model="singleActionButton.buttonTitle"
                    type="text"
                    :class="{error:$v.actionButtons.$each[index].buttonTitle.$error || !$v.actionButtons.$each[index].buttonTitle.maxLength || isTitleUnique}"
                    @blur="hasDuplicateTitles"
                    :placeholder="$t('FLOW.PLACEHOLDERS.BUTTON_TITLE')"
                  />
                  <select
                    v-model="singleActionButton.selectedAction"
                    class="operator__select"
                    style="margin-left: 5px; margin-right: 5px;"
                  >
                    <option
                      v-for="item in actionButtonOptions"
                      :key="item.id"
                      :value="item"
                    >{{ item.name }}</option>
                  </select>

                  <input
                    v-model="singleActionButton.buttonValue"
                    :class="{error:$v.actionButtons.$each[index].buttonValue.$error || !$v.actionButtons.$each[index].buttonValue.maxLength || isValueUnique}"
                    @blur="hasDuplicateValues"
                    type="text"
                    :placeholder="$t('FLOW.PLACEHOLDERS.VALUE')"
                  />

                  <div @click="deleteItem(index)">
                    <FluentIcon icon="dismiss" />
                  </div>
                </div>
                <div
                  style="margin-bottom: 1.6em; display: flex; justify-content: space-between;"
                  v-if="$v.actionButtons.$each[index].buttonTitle.$error || $v.actionButtons.$each[index].buttonValue.$error"
                >
                  <span
                    class="error-msg"
                    style="display: flex; justify-content: start;"
                    v-if="$v.actionButtons.$each[index].buttonTitle.$error"
                  >{{ !$v.actionButtons.$each[index].buttonTitle.maxLength ? $t('FLOW.BUTTON_TITLE_MAXLENGTH') : $t('FLOW.BUTTON_TITLE_ERROR') }}</span>
                  <span
                    class="error-msg"
                    style="display: flex; justify-content: end;"
                    v-if="$v.actionButtons.$each[index].buttonValue.$error"
                  >{{ !$v.actionButtons.$each[index].buttonValue.maxLength ? $t('FLOW.BUTTON_TITLE_MAXLENGTH') : $t('FLOW.BUTTON_TITLE_ERROR') }}</span>
                </div>
              </div>
              <span
                class="error-msg"
                v-if="isTitleUnique"
              >{{$t('FLOW.DUPLICATE_ACTION_BUTTON_TITLE_ERROR')}}</span>
              <span
                class="error-msg"
                style="display: flex; justify-content: end;"
                v-if="isValueUnique"
              >{{$t('FLOW.DUPLICATE_ACTION_BUTTON_VALUE_ERROR')}}</span>
            </div>
          </label>
        </div>
        <div style="margin-top:5px;margin-bottom:10px">
          <div class="custom-btn-wrapper">
            <button class="btn" @click="addNewActionButton" v-show="this.actionButtons.length < 3">
              <img src="~dashboard/assets/images/flow-new/btn-add.png" />
              {{ $t('FLOW.ACTION_BUTTON') }}
            </button>
          </div>
        </div>
        <label class="custom-checkbox-container">
          {{ $t('FLOW.END_FLOW') }}
          <input
            v-model="endFlowAction"
            @change="onUpdateEndFlow"
            id="endFlow"
            type="checkbox"
          />
          <span class="checkmark"></span>
        </label>
      </div>
      <div class="modal-footer">
        <woot-button @click.prevent="checkValidation">{{ $t('FLOW.DONE') }}</woot-button>
        <!-- <woot-button variant="clear" @click.prevent="onClose">{{ $t('FLOW.CANCEL') }}</woot-button> -->
      </div>
    </form>
  </div>
</template>

<script>
import { required, maxLength, requiredIf } from 'vuelidate/lib/validators';
import alertMixin from 'shared/mixins/alertMixin';
import { Node } from '../../vue-diagram-editor/src';
import FluentIcon from '../../../../../../../shared/components/FluentIcon/Index.vue';

export default {
  name: 'QuestionNode',
  components: {
    FluentIcon,
  },
  mixins: [alertMixin],
  props: {
    node: {
      type: Node,
      required: true,
    },
    dref: {
      required: true,
    },
    botid: {
      required: true,
    },
    profiles: {
      required: true,
    },
  },
  data() {
    return {
      allGood: true,
      actionButtonOptions: [
        { id: 'act-btn-say', name: 'Say Something' },
        { id: 'act-btn-open-url', name: 'Open URL' },
      ],
      isTitleUnique: false,
      isValueUnique: false,
      questionTitle: this.node.data.subNodes[
        `content-${this.node.id.split('-')[1]}`
      ].data.contents[0].title,
      questionSubTitle: this.node.data.subNodes[
        `content-${this.node.id.split('-')[1]}`
      ].data.contents[0].subtitle,
      endFlowAction: this.node.data.subNodes[
        `action-${this.node.id.split('-')[1]}`
      ].data.endFlowAction,
      customAttr: this.node.data.subNodes[
        `condition-${this.node.id.split('-')[1]}`
      ].data.contents[1].customAttr,
    };
  },
  computed: {
    commonPostfix() {
      return this.node.id.split('-')[1];
    },
    actionButtons() {
      return this.node.data.subNodes[`content-${this.commonPostfix}`].data
        .contents[0].actionButtons;
    },
    attrTypes() {
      return [
        { id: 'standard', name: 'Standard' },
        { id: 'custom', name: 'Custom' },
      ];
    },
    contactAttributes() {
      return [
        { id: 'name', name: 'Name' },
        { id: 'email', name: 'Email' },
        { id: 'phone_number', name: 'Phone Number' },
        { id: 'identifier', name: 'Identifier' },
        { id: 'status_id', name: 'Status ID' },
      ];
    },
  },
  validations: {
    questionTitle: {
      required,
      maxLength: maxLength(13),
    },
    questionSubTitle: {
      required,
    },
    customAttr: {
      required: requiredIf(function(form) {
        return (
          this.node.data.subNodes[`condition-${this.commonPostfix}`].data.contents[1]
            .attrType.id == 'custom'
        );
      }),
      maxLength: maxLength(13),
    },
    actionButtons: {
      maxLength: maxLength(3),
      $each: {
        buttonTitle: {
          required,
          maxLength: maxLength(13),
        },
        buttonValue: {
          required,
          maxLength: maxLength(13),
        },
      },
    },
  },
  watch: {
    node: {
      handler(newVal) {
        let tempActionData = this.node.data.subNodes[
          `action-${this.commonPostfix}`
        ].data;

        let { attrType, attr, customAttr } = newVal.data.subNodes[
          `condition-${this.commonPostfix}`
        ].data.contents[1];

        tempActionData.contents[0] = {
          ...tempActionData.contents[0],
          attr: attr,
          attrType: attrType,
        };

        if (attrType.id === 'standard') {
          tempActionData.contents[0] = {
            ...tempActionData.contents[0],
            name: attr.name,
            customAttr: attr.id
          };
        } else {
          tempActionData.contents[0] = {
            ...tempActionData.contents[0],
            name: customAttr,
            customAttr: customAttr,  // _._
          };
        }

        // update diagram data
        this.dref.updateCombineNode({
          id: this.node.id,
          subNodeId: `action-${this.commonPostfix}`,
          name: 'data',
          value: tempActionData,
        });
      },
      deep: true,
    },
  },
  methods: {
    addNewActionButton() {
      if (this.actionButtons.length < 3) {
        let temp = {
          buttonTitle: '',
          selectedAction: this.actionButtonOptions[0],
          buttonValue: '',
        };
        this.actionButtons.push(temp);
      } else {
        this.showAlert('Maximun add 3 choices');
      }
    },
    deleteItem(index) {
      this.actionButtons.splice(index, 1);
    },
    hasDuplicateTitles() {
      const titles = this.actionButtons.map(button => button.buttonTitle);
      if (new Set(titles).size !== titles.length) {
        this.isTitleUnique = true;
      } else {
        this.isTitleUnique = false;
      }
      return new Set(titles).size !== titles.length;
    },
    hasDuplicateValues() {
      const values = this.actionButtons.map(button => button.buttonValue);
      if (new Set(values).size !== values.length) {
        this.isValueUnique = true;
      } else {
        this.isValueUnique = false;
      }
      return new Set(values).size !== values.length;
    },
    checkValidation() {
      this.$v.$touch();
      if (
        this.$v.$invalid ||
        this.hasDuplicateTitles() ||
        this.hasDuplicateValues()
      ) {
        return;
      } else {
        this.$emit('all-done');
      }
    },
    onClose() {
      this.$emit('on-close');
    },
    onQuestionTitleChange() {
      let tempContentData = this.node.data.subNodes[
        `content-${this.commonPostfix}`
      ].data;

      tempContentData.contents[0].title = this.questionTitle;

      this.dref.updateCombineNode({
        id: this.node.id,
        subNodeId: `content-${this.commonPostfix}`,
        name: 'data',
        value: tempContentData,
      });
    },
    onQuestionSubTitleChange() {
      let tempContentData = this.node.data.subNodes[
        `content-${this.commonPostfix}`
      ].data;

      tempContentData.contents[0].subtitle = this.questionSubTitle;

      this.dref.updateCombineNode({
        id: this.node.id,
        subNodeId: `content-${this.commonPostfix}`,
        name: 'data',
        value: tempContentData,
      });
    },
    onCustomAttrChange() {
      let tempConditionData = this.node.data.subNodes[
        `condition-${this.commonPostfix}`
      ].data;

      tempConditionData.contents[1].customAttr = this.customAttr;

      this.dref.updateCombineNode({
        id: this.node.id,
        subNodeId: `condition-${this.commonPostfix}`,
        name: 'data',
        value: tempConditionData,
      });
    },
    onUpdateEndFlow() {
      let tempActionData = this.node.data.subNodes[
        `action-${this.commonPostfix}`
      ].data;

      tempActionData.endFlowAction = this.endFlowAction;

      this.dref.updateCombineNode({
        id: this.node.id,
        subNodeId: `action-${this.commonPostfix}`,
        name: 'data',
        value: tempActionData,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.error {
  background: var(--r-50);
}
.error-msg {
  color: var(--r-500);
  margin-top: 2px;
}
</style>