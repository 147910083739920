import ApiClient from '../ApiClient';

class WebChannel extends ApiClient {
  constructor() {
    super('inboxes', { accountScoped: true });
  }

  // get WABA inbox status
  getWabaInboxStatus(inbox_id) {
    return axios.get(`${this.url}/status?id=${inbox_id}`);
  }

  // connect phone by verify waba PIN
  connectPhoneWithPin(payload){
    return axios.post(`${this.url}/connect_phone`, payload);
  }

}

export default new WebChannel();
