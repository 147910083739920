import ApiClient from './ApiClient';

class CampaignsAPI extends ApiClient {
  constructor() {
    super('campaigns', { accountScoped: true });
  }

  campaignsByPage(page=1){
    return axios.get(`${this.url}?page=${page}`);
  }
}

export default new CampaignsAPI();
