import ApiClient from '../ApiClient';

class CatalogueAPI extends ApiClient {
  constructor() {
    super('', { accountScoped: true });
  }

  get(inboxeId) {
    return axios.get(`${this.url}catalog_items/show`, {
      params: { inbox_id: inboxeId },
    });
  }

  getProductSets(inboxeId) {
    return axios.get(`${this.url}catalog_items/product_sets`, {
      params: { inbox_id: inboxeId },
    });
  }
  search(params) {
    return axios.get(`${this.url}catalog_items/search`, {
      params: params,
    });
  }

  getCatalogue(inboxeId) {
    return axios.get(`${this.url}catalog_items/catalog`, {
      params: { inbox_id: inboxeId },
    });
  }
  
  refreshCatalogue(payload) {
    return axios.post(`${this.url}catalog_items/refresh_catalog`, payload);
  }

  deleteCategory(payload) {
    return axios.post(`${this.url}catalog_items/destroy_category`, payload);
  }

  createCategory(payload) {
    return axios.post(`${this.url}catalog_items/create_category`, payload);
  }

  updateCategories(payload) {
    return axios.post(`${this.url}catalog_items/update_categories`, payload);
  }
}
export default new CatalogueAPI();
