<template>
  <div class="contacts-page row">
    <div class="left-wrap" :class="wrapClas">
      <contacts-header
        :search-query="searchQuery"
        :segments-id="segmentsId"
        :on-search-submit="onSearchSubmit"
        this-selected-contact-id=""
        :on-input-search="onInputSearch"
        :on-toggle-create="onToggleCreate"
        :on-toggle-import="onToggleImport"
        :on-toggle-filter="onToggleFilters"
        :onToggleBulkUpdate="onToggleBulkUpdate"
        :on-toggle-column-selector="onToggleColumnSelector"
        :header-title="pageTitle"
        :selectedRowIds="selectedRowIds"
        :onDeleteMany="onDeleteMany"
        :isDeletingMany="uiFlags.isDeletingMany"
        @on-toggle-save-filter="onToggleSaveFilters"
        @on-toggle-delete-filter="onToggleDeleteFilters"
      />
        <!-- :is-loading="uiFlags.isFetching" -->
      <contacts-table
        :contacts="records"
        :show-search-empty-state="showEmptySearchResult"
        :on-click-contact="openContactInfoPanel"
        :active-contact-id="selectedContactId"
        :sort-config="sortConfig"
        :cols="displayColumns"
        :selectedRowIds="selectedRowIds"
        @onSelectContactRow="onSelectContactRow"
        @onSelectAllContactRow="onSelectAllContactRow"
        @on-sort-change="onSortChange"
      />
      <!-- insted of pagination: do infinity data scroll -->
      <!--
        <table-footer
          :on-page-change="onPageChange"
          :current-page="Number(meta.currentPage)"
          :total-count="meta.count"
        />
      -->

        <!-- v-if="!hasCurrentPageEndReached && !chatListLoading" -->
        <div class="loadMore_btn">
          <woot-button
            variant="clear"
            size="small"
            color-scheme="primary"
            :is-loading="uiFlags.isFetching"
            :is-disabled="paginationCompute.isLastPage"
            @click="onPageChange(Number(meta.currentPage)+1,true)"
          >
            <!-- Load More Contacts -->
            {{paginationCompute.btnText}}
          </woot-button>
        </div>
    </div>

    <add-custom-views
      v-if="showAddSegmentsModal"
      :custom-views-query="segmentsQuery"
      :filter-type="filterType"
      :open-last-saved-item="openSavedItemInSegment"
      @close="onCloseAddSegmentsModal"
    />
    <delete-custom-views
      v-if="showDeleteSegmentsModal"
      :show-delete-popup.sync="showDeleteSegmentsModal"
      :active-custom-view="activeSegment"
      :custom-views-id="segmentsId"
      :active-filter-type="filterType"
      :open-last-item-after-delete="openLastItemAfterDeleteInSegment"
      @close="onCloseDeleteSegmentsModal"
    />

    <contact-info-panel
      v-if="showContactViewPane"
      :contact="selectedContact"
      :on-close="closeContactInfoPanel"
    />
    <create-contact :show="showCreateModal" @cancel="onToggleCreate" />
    <woot-modal :show.sync="showImportModal" :on-close="onToggleImport">
      <import-contacts v-if="showImportModal" :on-close="onToggleImport" />
    </woot-modal>

    <woot-modal
      :show.sync="showFiltersModal"
      :on-close="onToggleFilters"
      size="medium"
    >
      <contacts-advanced-filters
        v-if="showFiltersModal"
        :on-close="onToggleFilters"
        :initial-filter-types="contactFilterItems"
        @applyFilter="onApplyFilter"
        @clearFilters="clearFilters"
      />
    </woot-modal>

    <woot-modal
      :show.sync="showBulkUpdateModal"
      :on-close="onToggleBulkUpdate"
      size="medium"
    >
      <contacts-bulk-update
        v-if="showBulkUpdateModal"
        :on-close="onToggleBulkUpdate"
        :initial-filter-types="contactFilterItems"
        @applyFilter="onApplyFilterToUpdate"
        @clearFilters="clearFilters"
      />
    </woot-modal>

    <woot-modal
      :show.sync="showColumnSelector"
      :on-close="onToggleColumnSelector"
      size="medium"
    >
      <column-selector
        v-if="showColumnSelector"
        :contacts="records"
        :on-close="onToggleColumnSelector"
        :initial-filter-types="contactFilterItems"
        :cols="displayColumns"
        @selectColumn="onSelectColumn"
        @clearFilters="clearFilters"
      />
    </woot-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Vue from 'vue';
import ContactsHeader from './Header';
import ContactsTable from './ContactsTable';
import ContactInfoPanel from './ContactInfoPanel';
import CreateContact from 'dashboard/routes/dashboard/conversation/contact/CreateContact';
import TableFooter from 'dashboard/components/widgets/TableFooter';
import ImportContacts from './ImportContacts.vue';
import ContactsAdvancedFilters from './ContactsAdvancedFilters.vue';
import ContactsBulkUpdate from './ContactsBulkUpdate.vue';
import contactFilterItems from '../contactFilterItems';
import filterQueryGenerator from '../../../../helper/filterQueryGenerator';
import AddCustomViews from 'dashboard/routes/dashboard/customviews/AddCustomViews';
import DeleteCustomViews from 'dashboard/routes/dashboard/customviews/DeleteCustomViews';
import ColumnSelector from './ColumnSelector.vue';
import alertMixin from 'shared/mixins/alertMixin';

const DEFAULT_PAGE = 1;
const FILTER_TYPE_CONTACT = 1;

export default {
  components: {
    ContactsHeader,
    ContactsTable,
    TableFooter,
    ContactInfoPanel,
    CreateContact,
    ImportContacts,
    ContactsAdvancedFilters,
    ContactsBulkUpdate,
    AddCustomViews,
    DeleteCustomViews,
    ColumnSelector,
  },
  mixins: [alertMixin],
  props: {
    label: { type: String, default: '' },
    segmentsId: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      selectedColumns: [],
      searchQuery: '',
      showCreateModal: false,
      showImportModal: false,
      selectedContactId: '',
      sortConfig: { name: 'asc' },
      showFiltersModal: false,
      showBulkUpdateModal: false,
      showColumnSelector: false,
      contactFilterItems: contactFilterItems.map(filter => ({
        ...filter,
        attributeName: this.$t(
          `CONTACTS_FILTER.ATTRIBUTES.${filter.attributeI18nKey}`
        ),
      })),
      segmentsQuery: {},
      filterType: FILTER_TYPE_CONTACT,
      showAddSegmentsModal: false,
      showDeleteSegmentsModal: false,
      cols: [
        { key: 'selected', name: 'Selected', visible: true, type: 'default' },

        { key: 'name', name: 'Name', visible: true, type: 'default' },

        { key: 'email', name: 'Email', visible: true, type: 'default' },

        {
          key: 'phone_number',
          name: 'Phone Number',
          visible: true,
          type: 'default',
        },

        { key: 'company', name: 'Company', visible: true, type: 'default' },

        { key: 'profiles', name: 'Profiles', visible: true, type: 'default' },

        { key: 'city', name: 'City', visible: true, type: 'default' },

        { key: 'country', name: 'Country', visible: true, type: 'default' },
        { key: 'status_id', name: 'Status Id', visible: true, type: 'default' },

        {
          key: 'countryCode',
          name: 'Country Code',
          visible: true,
          type: 'default',
        },
        /*
        {
          key: 'conversationsCount',
          name: 'Conversation Count',
          visible: false,
          type: 'default',
        },
        */
        {
          key: 'last_activity_at',
          name: 'Last Activity At',
          visible: true,
          type: 'default',
        },
      ],
      selectedRowIds:[],
    };
  },
  computed: {
    ...mapGetters({
      records: 'contacts/getContacts',
      uiFlags: 'contacts/getUIFlags',
      meta: 'contacts/getMeta',
      segments: 'customViews/getCustomViews',
      getAppliedContactFilters: 'contacts/getAppliedContactFilters',
    }),
    attributes() {
      return this.$store.getters['attributes/getAttributesByModel'](
        'contact_attribute'
      );
    },
    displayColumns() {
      if (Array.isArray(this.attributes)) {
        this.attributes.forEach(attribute => {
          let key = attribute.attribute_key;
          let temp = {};
          temp.key = key;
          temp.name = attribute.attribute_display_name;
          temp.visible = false;
          this.cols.push(temp);
        });
      }

      return this.cols;
    },
    showEmptySearchResult() {
      const hasEmptyResults = !!this.searchQuery && this.records.length === 0;
      return hasEmptyResults;
    },
    hasAppliedFilters() {
      return this.getAppliedContactFilters.length;
    },
    hasActiveSegments() {
      return this.activeSegment && this.segmentsId !== 0;
    },
    isContactAndLabelDashboard() {
      return (
        this.$route.name === 'contacts_dashboard' ||
        this.$route.name === 'contacts_labels_dashboard'
      );
    },
    pageTitle() {
      if (this.hasActiveSegments) {
        return this.activeSegment.name;
      }
      if (this.label) {
        return `#${this.label}`;
      }
      return this.$t('CONTACTS_PAGE.HEADER');
    },
    selectedContact() {
      if (this.selectedContactId) {
        const contact = this.records.find(
          item => this.selectedContactId === item.id
        );
        return contact;
      }
      return undefined;
    },
    showContactViewPane() {
      return this.selectedContactId !== '';
    },
    wrapClas() {
      return this.showContactViewPane ? 'medium-9' : 'medium-12';
    },
    pageParameter() {
      const selectedPageNumber = Number(this.$route.query?.page);
      return !Number.isNaN(selectedPageNumber) &&
        selectedPageNumber >= DEFAULT_PAGE
        ? selectedPageNumber
        : DEFAULT_PAGE;
    },
    activeSegment() {
      if (this.segmentsId) {
        const [firstValue] = this.segments.filter(
          view => view.id === Number(this.segmentsId)
        );
        return firstValue;
      }
      return undefined;
    },
    paginationCompute(){
      const limit_per_page = 15; // default in backend
      const totalPages = Math.ceil(this.meta.count / limit_per_page);
      const isLastPage = this.meta.currentPage >= totalPages;
      const currentCount = Math.min(this.meta.currentPage * limit_per_page, this.meta.count);
      const btnText = `Load More Contacts (${currentCount}/${this.meta.count})`;

      return{
        isLastPage,
        btnText,
      }
    },
  },
  watch: {
    label() {
      this.fetchContacts(DEFAULT_PAGE);
      if (this.hasAppliedFilters) {
        this.clearFilters();
      }
    },
    activeSegment() {
      if (this.hasActiveSegments) {
        const payload = this.activeSegment.query;
        this.fetchSavedFilteredContact(payload, DEFAULT_PAGE);
      }
      if (this.hasAppliedFilters && this.$route.name === 'contacts_dashboard') {
        this.fetchFilteredContacts(DEFAULT_PAGE,true);
      } else {
        this.fetchContacts(DEFAULT_PAGE);
      }
    },
  },
  mounted() {
    this.fetchContacts(this.pageParameter,null,true);
    // check searchQuery contain email/phone/name, open showCreateModal=true
    this.openCreateContactModal();
  },
  methods: {
    updatePageParam(page) {
      const currentRoute = this.$route;
      const newParams = {page}
      if (currentRoute.query.name) {
        newParams.name = currentRoute.query.name;
      }
      if (currentRoute.query.phone) {
        newParams.phone = currentRoute.query.phone;
      }
      if (currentRoute.query.email) {
        newParams.email = currentRoute.query.email;
      }
      // window.history.pushState({}, null, `${this.$route.path}?page=${page}`);
      window.history.pushState({}, null, `${this.$route.path}?${new URLSearchParams(newParams)}`);
    },
    openCreateContactModal(){
      const paramQuery = new URLSearchParams(window.location.search);
      const query = Object.fromEntries(paramQuery);
      console.log(query,"query in contact");
      if (query.phone || query.email) {
        this.showCreateModal = true;
      }
    },
    getSortAttribute() {
      let sortAttr = Object.keys(this.sortConfig).reduce((acc, sortKey) => {
        const sortOrder = this.sortConfig[sortKey];
        if (sortOrder) {
          const sortOrderSign = sortOrder === 'asc' ? '' : '-';
          return `${sortOrderSign}${sortKey}`;
        }
        return acc;
      }, '');
      if (!sortAttr) {
        this.sortConfig = { name: 'asc' };
        sortAttr = 'name';
      }
      return sortAttr;
    },
    fetchContacts(page, sortDetails = null,isInfinityScroll=false) {
      if (this.isContactAndLabelDashboard) {
        this.updatePageParam(page);
        let value = '';
        if (this.searchQuery.charAt(0) === '+') {
          value = this.searchQuery.substring(1);
        } else {
          value = this.searchQuery;
        }
        const requestParams = {
          page,
          sortAttr: this.getSortAttribute(),
          label: this.label,
        };


        if (!value) {
          if (sortDetails) {
            let toSendPayload = {isInfinityScroll};

            if (['status_id'].includes(sortDetails.key)) {
              toSendPayload.isCustomAttribute = true;
            }

            toSendPayload.sortDetails = sortDetails;
            toSendPayload.records = this.records;
            this.$store.dispatch('contacts/localSort', toSendPayload);
          } else {
            this.$store.dispatch('contacts/get', {...requestParams,isInfinityScroll});
          }
        } else {
          this.$store.dispatch('contacts/search', {
            search: value,
            isInfinityScroll,
            ...requestParams,
          });
        }
      }
    },
    fetchSavedFilteredContact(payload, page,isInfinityScroll=false) {
      if (this.hasActiveSegments) {
        this.updatePageParam(page);
        this.$store.dispatch('contacts/filter', {
          queryPayload: payload,
          page,
          isInfinityScroll,
        });
      }
    },
    fetchFilteredContacts(page,isInfinityScroll=false) {
      if (this.hasAppliedFilters) {
        const payload = this.segmentsQuery;
        this.updatePageParam(page);
        this.$store.dispatch('contacts/filter', {
          queryPayload: payload,
          page,
          isInfinityScroll,
        });
      }
    },

    onInputSearch(event) {
      const newQuery = event.target.value;
      const refetchAllContacts = !!this.searchQuery && newQuery === '';
      this.searchQuery = newQuery;
      if (refetchAllContacts) {
        this.fetchContacts(DEFAULT_PAGE);
      }
    },
    onSearchSubmit() {
      this.selectedContactId = '';
      if (this.searchQuery) {
        this.fetchContacts(DEFAULT_PAGE);
      }
    },
    onPageChange(page,isInfinityScroll=false) {
      this.selectedContactId = '';
      if (this.segmentsId !== 0) {
        const payload = this.activeSegment.query;
        this.fetchSavedFilteredContact(payload, page,isInfinityScroll);
      }
      if (this.hasAppliedFilters) {
        this.fetchFilteredContacts(page,isInfinityScroll);
      } else {
        this.fetchContacts(page,null,isInfinityScroll);
      }
    },
    openContactInfoPanel(contactId) {
      this.selectedContactId = contactId;
      this.showContactInfoPanelPane = true;
    },
    closeContactInfoPanel() {
      this.selectedContactId = '';
      this.showContactInfoPanelPane = false;
    },
    onToggleCreate() {
      this.showCreateModal = !this.showCreateModal;
    },
    onToggleSaveFilters() {
      this.showAddSegmentsModal = true;
    },
    onCloseAddSegmentsModal() {
      this.showAddSegmentsModal = false;
    },
    onToggleDeleteFilters() {
      this.showDeleteSegmentsModal = true;
    },
    onCloseDeleteSegmentsModal() {
      this.showDeleteSegmentsModal = false;
    },
    onToggleImport() {
      this.showImportModal = !this.showImportModal;
    },
    onSortChange(params) {
      let sortDetails = null;
      Object.keys(params).forEach(function(key) {
        if (params[key] !== '') {
          if (
            ![
              'email',
              'name',
              'phone_number',
              'last_activity_at',
              'company',
              'city',
              'country',
            ].includes(key)
          ) {
            sortDetails = {};
            sortDetails.key = key;
            sortDetails.order = params[key];
          }
        }
      });
      this.sortConfig = params;
      this.fetchContacts(this.meta.currentPage, sortDetails);
    },
    onToggleFilters() {
      this.showFiltersModal = !this.showFiltersModal;
    },
    onToggleBulkUpdate() {
      this.showBulkUpdateModal = !this.showBulkUpdateModal;
    },
    onToggleColumnSelector() {
      this.showColumnSelector = !this.showColumnSelector;
    },
    onApplyFilter(payload) {
      this.closeContactInfoPanel();
      this.segmentsQuery = filterQueryGenerator(payload);
      this.$store.dispatch('contacts/filter', {
        queryPayload: filterQueryGenerator(payload),
      });
      this.showFiltersModal = false;
    },
    async onApplyFilterToUpdate(payload) {
      try {
        const idsTobeUpdated = this.selectedRowIds.filter(el=> typeof el === 'number');
        if (!idsTobeUpdated.length) {
          // this.showAlert("Must select contacts to be updated");
          this.showAlert(this.$t('UPDATE_CONTACT.MESSAGE.REQUIRED_SELECT'));
        }
        const updatedAttributes = {};
        payload.forEach(attribute => {
          if (attribute.attribute_key === 'status_id') {
            // allow only status_id from standard attributes
            updatedAttributes[attribute.attribute_key] =  typeof attribute.values === 'object' ? attribute.values.id : attribute.values;
          } else {
            // If custom_attributes doesn't exist, create it
            if (!updatedAttributes.custom_attributes) {
              updatedAttributes.custom_attributes = {};
            }
            // Add to the custom_attributes nested object
            updatedAttributes.custom_attributes[attribute.attribute_key] = typeof attribute.values === 'object' ? attribute.values.id : attribute.values;
          }
        });

        // call api to bulk update
        const result = await this.$store.dispatch("contacts/updateMany",{contact_ids:idsTobeUpdated,updatedAttributes}); // write vuex logic
        this.selectedRowIds = []; // clean the selected list
        this.showBulkUpdateModal = false;
        this.fetchContacts(1,null,false);

        // this.showAlert("Contacts updated successfully!");
        this.showAlert(this.$t('UPDATE_CONTACT.MESSAGE.SUCCESSFUL'));
      } catch (error) {
        console.log(error);
        // this.showAlert(this.$t('CONVERSATION.CHANGE_STATUS'));
        this.showAlert(error.message? error.message : "Something went wrong");
      }
    },
    clearFilters() {
      this.$store.dispatch('contacts/clearContactFilters');
      this.fetchContacts(this.pageParameter);
    },

    openSavedItemInSegment() {
      const lastItemInSegments = this.segments[this.segments.length - 1];
      const lastItemId = lastItemInSegments.id;
      this.$router.push({
        name: 'contacts_segments_dashboard',
        params: { id: lastItemId },
      });
    },
    openLastItemAfterDeleteInSegment() {
      if (this.segments.length > 0) {
        this.openSavedItemInSegment();
      } else {
        this.$router.push({ name: 'contacts_dashboard' });
        this.fetchContacts(DEFAULT_PAGE);
      }
    },

    onSelectColumn(payload) {
      this.cols = payload;
    },
    onSelectContactRow(rowID){
      const isexistId = this.selectedRowIds.includes(rowID);
      if (isexistId) {
        this.selectedRowIds = this.selectedRowIds.filter(el=>el !== rowID);
      }else{
        this.selectedRowIds.push(rowID);
      }
      console.log(this.selectedRowIds,"selectedRowIds");
    },
    onSelectAllContactRow(isAllSelect,ids){
      console.log({isAllSelect});
      if (isAllSelect) {
        this.selectedRowIds = [...ids];
      }else{
        this.selectedRowIds = [];
      }
      console.log(this.selectedRowIds,"selectedRowIds");
    },
    onDeleteMany(){
      // dispatch to delete the contact id list
      const idsTobDeleted = this.selectedRowIds.filter(el=> typeof el === 'number');
      this.$store.dispatch("contacts/deleteMany",idsTobDeleted);
      this.selectedRowIds = []; // clean the selected list
    },
  },
};
</script>

<style lang="scss" scoped>
.contacts-page {
  width: 100%;
}

.left-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.loadMore_btn button{
  display: block;
  margin: auto;
}
</style>
