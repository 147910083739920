<template>
  <!-- <section style="display:grid;grid-template-columns: var(--space-jumbo) 1fr;"> -->
  <section class="top_notification_container" :class="responsiveClass">
        <div class="side_bar"></div>
        <div class="outlet">
            <p class="order_credit_msg">{{order_topup_warn_msg}}</p>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';


export default {
    mixins:[alertMixin],
    props: {
        isSideOpen: {
            type: Boolean,
            default: false,
        },
    },
    data(){
        return {
            // isBarShow: true,
        }
    },
    computed:{
        ...mapGetters({
            currentUser: 'getCurrentUser',
        }),
        order_topup_warn_msg(){
            if (this.currentUser.account_top_amount <= 0) {
                return 'Chatbot and sales orders are both deactivated. Top-up now to reactivate these features';
            }else if (this.currentUser.account_top_amount < 5) {
                return 'Your account balance is running now, top-up now to avoid missing new sales order and opportunities';
            }else{
                // this.isBarShow= false;
            }
        },
        responsiveClass(){
            return this.isSideOpen ? 'grid_container' : 'block_container';
        },
    },
    methods:{},
}
</script>

<style lang="scss" scoped>
    .grid_container{
        display: grid;
        grid-template-columns: var(--space-jumbo) 1fr;
    }
    .block_container{
        display: block;
    }
    .top_notification_container{
        .side_bar{
            background-color: var(--w-500);
        }
        .outlet{
            padding: 20px 20px 0px 20px;

            .order_credit_msg{
                margin: 0;
            }
        }
    }
</style>