/* global axios */
import ApiClient from './ApiClient';
import { accountIdFromPathname } from '../helper/URLHelper';

class StripeAPI extends ApiClient {
  constructor() {
    super('', {});
  }

  createPaymentIntent(amount, currency, topUpAmount, productId) {
    const { pathname } = window.location;
    const accountId = accountIdFromPathname(pathname);
    const config = {
      method: 'post',
      url: `${this.url}create_stripe_payment`,
      data: {
        amount: amount,
        currency: currency,
        account_id: accountId,
        actual_amount: topUpAmount,
        product_id: productId,
      },
    };
    return axios(config)
      .then(function(response) {
        return {
          status: response.status,
          client_secret: response.data.client_secret,
          payment_intent_id: response.data.id,
        };
      })
      .catch(error => {
        return {
          status: error.response.status,
          message: 'Not able to process your request. Please try again.',
        };
      });
  }

  InitializeStripeCredentials() {
    const config = {
      method: 'post',
      url: `${this.url}stripe_credentials`,
    };
    return axios(config)
      .then(function(response) {
        // eslint-disable-next-line no-undef
        window.stripe = Stripe(response.data.stripe_publish);
      })
      .catch(error => {
        return {
          status: error.response.status,
        };
      });
  }

  fetchTaxAmount(currency) {
    const config = {
      method: 'post',
      url: `${this.url}fetch_tax_amount`,
      data: {
        currency: currency,
      },
    };
    return axios(config)
      .then(function(response) {
        return {
          status: response.status,
          tax_percentage: response.data[0].percentage,
        };
      })
      .catch(error => {
        return {
          status: error.response.status,
        };
      });
  }

  createStripeSession(priceId, accountId) {
    const config = {
      method: 'post',
      url: `${this.url}create_stripe_session`,
      data: {
        price_id: priceId,
        account_id: accountId,
      },
    };
    return axios(config)
      .then(function(response) {
        return response.data;
      })
      .catch(error => {
        return error;
      });
  }

  updateSubscriptionPlan(priceId, accountId, promotionCode) {
    const config = {
      method: 'post',
      url: `${this.url}update_stripe_subscription_plan`,
      data: {
        price_id: priceId,
        account_id: accountId,
        promotion_code: promotionCode,
      },
    };
    return axios(config)
      .then(function(response) {
        return response.data;
      })
      .catch(error => {
        return error.response;
      });
  }

  getSavedPaymentMethods() {
    const config = {
      method: 'post',
      url: `${this.url}get_saved_payment_methods`,
      data: {},
    };
    return axios(config)
      .then(function(response) {
        return response.data;
      })
      .catch(error => {
        return error;
      });
  }
}

export default new StripeAPI();
