<template>
  <div class="column content-box messages_wrapper">
    <div class="top-right">
      <woot-button
        v-if="activeTab === 'template'"
        color-scheme="success"
        class-names="button--template"
        icon="add-circle"
        @click="onShowTemplateModal"
        >
        <!-- + Create Template message -->
        {{$t("MESSAGE_MGMT.HEADER_BTN_TXT")}}
      </woot-button>
      <woot-button
        v-if="activeTab === 'canned'"
        color-scheme="success"
        class-names="button--template"
        icon="add-circle"
        @click="openAddPopup()"
      >
        {{ $t('CANNED_MGMT.HEADER_BTN_TXT') }}
      </woot-button>
    </div>
    <div class="tabs">
      <woot-button
          v-for="tab in tabs"
          color-scheme="primary"
          class-names="tab_btn"
          :class="{'active_btn':activeTab === tab.id}"
          :variant="activeTab === tab.id ? 'smooth':'clear'"
          :key="tab.id"
          @click="setActiveTab(tab.id)"
          >
          {{tab.label}}
      </woot-button>
        <!-- <aside class="">
        </aside> -->
        <!-- <aside>
            <woot-button
                color-scheme="success"
                class-names="button--template"
                icon="add-circle"
                @click="onShowTemplateModal"
                >
                {{$t("MESSAGE_MGMT.HEADER_BTN_TXT")}}
            </woot-button>
        </aside> -->
    </div>
    <div>
        <div class="search-wrap">
          <fluent-icon icon="search" class="search-icon msg_search_icon" />
          <input
            type="text"
            :placeholder="$t('MESSAGE_MGMT.SEARCH_PLACEHOLDER')"
            class="contact-search"
            :value="searchQuery"
            @keyup.enter="onSearchSubmit"
            @input="onInputSearch"
          />
          <woot-button
            :is-loading="false"
            class="clear"
            :class-names="searchButtonClass"
          >
            <!-- @click="onSearchSubmit" -->
            {{ $t('CONTACTS_PAGE.SEARCH_BUTTON') }}
          </woot-button>
        </div>
    </div>

    <MessageList
        :onShowTemplateModal="onShowTemplateModal"
        :searchKey="searchQuery"
        :cannType="activeTab"
        :onShowAddCannModal="openAddPopup"
    />
    <CreateTemplateMessageModal
        :onClose="onCloseTemplateModal"
        :show="addTemplateMsgShow"
        :onCreateSuccess="onSuccessTemplateCreate"
    />
    <!-- add a success notify modal -->
    <ReviewNotifyModal
        :show="reviewNotifyShow"
        :onClose="onCloseReviewNotifyModal"
        :onOkay="onReviewNotifyOkay"
    />
    <!-- Add Agent -->
    <woot-modal :show.sync="showAddPopup" :on-close="hideAddPopup">
      <add-canned :on-close="hideAddPopup" />
    </woot-modal>
  </div>
</template>

<script>
import MessageList from "./MessageList.vue";
import CreateTemplateMessageModal from "./CreateTemplateMessage.vue";
import ReviewNotifyModal from "./ReviewNotifyModal.vue";
import AddCanned from "../canned/AddCanned.vue"

export default {
    components: {
      MessageList,
      CreateTemplateMessageModal,
      ReviewNotifyModal,
      AddCanned,
    },
    data() {
        return {
          activeTab: 'template',
          tabs:[
              {id:"template",label:"Template Message"},
              {id:"canned",label:"Canned Response"},
          ],
          searchQuery:"",
          addTemplateMsgShow: false,
          // isVerifyVisible: true,
          reviewNotifyShow: false,
          showAddPopup: false,
      };
    },
    computed: {
        searchButtonClass() {
            return this.searchQuery !== '' ? 'show' : '';
        },
    },
    methods:{
        onSearchSubmit(e){
          const value = e.target.value;
          this.searchQuery = value;
        },
        onInputSearch(e){
          const value = e.target.value;
        },
        setActiveTab(tab) {
            this.activeTab = tab;
        },
        onShowTemplateModal(){
            this.addTemplateMsgShow = true
        },
        onCloseTemplateModal(){
            this.addTemplateMsgShow = false
        },
        onCloseReviewNotifyModal(){
            this.reviewNotifyShow = false;
        },
        onReviewNotifyOkay(){
            this.onCloseReviewNotifyModal();
        },
        onSuccessTemplateCreate(){
          this.onCloseTemplateModal();
          this.$store.dispatch('getCannedResponse', { searchKey: "" });
          this.reviewNotifyShow = true;
        },
        // add cann message methods
        openAddPopup() {
          this.showAddPopup = true;
        },
        hideAddPopup() {
          this.showAddPopup = false;
        },

    // end methods
    },
}
</script>

<style lang="scss" scoped>
.messages_wrapper{
  background: var(--white);
}
.top-right {
  position: absolute;
  right: 0px;
  display: inline;
  top: 6.8rem;
}

.tabs{
    // display: grid;
    // grid-template-columns: 1fr 230px;
    // padding-left: 0;

    .tab_btn{
        font-weight: 500;
        font-size: 14px;
        color: #858585;
        background: transparent;
        &:hover{
            color: #8D25F6;
        }
    }
    .active_btn{
        font-weight: 700;
        color: #8D25F6;
        background-color: #F2E7FE;
    }
}

.search-wrap {
  width: 280px;
  display: flex;
  align-items: center;
  position: relative;
  margin-top: var(--space-slab);

  .search-icon {
    position: absolute;
    top: 1px;
    left: var(--space-one);
    height: 3.8rem;
    line-height: 3.6rem;
    font-size: var(--font-size-small);
    color: #858585;
  }
  .contact-search {
    margin: 0;
    height: 3.8rem;
    width: 100%;
    padding-top: var(--space-one);
    padding-bottom: var(--space-one);
    padding-left: var(--space-large);
    padding-right: 6rem;
    border-color: var(--s-100);
    font-size: var(--font-size-small);
  }

  .button {
    margin-left: var(--space-small);
    height: 3.2rem;
    right: var(--space-smaller);
    position: absolute;
    padding: 0 var(--space-small);
    transition: transform 100ms linear;
    opacity: 0;
    transform: translateX(-1px);
    visibility: hidden;
  }

  .button.show {
    opacity: 1;
    transform: translateX(0);
    visibility: visible;
  }
}
</style>