<template>
  <div class="column content-box">
    <div
      style="display: flex; padding: 8px;
    padding-left: 24px;
    padding-right: 24px;
    color:grey;
    align-items: center;
    "
    >
      <h5>{{ $t('FLOW.EDIT_CARD') }}</h5>
    </div>
    <form class="row" @submit.prevent>
      <div class="medium-12 columns">
        <div style="display:flex;align-items: center; margin-bottom: 1.6em;">
          <label style="width:100%">
            {{ $t('FLOW.CARD_TITLE') }}

            <input
              v-model="card.title"
              class="w3-input"
              type="text"
              style="margin:0"
              :placeholder="$t('FLOW.PLACEHOLDERS.TITLE')"
            />
          </label>
        </div>

        <div style="display:flex;align-items: center; margin-bottom: 1.6em;">
          <label style="width:100%">
            {{ $t('FLOW.CARD_SUBTITLE') }}

            <input
              v-model="card.subtitle"
              class="w3-input"
              type="text"
              style="margin:0"
              :placeholder="$t('FLOW.PLACEHOLDERS.SUBTITLE')"
            />
          </label>
        </div>

        <div class="content-image-input">
          <div
            v-if="card.file == ''"
            style="    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;"
          >
            <woot-loading-state v-if="isUploading" message="Uploading..." />

            <div
              v-if="!isUploading"
              style="display: flex;flex-direction: column;align-items: center;justify-content: center;"
            >
              <div class="upload-btn-wrapper">
                <button class="btn">
                  {{ $t('FLOW.UPLOAD_FILE') }}
                </button>
                <input
                  type="file"
                  name="myfile"
                  accept="video/*,image/*,application/pdf"
                  @change="handleFileUpload($event)"
                />
              </div>

              <div>
                {{ $t('FLOW.OR') }}
              </div>

              <input
                class="w3-input"
                type="text"
                style="margin:0"
                :placeholder="$t('FLOW.PLACEHOLDERS.PASTE_FILE_URL')"
                @input="handleInputChange"
              />
            </div>
            <br />

            <div v-if="!isUploading" style="margin-top: 12px;">
              <woot-button @click.prevent="uploadFromUrl()">
                {{ $t('FLOW.UPLOAD') }}
              </woot-button>
            </div>
          </div>

          <div
            v-else
            style="padding: 5px;background-color: #e1e5ea;margin:5px;border-radius: 5px;display: flex; justify-content: center; align-items: center; flex-direction: column;"
          >
            <img
              v-if="isImage(card.file)"
              :src="getFileUrl(card.file)"
              style="object-fit:contain; max-height:120px ;"
            />

            <a v-else :href="getFileUrl(card.file)" target="_blank">
              🔗 {{ $t('FLOW.FILE') }}
            </a>
            <div class="delete" @click="deleteImage()">
              <FluentIcon icon="dismiss" />
            </div>
          </div>
        </div>

        <div style="display:flex;align-items: center; margin-bottom: 0em;">
          <label style="width:100%">
            {{ $t('FLOW.ACTION_BUTTONS') }}

            <div
              style=" background-color:#f6f6f6;  min-height: 100px;border-radius: 10px;margin-bottom: 12px; padding: 12px;"
            >
              <div
                v-for="(singleActionButton, index) in actionButtons"
                :key="index"
              >
                <div
                  style="display:flex;align-items: center; margin-bottom: 1.6em;align-content: center;"
                >
                  <input
                    v-model="singleActionButton.buttonTitle"
                    type="text"
                    :placeholder="$t('FLOW.PLACEHOLDERS.BUTTON_TITLE')"
                  />

                  <select
                    v-model="singleActionButton.selectedAction"
                    class="operator__select"
                    style="margin-left: 5px;
    margin-right: 5px;"
                  >
                    <option
                      v-for="item in actionButtonOptions"
                      :key="item.id"
                      :value="item"
                    >
                      {{ item.name }}
                    </option>
                  </select>

                  <input
                    v-model="singleActionButton.buttonValue"
                    type="text"
                    :placeholder="$t('FLOW.PLACEHOLDERS.VALUE')"
                  />

                  <div @click="deleteItem(index)">
                    <FluentIcon icon="dismiss" />
                  </div>
                </div>
              </div>
            </div>
          </label>
        </div>

        <div style="margin-top:5px;margin-bottom:10px">
          <div class="custom-btn-wrapper">
            <button class="btn" @click="addNewActionButton">
              <img src="~dashboard/assets/images/flow-new/btn-add.png" />

              {{ $t('FLOW.ACTION_BUTTON') }}
            </button>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <woot-button @click.prevent="allDone">
          {{ $t('FLOW.DONE') }}
        </woot-button>
        <woot-button variant="clear" @click.prevent="onClose">
          {{ $t('FLOW.CANCEL') }}
        </woot-button>
      </div>
    </form>
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import FluentIcon from '../../../../../../../shared/components/FluentIcon/Index.vue';
import FlowApi from '../../FlowApi';
export default {
  components: {
    FluentIcon,
  },

  mixins: [alertMixin],
  props: {
    dref: {
      required: true,
    },
    botid: {
      required: true,
    },
    card: {
      required: true,
    },
  },
  data() {
    return {
      title: this.card.title,
      subtitle: this.card.subtitle,
      show: true,
      enabled: true,
      contents: this.node,
      tempText: '',
      tempText2: '',
      currentSelection: 'user',
      actionButtons: this.card.actionButtons,
      isUploading: false,
    };
  },

  computed: {
    actionButtonOptions() {
      return [
        { id: 'act-btn-say', name: 'Say Something' },
        { id: 'act-btn-open-url', name: 'Open URL' },
      ];
    },
  },
  methods: {
    onUpdateListValue(value, item, type) {
      item[type] = value;
    },

    getFileUrl(imageName) {
      return FlowApi.getImgUrl(imageName, this.botid);
    },

    isImage(imageName) {
      if (!imageName) {
        return false;
      }
      if (imageName.match(/.(jpg|jpeg|png|gif)$/i)) {
        return true;
      }
      return false;
    },

    async handleInputChange(event) {
      this.tempText = event.target.value;
    },

    async handleFileUpload(event) {
      this.isUploading = true;

      const file = event.target.files[0];

      let uploadRes = await FlowApi.uploadFile(this.botid, file);

      if (uploadRes.status === 'ok') {
        this.card.file = uploadRes.data.name;
      } else {
        this.showAlert(uploadRes.message);
      }
      this.isUploading = false;
    },

    async uploadFromUrl() {
      this.isUploading = true;
      let uploadRes = await FlowApi.copyUpload(this.botid, this.tempText);

      if (uploadRes.status === 'ok') {
        this.card.file = uploadRes.data.name;
      } else {
        this.showAlert(uploadRes.message);
      }
      this.isUploading = false;
    },
    deleteItem(index) {
      this.actionButtons.splice(index, 1);
    },
    deleteImage() {
      this.card.file = '';
    },

    addNewActionButton() {
      let temp = {
        buttonTitle: '',
        selectedAction: this.actionButtonOptions[0],
        buttonValue: '',
      };

      this.card.actionButtons.push(temp);
    },

    allDone() {
      this.$emit('card-edit-done', this.node);
    },

    onClose() {
      this.$emit('on-close');
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .ProseMirror-woot-style {
  height: 8rem;
}

.bottom-margin {
  margin-bottom: var(--space-small) !important;
}
</style>
