<template>
  <!--  -->
  <section class="sessions-table-wrap">
    <ve-table :columns="columns" :table-data="tableData" />

    <empty-state
      v-if="!isLoading && !sessions.length"
      :title="$t('CALENDAR_MGMT.LIST.404')"
    />
    <empty-state
      v-else-if="!isLoading && !sessions.length"
      :title="$t('CALENDAR_MGMT.LIST.NO_SESSION')"
    />
    <div v-if="isLoading" class="contacts--loader">
      <spinner />
      <span> {{ $t('CALENDAR_MGMT.LIST.LOADING_MESSAGE') }} </span>
    </div>
  </section>
</template>

<script>
import { VeTable } from 'vue-easytable';
import CalendarApi from '../../../../api/calendar';
import alertMixin from 'shared/mixins/alertMixin';
import Spinner from 'shared/components/Spinner.vue';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import copy from 'copy-text-to-clipboard';
import { SESSSION_PREFIX_URL } from './constants';

export default {
  name: 'sessions-table',
  mixins: [alertMixin],
  props: {
    sessions: {
      type: Function,
    },
    refresh: {
      type: Function,
    },
    openSessionEditor: {
      type: Function,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    currentUserId: {
      type: Number,
    },
  },
  components: { EmptyState, VeTable, Spinner },

  data() {
    return {
      columns: [
        { field: 'name', key: 'name', title: 'Name', align: 'left' },
        {
          field: 'duration',
          key: 'duration',
          title: 'Duration (Min)',
          align: 'left',
        },
        {
          field: 'unique_link',
          key: 'unique_link',
          title: 'Unique Link',
          align: 'left',
        },
        {
          field: 'actions',
          key: 'actions',
          title: 'Actions',
          fixed: 'right',
          align: 'center',
          width: 300,
          renderBodyCell: ({ row }) => (
            <div className="actions-wrapper">
              <woot-button
                variant="smooth"
                color-scheme="secondary"
                icon="copy"
                size="small"
                class-names="margin-right-button"
                onClick={() => this.copySessionLink(row.unique_link)}
              />
              <woot-button
                variant="smooth"
                color-scheme="primary"
                icon="edit"
                class-names="margin-right-button"
                size="small"
                onClick={() => this.editSession(row.rawData)}
              />
              <woot-button
                variant="smooth"
                color-scheme="alert"
                icon="dismiss-circle"
                class-names="grey-btn"
                size="small"
                onClick={() => this.deleteSession(row.id)}
              />
            </div>
          ),
        },
      ],
    };
  },
  computed: {
    tableData() {
      return this.sessions.map(item => {
        return {
          name: item.title,
          duration: item.duration,
          unique_link: item.unique_link,
          id: item.id,
          rawData: item,
        };
      });
    },
  },
  methods: {
    copySessionLink(link) {
      copy(SESSSION_PREFIX_URL + this.currentUserId + '/' + link);
      this.showAlert('Link Copied');
    },
    async deleteSession(id) {
      try {
        console.log('id', id);
        await CalendarApi.deleteMeetingSession(id);
        this.showAlert('Session Deleted');
        this.refresh();
      } catch (error) {
        this.showAlert(error);
      }
    },
    async editSession(sessionData) {
      try {
        console.log('editSession', sessionData);
        // await CalendarApi.deleteMeetingSession(id);
        // this.showAlert('Session Deleted');
        this.openSessionEditor(sessionData);
      } catch (error) {
        this.showAlert(error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.actions-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.business_hours_select {
  margin: 0 !important;
}

.grey-btn {
  padding: 0 5px;
}

.sessions-table-wrap::v-deep {
  .ve-table-header-th {
    padding: var(--space-small) var(--space-two) !important;
    background-color: var(--w-500) !important;
    color: var(--white) !important;
  }

  .margin-right-button {
    margin-right: 5px;
  }

  .ve-table-body-td {
    padding: var(--space-small) var(--space-two) !important;
  }

  .ve-table-header-th {
    font-size: var(--font-size-mini) !important;
  }

  .ve-table {
    padding-bottom: var(--space-large);
  }
}
</style>
