var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('button',{staticClass:"create_temp_msg_btn",style:(( _obj = {
    top: _vm.getTopPadding(-3) + 'rem', position: _vm.getPosition()
    }, _obj['z-index'] = _vm.isCreateShow?101:100, _obj )),on:{"click":function($event){$event.stopPropagation();return _vm.onCreateTemplateBtn($event)}}},[_vm._v("\n    "+_vm._s(_vm.$t('MESSAGE_MGMT.CREATE_BTN'))+"\n    ")]),_vm._v(" "),(_vm.items.length)?_c('ul',{staticClass:"vertical dropdown menu mention--box",style:({ top: _vm.getTopPadding() + 'rem', position: _vm.getPosition() })},[[_c('div',_vm._l((_vm.items),function(item,index){
    var _obj;
return _c('li',{key:item.key,class:{
          active: index === _vm.selectedIndex,
          pending_cann: item.template_status === 'pending',
          cannItemWrap: true,
        },attrs:{"id":("mention-item-" + index)},on:{"mouseover":function($event){return _vm.onHover(index)}}},[_c('a',{staticClass:"cann_full_show",class:{pending_cann: item.template_status === 'pending'},on:{"click":function($event){$event.stopPropagation();!['pending', 'rejected'].includes(item.template_status) ? _vm.onListItemSelection(index) : _vm.onPendingClick(index, $event)}}},[_c('strong',[_vm._v(_vm._s(item.label)+" "),_c('span',{staticClass:"status",class:( _obj = {}, _obj[item.template_status] = true, _obj )},[_vm._v(_vm._s(_vm.statusList[item.template_status] || ""))])]),_vm._v(" "),_c('span',[_vm._v(" "+_vm._s(item.description))])]),_vm._v(" "),_c('woot-button',{attrs:{"class-names":"preview_btn","variant":"clear","icon":"previeweye"},on:{"click":function($event){$event.stopPropagation();return _vm.onPreviewClickHandler(item)}}},[_vm._v("\n        "+_vm._s(_vm.$t("MESSAGE_MGMT.SHORT_CREATE.PREVIEW_BTN"))+"\n        ")])],1)}),0)],_vm._v(" "),_c('CreateTemplateMessageModal',{attrs:{"onClose":_vm.onCloseTemplateModal,"show":_vm.addTemplateMsgShow,"onCreateSuccess":_vm.onSuccessTemplateCreate}}),_vm._v(" "),_c('ReviewNotifyModal',{attrs:{"show":_vm.reviewNotifyShow,"onClose":_vm.onCloseReviewNotifyModal,"onOkay":_vm.onReviewNotifyOkay}}),_vm._v(" "),_c('PreviewTemplateModal',{attrs:{"show":_vm.previewMessageShow,"onClose":_vm.onClosePreviewModal,"cannItem":_vm.previewMessage}})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }