<template>
  <modal
  :show.sync="show"
  :on-close="onClose"
  closeIcon="chevron-left"
  closeBtnClass="modal_box_close_btn"
  modalMainContainerClass="create_tpl_modal_container"
  >
    <div class="modal_box create_template_modal">
        <div class="header">
            <h2 class="header_title">{{$t("MESSAGE_MGMT.ADD.TITLE")}}</h2>
        </div>
        <!-- <TemplateMsgAlert
            :description="$t('MESSAGE_MGMT.ADD.VERIFY.DESCRIPTION')"
            :title="$t('MESSAGE_MGMT.ADD.VERIFY.DESCRIPTION')"
            :show="isVerifyVisible"
            @close="onCloseVerifyAlert"
        /> -->
        <!-- <div class="verify">
            <h2 class="title">{{$t("MESSAGE_MGMT.ADD.VERIFY.TITLE")}}</h2>
            <p class="description">{{$t("MESSAGE_MGMT.ADD.VERIFY.DESCRIPTION")}}</p>
            <woot-button
                icon="dismiss"
                color-scheme="secondary"
                :variant="clear"
                classNames="verify_close_btn"
            ></woot-button>
        </div> -->
        <section class="body">
            <aside>
                <label class="field font_medium">
                     {{ $t('MESSAGE_MGMT.ADD.FORM.TEMPLATE_NAME_LABEL') }}
                     <input
                        v-model.trim="templateName"
                        class="font_large"
                        type="text"
                        :placeholder="$t('MESSAGE_MGMT.ADD.FORM.TEMPLATE_NAME_PLACEHOLDER')"
                        @input="$v.templateName.$touch"
                        @blur="$v.templateName.$touch"
                     >
                     <span v-if="$v.templateName.$error" class="err_message">
                        {{ $t('MESSAGE_MGMT.ADD.FORM.TEMPLATE_NAME_ERR') }}
                    </span>
                    <span v-else class="hint">{{ $t('MESSAGE_MGMT.ADD.FORM.TEMPLATE_NAME_HINT') }}</span>
                </label>
                <aside class="field font_medium">
                     {{ $t('MESSAGE_MGMT.ADD.FORM.INBOX_LABEL') }}
                    <div class="tmp_select_wrapper">
                        <multiselect
                            v-model="selectedInbox"
                            :options="inboxListWhatsApp"
                            :disabled="false"
                            class="small-12"
                            track-by="id"
                            label="name"
                            :multiple="false"
                            :close-on-select="true"
                            :clear-on-select="false"
                            :hide-selected="true"
                            :placeholder="$t('MESSAGE_MGMT.ADD.FORM.INBOX_PLACEHOLDER')"
                            selected-label
                            :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                            :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
                            :key="'close_' + 'index_inbox'"
                        />
                    </div>
                    <span v-if="$v.selectedInbox.$error" class="err_message">
                        {{ $t('MESSAGE_MGMT.ADD.FORM.INBOX_ERR') }}
                    </span>
                </aside>
                <aside class="field font_medium">
                     {{ $t('MESSAGE_MGMT.ADD.FORM.LANG_LABEL') }}
                    <div class="tmp_select_wrapper">
                        <multiselect
                            v-model="selectedLanguage"
                            :options="LANGUAGES"
                            :disabled="false"
                            class="small-12"
                            track-by="value"
                            label="label"
                            :multiple="false"
                            :close-on-select="true"
                            :clear-on-select="false"
                            :hide-selected="true"
                            :placeholder="$t('MESSAGE_MGMT.ADD.FORM.LANG_PLACEHOLDER')"
                            selected-label
                            :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                            :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
                            :key="'close_' + 'index_lang'"
                        />
                        </div>
                        <span v-if="$v.selectedLanguage.$error" class="err_message">
                            {{ $t('MESSAGE_MGMT.ADD.FORM.TYPE_LANG') }}
                        </span>
                </aside>

                <aside class="field font_medium">
                     {{ $t('MESSAGE_MGMT.ADD.FORM.TYPE_LABEL') }}
                    <div class="tmp_select_wrapper">
                        <multiselect
                            v-model="selectedType"
                            :options="TEMPLATE_TYPES"
                            :disabled="false"
                            class="small-12"
                            track-by="value"
                            label="label"
                            :multiple="false"
                            :close-on-select="true"
                            :clear-on-select="false"
                            :hide-selected="true"
                            :placeholder="$t('MESSAGE_MGMT.ADD.FORM.TYPE_PLACEHOLDER')"
                            selected-label
                            :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                            :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
                            :key="'close_' + 'index'"
                        />
                    </div>
                    <span v-if="$v.selectedType.$error" class="err_message">
                        {{ $t('MESSAGE_MGMT.ADD.FORM.TYPE_ERR') }}
                    </span>
                </aside>
                <label class="field font_medium" :class="{ error: $v.content.$error }">
                     {{ $t('MESSAGE_MGMT.ADD.FORM.CONTENT_LABEL') }}
                     <textarea
                        v-model.trim="content"
                        class="font_large"
                        type="text"
                        :placeholder="$t('MESSAGE_MGMT.ADD.FORM.CONTENT_PLACEHOLDER')"
                        @input="$v.content.$touch"
                        @blur="$v.content.$touch"
                     />
                     <span v-if="$v.content.$error" class="err_message">
                        {{ $t('MESSAGE_MGMT.ADD.FORM.CONTENT_ERR') }}
                    </span>
                </label>

                <div class="field font_medium">
                     {{ $t('MESSAGE_MGMT.ADD.FORM.ATTACHMENT_LABEL') }}
                    <div class="attachment">
                        <RadioList
                        :options="attachmentList"
                        v-model="selectedAttachType"
                        @change="onAttachTypeSelect"
                        />
                    </div>
                    <div v-if="selectedAttachRadio === 'new_attachement'">
                        <input
                            class="font_large file_upload"
                            id="file"
                            ref="file"
                            type="file"
                            accept="image/*, .mp4, .pdf"
                            @change="handleImageUpload"
                        />
                    </div>
                    <!-- <div class="tmp_select_wrapper">
                        <multiselect
                            v-model="selectedInbox"
                            :options="inboxes"
                            :disabled="false"
                            class="small-12"
                            track-by="id"
                            label="name"
                            :multiple="false"
                            :close-on-select="true"
                            :clear-on-select="false"
                            :hide-selected="true"
                            :placeholder="$t('MESSAGE_MGMT.ADD.FORM.ATTACH_PLACEHOLDER')"
                            selected-label
                            :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                            :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
                            :key="'close_' + 'index'"
                        />
                    </div> -->
                </div>


                <aside class="field font_medium">
                     {{ $t('MESSAGE_MGMT.ADD.FORM.ACTION_LABEL') }}
                    <div class="tmp_select_wrapper">
                        <multiselect
                            v-model="selectedActionBtnList"
                            :options="actionBtnOptions"
                            :disabled="false"
                            class="small-12"
                            track-by="code"
                            label="name"
                            :max="3"
                            :multiple="true"
                            :taggable="true"
                            @tag="addNewButtonAction"
                            :close-on-select="true"
                            :clear-on-select="false"
                            :hide-selected="true"
                            :tag-placeholder="$t('TEMPLATE_MSG.ADD.FORM.BUTTON.LABEL')"
                            :placeholder="$t('MESSAGE_MGMT.ADD.FORM.ACTION_PLACEHOLDER')"
                            selected-label
                            :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                            :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
                            :key="'close_' + 'index'"
                        />
                    </div>
                </aside>

            </aside>
            <aside class="col_preview preview">
                <div class="pre_header">
                    <h3 class="title">{{$t('MESSAGE_MGMT.ADD.PREVIEW.TITLE')}}</h3>
                </div>
                <div class="pre_message_box">
                    <div class="preview_card">
                        <div class="media">
                            <!-- <div class="arrow_mark"></div> -->
                            <template>
                                <div class="file_view_wrapper" v-if="fileType.isImage">
                                    <img :src="filePreviewUrl" alt="">
                                </div>
                                <div v-else-if="fileType.isVideo" class="file_view_wrapper">
                                    <video  controls autoplay="false">
                                        <source :src="filePreviewUrl" type="video/mp4">
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                                <div v-else-if="fileType.isPDF" class="file_view_wrapper">
                                    <iframe  :src="filePreviewUrl" frameborder="0" width="100%" height="100%">
                                        This browser does not support PDFs. Please download the PDF to view it.
                                    </iframe>
                                </div>
                            </template>
                        </div>
                        <div class="content">
                            <p v-if="content">{{content}}</p>
                            <p v-else class="placeholder">{{ $t('MESSAGE_MGMT.ADD.PREVIEW.CONTENT_PLACEHOLDER')}}</p>
                            <time>{{getCurrentTime()}}</time>
                        </div>
                        <div v-if="selectedActionBtnList.length" class="buttons">
                            <div class="btn_wrapper">
                                <a v-for="buttonEl in selectedActionBtnList" :key="buttonEl.code">
                                {{ buttonEl.name }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </aside>
        </section>
        <div class="row submit_btns">
            <aside class="medium-6 columns">
                <woot-button
                    class-names="tmp_submit"
                    :isDisabled="isLoading"
                    :isLoading="isLoading"
                    @click="addTemplateMessage"
                >
                    {{$t("MESSAGE_MGMT.ADD.BTNS.SUBMIT")}}
                </woot-button>
                <woot-button class-names="tmp_submit" variant="clear" @click="onClose">
                {{$t("MESSAGE_MGMT.ADD.BTNS.CANCEL")}}
                </woot-button>
            </aside>

        </div>
    </div>
  </modal>
</template>

<script>
import Modal from '../../../../components/Modal.vue';
import alertMixin from 'shared/mixins/alertMixin';
import WootButton from '../../../../components/ui/WootButton.vue';
import { required, minLength ,maxLength} from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import moment from "moment";
import RadioList from "../../arrange/RadioList.vue"
import TemplateMsgAlert from "./TemplateMsgAlert.vue"
import LANGUAGES from '../canned/templateLanguages';
import TEMPLATE_TYPES from '../canned/templateTypes';

const templateNameValidator = (value) =>{
    const regexPattern = /^[a-z0-9]+$/;
    return regexPattern.test(value);
}


export default {
    components: {
        Modal,
        WootButton,
        RadioList,
        TemplateMsgAlert,
    },
    mixins: [alertMixin],
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        onClose: {
            type: Function,
            default: () => {},
        },
        onCreateSuccess: {
            type: Function,
            default: () => {},
        },
    },
    data(){
        return {
            isLoading:false,
            // form fields
            templateName: '',
            content: '',
            file: '',
            filePreviewUrl:"",
            buttonsData: [],

            // other var
            isVerifyVisible: true,
            selectedInbox: null,
            selectedLanguage: null,
            LANGUAGES,
            selectedType: null,
            TEMPLATE_TYPES,
            selectedActionBtn:null,
            selectedActionBtnList:[],
            actionBtnOptions:[],
            attachmentList:[
                // {
                //     id:"exist_attachement",
                //     label:this.$t('MESSAGE_MGMT.ADD.FORM.ATTACH_EXTIST'),
                // },
                {
                    id:"new_attachement",
                    label:this.$t('MESSAGE_MGMT.ADD.FORM.ATTACH_NEW'),
                },
            ],
            selectedAttachType: null,
            selectedAttachRadio: {},
        }
    },
    computed:{
        ...mapGetters({
            inboxes: 'inboxes/getInboxes',
        }),
        inboxListWhatsApp(){
            return this.inboxes.filter(inbox => inbox.channel_type === 'Channel::Whatsapp')
        },
        fileType(){
            if (!this.file) {
                return{
                    isImage: false,
                    isVideo: false,
                    isPDF: false
                };
            }
            const isImage = this.file?.type?.startsWith('image');
            const isVideo = this.file?.type?.startsWith('video/mp4');
            const isPDF = this.file?.type?.startsWith('application/pdf');
            console.log({
                type: this.file.type,
                isImage,
                isPDF,
                isVideo,
            });

            return {
                isImage,
                isVideo,
                isPDF,
            }
        },
        // end computed
    },
    validations: {
        templateName: {
            required,
            minLength: minLength(2),
            templateNameValidator,
        },
        content: {
            required,
            minLength: minLength(1),
            maxLength: maxLength(1024),
        },
        /*
        language: {
            required,
        },
        */
       selectedInbox: {
           required,
       },
       selectedLanguage: {
           required,
       },
       selectedType: {
           required,
       },
    },
    methods:{
        async addTemplateMessage() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            let data = {
                short_code: this.templateName,
                content: this.content,
                inbox_id: this.selectedInbox?.id,
                file: this.file,
                buttonsData: this.selectedActionBtnList,
                language: this.selectedLanguage?.value,
                templateType: this.selectedType?.value,
            };
            this.isLoading = true;

            let formData = new FormData();
            Object.entries(data).forEach(([key, value]) => {
                if (key === 'buttonsData') {
                    formData.append(key, JSON.stringify(value));
                } else {
                    formData.append(key, value);
                }
            });
            await this.$store
            .dispatch('accounts/createTemplateMessage', formData)
            .then(() => {
                // clear the fields
                this.templateName = "";
                this.content = "";
                this.selectedInbox = null;
                this.file = "";
                this.selectedActionBtnList = [];
                this.selectedLanguage = null;
                this.selectedType = null;

                this.isLoading = false;
                this.showAlert(this.$t('TEMPLATE_MSG.ADD.API.SUCCESS_MESSAGE'));
                this.onCreateSuccess();

            })
            .catch(error => {
                this.isLoading = false;
                this.showAlert(error.response.data.message);
            });
        },
        getCurrentTime() {
            const now = moment();
            return now.format('h:mm A'); // 8:04 AM
        },
        onCloseVerifyAlert() {
            this.isVerifyVisible = false;
        },
        addNewButtonAction(newTag){
            const tag = {
                name: newTag,
                code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
            };
            this.actionBtnOptions.push(tag);
            this.selectedActionBtnList.push(tag);
        },
        handleImageUpload(event) {
            this.file = event.target.files[0];

            if (this.file.size > 1024 * 1024 * 14) {
                alert('File is too big. Please select file of size less then 14mb.');
                this.$refs.file.value = null;
                this.file = '';
                this.filePreviewUrl = '';
            }

            if (this.file) {
                if (this.file.type === 'video/mp4') {
                    // Create a URL for the video file
                    this.filePreviewUrl = URL.createObjectURL(this.file);
                }else{
                    // read for image and pdf
                    const reader = new FileReader();
                    reader.onload = (e) =>{
                    this.filePreviewUrl = e.target.result;
                    }
                    reader.readAsDataURL(this.file);
                }

            }
        },
        onAttachTypeSelect(index){
            // console.log(index);
            this.selectedAttachRadio = this.attachmentList[index].id;
        },
        // method end
    },

}
</script>

<style lang="scss" scoped>
.header{
    margin-left: 30px;
    .header_title{
        font-weight: 700;
        font-size: 20px;
        color: #1F1F1F;
    }
}
/*
.verify{
    position: relative;
    margin-top: 24px;
    padding: 12px;
    background-color: #F2E7FE;
    .title{
        font-weight: 700;
        font-size: 14px;
        color: #8D25F6;
    }
    .description{
        font-weight: 500;
        font-size: 12px;
        color: #1F1F1F;
    }
    .verify_close_btn{
        position: absolute;
        top: 0;
        right: 0;
        background-color: transparent;
    }
}
*/
.body{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: var(--space-two);
    margin-top: 20px;
}


.field{
    position: relative;
    // font-weight: 500;
    color: #1F1F1F;
    // font-size: var(--font-size-mini);
    margin-bottom: var(--space-large);
    input{
        margin-top: 8px;
        margin-bottom: 4px;
        padding: var(--space-one);
    }
    textarea{
        min-height: 160px;
        margin-bottom: 4px;
    }
    span{
        position: absolute;
        bottom: -21px;
        left: 0;
        line-height: 105%;
    }
    .err_message{
        color: var(--r-400);
        bottom: -20px;
    }
    .hint{
        color: #858585;
    }
    .tmp_select_wrapper{
        margin-top: var(--space-small);
    }
    .attachment{
         margin-top: 8px;
    }
}

// preview start
.col_preview{
    position: relative;
    min-height: 100%;
    // background: #00000082;
    background-image: url('../../../../assets/images/background/whatsapp_bg.jpeg');
    background-size: cover;
}
.col_preview::before{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.02);
}

.preview{
    .pre_header{
        padding: var(--space-slab);
        background-color: #F2E7FE;
        .title{
            font-weight: 700;
            font-size: var(--font-size-small);
            color: #8D25F6;
        }
    }
    .pre_message_box{
        margin: var(--space-two) var(--space-slab);
    }
}

.preview_card{
    position: relative;
    max-width: 90%;
    margin: 30px auto;
    background-color: #fff;
    padding: 4px;
    border-radius: 4px;
    max-width: 350px;
    min-width: 250px;

    .arrow_mark{
        width: 20px;
        height: 20px;
        background: #fff;
        position: absolute;
        top: 0;
        left: -15px;
        clip-path: polygon(0 0, 100% 100%, 100% 0);
    }

    .media{
        max-height: 220px;
        overflow: hidden;

        .file_view_wrapper{
            position: relative;
            width: 100%;
            height: 220px;

            img,video,iframe{
                position: absolute;
                width: 100%;
                height: auto;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
            }
        }
    }
    .content{
        position: relative;
        min-height: 10px;
        margin-bottom:  var(--space-small);
        padding: var(--space-one) var(--space-slab);
        p{
            white-space: pre-wrap;
            margin: 0;
        }
        .placeholder{
            color: #BBBBBB;
            font-size: var(--font-size-small);
            font-weight: 500;
        }
        time{
            position: absolute;
            width: 45px;
            height: 10px;
            right: 0;
            bottom: -5px;
            color: #8492a6;
        }
    }
    .buttons{

        .btn_wrapper{
            padding: 4px;

            a{
                display: block;
                text-align: center;
                padding: 5px;
                border-top: 1px solid #ebf0f5;
            }
        }
    }
}
// preview end

.submit_btns{
    // margin-top: 24px;
    .tmp_submit{
        margin-top: 16px;
    }
}
.file_upload{
    color: #304352;
}
.file_upload::file-selector-button{
    color: #304352;
}
</style>

<style lang="scss">
// globally apply
.modal_box_close_btn{
    left: 27px;
    right: auto;
    top: 30px;
    padding: 6px;
}
// .create_template_modal .verify .verify_close_btn{
//     color: #858585;
// }
// .create_template_modal .verify .verify_close_btn:hover{
//     color: #858585;
// }
// multiselect css start
.create_template_modal .multiselect__option{
    font-weight: 500;
}
.create_template_modal .multiselect__single{
    font-weight: 400;
    font-size: var(--font-size-small);
}
.create_template_modal .multiselect__tag{
    font-weight: 400;
    font-size: var(--font-size-small);
}
// multiselect css end


// radio css start
.create_template_modal .radio-item{
    height: 20px;
}
.create_template_modal .radio-item b{
    font-weight: 500;
    color: #304352;
}
.create_template_modal .radio-item input{
    border-color: #858585;
}
.create_template_modal .radio-item input:checked{
    border-color: #8D25F6;
}
// radio css end

.create_template_modal .tmp_submit{
    display: block;
    width: 100%;
}
.create_tpl_modal_container{
    border-radius: 20px;
    width: 812px;
    padding: 32px !important;

    .font_medium{
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
    }
    .font_large{
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
    }
}
</style>

