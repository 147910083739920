/* global axios */
import ApiClient from './ApiClient';

class UsageReportsAPI extends ApiClient {
  constructor() {
    super('top_up_billings', { accountScoped: true, apiVersion: 'v1' });
  }

  getReports(apiUrl, from, to) {
    return axios.post(`${this.url}/` + apiUrl, {
      start_date: from,
      end_date: to,
    });
  }
}

export default new UsageReportsAPI();
