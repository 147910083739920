import { frontendURL } from '../../../helper/URLHelper';
import ArrangeWrapper from './ArrangeWrapper.vue';
import BusinessTypes from './BusinessTypes.vue';

/*
  1) go to "config/sidebaritems/fileNameRoutes.js" and add the route in "menuItems"
  2) now add the Component will be render for the added router, Add it is here with child
   3) add this catelogue.routes.js file to '/dashboard/dashboard.routes.js' file to active this route
*/

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/arrange'),
      name: 'arrange_home',  // must match with 'toStateName'
      roles: ['administrator', ],
      component: ArrangeWrapper,
      props: () => {
        return {
          headerTitle: 'Business',
          icon: 'video-call',
          showBackButton: false,
         };
      },
      children: [
        {
          path: 'business-type',
          name: 'arrange_business_type',
          component: BusinessTypes,
          roles: ['administrator'],
        },
      ],

    },
  ],
};
