<template>
    <fragment>
        <!-- select inbox -->
        <DemoAutomotionModal :isOpen="isInboxSelectOpen" @onClose="closeAllPopup">
            <div>
                <woot-modal-header
                    :header-title="$t('INSTRUCTION_POPUP.INBOXES.HEADER')"
                    :header-content="$t('INSTRUCTION_POPUP.INBOXES.INTRO')"
                />
                <div class="popup_body">
                    <select @change="selectInboxHandler"  name="selectedInbox" id="selectedInbox">
                        <option value="" >{{$t('INSTRUCTION_POPUP.INBOXES.DEFAULT_INBOX_SELECT')}}</option>
                        <option v-for="inbox in inboxes" :key="inbox.id" :value="inbox.id">{{inbox.name}}</option>
                    </select>
                    <woot-submit-button
                        :button-text="$t('INSTRUCT_POPUP.SCHEDULING.BUTTONS.SKIP')"
                        @click="skipPopupHandler('isScheduleSkip')"
                        class="instruction_btn"
                    />
                </div>
            </div>
        </DemoAutomotionModal>

        <!-- steps for scheduling  -->
        <!-- add google account popup  -->
        <DemoAutomotionModal :isOpen="isScheduleOpen" @onClose="closeAllPopup">
            <div>
                <woot-modal-header
                    :header-title="$t('INSTRUCT_POPUP.SCHEDULING.GOOGLE_ACCOUNT_ADD.TITLE')"
                    header-content=""
                />
                <div class="popup_body">
                    <woot-submit-button
                        :button-text="$t('INSTRUCT_POPUP.SCHEDULING.BUTTONS.SKIP')"
                        @click="skipPopupHandler('isScheduleSkip')"
                        class="instruction_btn"
                    />
                </div>
            </div>
        </DemoAutomotionModal>

        <!-- step:5 schedule booking,confirm,cancel,reminder form  -->
        <DemoAutomotionModal :isOpen="isScheduleBookingForm" @onClose="closeAllPopup">
            <woot-modal-header
                :header-title="$t('INSTRUCT_POPUP.SCHEDULING.APPOINTMENT_MSG.TITLE')"
                header-content=""
            />
            <form class="row" @submit.prevent="scheduleTemplateMessageHandler">
                <div class="medium-12" v-for="(message,key) in schedule_bot_msg" :key="key">
                    <woot-input
                        v-model="schedule_bot_msg[key]"
                        :label="getScheduleMessageLabel(key)"
                        type="text"
                        :class="{ error: $v.schedule_bot_msg[key].$error }"
                        :error="$v.schedule_bot_msg[key].$error? $t('INSTRUCT_POPUP.SCHEDULING.APPOINTMENT_MSG.FIELD_VALIDATION_ERR_MSG'):''"
                        :placeholder="getScheduleMessagePlaceholder(key)"
                        @blur="$v.schedule_bot_msg[key].$touch"
                        @input="pageNavigator('calander_list')"
                    />
                </div>
                <div class="btn_group">
                    <woot-submit-button
                        :button-text="$t('INSTRUCT_POPUP.SCHEDULING.BUTTONS.SKIP')"
                        @click="skipPopupHandler('isScheduleSkip')"
                        class="instruction_btn"
                    />
                    <woot-submit-button
                        :button-text="$t('INSTRUCT_POPUP.SCHEDULING.BUTTONS.NEXT')"
                        class="instruction_btn"
                    />
                </div>
            </form>
        </DemoAutomotionModal>

        <!-- step:6 shceduling qualification questonds and attributes  -->
        <DemoAutomotionModal :isOpen="isScheduleQualificationQues" @onClose="closeAllPopup">
            <woot-modal-header
                :header-title="$t('INSTRUCT_POPUP.SCHEDULING.QUALIFICATION_QUESTIONS.TITLE')"
                :header-content="$t('INSTRUCT_POPUP.SCHEDULING.QUALIFICATION_QUESTIONS.HEADER_CONTENT')"
            />
            <div class="row form_padding">
                <div class="medium-12">
                    <woot-input
                        v-model="qualification_questions.question1"
                        :label="$t('INSTRUCT_POPUP.SCHEDULING.QUALIFICATION_QUESTIONS.QUESTION_LIST.QUES_LABEL_1')"
                        type="text"
                        :class="{ error: $v.qualification_questions.question1.$error }"
                        :error="$v.qualification_questions.question1.$error?  $t('INSTRUCT_POPUP.SCHEDULING.QUALIFICATION_QUESTIONS.QUESTION_LIST.ERR_LABEL_1'):''"
                        :placeholder="$t('INSTRUCT_POPUP.SCHEDULING.QUALIFICATION_QUESTIONS.QUESTION_LIST.QUES_PLACEHOLDER_1')"
                        @blur="$v.qualification_questions.question1.$touch"
                        @input="pageNavigator('calander_list')"
                    />
                    <woot-input
                        v-model="qualification_questions.attribute1"
                        type="text"
                        :class="{ error: $v.qualification_questions.attribute1.$error }"
                        :error="$v.qualification_questions.attribute1.$error? $t('INSTRUCT_POPUP.SCHEDULING.QUALIFICATION_QUESTIONS.QUESTION_LIST.ERR_LABEL_1') :''"
                        :placeholder="$t('INSTRUCT_POPUP.SCHEDULING.QUALIFICATION_QUESTIONS.QUESTION_LIST.ATTRIBUTE_PLACEHOLDER_1')"
                        @blur="$v.qualification_questions.attribute1.$touch"
                        @input="pageNavigator('calander_list')"
                    />
                    <woot-input
                        v-model="qualification_questions.question2"
                        :label="$t('INSTRUCT_POPUP.SCHEDULING.QUALIFICATION_QUESTIONS.QUESTION_LIST.QUES_LABEL_2')"
                        type="text"
                        :class="{ error: $v.qualification_questions.question2.$error }"
                        :error="$v.qualification_questions.question2.$error? $t('INSTRUCT_POPUP.SCHEDULING.QUALIFICATION_QUESTIONS.QUESTION_LIST.ERR_LABEL_1') :''"
                        :placeholder="$t('INSTRUCT_POPUP.SCHEDULING.QUALIFICATION_QUESTIONS.QUESTION_LIST.QUES_PLACEHOLDER_2')"
                        @blur="$v.qualification_questions.question2.$touch"
                        @input="pageNavigator('calander_list')"
                    />
                    <woot-input
                        v-model="qualification_questions.attribute2"
                        type="text"
                        :class="{ error: $v.qualification_questions.attribute2.$error }"
                        :error="$v.qualification_questions.attribute2.$error?  $t('INSTRUCT_POPUP.SCHEDULING.QUALIFICATION_QUESTIONS.QUESTION_LIST.ERR_LABEL_1'):''"
                        :placeholder="$t('INSTRUCT_POPUP.SCHEDULING.QUALIFICATION_QUESTIONS.QUESTION_LIST.ATTRIBUTE_PLACEHOLDER_1')"
                        @blur="$v.qualification_questions.attribute2.$touch"
                        @input="pageNavigator('calander_list')"
                    />
                    <woot-input
                        v-model="qualification_questions.question3"
                        :label="$t('INSTRUCT_POPUP.SCHEDULING.QUALIFICATION_QUESTIONS.QUESTION_LIST.QUES_LABEL_3')"
                        type="text"
                        :class="{ error: $v.qualification_questions.question3.$error }"
                        :error="$v.qualification_questions.question3.$error? $t('INSTRUCT_POPUP.SCHEDULING.QUALIFICATION_QUESTIONS.QUESTION_LIST.ERR_LABEL_1') :''"
                        :placeholder="$t('INSTRUCT_POPUP.SCHEDULING.QUALIFICATION_QUESTIONS.QUESTION_LIST.QUES_PLACEHOLDER_3')"
                        @blur="$v.qualification_questions.question3.$touch"
                        @input="pageNavigator('calander_list')"
                    />
                    <woot-input
                        v-model="qualification_questions.attribute3"
                        type="text"
                        :class="{ error: $v.qualification_questions.attribute3.$error }"
                        :error="$v.qualification_questions.attribute3.$error? $t('INSTRUCT_POPUP.SCHEDULING.QUALIFICATION_QUESTIONS.QUESTION_LIST.ERR_LABEL_1') :''"
                        :placeholder="$t('INSTRUCT_POPUP.SCHEDULING.QUALIFICATION_QUESTIONS.QUESTION_LIST.ATTRIBUTE_PLACEHOLDER_1')"
                        @blur="$v.qualification_questions.attribute3.$touch"
                        @input="pageNavigator('calander_list')"
                    />
                </div>
                <div class="btn_group">
                    <woot-submit-button
                        :button-text="$t('INSTRUCT_POPUP.SCHEDULING.BUTTONS.SKIP')"
                        @click="skipPopupHandler('isScheduleSkip')"
                        class="instruction_btn"
                    />
                    
                    <button 
                        class="instruction_btn button nice" 
                        @click="schedulingQualificationSubmit"
                        :disabled="isScheduleInstructLoading"
                    >
                        <span v-if="isScheduleInstructLoading">
                            <spinner :size="small" />
                        </span>
                        {{$t('INSTRUCT_POPUP.SCHEDULING.BUTTONS.SUBMIT')}}
                    </button>
                </div>
            </div>
        </DemoAutomotionModal>

        <!-- scheduling path success popup message  -->
        <DemoAutomotionModal :isOpen="isScheduleSuccessPopupOpen" @onClose="closeAllPopup">
            <div>
                <woot-modal-header
                    :header-title="$t('INSTRUCT_POPUP.SCHEDULING.SUCCESS_MSG.TITLE')"
                    :header-content="$t('INSTRUCT_POPUP.SCHEDULING.SUCCESS_MSG.HEADER_CONTENT')"
                />
                <div class="popup_body">
                    <woot-submit-button
                        :button-text="$t('INSTRUCT_POPUP.SCHEDULING.BUTTONS.OK')"
                        @click="closeAllPopup"
                        class="instruction_btn"
                    />
                </div>
            </div>
        </DemoAutomotionModal>

        <!-- steps for lead qualification  -->
        <!-- [lead]step: 2 create template message -->
        <DemoAutomotionModal :isOpen="isLeadTemplateMsgOpen" @onClose="closeAllPopup">
            <div class="modal_content primary_bg_shade transparent_body overflow_visiable lead_template">
                <div class="octopus_img">
                    <img :src="onboardingBgs.octopusComputerImg" alt="">
                </div>
                <woot-modal-header
                    :header-title="$t('INSTRUCT_POPUP.LEAD_QUALIFICATION.TEMPLATE_MSG.HEAD_TITLE')"
                    :header-content="$t('INSTRUCT_POPUP.LEAD_QUALIFICATION.TEMPLATE_MSG.HEAD_CONTENT')"
                />
                <div class="popup_body text-center">
                    <div class="medium-12 columns">
                        <label>
                            <input  v-model.trim="lead_temphelloMsg" :style="{ outline: '1px solid var(--s-200)' }"  type="text" />
                        </label>
                    </div>
                    <div class="medium-12 columns">
                        <label>
                            <input ref="lead_temphelloMsgCheckBoxRef" type="checkbox" name="" id="">
                            <!-- I want this message to be automatically to all new contacts -->
                            {{$t('INSTRUCT_POPUP.LEAD_QUALIFICATION.TEMPLATE_MSG.AUTOMATE_TEXT')}}
                        </label>
                    </div>
                    <div class="btns_center">
                        <woot-submit-button
                            :button-text="$t('INSTRUCT_POPUP.SCHEDULING.BUTTONS.SKIP')"
                            @click="skipPopupHandler('isLeadQualificationSkip')"
                            class="instruction_btn"
                        />
                        <woot-submit-button
                            :button-text="$t('INSTRUCT_POPUP.SCHEDULING.BUTTONS.NEXT')"
                            @click="leadTemplateMsgHandler"
                            class="instruction_btn"
                            :loading="isLeadInstructLoading"
                            :disabled="isLeadInstructLoading"
                        />
                        
                    </div>
                </div>
            </div>
        </DemoAutomotionModal> 
        <!-- [lead]step: 3.1 create lead qualification questions -->
        <DemoAutomotionModal :isOpen="isLeadQualificationQuestionOpen" @onClose="closeAllPopup">
            <div class="modal_content primary_bg_shade transparent_body overflow_visiable lead_qualification lead_content text-center">
                <div class="octopus_img">
                    <img :src="onboardingBgs.octopusTodoImg" alt="">
                </div>
                <div>
                    <h2 class="lead_sub_title color_white">{{$t('INSTRUCT_POPUP.LEAD_QUALIFICATION.LEAD_QUESTIONS.HEAD_TITLE')}}</h2>
                    <p>{{$t('INSTRUCT_POPUP.LEAD_QUALIFICATION.LEAD_QUESTIONS.HEAD_CONTENT')}}</p>
                </div>

                <div class="popup_body">
                    <div>
                        <div v-for="(questionSet,index) in lead_qualificationQuestions" :key="questionSet.question" class="leadQuestionSet center lead_card relative">
                            <div class="black_field">
                                <label class="question_label">
                                    <p class="font_normal black_field">{{$t('INSTRUCT_POPUP.LEAD_QUALIFICATION.LEAD_QUESTIONS.QUESTION_LABELES.CARD_TITLE')}}</p>
                                    <p class="font_normal bg_white field">{{questionSet.title}}</p>
                                </label>
                                <label class="question_label">
                                    <p class="font_normal black_field">{{$t('INSTRUCT_POPUP.LEAD_QUALIFICATION.LEAD_QUESTIONS.QUESTION_LABELES.QUESTION_LABEL')}}</p>
                                    <p class="font_normal bg_white field">{{questionSet.question}}</p>
                                </label>
                                <label class="font_normal black_field">{{$t('INSTRUCT_POPUP.LEAD_QUALIFICATION.LEAD_QUESTIONS.QUESTION_LABELES.OPTION_LABEL')}}</label>
                                <div class="multiselect multi_options">
                                    <span 
                                        class="multiselect__tag font_normal bg_white field" 
                                        v-for="option in questionSet.options" 
                                        :key="option.name"
                                    >{{option.name}}</span>
                                </div>
                                <label class="font_normal black_field">
                                    <!-- Answer from the options to qualify the contact -->
                                    {{$t('INSTRUCT_POPUP.LEAD_QUALIFICATION.LEAD_QUESTIONS.QUESTION_LABELES.ACCEPTABLE_OPTION_LABEL')}}
                                </label>
                                <div class="multiselect multi_options">
                                    <span 
                                        class="multiselect__tag font_normal bg_white field"
                                        v-for="option in questionSet.acceptableOptions" 
                                        :key="option.name"
                                    >{{option.name}}</span>
                                </div>
                            </div>
                            <div class="horizontal_center" :style="{right:0,}">
                                <button class="modal--close" style="position:relative;" @click="removeLeadQuestion(index)">
                                    <fluent-icon icon="dismiss" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <!-- lead question input  -->
                    <div class="lead_question_add_card lead_card" v-if="notLeadQualificationQuestionsLimitFull">
                        <div class="medium-12 columns black_field">
                            <p>{{$t('INSTRUCT_POPUP.LEAD_QUALIFICATION.LEAD_QUESTIONS.QUESTION_LABELES.CARD_TITLE')}}</p>
                                <!-- :label="$t('INSTRUCT_POPUP.LEAD_QUALIFICATION.LEAD_QUESTIONS.QUESTION_LABELES.CARD_TITLE')" -->
                            <woot-input
                                v-model="leadQualificationQuestion.title"
                                type="text"
                                class="font_normal_wootInput"
                                :class="{ error: $v.leadQualificationQuestion.title.$error }"
                                :error="$v.leadQualificationQuestion.title.$error? 'Title is required and should not exceed 13 characters':''"
                                @blur="$v.leadQualificationQuestion.title.$touch"
                            />
                        </div>
                        <div class="medium-12 columns black_field">
                            <!-- label="Type in your qualification question:" -->
                            <p>{{$t('INSTRUCT_POPUP.LEAD_QUALIFICATION.LEAD_QUESTIONS.QUESTION_LABELES.QUESTION_LABEL')}}</p>
                                <!-- :label="$t('INSTRUCT_POPUP.LEAD_QUALIFICATION.LEAD_QUESTIONS.QUESTION_LABELES.QUESTION_LABEL')" -->
                            <woot-input
                                v-model="leadQualificationQuestion.question"
                                type="text"
                                class="font_normal_wootInput"
                                :class="{ error: $v.leadQualificationQuestion.question.$error }"
                                :error="$v.leadQualificationQuestion.question.$error? 'Question is required':''"
                                @blur="$v.leadQualificationQuestion.question.$touch"
                            />
                        </div>
                        <!-- multi button options  -->
                        <div class="medium-12 columns">
                            <label class="multiselect_label" :class="{ error: $v.leadQualificationQuestion.options.$error }">
                                <!-- <span class="font_normal">What are the selectable options for your questions</span> -->
                                <span class="font_normal black_field">{{$t('INSTRUCT_POPUP.LEAD_QUALIFICATION.LEAD_QUESTIONS.QUESTION_LABELES.OPTION_LABEL_INPUT')}}</span>
                                <multiselect
                                    v-model="leadQualificationQuestion.options"
                                    class="small-12 font_multi_buttons"
                                    :tag-placeholder="$t('TEMPLATE_MSG.ADD.FORM.BUTTON.LABEL')"
                                    :placeholder="$t('TEMPLATE_MSG.ADD.FORM.BUTTON.PLACEHOLDER')"
                                    label="name"
                                    :max="3"
                                    track-by="code"
                                    :options="leadQualificationQuestion.buttonsData"
                                    :multiple="true"
                                    :taggable="true"
                                    @tag="addButton"
                                    @remove="leadOptionRemoveHandle"
                                />
                                <span v-if="$v.leadQualificationQuestion.options.$error" class="multi_err font_normal message">
                                    <!-- min 2 and max 3 options are required -->
                                    {{$t('INSTRUCT_POPUP.LEAD_QUALIFICATION.LEAD_QUESTIONS.QUESTION_LABELES.OPTION_ERR_MSG')}}
                                </span>
                            </label>
                        </div>
                        
                        <!-- acceptable options input  -->
                        <div class="medium-12 columns">
                            <label class="multiselect_label" :class="{ error: $v.leadQualificationQuestion.acceptableOptions.$error }">
                                <!-- <span class="font_normal">Pick an answer from the options to qualify the contact</span> -->
                                <span class="font_normal black_field">{{$t('INSTRUCT_POPUP.LEAD_QUALIFICATION.LEAD_QUESTIONS.QUESTION_LABELES.ACCEPTABLE_OPTION_INPUT')}}</span>
                                <multiselect
                                    v-model="leadQualificationQuestion.acceptableOptions"
                                    class="small-12 font_multi_buttons"
                                    :tag-placeholder="$t('TEMPLATE_MSG.ADD.FORM.BUTTON.LABEL')"
                                    :placeholder="$t('TEMPLATE_MSG.ADD.FORM.BUTTON.PLACEHOLDER')"
                                    label="name"
                                    :max="leadQualificationQuestion.options.length - 1"
                                    track-by="code"
                                    :options="leadQualificationQuestion.options"
                                    :multiple="true"
                                    :taggable="true"
                                    @select="selectAcceptableButton"
                                    @tag="addAcceptableButton"
                                />
                                <span v-if="acceptableOptions_err" class="multi_err font_normal message error_cls">{{acceptableOptions_err}}</span> 
                                <span v-else-if="$v.leadQualificationQuestion.acceptableOptions.$error" class="multi_err font_normal message error_cls">{{$t('INSTRUCT_POPUP.LEAD_QUALIFICATION.LEAD_QUESTIONS.QUESTION_LABELES.ACCEPTABLE_OPTION_ERR')}}</span> 
                                <!-- <span v-else-if="$v.leadQualificationQuestion.acceptableOptions.$error" class="multi_err font_normal message error_cls">Select at least one and at most one less than the selectable options.</span>  -->

                            </label>
                        </div>
                        <div class="medium-12 columns">
                            <button class="modal--close leadQuestionAddBtn" @click="addMoreQualificationField">
                                <fluent-icon icon="add" />
                            </button>
                        </div>
                    </div>
                    <div class="btns_center">
                        <woot-submit-button
                            :button-text="$t('INSTRUCT_POPUP.SCHEDULING.BUTTONS.SKIP')"
                            @click="skipPopupHandler('isLeadQualificationSkip')"
                            class="instruction_btn"
                        />
                        <woot-submit-button
                            :button-text="$t('INSTRUCT_POPUP.SCHEDULING.BUTTONS.NEXT')"
                            @click="leadQualificationQuesHandler"
                            class="instruction_btn"
                            :disabled="isLeadInstructLoading"
                            :loading="isLeadInstructLoading"
                        />
                    </div>
                </div>
            </div>
        </DemoAutomotionModal> 
        <!-- [lead]step: 3.2 create teams agent distributor -->
        <DemoAutomotionModal :isOpen="isLeadRulesOpen" @onClose="closeAllPopup">
            <div>
                    <!-- header-title="Step 5: Set your agents and teams" -->
                <woot-modal-header
                    :header-title="$t('INSTRUCT_POPUP.LEAD_QUALIFICATION.TEAM_AGENT.HEAD_TITLE')"
                    header-content=""
                />
                <div class="popup_body">
                    <!-- distribution options -->
                    <div class="medium-12 columns">
                        <!-- <p class="font_normal">How will the conversations be distributed amongst your agents?</p> -->
                        <p class="font_normal">{{$t('INSTRUCT_POPUP.LEAD_QUALIFICATION.TEAM_AGENT.DISTRIBUTION.LABEL')}}</p>
                        <p class="territorial_rules">
                            <span>
                                <input 
                                    v-model="leadDistributionRuleType" 
                                    type="radio"
                                    value="equally"
                                    name="distributionRule"
                                />
                            </span>
                            <!-- <span class="font_normal">Divided equally so that each agent receive equal number of conversations</span> -->
                            <span class="font_normal">{{$t('INSTRUCT_POPUP.LEAD_QUALIFICATION.TEAM_AGENT.DISTRIBUTION.EQUALLY')}}</span>
                        </p>
                        <p class="territorial_rules">
                            <span>
                                <input 
                                    v-model="leadDistributionRuleType" 
                                    type="radio" 
                                    value="territorial"
                                    name="distributionRule"
                                />
                            </span>
                            <span class="font_normal">{{$t('INSTRUCT_POPUP.LEAD_QUALIFICATION.TEAM_AGENT.DISTRIBUTION.TERITORIAL')}}</span>
                        </p>
                    </div>

                    <!-- territorial rules  -->
                    <div v-if="leadDistributionRuleType === 'territorial'" class="teritorial_container">
                        <!-- <h2>Details of the teritorial rule</h2> -->
                        <h2>{{$t('INSTRUCT_POPUP.LEAD_QUALIFICATION.TEAM_AGENT.RULE.TITLE')}}</h2>
                        <div class="medium-12 columns">
                            <p class="font_normal">
                                <!-- Specify the question the chatbot will ask to obtain the attribute?  -->
                                {{$t('INSTRUCT_POPUP.LEAD_QUALIFICATION.TEAM_AGENT.RULE.QUESTION')}}
                                <woot-input
                                    v-model="teritorialRule.question"
                                    type="text"
                                    class="font_normal"
                                    :class="{ error: $v.teritorialRule.question.$error }"
                                    :error="$v.teritorialRule.question.$error? $t('INSTRUCT_POPUP.LEAD_QUALIFICATION.TEAM_AGENT.RULE.QUESTION_ERR_MSG'):''"
                                    @blur="$v.teritorialRule.question.$touch"
                                />
                            </p>
                        </div>
                        <div class="medium-12 columns">
                            <p class="font_normal">
                                <!-- Which attribute will we use to decide conversation assignment? -->
                                {{$t('INSTRUCT_POPUP.LEAD_QUALIFICATION.TEAM_AGENT.RULE.ATTRIBUTE')}}
                                <woot-input
                                    v-model="teritorialRule.attribute"
                                    type="text"
                                    class="font_normal"
                                    :class="{ error: $v.teritorialRule.attribute.$error }"
                                    :error="$v.teritorialRule.attribute.$error?  $t('INSTRUCT_POPUP.LEAD_QUALIFICATION.TEAM_AGENT.RULE.ATTRIBUTE_ERR_MSG'):''"
                                    @blur="$v.teritorialRule.attribute.$touch"
                                />
                            </p>
                        </div>
                        <div class="medium-12 columns  leadQuestionSet lead_card" v-for="(option, index) in teritorialRule.options" :key="index" style="display:flex;gap:0.7rem">
                            <div>
                                <p class="lead_team_attribute font_normal">
                                    <!-- When  -->
                                    {{$t('INSTRUCT_POPUP.LEAD_QUALIFICATION.TEAM_AGENT.RULE.TEAM_PART_WHEN')}}
                                    <span>{{teritorialRule.attribute ? teritorialRule.attribute : 'attribute'}}</span>
                                    <!-- is -->
                                    {{$t('INSTRUCT_POPUP.LEAD_QUALIFICATION.TEAM_AGENT.RULE.TEAM_PART_IS')}}
                                    <span class="team_attribute_wrap">
                                        <input 
                                            v-model="option.label" 
                                            type="text" 
                                            @input="teritorialRuleInputHandler"
                                            @blur="handleLabelBlur(index)" 
                                        />
                                        <span v-if="hasTeritorialLabelError(index)" class="team_attr_err">
                                            {{$t('INSTRUCT_POPUP.LEAD_QUALIFICATION.TEAM_AGENT.RULE.ATTRIBUTE_ERR')}}
                                        </span>
                                    </span>
                                    <!-- assign to team -->
                                    {{$t('INSTRUCT_POPUP.LEAD_QUALIFICATION.TEAM_AGENT.RULE.TEAM_PART_ASSIGN')}}
                                    <span class="team_attribute_wrap">
                                        <input 
                                            v-model="option.team" 
                                            type="text" 
                                            @input="teritorialRuleInputHandler"
                                            @blur="handleTeamBlur(index)" 
                                        />
                                        <span v-if="hasTeritorialTeamError(index)" class="team_attr_err">
                                            {{$t('INSTRUCT_POPUP.LEAD_QUALIFICATION.TEAM_AGENT.RULE.TEAM_ERR')}}
                                        </span>
                                    </span>
                                </p>
                            </div>
                            <div>
                                <button class="modal--close" style="position:relative;padding:0;" @click="removeTeritorialTeam(index)">
                                    <fluent-icon icon="dismiss" />
                                </button>
                            </div>
                        </div>
                        <p v-if="teritorialRule.option_err" class="error_cls font_normal">{{teritorialRule.option_err}}</p>
                        <!-- <p v-else-if="$v.teritorialRule.options.$error" class="error_cls font_normal">Minimum 1 and maximum 3 teams are allowed</p> -->
                        <p v-else-if="$v.teritorialRule.options.$error" class="error_cls font_normal"> {{$t('INSTRUCT_POPUP.LEAD_QUALIFICATION.TEAM_AGENT.RULE.COUNT_ERR')}}</p>
                        <div class="medium-12 columns" v-if="canAddNewTeamOption">
                            <button class="modal--close leadQuestionAddBtn" @click="addNewLeadTeamOption">
                                <fluent-icon icon="add" />
                            </button>
                        </div>
                    </div>
                    
                    <div>
                        <woot-submit-button
                            :button-text="$t('INSTRUCT_POPUP.SCHEDULING.BUTTONS.SKIP')"
                            @click="skipPopupHandler('isLeadQualificationSkip')"
                            class="instruction_btn"
                        />
                        <woot-submit-button
                            :button-text="$t('INSTRUCT_POPUP.SCHEDULING.BUTTONS.NEXT')"
                            @click="leadTeamsAndBotHandler"
                            class="instruction_btn"
                            :disabled="isLeadInstructLoading"
                            :loading="isLeadInstructLoading"
                        />
                    </div>
                </div>
            </div>
        </DemoAutomotionModal> 
        <!-- [lead]step: 4 lead path success popup message -->
        <DemoAutomotionModal :isOpen="isLeadSuccessOpen" @onClose="closeAllPopup">
            <div>
                <woot-modal-header
                    :header-title="$t('INSTRUCT_POPUP.LEAD_QUALIFICATION.SUCCESS.HEAD_TITLE')"
                    header-content=""
                />
                <p style="padding: 0 3.2rem;">
                    {{$t('INSTRUCT_POPUP.LEAD_QUALIFICATION.SUCCESS.PARA.PART_1')}} 
                    <a href="https://peasy.ai/docs/add-agents/" target="_blank">
                    {{$t('INSTRUCT_POPUP.LEAD_QUALIFICATION.SUCCESS.PARA.PART_2')}} 
                    </a> 
                    {{$t('INSTRUCT_POPUP.LEAD_QUALIFICATION.SUCCESS.PARA.PART_3')}}
                    <a href="https://peasy.ai/docs/add-team/" target="_blank">
                    {{$t('INSTRUCT_POPUP.LEAD_QUALIFICATION.SUCCESS.PARA.PART_4')}}
                    </a>.
                </p>
                <div class="popup_body">
                    <woot-submit-button
                        :button-text="$t('INSTRUCT_POPUP.SCHEDULING.BUTTONS.OK')"
                        @click="closeAllPopup"
                        class="instruction_btn"
                    />
                </div>
            </div>
        </DemoAutomotionModal>



        <!-- <DemoAutomotionModal :isOpen="isPopupOpen" @onClose="closeModalHandler"> -->
        <DemoAutomotionModal :isOpen="false" @onClose="closeModalHandler">
            <div>
                
                <!-- whatsapp inbox create  -->
                <!-- 
                    <div v-if="isWhatsAppInboxCreate">
                    <woot-modal-header
                        header-title="Add your whatsapp inbox here"
                        header-content=""
                    />
                    <woot-submit-button
                        button-text="Ok"
                        @click="closeWhatsAppinboxCreate"
                        class="instruction_btn"
                    />
                </div> 
                -->
                
                <!-- whatsapp add agent -->
                <!-- 
                <div v-if="isInboxAgentAdd">
                    <woot-modal-header
                        header-title="Add your agent to the inbox"
                        header-content=""
                    />
                    <woot-submit-button
                        button-text="Ok"
                        @click="closeInboxAgentAdd"
                        class="instruction_btn"
                    />
                </div>
                 -->

                <!-- Scheduling popup  -->
                <!-- <div v-if="isScheduleOpen">
                    <woot-modal-header
                        :header-title="scheduleMsg"
                        header-content=""
                    />

                    <woot-submit-button
                        :button-text="$t('INSTRUCT_POPUP.SCHEDULING.BUTTONS.SKIP')"
                        @click="skipPopupHandler('isScheduleSkip')"
                        class="instruction_btn"
                    />
                </div>  -->
                
            </div>
        </DemoAutomotionModal>

       </fragment>
</template>

<script>
import { mapGetters } from 'vuex';
import DemoAutomotionModal from "./DemoAutomotionModal.vue";
import {demoAutoSteps,getContactAttributes,getUserAttributes,updateDemoOnBoardDataLayer,instructSteps,popupHelpers,popupApiHelper,incrementUserStepAttributes, updateUserAttributes} from "../../../helper/demoAutomationHelper";
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import alertMixin from 'shared/mixins/alertMixin';
import AccountAPI from '../../../api/account';
import FlowAPI from '../../../routes/dashboard/settings/bot/FlowApi';
import Spinner from '../../../../shared/components/Spinner.vue';

const leadQualificationQuestionValidations = {
  title: {
    required,
    minLength: minLength(1),
    maxLength: maxLength(13),
  },
  question: {
    required,
    minLength: minLength(2),
  },
  options: {
    minLength: (value) => {
      if (Array.isArray(value)) {
        return value.length >= 2;
      }
      return false;
    },
    maxLength: (value) => {
      if (Array.isArray(value)) {
        return value.length <= 3;
      }
      return false;
    },
  },
  acceptableOptions: {
    minLength: (value, vm) => {
      if (Array.isArray(value)) {
        const options = vm.options;
        return value.length >= 1 && value.length < options.length;
      }
      return false;
    },
  },
};

const teritorialRuleValidator = {
    question: {
        required,
        minLength: minLength(2)
    },
    attribute:{
        required,
        minLength: minLength(1)
    },
    options: {
        required,
        minLength: minLength(1),
        maxLength: (value)=>{
            if (Array.isArray(value)) {
                return value.length <= 3;
            }
            return false;
        },
        $each:{
            label:{
                required: true,
                minLength: minLength(1),
            },
            team:{
                required: true,
                minLength: minLength(1),
            },
        },
    }
}


export default {
    components:{
        DemoAutomotionModal,
        Spinner,
    },
    mixins: [alertMixin],
    props:{},
    data(){
        return {
            isPopupOpen:false,
            isPopupClosedByUser: false,
            // inbox create 
            // isWhatsAppInboxCreate: false,
            // isInboxAgentAdd: false,

            // select inbox
            isInboxSelectOpen: false,

            // scheduling states
            isScheduleOpen: false,
            isScheduleBookingForm: false,
            isScheduleQualificationQues: false,
            isScheduleSuccessPopupOpen:false,
            isScheduleInstructLoading: false,

            // schedule Booking template messages
            schedule_bot_msg:{
                confirm_msg: this.$t('INSTRUCT_POPUP.SCHEDULING.APPOINTMENT_MSG.CONFIRM.MESSAGE'),
                reschedule_msg: this.$t('INSTRUCT_POPUP.SCHEDULING.APPOINTMENT_MSG.RESCHEDULE.MESSAGE'),
                reminder_msg: this.$t('INSTRUCT_POPUP.SCHEDULING.APPOINTMENT_MSG.REMINDER.MESSAGE'),
                accept_msg: this.$t('INSTRUCT_POPUP.SCHEDULING.APPOINTMENT_MSG.ACCEPT.MESSAGE'),
            },
           qualification_questions:{
                question1: "",
                attribute1: "",
                question2: "",
                attribute2: "",
                question3: "",
                attribute3: "",
            },

            // lead qualification states 
            isLeadTemplateMsgOpen: false,
            isLeadQualificationQuestionOpen: false,
            isLeadRulesOpen: false,
            isLeadSuccessOpen: false,
            isLeadInstructLoading: false,
            lead_temphelloMsg:"Hi {{contact.name}}, thanks for contacting Peasy",
            leadQualificationQuestion:{
                title:"",
                question:"",
                buttonsData: [
                    // {name:"button1",code: '"button1"'},
                ],
                options:[
                    // {name:"button1",code: "button1"},
                ],
                acceptableOptions:[],
                acceptableSuggestions:[
                    // 'button1',
                ],
                acceptableOptions_err: null,
            },
            lead_qualificationQuestions:[
                /*
                {
                    question_id: 'q_1',
                    question:"How much is your budget?.",
                    options:[
                        {name:"button1",code: "button1"},
                        {name:"button2",code: "button2"},
                        {name:"button3",code: "button3"},
                    ],
                    acceptableOptions:[
                        {name:"button1",code: "button1"},
                        {name:"button3",code: "button3"},
                    ],
                },
                */
            ],
            
            leadDistributionRuleType: 'equally',   //'territorial'

            teritorialRule:{
                question:"",
                attribute:"language",
                options: [ 
                    {label:"English", team:"English Sales Team",team_id:null}, 
                    // {label:"Tamil", team: "Tamil Sales Team",team_id:null}
                ],
                option_err: null,
            },
        }
    },
    validations: {
        schedule_bot_msg: {
            reschedule_msg: {
                required,
                minLength: minLength(2),
            },
            confirm_msg: {
                required,
                minLength: minLength(2),
            },
            accept_msg: {
                required,
                minLength: minLength(2),
            },
            reminder_msg: {
                required,
                minLength: minLength(2),
            },
        },
        qualification_questions:{
            question1: {
                required,
                minLength: minLength(2),
            },
            attribute1: {
                required,
                minLength: minLength(2),
            },
            question2: {
                required,
                minLength: minLength(2),
            },
            attribute2: {
                required,
                minLength: minLength(2),
            },
            question3: {
                required,
                minLength: minLength(2),
            },
            attribute3: {
                required,
                minLength: minLength(2),
            },
        },
        leadQualificationQuestion: leadQualificationQuestionValidations,
        teritorialRule: teritorialRuleValidator,
        
    },
    computed:{
        ...mapGetters({
            currentUser: 'getCurrentUser',
            inboxes: 'inboxes/getInboxes',
        }),

        // controll lead qualification v-if
        notLeadQualificationQuestionsLimitFull(){
            return this.lead_qualificationQuestions?.length < 3;
        },
        hasSuggestLeadOption(){
            return this.leadQualificationQuestion.acceptableSuggestions?.length;
        },
        /*
        hasLeadOptionInput(){
            return this.leadQualificationQuestion.options?.length < 3;
        },
        hasLeadAcceptableOptionInput(){
            return (this.leadQualificationQuestion.acceptableOptions?.length + 1) < this.leadQualificationQuestion.options?.length;
        },
        */
        canAddNewTeamOption(){
            return this.teritorialRule.options?.length < 3;
        },
        hasTeritorialLabelError() {
            return (index) => {
                return (
                    // this.$v.teritorialRule.options[index] &&
                    // this.$v.teritorialRule.options[index].label &&
                    this.$v.teritorialRule.options[index]?.label?.$error
                );
            };
        },
        hasTeritorialTeamError() {
            return (index) => {
                return (
                    this.$v.teritorialRule.options[index]?.team?.$error
                );
            };
        },
        onboardingBgs(){
            const octopusComputerImg = require('dashboard/assets/images/banners/octopus-computer.png');
            const octopusTodoImg = require('dashboard/assets/images/banners/octopus-todo.png');
            return {
                octopusComputerImg,
                octopusTodoImg,
            }
        }
    },
    watch:{
        currentUser:{
            immediate: true,
            handler(newValue) {
                // this.checkModalOpen(newValue);
                this.checkPopupConditions(newValue);
            },
        },
        // Watch for changes in route to determine when to open the modal
        $route(to,from) {
            // this.checkModalOpen(null,to.path);
            this.checkPopupConditions();
        },
    },
    mounted(){
        // this.checkModalOpen();
        this.checkPopupConditions();
    },
    methods:{
        closeModalHandler(){
            this.isPopupOpen = false;
            // updateDemoOnBoardDataLayer({isClosedByUser:true});
        },
        closeAllPopup(){
            // inbox select
            this.isInboxSelectOpen = false;

            // schedule popups
            this.isPopupOpen = false;
            this.isScheduleOpen = false;
            this.isScheduleBookingForm = false;
            this.isScheduleQualificationQues = false;
            this.isScheduleSuccessPopupOpen = false;

            // lead popups
            this.isLeadTemplateMsgOpen = false;
            this.isLeadQualificationQuestionOpen = false;
            this.isLeadRulesOpen = false;
            this.isLeadSuccessOpen = false;
        },

        // methods to control scheduling popups
        async checkPopupConditions(){
            // this.closeAllPopup();
            if (!this.currentUser.id) {
                return;
            }

            if (!this.inboxes.length) {
                // wait to populate vuex store of inboxes
                await popupHelpers.sleep(2000);   
            }
            
            const {schedulingSteps,leadQualificationSteps:leadSteps} = instructSteps;

            // [schedule]: check to open google account coonect
            if (popupHelpers.isSchedulePopupOpen(schedulingSteps.path,schedulingSteps.selectInboxIdStep, schedulingSteps.localStorageSkipKey)) {
               const {isConnected} = await popupApiHelper.getGoogleCalander()
               if (isConnected) {
                    // increase the step that google is connected
                    const {isError:isStepErr,data:stepData,errMsg:stepErrMsg} = await incrementUserStepAttributes(schedulingSteps.connectGoogleAccount);
                        if (isStepErr) {
                            this.showAlert(stepErrMsg);
                        }
                    // navigate to other route 
                    const {isInstructPopupOpen,url: popupRedirectUrl} =await popupHelpers.navigateToPage();
                    if (isInstructPopupOpen) {
                        this.$router.push(popupRedirectUrl);
                    }
                }else{
                    this.isPopupOpen = true;
                    this.isScheduleOpen = true;
               }
           }else if (popupHelpers.isSchedulePopupOpen(schedulingSteps.path,schedulingSteps.connectGoogleAccount, schedulingSteps.localStorageSkipKey)) {
                // [schedule]: open bookign message form
               this.isPopupOpen = true;
               this.isScheduleBookingForm = true;
           }else if (popupHelpers.isSchedulePopupOpen(leadSteps.path,leadSteps.selectInboxIdStep,leadSteps.localStorageSkipKey)) {
            // [lead]:2 template message 
            this.closeAllPopup();
            
            // if inbox not exist, fit select inbox, then show this popup
            if (!this.checkIfInboxIdExist()) {
                this.isInboxSelectOpen = true;
            }else if(this.checkIfInboxIdExist()){
                this.isLeadTemplateMsgOpen = true;
            }
            
           }else if (popupHelpers.isSchedulePopupOpen(leadSteps.path,leadSteps.template_msg,leadSteps.localStorageSkipKey)) {
               // [lead]:3 lead qualification questions + conversation questions
                this.isLeadQualificationQuestionOpen = true;
           }

        },

        skipPopupHandler(propertyName){
            // return; // temporary return here, remove this later

            // skip in local storage & custom attributes
            updateUserAttributes('skip','true');
            popupHelpers.setSkipInStorage(propertyName,'true');
            this.closeAllPopup();
        },

        checkIfInboxIdExist(){
            const {inbox_id} = getUserAttributes();
            const isExist = this.inboxes.some(inbox=> inbox.id === parseInt(inbox_id));
            console.log({isInboxIdExist: isExist, attributeInbox: inbox_id, inbList: this.inboxes});
            return isExist;
        },
        async selectInboxHandler(event){
            const selectedInboxId = event.target.value;
            // save it to user.custom attribute
            const {isError:isInboxErr,data,errMsg:inboxErrMsg} = await updateUserAttributes('inbox_id', selectedInboxId);
            if (isInboxErr){
                this.showAlert(inboxErrMsg);
            } else{
                this.closeAllPopup();
                // check if any next popup should open
                this.checkPopupConditions();
            }
        },

        
        scheduleTemplateMessageHandler(){
            /*
            // check validation for all validation fields
            this.$v.$touch();
            if (this.$v.$invalid) {
                console.log("Got invalid field",this.$v.$touch);
                return;
            }
            */
            // Validate only the schedule_bot_msg fields
            this.$v.schedule_bot_msg.$touch();
            if (this.$v.schedule_bot_msg.$invalid) {
                console.log("Got invalid field in schedule_bot_msg");
                return;
            }
            
            this.isScheduleBookingForm = false;
            this.isScheduleQualificationQues = true;
            
            this.showAlert('now write the qualification questions');
        },
        
        // create scheduling qualification process
        async schedulingQualificationSubmit(){
            // this.$v.$touch();
            // if (this.$v.$invalid) {
            //     return;
            // }

            // Validate only the qualification question fields
            this.$v.qualification_questions.$touch();
            if (this.$v.qualification_questions.$invalid) {
                console.log("Got invalid field in qualification_questions",this.qualification_questions);
                return;
            }
            
            
            try {
                this.isScheduleInstructLoading = true;
                
                const userAttributes = getUserAttributes();
                const {inbox_id} = userAttributes;
                // 1) create template message based on the schedule tpl messages
                const templateMessages = [
                    {
                        short_code: "template_confirmation_" + popupHelpers.randomNumber(),
                        content: this.schedule_bot_msg.confirm_msg,
                        inbox_id,
                        // onboard: 'yes',
                        file:"",
                        language: 'en',
                        buttonsData: [{name:"Say Hi",code:"Sa2252262"}],
                        templateType:"MARKETING"
                    },
                    {
                        short_code: "template_reschedule_"  + popupHelpers.randomNumber(),
                        content: this.schedule_bot_msg.reschedule_msg,
                        inbox_id,
                        // onboard: 'yes',
                        file:"",
                        language: 'en',
                        buttonsData: [{name:"Say Hi",code:"Sa2252262"}],
                        templateType:"MARKETING"
                    },
                    {
                        short_code: "template_reminder_" + popupHelpers.randomNumber(),
                        content: this.schedule_bot_msg.reminder_msg,
                        inbox_id,
                        // onboard: 'yes',
                        file:"",
                        language: 'en',
                        buttonsData: [{name:"Say Hi",code:"Sa2252262"}],
                        templateType:"MARKETING"
                    },
                    {
                        short_code: "template_accept_" + popupHelpers.randomNumber(),
                        content: this.schedule_bot_msg.accept_msg,
                        inbox_id,
                        // onboard: 'yes',
                        file:"",
                        language: 'en',
                        buttonsData: [{name:"Say Hi",code:"Sa2252262"}],
                        templateType:"MARKETING"
                    },
                ]
                
                
                // call to create the tpl list and store the ids to undo
                let isUndo = false;
                const created_tpl_ids = [];
                for(const msg of templateMessages){
                    const {isError,error,data} = await popupApiHelper.addTemplateMessage(msg);
                    if (isError) {
                        isUndo = true;
                        this.showAlert(error.message? error.message : "Failed to create template message")
                        break; 
                    }else{
                        created_tpl_ids.push(data.id);
                    }
                }
                
                if (isUndo) {
                    console.log("Error to call Undo method and return");
                    await popupApiHelper.deleteTemplateMessageList(created_tpl_ids);
                    this.isScheduleInstructLoading = false;
                    return;
                }
                
                // 2) create custom attributes based on qualification questions
                // const customAttributes = this.qualification_questions.map(question => {
                //     return {
                //         attribute_display_name: question.customAttributeProperty,
                //         attribute_description: question.question,
                //         attribute_model: 1,
                //         attribute_display_type: 0,
                //         attribute_key: question.customAttributeProperty,
                //         attribute_values: []
                //     };
                // });
                const customAttributes = [
                    {
                        attribute_display_name: this.qualification_questions.attribute1,
                        attribute_description: this.qualification_questions.question1,
                        attribute_model: 1,
                        attribute_display_type: 0,
                        attribute_key: this.qualification_questions.attribute1,
                        attribute_values: []
                    },
                    {
                        attribute_display_name: this.qualification_questions.attribute2,
                        attribute_description: this.qualification_questions.question2,
                        attribute_model: 1,
                        attribute_display_type: 0,
                        attribute_key: this.qualification_questions.attribute2,
                        attribute_values: []
                    },
                    {
                        attribute_display_name: this.qualification_questions.attribute3,
                        attribute_description: this.qualification_questions.question3,
                        attribute_model: 1,
                        attribute_display_type: 0,
                        attribute_key: this.qualification_questions.attribute3,
                        attribute_values: []
                    },
                ];
                
                // addition custom attributes
                const additionalAttribures = [
                    {
                        attribute_display_name:"appointment_date",
                        attribute_description:"Appointment date",
                        attribute_model:1,
                        attribute_display_type: 5,  // 5 is the 'date' type
                        attribute_key:"appointment_date",
                        attribute_values:[]
                    },
                    {
                        attribute_display_name:"Botflag",
                        attribute_description:"Demo Purpose",
                        attribute_model:1,
                        attribute_display_type:0,
                        attribute_key:"botflag",
                        attribute_values:[]
                    },
                ];
                customAttributes.push(...additionalAttribures)
                const created_attribute_ids = [];
                for(const attribute of customAttributes){
                    const {isError,error,data} = await popupApiHelper.addCustomAttributes(attribute);
                    if (isError) {
                        isUndo = true;
                        this.showAlert(error.message? error.message : "Failed to add custom attribute")
                        break; 
                    }else{
                        created_attribute_ids.push(data.id);
                    }
                }
                
                if (isUndo) {
                    console.log("Error to call Undo method and return");
                    await popupApiHelper.deleteTemplateMessageList(created_tpl_ids);
                    await popupApiHelper.deleteCustomAttributeList(created_attribute_ids);
                    this.isScheduleInstructLoading = false;
                    return;
                }
                

                
                // *) create the appointment meeting here to pass unique link to bot 
                // 6) create appointment
                const appointmentBody = {
                    title:"test title by test dev",
                    description:"test description by popup",
                    duration:15,
                    availability:[{
                        day:"Mon",
                        start_hour:"11:00",
                        end_hour:"20:00"
                    }],
                    max_meetings:5,
                    buffer_time:15,
                    notice_time:0,
                    reminder_time:15,
                    confirmation_msg:{
                        template_name:"",
                        message: this.schedule_bot_msg.confirm_msg,
                    },
                    reminder_msg:{
                        template_name:"",
                        message: this.schedule_bot_msg.reminder_msg,
                    },
                    inbox_id,
                    unique_link: "meetingdemo",
                    location:{type:"google_meet",custom:""},
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                }
                const {isError:isScheduleErr,error:claErr,data:instructSchedule}  = await popupApiHelper.createScheduleAppointmentMeeting(appointmentBody);
                
                if (isScheduleErr) {
                    await popupApiHelper.deleteTemplateMessageList(created_tpl_ids);
                    await popupApiHelper.deleteCustomAttributeList(created_attribute_ids);
                    this.showAlert(claErr.message? claErr.message : "Failed to create appointment")

                    this.isScheduleInstructLoading = false;
                    return;
                }
    

    
                // 3) create chat bot id
                const chatBotList = [
                    {
                        botName: "meetingchatbot",
                        bot_template: {id:'4-meetingchatbot-vHcxsW'}
                    },
                    {
                        botName: "carworkshop",
                        bot_template: {id:'4-carworkshop-JIUeae'}
                    },
                ];
                 const created_bot_ids = [];
                 const botIdTrack ={};
                for(const chatBot of chatBotList){
                    const {isError,error,data} = await popupApiHelper.createBotIdAndExportFlow(chatBot.botName,chatBot.bot_template);
                    if (isError) {
                        isUndo = true;
                        this.showAlert(error.message? error.message : "Failed to create bot")
                        break; 
                    }else{
                        botIdTrack[chatBot.botName] = data.id;
                        created_bot_ids.push(data.id);
                    }
                }
                
                if (isUndo) {
                    console.log("Error to call Undo method and return");
                    await popupApiHelper.deleteTemplateMessageList(created_tpl_ids);
                    await popupApiHelper.deleteCustomAttributeList(created_attribute_ids);
                    await popupApiHelper.deleteBotIdList(created_bot_ids);
                    this.isScheduleInstructLoading = false;
                    return;
                }
                

                // 4) Update the bot flowdata to export
                //     - get the created bot nodes and update with real questions
                // 4.1) get "carworkshop" flow data
                const carWorshopBotFlowResp = await FlowAPI.getFlowById(botIdTrack.carworkshop,this.currentUser.id);
                
                if (carWorshopBotFlowResp.status !== 'ok') {
                    // undo operations
                    await popupApiHelper.deleteTemplateMessageList(created_tpl_ids);
                    await popupApiHelper.deleteCustomAttributeList(created_attribute_ids);
                    await popupApiHelper.deleteBotIdList(created_bot_ids);
                    this.showAlert("Failed to create bot")
                    this.isScheduleInstructLoading = false;
                    return;
                }
                // 4.2) modify flow data
               const { isError:isFlowModifyError,data:modifiedFlowData} = popupApiHelper.modifyBotFlowByQuestionAttribute(carWorshopBotFlowResp.data,this.qualification_questions,appointmentBody.unique_link);
               
                if (isFlowModifyError) {
                    // undo operations
                    await popupApiHelper.deleteTemplateMessageList(created_tpl_ids);
                    await popupApiHelper.deleteCustomAttributeList(created_attribute_ids);
                    await popupApiHelper.deleteBotIdList(created_bot_ids);
                    this.showAlert("Failed to update bot")
                    this.isScheduleInstructLoading = false;
                    return;
                }
                // 4.3) export and save flow data
                const saveFlowModifyResponse = await FlowAPI.exportFlow(botIdTrack.carworkshop,modifiedFlowData);
                
    
                // 5) create automation
                const automationBodyList = [
                    // msg create incommin botflag 'on'
                    {
                        name:"new_incomming_msg_with_botflag_on",
                        description:"Create new message with no botflag",
                        event_name:"message_created",
                        conditions:[
                            {
                                attribute_key:"message_type",
                                filter_operator:"equal_to",
                                values:["incoming"],
                                query_operator:"and"
                            },
                            {
                                attribute_key: "botflag",
                                filter_operator:"equal_to",
                                values:["on"]
                            }
                        ],
                        actions:[
                            {
                                action_name:"assign_bot",
                                action_params:[inbox_id],
                                // bot_id: botList.find(bot=>bot.name === 'carworkshop')?.botid,
                                bot_id: botIdTrack.carworkshop,
                            }
                        ]
                    },

                    // create incommin msg botflag not present and not equal 'on'
                    {
                        name:"new_incomming_msg_no_botflag",
                        description:"Create new message with no botflag",
                        event_name:"message_created",
                        conditions:[
                            {
                                attribute_key:"message_type",
                                filter_operator:"equal_to",
                                values:["incoming"],
                                query_operator:"and"
                            },
                            {
                                attribute_key:"botflag",
                                filter_operator:"is_not_present",
                                values:["on"],
                                query_operator:"and"
                            },
                            {
                                attribute_key:"botflag",
                                filter_operator:"not_equal_to",
                                values:["on"]
                            }
                        ],
                        actions:[
                            {
                                action_name:"assign_bot",
                                action_params:[inbox_id],
                                // bot_id: botList.find(bot=>bot.name === 'carworkshop')?.botid,
                                bot_id: botIdTrack.carworkshop,
                            }
                        ]
                    },

                    

                    // event created on meeting confirm
                    {
                        name:"create_meeting",
                        description:"Create a new meeting",
                        event_name:"event_created",
                        conditions:[
                            {
                                values: ["meeting_confirmed"],
                                attribute_key: "event_name",
                                query_operator: "or",
                                filter_operator: "equal_to"
                            },
                            {
                                values: ["meeting_rejected"],
                                attribute_key: "event_name",
                                filter_operator: "equal_to"
                            }
                        ],
                        actions:[
                            {
                                action_name:"assign_bot",
                                action_params:[inbox_id],   // what would be the dynamic value /
                                // bot_id: botList.find(bot=>bot.name === 'meetingchatbot')?.botid,
                                bot_id: botIdTrack.meetingchatbot,
                            }
                        ]
                    }
                ];
                for(const automationBody of automationBodyList){
                  const autoRes =  await this.$store.dispatch('automations/create', automationBody);
                }
    
                
    
                // 7) save appointment like id 
                // 8) redirect to the appointment url in new tab
               

                const appointment_url = `https://schedule.peasy.ai/book/user/${this.currentUser.id}/${instructSchedule.unique_link}`;
                popupHelpers.openNewTab(appointment_url);
                console.log("Opened new URL",appointment_url);

                await popupHelpers.sleep(1500);

                // ---------------------
                bus.$emit('instruct_demo_meeting_event',{instructSchedule,path:this.$route.path});
                // ---------------------

                // update step & se path to 'X'
                const {schedulingSteps} = instructSteps;
                const {isError:isStepErr,data:stepData,errMsg:stepErrMsg} = await incrementUserStepAttributes(schedulingSteps.createdBotandAppointment);
                if (isStepErr) {
                    this.showAlert(stepErrMsg);
                }
                const {isError:isAttrErr,errMsg:errAttrMsg} = await updateUserAttributes('path','X'); // end of this path
                if (isAttrErr) {
                    this.showAlert(errAttrMsg);
                }
                
                this.isScheduleInstructLoading = false;
                this.showAlert('Thank you for submitting');
                this.closeAllPopup();
                this.isScheduleSuccessPopupOpen = true; 
            } catch (error) {
                console.log(error);
            } finally{
                this.isScheduleInstructLoading = false;
            }
        },

        // lead template handler
        // for when step 2 
        async leadTemplateMsgHandler(){
            const isContactCreatChecked = this.$refs.lead_temphelloMsgCheckBoxRef?.checked;
            if (!this.lead_temphelloMsg?.length) {
                 this.showAlert("Template message can't be empty");
                return;
            }
            this.isLeadInstructLoading = true;
            const userAttributes = getUserAttributes();
            const {inbox_id} = userAttributes;
            try {
                // create tpl message
                const templateMessages = [
                    {
                        short_code: "temphello" + popupHelpers.randomNumber(),
                        content: this.lead_temphelloMsg,
                        inbox_id,
                        // onboard: 'yes',
                        file:"",
                        language: 'en',
                        buttonsData: [{name:"Say Hi",code:"Sa2252262"}],
                        templateType:"MARKETING"
                    },
                ];
                
                if (isContactCreatChecked) {
                    for(const msg of templateMessages){
                        const {isError,error,data} = await popupApiHelper.addTemplateMessage(msg);
                        if (isError) {
                            this.showAlert(error.message? error.message : "Failed to create template message");
                            this.isLeadInstructLoading = false;
                            return; 
                            // break; 
                        }
                    }
    
                    // (Au1) create automation when create new contact
                    const automationBodyList = [
                        {
                            name: "Hello greeting",
                            description: "Hello greet description on create contact",
                            event_name: "contact_created",
                            conditions: [
                                {
                                    attribute_key: "email",
                                    filter_operator: "contains",
                                    values: ["@"]
                                }
                            ],
                            actions: [
                                {
                                    action_name: "send_message",
                                    action_params: [inbox_id],
                                    message_content: this.lead_temphelloMsg,
                                }
                            ]
                        },
                    ];
                    for(const automationBody of automationBodyList){
                      const autoRes =  await this.$store.dispatch('automations/create', automationBody);
                      
                    }
                }

                
                // increase step 
                const {leadQualificationSteps} = instructSteps;
                const {isError:isStepErr,data:stepData,errMsg:stepErrMsg} = await incrementUserStepAttributes(leadQualificationSteps.template_msg);
                if (isStepErr) {
                    this.showAlert(stepErrMsg);
                }

                // check to open lead qualification questions
                this.isLeadInstructLoading = false;
                this.isLeadTemplateMsgOpen = false;
                this.isLeadQualificationQuestionOpen = true;
                
            } catch (error) {
                console.log(error);
                this.showAlert(error.message? error.message : "Unknown error occur")
                this.isLeadInstructLoading = false;
            } 
        },
        
        
        addMoreQualificationField(){
            // error handling
            this.leadQualificationQuestion.acceptableOptions_err = null;
            const isOptionLessThanTwo = this.leadQualificationQuestion.options.length < 2;
            const isAcceptableLessOne = this.leadQualificationQuestion.acceptableOptions.length >= this.leadQualificationQuestion.options.length;
            
            this.$v.leadQualificationQuestion.$touch();
            if (this.$v.leadQualificationQuestion.$invalid || isOptionLessThanTwo || isAcceptableLessOne) {

                if (isOptionLessThanTwo) {
                    this.leadQualificationQuestion.acceptableOptions_err = 'Minimum 2 selectable options are required';
                    return;
                }
                if (isAcceptableLessOne) {
                    this.leadQualificationQuestion.acceptableOptions_err = 'Maximum acceptable options must be one less than selectable';
                    return;
                }
                return;
            }

            this.lead_qualificationQuestions.push({
                question_id: `id_${this.lead_qualificationQuestions.length}`,
                ...this.leadQualificationQuestion,
            });
            
            this.leadQualificationQuestion.title = '';
            this.leadQualificationQuestion.question = '';
            this.leadQualificationQuestion.options = [];
            this.leadQualificationQuestion.buttonsData = [];
            this.leadQualificationQuestion.acceptableOptions = [];
            this.leadQualificationQuestion.acceptableSuggestions = [];
            
            // clear the validation error
            this.$v.leadQualificationQuestion.$reset();
        },
        
        
        deleteLeadAcceptableOption(option){
           const newList = this.leadQualificationQuestion.acceptableOptions.filter(el=>el!== option);
            this.leadQualificationQuestion.acceptableOptions = newList;
        },
        removeLeadQuestion(indexNumber){
           this.lead_qualificationQuestions.splice(indexNumber,1);
        },
        // multibuttton add for lead qualification
        addButton(newTag) {
            this.acceptableOptions_err = null;
            if (this.leadQualificationQuestion.options.length > 2) {
                this.$v.leadQualificationQuestion.options.$touch();
                if (this.$v.leadQualificationQuestion.options.$invalid) {
                    console.log('got Error in leadQualificationQuestion', this.leadQualificationQuestion);
                    return;
                }
            }
            const tag = {
                name: newTag,
                code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
            };
            this.leadQualificationQuestion.options.push(tag);
            this.leadQualificationQuestion.buttonsData.push(tag);
            this.leadQualificationQuestion.acceptableSuggestions.push(tag);
        },
        addAcceptableButton(newTag) {
            // leadQualificationQuestion.acceptableOptions_err
            // check: total options has minimum 2 length
            // check: acceptable options length not exceed options -1
            try {
                
                this.leadQualificationQuestion.acceptableOptions_err = null;
                if (this.leadQualificationQuestion.options.length < 2) {
                    this.leadQualificationQuestion.acceptableOptions_err = 'Minimum 2 selectable options are required';
                    return;
                }
                if (this.leadQualificationQuestion.acceptableOptions.length >= this.leadQualificationQuestion.options.length -1 ) {
                    this.leadQualificationQuestion.acceptableOptions_err = 'Maximum acceptable options must be one less than selectable';
                    return;
                }
                this.leadQualificationQuestion.acceptableOptions.push(newTag);
            } catch (error) {
                console.log(error);
            }
        },
        selectAcceptableButton(el){
            this.acceptableOptions_err = null;
            const existsInOptions = this.leadQualificationQuestion.options.some(option => option.name === el.name);
            if (this.leadQualificationQuestion.options.length < 2) {
                this.leadQualificationQuestion.acceptableOptions = [];
                this.acceptableOptions_err = this.$t('INSTRUCT_POPUP.LEAD_QUALIFICATION.LEAD_QUESTIONS.QUESTION_LABELES.OPTION_ERR_MSG_MANHY');
            }
            if (!existsInOptions) {
                // Remove the element from acceptableOptions by filtering it out
                this.leadQualificationQuestion.acceptableOptions = this.leadQualificationQuestion.acceptableOptions
                    .filter(item => item.name !== el.name);
            }
        },
        leadOptionRemoveHandle(el){

            // Filter acceptableOptions to keep only items that have a name different from the removed element's name
            this.leadQualificationQuestion.acceptableOptions = this.leadQualificationQuestion.acceptableOptions
                .filter(item => item.name !== el.name);

            // Filter acceptableSuggestions to keep only items that have a name different from the removed element's name
            this.leadQualificationQuestion.acceptableSuggestions = this.leadQualificationQuestion.options;
            // {name:"button1",code: "button1"},
        },
        
        async leadQualificationQuesHandler(){
            // return if list is empty
            try {
                
                // push the last question element which is not added by addMoreQualificationField()
                if (this.lead_qualificationQuestions.length < 3) {
                    this.$v.leadQualificationQuestion.$touch();
                    if (this.$v.leadQualificationQuestion.$invalid) {
                        return;
                    }
                    this.lead_qualificationQuestions.push({
                        question_id: `id_${this.lead_qualificationQuestions.length}`,
                        ...this.leadQualificationQuestion,
                    });
                    console.log("final lead_qualificationQuestions",this.lead_qualificationQuestions);
                }

                if (!this.lead_qualificationQuestions.length) {
                    this.showAlert('Atleast one qualification question is required')
                    return;
                }

                this.isLeadQualificationQuestionOpen = false;
                this.isLeadRulesOpen = true;
            } catch (error) {
                console.log(error);
                this.showAlert(error.message? error.message : "Unknown error occur")
            } 
        },
        handleLabelBlur(index) {
            if (this.teritorialRule.options[index] && this.$v.teritorialRule.options[index]?.label) {
                this.$v.teritorialRule.options[index]?.label?.$touch();
            }
        },
        handleTeamBlur(index) {
            if (this.teritorialRule.options[index] && this.$v.teritorialRule.options[index]?.team) {
                this.$v.teritorialRule.options[index]?.team?.$touch();
            }
        },
        addNewLeadTeamOption(){
            if (this.canAddNewTeamOption) {
                this.teritorialRule.options.push({label:"", team: ""});
            }
        },
        removeTeritorialTeam(indexNumber){
            this.teritorialRule.options.splice(indexNumber,1);
        },
        teritorialRuleInputHandler(){
            this.teritorialRule.option_err = null;
        },
        // create lead qualification process
        async leadTeamsAndBotHandler(){
            try {
                this.teritorialRule.option_err = null;
                if (this.leadDistributionRuleType === 'territorial') {
                    // validate only for teritorial rules
                    if (!this.teritorialRule.options?.length) {
                        // this.teritorialRule.option_err = 'Atleast one team is required';
                        this.teritorialRule.option_err = this.$t('INSTRUCT_POPUP.LEAD_QUALIFICATION.TEAM_AGENT.RULE.TEAM_REQUIRE');
                        return;
                    }
                    this.$v.teritorialRule.question.$touch();
                    this.$v.teritorialRule.attribute.$touch();
                    // chek any of the rule option is not empty
                     for (const option of this.teritorialRule.options) {
                         if (!option.label?.length || !option.team?.length) {
                             this.teritorialRule.option_err = 'Attribute and team are required for all';
                            //  this.showAlert('Attribute and team are required for all');
                            return; 
                        }
                    }
                    this.$v.teritorialRule.$touch();
                    if (this.$v.teritorialRule.question.$invalid || this.$v.teritorialRule.attribute.$invalid) {
                        return;
                    }
                }

                this.isLeadInstructLoading = true;
                
                const result = await this.createTeamAndBotOfTeritorialRule();

                this.isLeadInstructLoading = false;
            } catch (error) {
                console.log(error);
                this.showAlert(error.message? error.message : "Unknown error occur")
                this.isLeadInstructLoading = false;
            } 
        },
        async createTeamAndBotOfTeritorialRule(){
            const {inbox_id} = getUserAttributes();
            try {
                let isUndo = false;
                // - (1) create customAttributes
                const customAttributes = [
                    // common attributes
                    {
                        attribute_display_name:"Botflag",
                        attribute_description:"Demo Purpose",
                        attribute_model:1,
                        attribute_display_type:0,
                        attribute_key:"botflag",
                        attribute_values:[]
                    },
                ];
                // add the teritorial attribute if this.leadDistributionRuleType === 'territorial'
                if (this.leadDistributionRuleType === 'territorial') {
                    customAttributes.push({
                        attribute_display_name: this.teritorialRule.attribute,
                        attribute_description: this.teritorialRule.attribute,
                        attribute_model:1,
                        attribute_display_type: 0,  // 5 is the 'date' type
                        attribute_key: this.teritorialRule.attribute,
                        attribute_values:[]
                    })
                }
                const created_attribute_ids = [];
                for(const attribute of customAttributes){
                    const {isError,error,data} = await popupApiHelper.addCustomAttributes(attribute);
                    if (isError) {
                        isUndo = true;
                        this.showAlert(error.message? error.message : "Failed to add custom attribute")
                        break; 
                    }else{
                        created_attribute_ids.push(data.id);
                    }
                }
                if (isUndo) {
                    console.log("Error to call Undo method and return");
                    await popupApiHelper.deleteCustomAttributeList(created_attribute_ids);
                    return;
                }

                // - (2) loop: create team + automation when teritorial
                const created_team_ids= [];
                if (this.leadDistributionRuleType === 'territorial') {
                    for(const [index,{label,team}] of this.teritorialRule.options.entries()){
                        const teamData = {
                            description: team,
                            name: team,
                            allow_auto_assign: true,
                        }
                        const teamRes = await this.$store.dispatch('teams/create', teamData);
                        
                        if (teamRes.id) {
                            this.teritorialRule.options[index].team_id = teamRes.id;
                            created_team_ids.push(teamRes.id);
                        }else{
                            isUndo = true;
                        }
                        
                        // - (Au4 + Au5) loop: create automation
                        const automationBody = {
                            name: `${team} Automation`,
                            description: `Automation for ${team}`,
                            event_name:"contact_updated",
                            conditions:[
                                {
                                    attribute_key: this.teritorialRule.attribute,
                                    filter_operator:"equal_to",
                                    values:[label]
                                }
                            ],
                            actions:[
                                {
                                    action_name:"assign_team",
                                    action_params:[teamRes.id]
                                }
                            ]
                        };
                        const autoRes =  await this.$store.dispatch('automations/create', automationBody);
                    }
                }
                
                if (isUndo) {
                    console.log("Error to call Undo method and return");
                    await popupApiHelper.deleteCustomAttributeList(created_attribute_ids);
                    for(const teamId of created_team_ids){
                        await this.$store.dispatch('teams/delete', teamId);
                    }
                    return;
                }

                

                // - (4) create bot
                const demoBotName = "incomming_msg_bot"; 
                const bot_template = {id:"4-lead-qualification-template-Di5a90"};  
                const createFlowResponse = await FlowAPI.createFlow(this.currentUser.id, demoBotName,bot_template,this.currentUser.account_id);

                // - (5.1) get the bot
                const botList = await FlowAPI.getBotsByUserId(this.currentUser.id,true,this.currentUser.account_id);
                const new_bot_id = botList.find(bot=>bot.name === demoBotName)?.botid;
                const flowData = await FlowAPI.getFlowById(new_bot_id,this.currentUser.id);
                
                // flowData.data
                // if this.leadDistributionRuleType === 'equally', don't create nodes, so make the array empty
                const formattedTeritorialRules = {
                    ...this.teritorialRule,
                    subtitle: this.teritorialRule.question,
                    options: this.teritorialRule.options.map(el=>{
                        return{
                            buttonTitle: el.team,
                            buttonValue: el.label,
                        }
                    })
                }
                if (this.leadDistributionRuleType === 'equally') {
                    formattedTeritorialRules.isEquallyType = true;
                }
                const {isError:isFlwErr,error:flwErr,data:newFlowData} = await popupApiHelper.modifyLeadQualificationBotFlow(flowData.data,this.lead_qualificationQuestions,formattedTeritorialRules);
                
                if (isFlwErr) {
                    // undo the previous list
                    this.showAlert(flwErr? flwErr : "failed to modify bot");
                    return
                }
                // export(save) the new bot
                const saveFlowResponse = await FlowAPI.exportFlow(new_bot_id,newFlowData);
                
                // create mandatory two automation for "message_created"
                const automationBodyList = [
                    // Au2. msg create incommin botflag 'on'
                    {
                        name:"new_incomming_msg_with_botflag_on",
                        description:"Create new message with no botflag",
                        event_name:"message_created",
                        conditions:[
                            {
                            attribute_key:"message_type",
                            filter_operator:"equal_to",
                            values:["incoming"],
                            query_operator:"and"
                            },
                            {
                                attribute_key:"botflag",
                                filter_operator:"equal_to",
                                values:["on"]
                            }
                        ],
                        actions:[
                            {
                                action_name:"assign_bot",
                                action_params:[inbox_id],
                                bot_id: new_bot_id
                            }
                        ]
                    },

                    // Au3. create incommin msg botflag not present and not equal 'on'
                    {
                        name:"new_incomming_msg_no_botflag",
                        description:"Create new message with no botflag",
                        event_name:"message_created",
                        conditions:[
                            {
                            attribute_key:"message_type",
                            filter_operator:"equal_to",
                            values:["incoming"],
                            query_operator:"and"
                            },
                            {
                            attribute_key:"botflag",
                            filter_operator:"is_not_present",
                            values:["on"],
                            query_operator:"and"
                            },
                            {
                            attribute_key:"botflag",
                            filter_operator:"not_equal_to",
                            values:["on"]
                            }
                        ],
                        actions:[
                            {
                                action_name:"assign_bot",
                                action_params:[inbox_id],
                                bot_id: new_bot_id
                            }
                        ]
                    },
                ];
                for (const autoBody of automationBodyList) {
                    const autoRes =  await this.$store.dispatch('automations/create', autoBody);
                    
                }

                // - (6) increase step
                // update step & se path to 'X'
                const {leadQualificationSteps} = instructSteps;
                const {isError:isStepErr,data:stepData,errMsg:stepErrMsg} = await incrementUserStepAttributes(leadQualificationSteps.successFulMsgStep);
                if (isStepErr) {
                    this.showAlert(stepErrMsg);
                    return;
                }
                const {isError:isAttrErr,errMsg:errAttrMsg} = await updateUserAttributes('path','X'); // end of this path
                if (isAttrErr) {
                    this.showAlert(errAttrMsg);
                    return;
                }
                const teamAddUrl = `/app/accounts/${this.currentUser.account_id}/settings/teams/list`;
                // - open success opup
               this.isLeadRulesOpen = false;
                this.$router.push(teamAddUrl);
               this.isLeadSuccessOpen = true;
               return {isError:false}
            } catch (error) {
                console.log(error);
                this.showAlert(error.message? error.message : "Unknown error occur")
                this.isLeadInstructLoading = false;
                return {isError:true}
            }
        },
        

        pageNavigator(routeSwitch){
            // console.log(routeSwitch);
            switch (routeSwitch) {
                case 'calander_list':
                    const calanderPath = `/app/accounts/${this.currentUser.account_id}/calendar/list`;
                    if (this.$route.path !== calanderPath) {
                        this.$router.push(calanderPath);
                    }
                    break;
            
                default:
                    break;
            }
        },
        
        // HTML helper methods
        getScheduleMessageLabel(key){
            switch (key) {
                case "confirm_msg":
                    return this.$t('INSTRUCT_POPUP.SCHEDULING.APPOINTMENT_MSG.CONFIRM.LABEL');
                case "reschedule_msg":
                    return this.$t('INSTRUCT_POPUP.SCHEDULING.APPOINTMENT_MSG.RESCHEDULE.LABEL');
                case "reminder_msg":
                    return this.$t('INSTRUCT_POPUP.SCHEDULING.APPOINTMENT_MSG.REMINDER.LABEL');
                case "accept_msg":
                    return this.$t('INSTRUCT_POPUP.SCHEDULING.APPOINTMENT_MSG.ACCEPT.LABEL');
                default:
                    return "";
            }
        },
        getScheduleMessagePlaceholder(key) {
            switch (key) {
                case "confirm_msg":
                    return this.$t('INSTRUCT_POPUP.SCHEDULING.APPOINTMENT_MSG.CONFIRM.PLACEHOLDER');
                case "reschedule_msg":
                    return this.$t('INSTRUCT_POPUP.SCHEDULING.APPOINTMENT_MSG.RESCHEDULE.PLACEHOLDER');
                case "reminder_msg":
                    return this.$t('INSTRUCT_POPUP.SCHEDULING.APPOINTMENT_MSG.REMINDER.PLACEHOLDER');
                case "accept_msg":
                    return this.$t('INSTRUCT_POPUP.SCHEDULING.APPOINTMENT_MSG.ACCEPT.PLACEHOLDER');
                default:
                    return "";
            }
        },
        
    },
}
</script>

<style>
.instruction_btn{
    /* display: block; */
    /* margin: auto; */
}
.btn_group{
    display: flex;
    gap: 1rem;
    width: 100%;
}

.form_padding{
    padding: 0.8rem 3.2rem;

}
.qualification-question-wrap{
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0.8rem 3.2rem;
}
.qualification-question-wrap .qualification-question{
    width: 100%;
}
.qualification-question-wrap .qualification-question:nth-child(1) input{
    margin: 0;
}
.add-icon-wrap{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup_body{
    padding: var(--space-normal) var(--space-large) var(--space-large);
}

.lead_template .octopus_img{
    position: absolute;
    top: -110px;
    width: 180px;
    left: 50%;
    transform: translateX(-50%);
}
.popup_body .btns_center{
    width: fit-content;
    margin: auto;
    display: flex;
    gap: 2rem;
}
.modal-container:has(.transparent_body){
   background: transparent;
   padding: 0;
}
.modal-container:has(.overflow_visiable){
   overflow: visible;
}

/* lead question stules  start */
.question_label{
    margin-bottom: var(--space-small);
}
.center{
    align-items: center;
    justify-content: center;
}
.lead_card{
    border: 1px solid var(--w-500);
    padding: 12px;
    /* padding: 0 8px; */
    border-radius: 0.4rem;
    margin: 8px auto;
}
.leadQuestionSet .multi_options{
    text-align: center;
}
.leadQuestionSet{
    /* display: grid;
    grid-template-columns: 1fr 40px;
    align-items: center; */
}

.leadQuestionSet input{
    height: auto;
    padding: 0;
    font-size: inherit;
} 

.lead_question_add_card button{
    margin-right: 4px;
    margin-bottom: 4px;
}
.lead_suggest_container {
    position: relative;
}
.lead_suggest_container input{
    margin-top: 8px;
}
.lead_suggest_container div{
    position: absolute;
    bottom: -58px;
    left: 0;
    padding: 8px;
    border-radius: 4px;
    box-shadow: 0 0px 0px 1px #b9b2b275;
    z-index: 1;
}
.leadQuestionAddBtn{
    position: relative;
    display: block;
    margin: auto !important;
    border: 1px solid var(--w-500);
}
.leadQuestionAddBtn svg{
    color: var(--w-500);
}
.lead_team_attribute{
    display: flex;
    align-items: flex-end;
    gap: 8px;
    white-space: nowrap;
}
.lead_team_attribute input{
    margin-bottom: 0;
}
.multiselect_label .multiselect{
    margin-bottom: 0;
}
.multiselect_label .multi_err{
    margin-top: 0px;
    margin-bottom: var(--space-normal);
}
.teritorial_container{
    margin-top: 1.9rem;
}
.territorial_rules{
    display: flex;
    gap: 12px;
    margin-bottom: 8px;
    margin-left: 12px !important;
}
.team_attribute_wrap{
    position: relative;
}

.team_attr_err{
    position: absolute;
    bottom: -16px;
}
.error_cls{
    color: var(--r-400);
}

 /* -> font styling of lead question popup  */
.font_normal{
    font-size: 1.4rem;
    font-weight: 400;
}
.font_normal_wootInput{
    font-size: 1.4rem;
    font-weight: 400;
}
.font_normal_wootInput input{
    font-size: 1.4rem;
    font-weight: 400;
}
.font_multi_buttons .multiselect__tag span{
    font-size: 1.4rem;
    font-weight: 400;

}
/* lead question stules  end */
</style>


<style scoped>
.lead_content{
    overflow: auto;
    max-height: 90vh;
    border-radius: 15px;
}

.lead_content .octopus_img{
    position: absolute;
    top: -110px;
    width: 180px;
    left: -60px;

}
.lead_content .page-top-bar{
    padding: 0;
}
.lead_content .lead_sub_title{
    padding: 1rem;
    background-color: var(--w-500);
    border-radius: 15px;
}

.lead_content .horizontal_center{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.lead_content .relative{
    position: relative;
}
.lead_content .bg_white{
    background-color: #fff;
}
.lead_content .color_white{
    color: #fff;
}
.black_field{
    color: #000;
}
.black_field label span{
    color: #000 !important;
}
.lead_content .field{
    padding: 6px;
}

</style>

