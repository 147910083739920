export default {
  computed: {
    hostURL() {
      return window.peasyConfig.hostURL;
    },
    vapidPublicKey() {
      return window.peasyConfig.vapidPublicKey;
    },
    enabledLanguages() {
      return window.peasyConfig.enabledLanguages;
    },
  },
};
