/* eslint no-param-reassign: 0 */
import fromUnixTime from 'date-fns/fromUnixTime';
import differenceInDays from 'date-fns/differenceInDays';
import Cookies from 'js-cookie';
import posthog from 'posthog-js';
import { frontendURL } from '../../helper/URLHelper';
import {
  ANALYTICS_IDENTITY,
  ANALYTICS_RESET,
  CHATWOOT_RESET,
  CHATWOOT_SET_USER,
} from '../../helper/scriptHelpers';

Cookies.defaults = { sameSite: 'Lax' };

export const getLoadingStatus = state => state.fetchAPIloadingStatus;
export const setLoadingStatus = (state, status) => {
  state.fetchAPIloadingStatus = status;
};

export const setUser = (user, expiryDate, options = {}) => {
  if (options && options.setUserInSDK) {
    window.bus.$emit(CHATWOOT_SET_USER, { user });
    window.bus.$emit(ANALYTICS_IDENTITY, { user });
  }
  // add onboard_limit to all cookie
  user.onboard_limit = user.onboard_limit || 0;
  Cookies.set('user', user, {
    expires: differenceInDays(expiryDate, new Date()),
  });
};

export const getHeaderExpiry = response =>
  fromUnixTime(response.headers.expiry);

export const setAuthCredentials = response => {
  const expiryDate = getHeaderExpiry(response);
  Cookies.set('auth_data', response.headers, {
    expires: differenceInDays(expiryDate, new Date()),
  });
  setUser(response.data.data, expiryDate);
};

export const clearCookiesOnLogout = () => {
  window.bus.$emit(CHATWOOT_RESET);
  window.bus.$emit(ANALYTICS_RESET);

  // hardcodedly reset $peasy. don't wait for event
  if (window.$peasy) {
    window.$peasy.reset();
    console.log("Reseted $peasy");
  }
  if (window.analyticsConfig) {
    posthog.reset();
    console.log("Reseted posthog");
  }


  Cookies.remove('auth_data');
  Cookies.remove('user');

  console.log("Emmitted CHATWOOT_EVENTS on lgoout");

  const globalConfig = window.globalConfig || {};
  const logoutRedirectLink =
    globalConfig.LOGOUT_REDIRECT_LINK || frontendURL('login');
  window.location = logoutRedirectLink;
};
