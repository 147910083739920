/* global axios */
import Cookies from 'js-cookie';
const PM_BASE_URL = 'https://app.peasy.ai';
const PMAPI_BASE_URL = 'https://app.peasy.ai/service/api/v1';
// const PM_BASE_URL = 'http://localhost:3001';
// const PMAPI_BASE_URL = 'http://localhost:3001/service/api/v1';
class PMConnectAPI {
  getAuthToken = () => {
    if (localStorage.getItem('pm-auth-token')) {
      return localStorage.getItem('pm-auth-token');
    }
    return null;
  };

  getPMToken = () => {
    const user = Cookies.getJSON('user') || {};
    return user?.pm_access_token;
  };

  getPSAPIKey = () => {
    const user = Cookies.getJSON('user') || {};
    return user?.access_token;
  };

  getBaseURL = () => {
    return PM_BASE_URL;
  };

  getBrandProfileList = async () => {
    let config = {
      method: 'get',
      url: PMAPI_BASE_URL + '/brandprofiles',
      headers: {
        'Content-Type': 'application/json',
        'X-Authorization': this.getPMToken(),
      },
    };
    try {
      const res = await axios(config);
      if (res.status === 200) {
        return res.data;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  getBrandProfileKey = async (salesApiKey, brand, genericToken) => {
    var data = {
      api_key: salesApiKey,
      brand_profile_id: brand,
    };

    let config = {
      method: 'post',
      url: PMAPI_BASE_URL + '/integration/create',
      headers: {
        'Content-Type': 'application/json',
        'X-Authorization': genericToken,
      },
      data: data,
    };
    try {
      const res = await axios(config);
      if (res.status === 200) {
        return res.data;
      }
      return false;
    } catch (error) {
      return false;
    }
  };
  
  updateBrandProfileKey = async (newApiKey, oldApiKey, brand, genericToken) => {
    var data = {
      new_api_key: newApiKey,
      old_api_key: oldApiKey,
      brand_profile_id: brand,
    };
    let config = {
      method: 'post',
      url: PMAPI_BASE_URL + '/integration/update',
      headers: {
        'Content-Type': 'application/json',
        'X-Authorization': genericToken,
      },
      data: data,
    };
    try {
      const res = await axios(config);
      
      if (res.status === 200) {
        return res.data;
      }
      return false;
    } catch (error) {
      return false;
    }
  };


  getGenericAcesssToken = async () => {
    const psApiKey = this.getPSAPIKey();
    if (!psApiKey) return false;

    let data = {
      api_key: psApiKey,
    };

    let config = {
      method: 'post',
      url: PMAPI_BASE_URL + '/apiKey/main',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.getAuthToken(),
      },
      data: data,
    };
    try {
      const res = await axios(config);
      if (res.status === 200) {
        return res.data;
      }
      return false;
    } catch (error) {
      return false;
    }
  };
}

export default new PMConnectAPI();
