import { render, staticRenderFns } from "./SHImage.vue?vue&type=template&id=3468d1c4&scoped=true&"
import script from "./SHImage.vue?vue&type=script&lang=js&"
export * from "./SHImage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3468d1c4",
  null
  
)

export default component.exports