/* global axios */
import ApiClient from './ApiClient';

class UserAttributeAPI extends ApiClient {
  constructor() {
    super('', { accountScoped: true });
  }

  incrementStepAttribute(step) {
    return axios.post(`${this.url}/increment_step`,{
        step: parseInt(step),
    });
  }

  updateCustomAttribute(attributeKey,attributeValue){
      return axios.post(`${this.url}/add_user_custom_attributes`,{
        ca_key: attributeKey,
        ca_value: attributeValue
      });
  }

}

export default new UserAttributeAPI();
