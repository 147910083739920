<template>
  <woot-modal :show.sync="show" :on-close="onClose">
    <woot-modal-header
      :header-title="isInComing ? $t('CONVERSATION.ORDER')  : $t('CONVERSATION.YOUR_SENT_CART')"
    />
    <div class="odr-msg">
      <h6>{{ productRetailIds.length }} {{ productRetailIds.length === 1 ? $t('CONVERSATION.ITEM') : $t('CONVERSATION.ITEMS') }}</h6>
      <span
        v-if="isInComing"
      >{{ $t('CONVERSATION.MYR') }} {{ calculateTotalItems }} ({{ $t('CONVERSATION.ESTIMATED') }})</span>
      <div
        class="row odr-content"
        v-for="(item, itemIndex) in productDetailWithPriceQuantity"
        :key="itemIndex"
      >
        <div class="column small-2 img-card card">
          <bubble-image class="food-img" :url="item.image_link" />
        </div>
        <div class="odr-detail column small-9">
          <h6>{{ item.retailer_id }}</h6>
          <p>{{ item.description }}</p>
          <span>{{ item.order_item_price }} {{ item.order_currency }}</span>
          <span>{{ item.order_quantity }}</span>
          <span>{{ item.total_order_price }} {{ item.order_currency }}</span>
        </div>
      </div>
      <h6 v-if="isInComing">{{ $t('CONVERSATION.TOTAL_PRICE') }}: {{ calculateTotalItems }}</h6>
      <span>{{ readableTime }}</span>
    </div>
  </woot-modal>
</template>

<script>
import BubbleImage from '../bubble/Image.vue';
import CatalogueAPI from '../../../../api/inbox/catalogue';

export default {
  name: 'CartItems',
  components: { BubbleImage },
  props: {
    contentAttributes: {
      type: Object,
      required: true,
    },
    productItems: {
      // type: Object || Array,
      required: true,
    },
    isInComing: {
      type: Boolean,
      required: true,
    },
    inboxId: {
      type: Number,
      required: true,
    },
    readableTime: {
      type: String,
      default: '',
    },
    calculateTotalItems: {
      type: Number,
      default: 0,
    },
    productDetails: {
      type: Array,
      default: [],
    },
  },
  computed: {
    productRetailIds() {
      if (this.isInComing) {
        return this.productItems.map(item => item.product_retailer_id);
      } else {
        return this.productItems.product_items.map(
          item => item.product_retail_id
        );
      }
    },
    productDetailWithPriceQuantity() {
      let tempData = [];
      if (this.isInComing) {
        this.productDetails.map(data => {
          const foundProduct = this.productItems.find(
            product => product.product_retailer_id === data.retailer_id
          );
          tempData.push({
            ...data,
            order_item_price: foundProduct.item_price,
            order_quantity: foundProduct.quantity,
            order_currency: foundProduct.currency,
            total_order_price: foundProduct.item_price * foundProduct.quantity,
          });
        });
      } else {
        tempData = this.productDetails;
      }
      return tempData;
    },
  },
  data() {
    return {
      show: true,
    };
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.odr-msg {
  display: flex;
  flex-direction: column;
  padding: 20px;
  .odr-content {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .img-card {
      margin-bottom: 0;
      padding: 0;
      .food-img {
        height: 100%;
        width: 100%;
        img {
          height: 100%;
          width: 100%;
        }
      }
    }
    .odr-detail {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 10px;
      text-align: left;
    }
  }
}
</style>