<template>
  <div class="row">
    <div class="column content-box">
      <div
        style="display:flex;justify-content: space-between; padding-left: 32px;padding-right: 32px;"
      >
        <span></span>
        <!-- search element here  -->
        <woot-button
          :class-names="this.allowCreation ? '' : 'disabled'"
          @click="openAddPopup"
        >
          + {{ $t('FLOW.CREATE_NEW_FLOW') }}
        </woot-button>
      </div>
      <input @input="searchFlow" type="search" v-model="searchFlowItem" style="width:70%;padding: 24px; align-self: center; margin:10px auto;" placeholder="Search flow">
      <div
        style="width:100%;padding: 24px; display: flex; justify-content: center;"
      >
        <woot-loading-state v-if="gettingBots" message="Fetching Bots" />

        <div
          v-if="showFlowList"
          style="width:75%;padding: 24px; align-self: center;"
        >
          <h1 v-if="flowList.length == 0" class="no-flow-msg">
            {{ $t('FLOW.404') }}
          </h1>

          <table v-if="flowList.length != 0" class="flow-list-table">
            <tr class="flow-header">
              <td class="center">
                <h1 class="name">
                  {{ $t('FLOW.NAME') }}
                </h1>
              </td>
              <td class="center">
                <h1 class="name flex">
                  {{ $t('FLOW.MODIFIED') }}

                  <img
                    v-if="modifiedSort"
                    src="~dashboard/assets/images/arrow-down-short.svg"
                    @click="onModifiedSortChange"
                  />

                  <img
                    v-else
                    src="~dashboard/assets/images/arrow-up-short.svg"
                    @click="onModifiedSortChange"
                  />
                </h1>
              </td>

              <td class="center">
                <h1 class="name">
                  {{ $t('FLOW.ACTIONS') }}
                </h1>
              </td>
            </tr>

            <tr
              v-for="(flowItem, index) in flowList"
              :key="index"
              class="flow-box"
              @click="itemClick($event, flowItem)"
            >
              <td class="left">
                <h1 class="name" @mousedown="aboutToSelect(true)">
                  {{ flowItem.name
                  }}<span
                    v-if="flowItem.template"
                    style="
                      margin-left: 5px;
                      background-color: gold;
                      border-radius: 5px;
                      padding: 5px;
                      font-size: 10px;
                      color: white;
                          "
                    >{{ $t('FLOW.TEMPLATE') }}</span
                  >
                </h1>
              </td>
              <td class="center">
                <h1 class="date">
                  {{
                    moment(flowItem.modified)
                      .local()
                      .fromNow()
                  }}
                </h1>
              </td>

              <td class="right">
                <woot-button
                  variant="smooth"
                  size="small"
                  color-scheme="secondary"
                  icon="delete"
                  class-names="grey-btn btn-width-auto"
                  @click="deleteFlow(flowItem)"
                >
                </woot-button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <woot-modal :show.sync="showAddPopup" :on-close="hideAddPopup">
      <create-flow
        :user-id="this.userId"
        :account-id="this.accountId"
        @on-close="hideAddPopup"
        @create-flow="createNewFlow"
      />
    </woot-modal>

    <woot-modal
      :show.sync="showDeleteConfirmation"
      :on-close="hideDeleteConfirmation"
    >
      <delete-flow
        :bot="currentBot"
        @on-close="hideDeleteConfirmation"
        @delete-flow="deleteFlowConfirmed"
      />
    </woot-modal>
  </div>
</template>
<script>
import alertMixin from 'shared/mixins/alertMixin';
import { mapGetters } from 'vuex';
import FlowApi from '../FlowApi';
import CreateFlow from './modals/CreateFlow';
import DeleteFlow from './modals/DeleteFlow';
import './style.css';

import moment from 'moment';

export default {
  name: 'FlowList',
  components: {
    CreateFlow,
    DeleteFlow,
  },
  mixins: [alertMixin],
  data: () => ({
    flowList: [],
    searchFlowItem:"",
    showFlowList: true,
    show: true,
    showAddPopup: false,
    userId: null,
    currentBot: null,
    showAddMenu: false,
    showDeleteConfirmation: false,
    moment: moment,
    gettingBots: false,
    modifiedSort: true,
    isDeleting: false,
    isSelecting: false,
    accountId: null,
    allowCreation: false,
  }),

  computed: {
    ...mapGetters({
      currentUserId: 'getCurrentUserID',
      currentUser: 'getCurrentUser',
      userPlan: 'accounts/getUserPlan',
    }),
  },

  watch: {
    $route: async function(to) {
      let path = to.fullPath;
      if (path.endsWith('/campaigns/flows')) {
        this.getBotsByUserID();
      }
    },

    userPlan(newUserPlan) {
      console.log('userplan', newUserPlan.max_chatbot, this.flowList.length);
      // this.allowCreation = true;

      this.checkForBotLimit(newUserPlan, this.flowList);
    },

    currentUserId(newCurrentUserId, prevCurrentUserId) {
      if (prevCurrentUserId !== newCurrentUserId || newCurrentUserId) {
        this.userId = newCurrentUserId;
        this.getBotsByUserID();
      }
    },

    currentUser(newCurrentUser, prevCurrentUser) {
      if (
        newCurrentUser !== prevCurrentUser ||
        (newCurrentUser && newCurrentUser.account_id)
      ) {
        this.accountId = newCurrentUser.account_id;
        this.getBotsByUserID();
      }
    },
  },

  async mounted() {
    if (this.currentUserId && this.currentUser && this.currentUser.account_id) {
      this.userId = this.currentUserId;
      this.accountId = this.currentUser.account_id;
      this.getBotsByUserID();
    }
  },
  async created() {
    let path = this.$route.path;

    if (path.endsWith('/campaigns/flows')) {
      this.getBotsByUserID();
    }
    if (this.currentUserId) {
      this.userId = this.currentUserId;
      this.getBotsByUserID();
    }
  },
  methods: {
    checkForBotLimit(userPlan, alreadyCreated) {
      if (userPlan && userPlan.max_chatbot) {
        if (userPlan.max_chatbot > alreadyCreated.length) {
          this.allowCreation = true;
        } else {
          this.allowCreation = false;
        }
      } else {
        this.allowCreation = false;
      }
    },

    aboutToSelect(val) {
      this.isSelecting = val;
    },

    onModifiedSortChange() {
      this.modifiedSort = !this.modifiedSort;
      this.getBotsByUserID();
    },
    itemClick(event, bot) {
      var selection = window.getSelection().toString();

      if (selection !== '') {
        return;
      }

      if (event.target.tagName === 'H1' || event.target.tagName === 'TD') {
        const params = {
          botid: bot.botid,
          flowid: bot.botid,
        };

        this.$router.push({
          name: 'settings_account_campaigns_flow_edit',
          params,
        });
      }
    },
    async getBotsByUserID() {
      if (!this.userId || !this.accountId) {
        return;
      }
      this.gettingBots = true;

      this.showFlowList = false;

      let response = await FlowApi.getBotsByUserId(
        this.userId,
        this.modifiedSort,
        this.accountId
      );

      if (response.status === 'ok') {
        this.flowList = [];

        this.checkForBotLimit(this.userPlan, response.data);

        console.log(
          'no. of pre existing bots',
          response.data.length,
          this.userPlan
        );
        // filter the search item here _._
        const searchRegex = new RegExp(this.searchFlowItem,'i');
        const filteredResponse = response.data.filter(el => searchRegex.test(el.botid));
        filteredResponse.forEach(element => {
          this.flowList.push({
            name: element.botid,
            botid: element.botid,
            createAt: new Date(),
            modified: element.modified,
            template: element.template,
          });
        });
        this.showFlowList = true;
      } else {
        this.showAlert(response.message);
      }

      this.gettingBots = false;
    },

    async deleteFlowConfirmed(response) {
      if (response) {
        this.hideDeleteConfirmation();
        this.showAlert('Bot Deleted!');

        this.getBotsByUserID();
      } else {
        this.showAlert('Could not delete bot');
      }

      this.isDeleting = false;
    },
    deleteFlow(bot) {
      this.currentBot = bot;
      this.openDeleteConfirmation();
    },

    async createNewFlow(response) {
      if (response) {
        this.showAddPopup = false;
        this.showAlert(this.$t('FLOW.NEW_BOT_CREATED'));
        this.getBotsByUserID();
      } else {
        this.showAlert(this.$t('FLOW.COULD_NOT_CREATE_BOT'));
      }
    },

    openAddPopup() {
      if (this.allowCreation) {
        this.showAddPopup = true;
      } else {
        this.showAlert(this.$t('FLOW.PLEASE_UPGRADE_YOUR_PLAN'));
      }
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },

    openDeleteConfirmation() {
      this.showDeleteConfirmation = true;
    },
    hideDeleteConfirmation() {
      this.showDeleteConfirmation = false;
    },
    // search the flow items _._
    searchFlow(){
      this.getBotsByUserID();
    },
    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

<style lang="scss" scoped>
.content-box {
  overflow: unset;
}

.btn-width-auto{
  width: auto !important;
}
</style>
