<template>
  <woot-modal :show="modalValue" :on-close="closeModal">
    <add-top-up-container
      :modal-value="this.modalValue"
      :registration-fee="this.registrationFee"
      :user-balance="userBalance"
      :product-id="this.productId"
      :old-plan-id="this.oldPlanId"
    />
  </woot-modal>
</template>

<script>
import AddTopUpContainer from './AddTopUpContainer';

export default {
  name: 'StripeComponent',
  components: {
    AddTopUpContainer,
  },
  props: {
    modalValue: {
      type: Boolean,
      required: true,
    },
    registrationFee: {
      type: Boolean,
      required: false,
    },
    userBalance: {
      type: Number,
      required: false,
    },
    productId: {
      type: String,
      default: '',
      required: false,
    },
    oldPlanId: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      topUpAmount: '',
    };
  },
  methods: {
    closeModal() {
      this.modalValue = false;
      this.$emit('close-modal');
    },
  },
};
</script>
