/* eslint no-console: 0 */
/* eslint no-param-reassign: 0 */
/* eslint no-shadow: 0 */
import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../mutation-types';
import AccountAPI from '../../api/account';

const state = {
  records: [],
  basePath: '',
  contactStatus: [],
  communalInboxes: {},
  userPlan: [],
  subscriptionPlans: [],
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
    isUpdating: false,
  },
};

export const getters = {
  getAccount: $state => id => {
    return $state.records.find(record => record.id === Number(id));
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getBasePath($state) {
    return $state.basePath;
  },
  getContactStatus($state) {
    return $state.contactStatus;
  },
  getCommunalInboxes($state) {
    return $state.communalInboxes;
  },
  getSubscriptionPlans($state) {
    return $state.subscriptionPlans;
  },
  getUserPlan($state) {
    return $state.userPlan;
  },
};

export const actions = {
  get: async ({ commit }) => {
    commit(types.default.SET_ACCOUNT_UI_FLAG, { isFetchingItem: true });
    try {
      const response = await AccountAPI.get();
      commit(types.default.ADD_ACCOUNT, response.data);
      commit(types.default.SET_ACCOUNT_UI_FLAG, {
        isFetchingItem: false,
      });
    } catch (error) {
      commit(types.default.SET_ACCOUNT_UI_FLAG, {
        isFetchingItem: false,
      });
    }
  },
  fetchBasePath: async ({ commit }) => {
    try {
      const response = await AccountAPI.fetchBasePath();
      commit(types.default.SET_BASE_PATH, response.data);
    } catch (error) {
      commit(types.default.SET_ACCOUNT_UI_FLAG, {
        isFetchingItem: false,
      });
    }
  },
  fetchContactStatus: async ({ commit }) => {
    try {
      const response = await AccountAPI.fetchContactStatus();
      commit(types.default.SET_CONTACT_STATUS, response.data);
    } catch (error) {
      commit(types.default.SET_ACCOUNT_UI_FLAG, {
        isFetchingItem: false,
      });
    }
  },
  createTemplateMessage: async ({ commit }, data) => {
    try {
      const response = await AccountAPI.createTemplateMsg(data);
      commit(types.default.ADD_CANNED, response.data);
    } catch (error) {
      console.log(error, 'error enter');
      throw error;
    }
  },
  fetchCommunalInboxes: async ({ commit }) => {
    try {
      const response = await AccountAPI.fetchCommunalInboxes();
      commit(types.default.SET_COMMUNAL_INBOXES, response.data);
    } catch (error) {
      commit(types.default.SET_ACCOUNT_UI_FLAG, {
        isFetchingItem: false,
      });
    }
  },
  fetchSubscriptionPlans: async ({ commit }) => {
    try {
      const response = await AccountAPI.fetchSubscriptionPlans();
      commit(types.default.SET_SUBSCRIPTION_PLANS, response.data);
    } catch (error) {
      commit(types.default.SET_ACCOUNT_UI_FLAG, {
        isFetchingItem: false,
      });
    }
  },
  fetchAccountInboxes: async ({ commit }) => {
    try {
      const response = await AccountAPI.fetchAccountInbox();
      commit(types.default.SET_USER_PLAN, response.data);
      return response.data;
    } catch (error) {
      commit(types.default.SET_ACCOUNT_UI_FLAG, {
        isFetchingItem: false,
      });
      console.log(error, 'error');
      throw error;
    }
  },
  update: async ({ commit }, updateObj) => {
    commit(types.default.SET_ACCOUNT_UI_FLAG, { isUpdating: true });
    try {
      await AccountAPI.update('', updateObj);
      commit(types.default.SET_ACCOUNT_UI_FLAG, { isUpdating: false });
    } catch (error) {
      commit(types.default.SET_ACCOUNT_UI_FLAG, { isUpdating: false });
      throw new Error(error);
    }
  },
  create: async ({ commit }, accountInfo) => {
    commit(types.default.SET_ACCOUNT_UI_FLAG, { isCreating: true });
    try {
      const response = await AccountAPI.createAccount(accountInfo);
      const account_id = response.data.data.account_id;
      commit(types.default.SET_ACCOUNT_UI_FLAG, { isCreating: false });
      return account_id;
    } catch (error) {
      commit(types.default.SET_ACCOUNT_UI_FLAG, { isCreating: false });
      throw error;
    }
  },
  fetchTopUpDetails: async () => {
    try {
      const response = await AccountAPI.fetchTopUpDetails();
      return response.data;
    } catch (error) {
      console.log(error, 'error');
      throw error;
    }
  },
  fetchUsdPrice: async () => {
    try {
      const response = await AccountAPI.fetchUsdPrice();
      return response.data.price;
    } catch (error) {
      console.log(error, 'error');
      throw error;
    }
  },
};

export const mutations = {
  [types.default.SET_ACCOUNT_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.default.SET_BASE_PATH]($state, data) {
    $state.basePath = data.base_path;
  },
  [types.default.SET_CONTACT_STATUS]($state, data) {
    $state.contactStatus = data.contact_status;
  },
  [types.default.SET_COMMUNAL_INBOXES]($state, data) {
    $state.communalInboxes = data;
  },
  [types.default.SET_USER_PLAN]($state, data) {
    $state.userPlan = data;
  },
  [types.default.SET_SUBSCRIPTION_PLANS]($state, data) {
    $state.subscriptionPlans = data;
  },
  [types.default.ADD_ACCOUNT]: MutationHelpers.setSingleRecord,
  [types.default.EDIT_ACCOUNT]: MutationHelpers.update,
  [types.default.ADD_CANNED]: MutationHelpers.create,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
