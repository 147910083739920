<template>
  <div
    class="filter"
    :class="{
      error:
        (v.action_params.$dirty && v.action_params.$error) ||
        (value.action_name === 'trigger_event' &&
          v.notNeeded.$dirty &&
          v.notNeeded.$error),
    }"
  >
    <div class="filter-inputs column">
      <select
        v-model="action_name"
        class="action__question"
        :class="{ 'full-width': !showActionInput }"
        @change="resetAction()"
      >
        <option
          v-for="attribute in actionTypes"
          :key="attribute.key"
          :value="attribute.key"
        >
          {{ attribute.label }}
        </option>
      </select>
      <div
        v-if="value.action_name === 'assign_bot'"
        class="multiselect-wrap--small column padding-right-small"
      >
        <input
          v-model="bot_id"
          type="text"
          class="answer--text-input"
          placeholder="Enter bot id"
        />
      </div>
      <div
        v-if="value.action_name === 'assign_bot'"
        class="multiselect-wrap--small column"
      >
        <multiselect
          v-model="action_params"
          track-by="id"
          label="name"
          :placeholder="'Select'"
          :multiple="true"
          selected-label
          :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
          deselect-label=""
          :max-height="160"
          :options="inboxes"
          :allow-empty="false"
        />
      </div>
      <div
        v-else-if="value.action_name === 'set_contact_attributes'"
        class="multiselect-wrap--small column"
      >
        <multiselect
          v-model="action_params"
          track-by="key"
          :multiple="true"
          label="name"
          :placeholder="'Select'"
          selected-label
          :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
          deselect-label=""
          :max-height="160"
          :options="customAttributes"
          :allow-empty="false"
        />
      </div>
      <div
        v-else-if="value.action_name === 'trigger_event'"
        class="multiselect-wrap--small column"
      >
        <input
          v-model="action_params"
          type="text"
          class="answer--text-input"
          placeholder="Enter event name"
        />
      </div>
      <div v-else-if="value.action_name === 'send_message'" class="multiselect-wrap--small column">
        <multiselect
          v-model="action_params"
          track-by="id"
          label="name"
          :placeholder="'Select'"
          :multiple="true"
          selected-label
          :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
          deselect-label=""
          :max-height="160"
          :options="dropdownValues"
          :allow-empty="false"
        />
      </div>
      <div v-else class="multiselect-wrap--small column">
        <multiselect
          v-model="action_params"
          track-by="id"
          label="name"
          :placeholder="'Select'"
          :multiple="true"
          selected-label
          :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
          deselect-label=""
          :max-height="160"
          :options="dropdownValues"
          :allow-empty="false"
        />
      </div>
      <woot-button
        icon="dismiss"
        variant="clear"
        color-scheme="secondary"
        @click="removeAction"
      />
    </div>
    <div
      v-if="value.action_name === 'send_message'"
      class="multiselect-wrap--small column padding-right-small margin-top"
    >
      <canned-response
        v-if="
          showMentions && hasSlashCommand && nonCommunalInboxes.length === 0
        "
        v-on-clickaway="hideMentions"
        top="37"
        :search-key="mentionSearchKey"
        @click="replaceText"
      />
      <textarea
        v-if="value.action_params && value.action_params.length > 0"
        v-model="message_content"
        rows="4"
        :placeholder="$t('AUTOMATION.ADD_MESSAGE_CONTENT')"
        class="action-message"
      ></textarea>
    </div>
    <div
      v-if="value.action_name === 'set_contact_attributes'"
      class="multiselect-wrap--small column padding-right-small margin-top"
    >
      <textarea
        v-model="content"
        rows="1"
        :placeholder="$t('AUTOMATION.ADD_CONTENT')"
        class="action-message"
      ></textarea>
    </div>
    <p
      v-if="v.action_params.$dirty && v.action_params.$error"
      class="filter-error"
    >
      {{ $t('FILTER.EMPTY_VALUE_ERROR') }}
    </p>
    <p
      v-if="
        value.action_name === 'trigger_event' &&
          v.notNeeded.$dirty &&
          v.notNeeded.$error
      "
      class="filter-error"
    >
      {{ $t('FILTER.NOT_BE_SAME') }}
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CannedResponse from './conversation/CannedResponse.vue';

export default {
  components: {
    CannedResponse,
  },
  props: {
    value: {
      type: Object,
      default: () => null,
    },
    actionTypes: {
      type: Array,
      default: () => [],
    },
    dropdownValues: {
      type: Array,
      default: () => [],
    },
    v: {
      type: Object,
      default: () => null,
    },
    showActionInput: {
      type: Boolean,
      default: true,
    },
    customAttributes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      nonCommunalInboxes: [],
      mentionSearchKey: '',
      templateName: '',
      showMentions: false,
      hasSlashCommand: false,
    };
  },
  watch: {
    message_content(updatedMessage) {
      this.hasSlashCommand = updatedMessage && updatedMessage[0] === '/';
      const hasNextWord = updatedMessage && updatedMessage.includes(' ');
      const isShortCodeActive = this.hasSlashCommand && !hasNextWord;
      if (isShortCodeActive && this.nonCommunalInboxes.length === 0) {
        this.mentionSearchKey = updatedMessage.substr(1, updatedMessage.length);
        this.showMentions = true;
      } else if (
        isShortCodeActive &&
        this.nonCommunalInboxes.length !== 0 &&
        updatedMessage.length < 5
      ) {
        this.showAlert(
          'Please select only 360 whatsapp inbox to use canned response.'
        );
      } else {
        this.mentionSearchKey = '';
        this.showMentions = false;
      }
    },
    action_params(updatedParams) {
      this.nonCommunalInboxes = updatedParams.filter(
          inbox => inbox.channel_type !== 'Channel::Whatsapp'
        );
        if (
          this.nonCommunalInboxes.length > 0 &&
          this.templateName.length > 0
        ) {
          this.message_content = '';
          this.templateName = '';
        }
    }
  },
  computed: {
    ...mapGetters({
      inboxesList: 'inboxes/getInboxes',
    }),
    inboxes() {
      const filtered_array = this.inboxesList.filter(function(e) {
        return e.id === 'default';
      });
      if (filtered_array.length === 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.inboxesList.push({
          id: 'default',
          name: 'Default',
        });
      }
      return this.inboxesList;
    },
    action_name: {
      get() {
        if (!this.value) return null;
        return this.value.action_name;
      },
      set(value) {
        const payload = this.value || {};
        this.$emit('input', { ...payload, action_name: value });
      },
    },
    action_params: {
      get() {
        if (!this.value) return null;
        return this.value.action_params;
      },
      set(value) {
        const payload = this.value || {};
        this.$emit('input', { ...payload, action_params: value });
      },
    },
    bot_id: {
      get() {
        if (!this.value) return null;
        return this.value.bot_id;
      },
      set(value) {
        const payload = this.value || {};
        this.$emit('input', { ...payload, bot_id: value });
      },
    },
    message_content: {
      get() {
        if (!this.value) return null;
        return this.value.message_content;
      },
      set(value) {
        const payload = this.value || {};
        if (this.value && this.templateName.length > 0) {
          payload.template_name = this.templateName;
        }

        this.$emit('input', { ...payload, message_content: value });
      },
    },
    content: {
      get() {
        if (!this.value) return null;
        return this.value.content;
      },
      set(value) {
        const payload = this.value || {};
        this.$emit('input', { ...payload, content: value });
      },
    },
  },
  methods: {
    replaceText(message, templateName) {
      setTimeout(() => {
        this.templateName = templateName;
        this.message_content = message;
      }, 100);
    },
    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
    hideMentions() {
      this.showMentions = false;
    },
    removeAction() {
      this.$emit('removeAction');
    },
    resetAction() {
      this.$emit('resetAction');
    },
    resetFilter() {
      this.$emit('resetFilter');
    },
  },
};
</script>

<style lang="scss" scoped>
.filter {
  background: var(--color-background);
  padding: var(--space-small);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-medium);
  margin-bottom: var(--space-small);
}

.filter.error {
  background: var(--r-50);
}

.filter-inputs {
  display: flex;
}

.filter-error {
  color: var(--r-500);
  display: block;
  margin: var(--space-smaller) 0;
}

.action__question,
.filter__operator {
  margin-bottom: var(--space-zero);
  margin-right: var(--space-smaller);
}

.action__question {
  max-width: 50%;
}

.filter__answer--wrap {
  margin-right: var(--space-smaller);
  flex-grow: 1;

  input {
    margin-bottom: 0;
  }
}
.filter__answer {
  &.answer--text-input {
    margin-bottom: var(--space-zero);
  }
}
.margin-top {
  margin-top: 10px;
}
.filter__join-operator-wrap {
  position: relative;
  z-index: var(--z-index-twenty);
  margin: var(--space-zero);
}

.filter__join-operator {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: var(--space-one) var(--space-zero);

  .operator__line {
    position: absolute;
    width: 100%;
    border-bottom: 1px solid var(--color-border);
  }

  .operator__select {
    position: relative;
    width: auto;
    margin-bottom: var(--space-zero) !important;
  }
}

.multiselect {
  margin-bottom: var(--space-zero);
}
</style>
