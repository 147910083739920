import {
  DuplicateContactException,
  ExceptionWithMessage,
} from 'shared/helpers/CustomErrors';
import types from '../../mutation-types';
import ContactAPI from '../../../api/contacts';
import AccountActionsAPI from '../../../api/accountActions';

export const actions = {
  search: async ({ commit }, { search, page, sortAttr, label,isInfinityScroll }) => {
    commit(types.SET_CONTACT_UI_FLAG, { isFetching: true });
    try {
      const {
        data: { payload, meta },
      } = await ContactAPI.search(search, page, sortAttr, label);
      console.log({isInfinityScroll});
      if (isInfinityScroll) {
        // no need clear contacts, only append
        commit(types.SET_CONTACTS_INFINITYSCROLL, payload);
      }else{
        commit(types.CLEAR_CONTACTS);
        commit(types.SET_CONTACTS, payload);
      }
      commit(types.SET_CONTACT_META, meta);
      commit(types.SET_CONTACT_UI_FLAG, { isFetching: false });
    } catch (error) {
      commit(types.SET_CONTACT_UI_FLAG, { isFetching: false });
    }
  },

  get: async ({ commit }, { page = 1, sortAttr, label,isInfinityScroll=false } = {}) => {
    commit(types.SET_CONTACT_UI_FLAG, { isFetching: true });
    try {
      const {
        data: { payload, meta },
      } = await ContactAPI.get(page, sortAttr, label);
      if (isInfinityScroll) {
        // no need clear contacts, only append
        commit(types.SET_CONTACTS_INFINITYSCROLL, payload);
      }else{
        commit(types.CLEAR_CONTACTS);
        commit(types.SET_CONTACTS, payload);
      }
      commit(types.SET_CONTACT_META, meta);
      commit(types.SET_CONTACT_UI_FLAG, { isFetching: false });
    } catch (error) {
      commit(types.SET_CONTACT_UI_FLAG, { isFetching: false });
    }
  },

  localSort: async (
    { commit },
    { sortDetails, records, isCustomAttribute = false } = {}
  ) => {
    commit(types.SET_CONTACT_UI_FLAG, { isFetching: true });
    try {
      let data = records;

      data.sort((a, b) => {
        let A = a.custom_attributes[sortDetails.key];
        let B = b.custom_attributes[sortDetails.key];
        if (isCustomAttribute) {
          A = a[sortDetails.key];
          B = b[sortDetails.key];
        }
        if (A === B) {
          // equal items sort equally
          return 0;
        }

        if (A === null || A === undefined) {
          // nulls sort after anything else
          return 1;
        }

        if (B === null || B === undefined) {
          return -1;
        }

        if (sortDetails.order === 'asc') {
          // otherwise, if we're ascending, lowest sorts first
          return A < B ? -1 : 1;
        }
        // if descending, highest sorts first
        return A < B ? 1 : -1;
      });

      commit(types.CLEAR_CONTACTS);
      commit(types.SET_CONTACTS, data);
      commit(types.SET_CONTACT_UI_FLAG, { isFetching: false });
    } catch (error) {
      commit(types.SET_CONTACT_UI_FLAG, { isFetching: false });
    }
  },

  show: async ({ commit }, { id }) => {
    commit(types.SET_CONTACT_UI_FLAG, { isFetchingItem: true });
    try {
      const response = await ContactAPI.show(id);
      commit(types.SET_CONTACT_ITEM, response.data.payload);
      commit(types.SET_CONTACT_UI_FLAG, {
        isFetchingItem: false,
      });
    } catch (error) {
      commit(types.SET_CONTACT_UI_FLAG, {
        isFetchingItem: false,
      });
    }
  },

  update: async ({ commit }, { id, ...updateObj }) => {
    commit(types.SET_CONTACT_UI_FLAG, { isUpdating: true });
    try {
      const response = await ContactAPI.update(id, updateObj);
      commit(types.EDIT_CONTACT, response.data.payload);
      commit(types.SET_CONTACT_UI_FLAG, { isUpdating: false });
    } catch (error) {
      commit(types.SET_CONTACT_UI_FLAG, { isUpdating: false });
      if (error.response?.status === 422) {
        throw new DuplicateContactException(error.response.data.attributes);
      } else {
        throw new Error(error);
      }
    }
  },

  updateMany: async ({ commit }, { contact_ids, updatedAttributes }) => {
    commit(types.SET_CONTACT_UI_FLAG, { isUpdating: true });
    try {
      const response = await ContactAPI.updateMany(contact_ids,updatedAttributes);
      console.log(response);
      if (response.status === 200) {
        commit(types.EDIT_CONTACTS, { contact_ids, updatedAttributes });
      }
      commit(types.SET_CONTACT_UI_FLAG, { isUpdating: false });
    } catch (error) {
      commit(types.SET_CONTACT_UI_FLAG, { isUpdating: false });
      if (error.response?.status === 422) {
        throw new DuplicateContactException(error.response.data.attributes);
      } else {
        throw new Error(error);
      }
    }
  },

  create: async ({ commit }, userObject) => {
    commit(types.SET_CONTACT_UI_FLAG, { isCreating: true });
    try {
      const response = await ContactAPI.create(userObject);
      commit(types.SET_CONTACT_ITEM, response.data.payload.contact);
      commit(types.SET_CONTACT_UI_FLAG, { isCreating: false });
    } catch (error) {
      commit(types.SET_CONTACT_UI_FLAG, { isCreating: false });
      if (error.response?.data?.message) {
        throw new ExceptionWithMessage(error.response.data.message);
      } else {
        throw new Error(error);
      }
    }
  },
  import: async ({ commit }, file) => {
    commit(types.SET_CONTACT_UI_FLAG, { isCreating: true });
    try {
      await ContactAPI.importContacts(file);
      commit(types.SET_CONTACT_UI_FLAG, { isCreating: false });
    } catch (error) {
      commit(types.SET_CONTACT_UI_FLAG, { isCreating: false });
      if (error.response?.data?.message) {
        throw new ExceptionWithMessage(error.response.data.message);
      }
    }
  },
  delete: async ({ commit }, id) => {
    commit(types.SET_CONTACT_UI_FLAG, { isDeleting: true });
    try {
      await ContactAPI.delete(id);
      commit(types.SET_CONTACT_UI_FLAG, { isDeleting: false });
    } catch (error) {
      commit(types.SET_CONTACT_UI_FLAG, { isDeleting: false });
      if (error.response?.data?.message) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error);
      }
    }
  },

  deleteMany: async({commit},ids) =>{
    try {
      commit(types.SET_CONTACT_UI_FLAG, { isDeletingMany: true });
      // fetch API to delete from database _._
      const response =  await ContactAPI.deleteMany(ids);
      console.log(response,"response");
      // commit to mutation to update vuex state
      ids.forEach(id =>{
        commit(types.DELETE_CONTACT, id);
      })
      commit(types.SET_CONTACT_UI_FLAG, { isDeletingMany: false });
    } catch (error) {
      commit(types.SET_CONTACT_UI_FLAG, { isDeletingMany: false });
      throw new Error(error);
    }
  },

  updateStatus: async ({ commit }, { id, statusId }) => {
    try {
      const response = await ContactAPI.updateInboxStatus(id, statusId);
      commit(types.EDIT_CONTACT, response.data.payload);
    } catch (error) {
      if (error.response?.data?.message) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error);
      }
    }
  },

  deleteCustomAttributes: async ({ commit }, { id, customAttributes }) => {
    try {
      const response = await ContactAPI.destroyCustomAttributes(
        id,
        customAttributes
      );
      commit(types.EDIT_CONTACT, response.data.payload);
    } catch (error) {
      throw new Error(error);
    }
  },

  fetchContactableInbox: async ({ commit }, id) => {
    commit(types.SET_CONTACT_UI_FLAG, { isFetchingInboxes: true });
    try {
      const response = await ContactAPI.getContactableInboxes(id);
      const contact = {
        id,
        contactableInboxes: response.data.payload,
      };
      commit(types.SET_CONTACT_ITEM, contact);
    } catch (error) {
      if (error.response?.data?.message) {
        throw new ExceptionWithMessage(error.response.data.message);
      } else {
        throw new Error(error);
      }
    } finally {
      commit(types.SET_CONTACT_UI_FLAG, { isFetchingInboxes: false });
    }
  },

  updatePresence: ({ commit }, data) => {
    commit(types.UPDATE_CONTACTS_PRESENCE, data);
  },

  setContact({ commit }, data) {
    commit(types.SET_CONTACT_ITEM, data);
  },

  merge: async ({ commit }, { childId, parentId }) => {
    commit(types.SET_CONTACT_UI_FLAG, { isMerging: true });
    try {
      const response = await AccountActionsAPI.merge(parentId, childId);
      commit(types.SET_CONTACT_ITEM, response.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CONTACT_UI_FLAG, { isMerging: false });
    }
  },

  deleteContactThroughConversations: ({ commit }, id) => {
    commit(types.DELETE_CONTACT, id);
    commit(types.CLEAR_CONTACT_CONVERSATIONS, id, { root: true });
    commit(`contactConversations/${types.DELETE_CONTACT_CONVERSATION}`, id, {
      root: true,
    });
  },

  updateContact: async ({ commit }, updateObj) => {
    commit(types.SET_CONTACT_UI_FLAG, { isUpdating: true });
    try {
      commit(types.EDIT_CONTACT, updateObj);
      commit(types.SET_CONTACT_UI_FLAG, { isUpdating: false });
    } catch (error) {
      commit(types.SET_CONTACT_UI_FLAG, { isUpdating: false });
    }
  },

  filter: async ({ commit }, { page = 1, sortAttr, queryPayload,isInfinityScroll } = {}) => {
    commit(types.SET_CONTACT_UI_FLAG, { isFetching: true });
    try {
      const {
        data: { payload, meta },
      } = await ContactAPI.filter(page, sortAttr, queryPayload);

      console.log({isInfinityScroll});
      if (isInfinityScroll) {
        // no need clear contacts, only append
        commit(types.SET_CONTACTS_INFINITYSCROLL, payload);
      }else{
        commit(types.CLEAR_CONTACTS);
        commit(types.SET_CONTACTS, payload);
      }
      commit(types.SET_CONTACT_META, meta);
      commit(types.SET_CONTACT_UI_FLAG, { isFetching: false });
    } catch (error) {
      commit(types.SET_CONTACT_UI_FLAG, { isFetching: false });
    }
  },

  setContactFilters({ commit }, data) {
    commit(types.SET_CONTACT_FILTERS, data);
  },

  clearContactFilters({ commit }) {
    commit(types.CLEAR_CONTACT_FILTERS);
  },
};
