<template>
     <img :src="validatedSrc" :class="className" alt="Image" />
</template>

<script>
import placeholderImage from "../../../../dashboard/assets/images/external/img_placeholder.jpg";


export default {
    name: "SHImage",
    props:{
        src:{
            type: String,
            required: true,
        },
        className:{
            type: String,
        },
    },
    data(){
        return {
            validatedSrc: placeholderImage,
        }
    },
    watch:{
        src:{
            immediate: true,
            handler(newSrc){
                 this.validateImage(newSrc);
            },
        },
    },
    methods:{
        validateImage(url){
            const img = new Image();
            img.src = url;
            img.onload = () =>{
                this.validatedSrc = url;
            };
            img.onerror = () =>{
                this.validatedSrc = placeholderImage;
            };
        },
    },
}
</script>

<style scoped lang="scss">

</style>