import Vue from 'vue';
import types from '../../mutation-types';

export const mutations = {
  [types.SET_CONTACT_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },

  [types.CLEAR_CONTACTS]: $state => {
    Vue.set($state, 'records', {});
    Vue.set($state, 'sortOrder', []);
  },

  [types.SET_CONTACT_META]: ($state, data) => {
    const { count, current_page: currentPage } = data;
    Vue.set($state.meta, 'count', count);
    Vue.set($state.meta, 'currentPage', currentPage);
  },

  [types.SET_CONTACTS]: ($state, data) => {
    const sortOrder = data.map(contact => {
      Vue.set($state.records, contact.id, {
        ...($state.records[contact.id] || {}),
        ...contact,
      });
      return contact.id;
    });
    $state.sortOrder = sortOrder;
  },
  [types.SET_CONTACTS_INFINITYSCROLL]: ($state, data) => {
    console.log("before appent infinity",$state.records);
    
     // Append new contacts to the records
     data.forEach(contact => {
        Vue.set($state.records, contact.id, {
            ...($state.records[contact.id] || {}),
            ...contact,
        });
      });
      // Append new sortOrder to the existing sortOrder
    const newIds = data.map(contact => contact.id);
    $state.sortOrder = [...$state.sortOrder, ...newIds];

    console.log("after appent infinity",{records:$state.records,sortOrder:$state.sortOrder,state:$state});
  },

  [types.SET_CONTACT_ITEM]: ($state, data) => {
    Vue.set($state.records, data.id, {
      ...($state.records[data.id] || {}),
      ...data,
    });

    if (!$state.sortOrder.includes(data.id)) {
      $state.sortOrder.push(data.id);
    }
  },

  [types.EDIT_CONTACT]: ($state, data) => {
    Vue.set($state.records, data.id, data);
  },
  [types.EDIT_CONTACTS]: ($state, data) => {
    const { contact_ids, updatedAttributes } = data;
    $state.appliedFilters = []; // clear the filter
  
     // Create a new object to hold the updated records
      const updatedRecords = { ...$state.records };

      for (const contactId of contact_ids) {
        // Update status_id if it exists
        if (updatedAttributes.status_id) {
          updatedRecords[contactId] = {
            ...updatedRecords[contactId],
            status_id: updatedAttributes.status_id,
          };
        }

        // Update custom_attributes if it exists
        if (updatedAttributes.custom_attributes) {
          updatedRecords[contactId] = {
            ...updatedRecords[contactId],
            custom_attributes: {
              ...updatedRecords[contactId].custom_attributes,
              ...updatedAttributes.custom_attributes,
            },
          };
        }
      }

    // Replace the existing records with the updatedRecords
    $state.records = updatedRecords;
  },

  [types.DELETE_CONTACT]: ($state, id) => {
    const index = $state.sortOrder.findIndex(item => item === id);
    Vue.delete($state.sortOrder, index);
    Vue.delete($state.records, id);
  },

  [types.UPDATE_CONTACTS_PRESENCE]: ($state, data) => {
    Object.values($state.records).forEach(element => {
      const availabilityStatus = data[element.id];
      if (availabilityStatus) {
        Vue.set(
          $state.records[element.id],
          'availability_status',
          availabilityStatus
        );
      } else {
        Vue.delete($state.records[element.id], 'availability_status');
      }
    });
  },

  [types.SET_CONTACT_FILTERS](_state, data) {
    _state.appliedFilters = data;
  },

  [types.CLEAR_CONTACT_FILTERS](_state) {
    _state.appliedFilters = [];
  },
};
