<template>
  <div class="columns profile--settings">
    <section v-if="!uiFlags.isFetchingItem">
        <div class="small-12 row profile--settings--row">
            <div class="columns small-12 medium-12">
                <div class="logo_container" @click="triggerShopLogoInput">
                    <p class="logo_hint">Click to upload your logo</p>
                    <img
                        class="logo"
                        :src="newUploadedLogoPreview || merchant.logo_url"
                        alt=""

                    >
                    <input
                        type="file"
                        ref="shopLogoInput"
                        @change="handleShopLogoChange"
                        style="display: none"
                        accept="image/*"
                    />

                </div>
            </div>
            <div v-if="merchant.shop_url" class="columns small-12 medium-12">
                <QRCode :shop_url="merchant.shop_url" />
            </div>
            <div class="columns small-12 medium-12">
                <div class="row label_wrapper">
                    <label class="columns small-12 medium-6" :class="{ error: $v.name.$error }">
                        <!-- Company Name -->
                        {{$t('CATALOGUE.SETTING.FIELDS.COMPANY_LABLE')}}
                        <input
                            v-model="name"
                            type="text"
                            :placeholder="$t('CATALOGUE.SETTING.FIELDS.COMPANY_LABLE')"
                            @blur="$v.name.$touch"
                        />
                        <span v-if="$v.name.$error" class="message">
                            <!-- Field is required -->
                            {{$t('CATALOGUE.SETTING.FIELDS.COMPANY_ERR')}}
                        </span>
                    </label>
                    <label class="columns small-12 medium-6"
                    >
                        {{$t('CATALOGUE.SETTING.FIELDS.DESCRIPTION_LABLE')}}
                        <input
                            v-model="custom_attributes.description"
                            type="text"
                            :placeholder="$t('CATALOGUE.SETTING.FIELDS.DESCRIPTION_PLACEHOLDER')"
                        />
                    </label>
                    <label class="columns small-12 medium-6" :class="{ error: $v.custom_attributes.address.line1.$error }">
                        {{$t('CATALOGUE.SETTING.FIELDS.ADDRESS_1_LABLE')}}
                        <input
                            v-model="custom_attributes.address.line1"
                            type="text"
                            :placeholder="$t('CATALOGUE.SETTING.FIELDS.ADDRESS_1_PLACEHOLDER')"
                            @blur="$v.custom_attributes.address.line1.$touch"
                        />
                        <span v-if="$v.custom_attributes.address.line1.$error" class="message">
                            {{$t('CATALOGUE.SETTING.FIELDS.ADDRESS_1_ERR')}}
                        </span>
                    </label>
                    <label class="columns small-12 medium-6">
                        {{$t('CATALOGUE.SETTING.FIELDS.ADDRESS_2_LABLE')}}
                        <input
                            v-model="custom_attributes.address.line2"
                            type="text"
                            :placeholder="$t('CATALOGUE.SETTING.FIELDS.ADDRESS_2_PLACEHOLDER')"
                        />
                    </label>
                    <label class="columns small-12 medium-6" :class="{ error: $v.custom_attributes.address.city.$error }">
                        {{$t('CATALOGUE.SETTING.FIELDS.CITY_LABLE')}}
                        <input
                            v-model="custom_attributes.address.city"
                            type="text"
                            :placeholder="$t('CATALOGUE.SETTING.FIELDS.CITY_PLACEHOLDER')"
                            @blur="$v.custom_attributes.address.city.$touch"
                        />
                        <span v-if="$v.custom_attributes.address.city.$error" class="message">
                            {{$t('CATALOGUE.SETTING.FIELDS.CITY_ERR')}}
                        </span>
                    </label>
                    <label class="columns small-12 medium-6" :class="{ error: $v.custom_attributes.address.state.$error }">
                        {{$t('CATALOGUE.SETTING.FIELDS.STATE_LABLE')}}
                        <input
                            v-model="custom_attributes.address.state"
                            type="text"
                            :placeholder="$t('CATALOGUE.SETTING.FIELDS.STATE_PLACEHOLDER')"
                            @blur="$v.custom_attributes.address.state.$touch"
                        />
                        <span v-if="$v.custom_attributes.address.state.$error" class="message">
                            {{$t('CATALOGUE.SETTING.FIELDS.STATE_ERR')}}
                        </span>
                    </label>
                    <label class="columns small-12 medium-6">
                        {{$t('CATALOGUE.SETTING.FIELDS.POSTCODE_LABLE')}}
                        <input
                            v-model="custom_attributes.address.postcode"
                            type="text"
                            :placeholder="$t('CATALOGUE.SETTING.FIELDS.POSTCODE_PLACEHOLDER')"
                        />
                    </label>
                    <label class="columns small-12 medium-6" :class="{ error: $v.phone_number.$error }">
                        {{$t('CATALOGUE.SETTING.FIELDS.PHONE_NUMBER_LABLE')}}
                        <input
                            v-model="phone_number"
                            type="text"
                            :placeholder="$t('CATALOGUE.SETTING.FIELDS.PHONE_NUMBER_PLACEHOLDER')"
                            @blur="$v.phone_number.$touch"
                        />
                        <span v-if="$v.phone_number.$error" class="message">
                            {{$t('CATALOGUE.SETTING.FIELDS.PHONE_NUMBER_ERR')}}
                        </span>
                    </label>
                    <label  class="group_section flex_justfy_between columns small-12 medium-6">
                        {{$t('CATALOGUE.SETTING.FIELDS.SST_LABLE')}}
                        <button
                            type="button"
                            class="toggle-button"
                            :class="{ active: custom_attributes.sst }"
                            role="switch"
                            :aria-checked="custom_attributes.sst.toString()"
                            @click="custom_attributes.sst = !custom_attributes.sst"
                            >
                            <span
                                aria-hidden="true"
                                :class="{ active: custom_attributes.sst }"
                            ></span>
                        </button>
                    </label>

                    <label class="group_section flex_justfy_between columns small-12 medium-6">
                        {{$t('CATALOGUE.SETTING.FIELDS.PAYMENT_BEFORE_ORDER_LABLE')}}
                        <button
                            type="button"
                            class="toggle-button"
                            :class="{ active: custom_attributes.payment_before_order }"
                            :disabled="!tekkis_hook_configured"
                            role="switch"
                            :aria-checked="custom_attributes.payment_before_order.toString()"
                            @click="custom_attributes.payment_before_order = !custom_attributes.payment_before_order"
                            >
                            <span
                                aria-hidden="true"
                                :class="{ active: custom_attributes.payment_before_order }"
                            ></span>
                        </button>
                    </label>
                    <label class="group_section flex_justfy_between columns small-12 medium-6">
                        <span>
                            {{$t('CATALOGUE.SETTING.FIELDS.GEOLOCATION_LABLE')}}
                            <span class="geo_info">
                                <span class="tooltiptext">{{$t('CATALOGUE.SETTING.FIELDS.GEOLOCATION_LABLE_INFO')}}</span>
                                <fluent-icon size="16" icon="question-circle" />
                            </span>
                        </span>
                        <button
                            type="button"
                            class="toggle-button"
                            :class="{ active: custom_attributes.georestrict }"
                            role="switch"
                            :aria-checked="custom_attributes.georestrict.toString()"
                            @click="geoRestrictHandler"
                            >
                            <span
                                aria-hidden="true"
                                :class="{ active: custom_attributes.georestrict }"
                            ></span>
                        </button>
                    </label>

                    <label
                        class="group_section flex_justfy_between columns small-12 medium-6"
                    >
                        {{$t('CATALOGUE.SETTING.FIELDS.DELIVERY_ADDRESS_LABLE')}}
                        <!-- Delivery Address -->
                        <button
                            type="button"
                            class="toggle-button"
                            :class="{ active: custom_attributes.delivery }"
                            role="switch"
                            :aria-checked="custom_attributes.delivery.toString()"
                            @click="deliveryAddressToggle"
                            >
                            <span
                                aria-hidden="true"
                                :class="{ active: custom_attributes.delivery }"
                            ></span>
                        </button>
                    </label>
                </div>


                <div v-if="showGoogleMap">
                    <GoogleMap
                        :address="custom_attributes.address"
                        :onClose="closeGoogleMapModal"
                        :show="showGoogleMap"
                    />
                </div>

                <div class="row">
                    <div class="columns small-12 medium-12 group_section">
                        <label>{{$t('CATALOGUE.SETTING.FIELDS.BUSINESS_HOUR_LABLE')}}</label>
                        <div>
                            <BusinessHour
                                class="business_hour"
                                v-for="business_hour,index in business_hours"
                                :key="business_hour"
                                :business_hour="business_hour"
                                :business_hours="business_hours"
                                :DAY_NAMES="DAY_NAMES"
                                :index="index"
                                :TIME_INTERVAL="TIME_INTERVAL"
                                :removeBusinessHour="removeBusinessHour"
                            />
                        </div>
                        <div>
                            <woot-button
                                variant="smooth"
                                size="small"
                                color-scheme="primary"
                                icon="add"
                                :is-disabled="isAllBusinessHoursTaken"
                                @click="addBusinessHour"
                            >
                            {{$t('CATALOGUE.SETTING.FIELDS.ADD_BUSINESS_HOUR_BTN')}}
                            </woot-button>
                        </div>
                    </div>
                </div>

                <section>
                    <div class="notification_header">
                        <h3 class="page-title">
                            <fluent-icon icon="alert"  />
                            <!-- <span>Order Notification Messages</span> -->
                            <span>{{$t('CATALOGUE.SETTING.NOTIFICATION_MSG.HEADER.TITLE')}}</span>
                        </h3>
                        <p class="integration--description">{{$t('CATALOGUE.SETTING.NOTIFICATION_MSG.HEADER.CONTENT')}}</p>
                    </div>
                    <div class="notification_inbox_wrap">
                        <p>{{$t('CATALOGUE.SETTING.NOTIFICATION_MSG.INBOX_SELECT_LABEL')}}</p>
                        <div class="inbox_select_wrapper">
                            <multiselect
                                v-model="selectedInbox"
                                :options="inboxes"
                                :disabled="catalog.inbox_id"
                                class="small-12"
                                track-by="id"
                                label="name"
                                :multiple="false"
                                :close-on-select="true"
                                :clear-on-select="false"
                                :hide-selected="true"
                                placeholder="Pick Inbox"
                                selected-label
                                :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                                :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
                                :key="'close_' + index"
                            />
                        </div>
                    </div>
                    <div v-if="catalog.communal_inbox">
                        <p class="communal_notify">{{$t('CATALOGUE.SETTING.COMUNNAL_INBOX_NOTIFY')}}</p>
                    </div>

                    <div v-if="(selectedInbox && selectedInbox.id) || (catalog.inbox_id)">
                        <OrderActionMessagesTable
                            :actionMessages="messages"
                            :cols="cols"
                            :toggleMessage="toggleMessageHandler"
                            :toggleMessageEditor="toggleMessageEditor"
                            :onMessageChange="onMessageChangeHandler"
                        />
                    </div>
                </section>


                <div>
                    <woot-button
                        color-scheme="primary"
                        :is-loading="isUpdatingSetting"
                        :is-disabled="isUpdatingSetting"
                        @click="updateCatalogSetting"
                    >
                    {{$t('GENERAL_SETTINGS.SUBMIT')}}
                    </woot-button>
                </div>

            </div>
        </div>

    </section>


  </div>
</template>

<script>
import { required, minValue, maxValue,  } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import configMixin from 'shared/mixins/configMixin';
import accountMixin from '../../../../mixins/account';
const semver = require('semver');
// import {DAY_NAMES,MESSAGE_ACTIONS_LIST} from "./setting_constant";
import {DAY_NAMES} from "./setting_constant";
import GoogleMap from "./googleMap/GoogleMap.vue"
import BusinessHour from "./BusinessHour.vue"
// import CannedResponse from '../../../../components/widgets/conversation/CannedResponse.vue';
import AccountAPI from "../../../../api/account"
import MerchantAPI from "../../../../api/merchant";
import CatalogAPI from "../../../../api/catalogue/catalog";
import OrderActionMessagesTable from "./OrderActionMessagesTable.vue";
import QRCode from "./QRCode.vue";
import {ExternalConfig} from "../../settings/inbox/channels/constant"
import placeholderImage from "../../../../../dashboard/assets/images/external/img_placeholder.jpg";

const BusinessHoursList = [
    { day: { id: 0, title: "Sunday" }, open: { id: "08:00", title: "08:00" }, close: { id: "22:00", title: "22:00" } },
    { day: { id: 1, title: "Monday" }, open: { id: "08:00", title: "08:00" }, close: { id: "22:00", title: "22:00" } },
    { day: { id: 2, title: "Tuesday" }, open: { id: "08:00", title: "08:00" }, close: { id: "22:00", title: "22:00" } },
    { day: { id: 3, title: "Wednesday" }, open: { id: "08:00", title: "08:00" }, close: { id: "22:00", title: "22:00" } },
    { day: { id: 4, title: "Thursday" }, open: { id: "08:00", title: "08:00" }, close: { id: "22:00", title: "22:00" } },
    { day: { id: 5, title: "Friday" }, open: { id: "08:00", title: "08:00" }, close: { id: "22:00", title: "22:00" } },
    { day: { id: 6, title: "Saturday" }, open: { id: "08:00", title: "08:00" }, close: { id: "22:00", title: "22:00" } },
]


export default {
    components:{
        GoogleMap,
        BusinessHour,
        // CannedResponse,
        OrderActionMessagesTable,
        QRCode,
    },
  mixins: [accountMixin, alertMixin, configMixin],
  data() {
    return {
        // loaders
        isDefaultNotificationLoading:false,
        isUpdatingSetting:false,
        newUploadedLogoFile: null,
        newUploadedLogoPreview: null,
        merchant:{
            shop_url:"",
            logo: "",
        },
        name:"",
        phone_number:"",
        business_hours:[
            // Don't know why but keep an empty element in the 1st index to solve multiselect reactivity issue
            {
                day: {},
                open: {},
                close: {},
            },
            {
                day: { id: 4, title: "Thursday" },
                open: {id:"08:00",title:"08:00"},
                close: {id:"22:00",title:"22:00"},
            },

            // {
            //     day: { id: 4, title: "Thursday" },
            //     open: {id:"08:00",title:"08:00"},
            //     close: {id:"22:00",title:"22:00"},
            // },

        ],
        custom_attributes:{
            description:"",
            address: {
                line1: "",
                line2: "",
                city: "",
                state: "",
                postcode: "",
                latitude: 0,
                longitude: 0,
            },
            sst: false,
            payment_before_order:false,
            georestrict:false,
            delivery:false,
        },
        tekkis_hook_configured: false,
        DAY_NAMES,
        TIME_INTERVAL: [],
        // gogle map
        showGoogleMap:false,
        // restrictedByGeoLocation: false,
        selectedInbox:{},

        // canned message select with order action type
        // message_content:"",
        template_name:"",
        // mentionSearchKey:"",
        hasSlashCommand: false,
        // selectedMessageAction:null,
        message_actions:[
            // {id:"order_created", title:"Order Created"},
            // ...MESSAGE_ACTIONS_LIST,
        ],

        messages:{
            // order_created:{
            //     message:"",
            //     template_name:"",
            // }
        },
        catalog: {},
        cols: [
            { key: 'action_id', name: 'When', visible: true, type: 'default' },
            { key: 'message', name: 'Action Message', visible: true, type: 'default' },
            { key: 'action', name: 'Action', visible: true, type: 'default' },
        ],

    };
  },
    validations: {
        name: {
            required,
        },
        phone_number: {
            required,
             validatePhoneNumber(value) {
                return /^\+\d+$/.test(value);
            },
        },
        custom_attributes: {
            address: {
                line1: {
                    required,
                },
                city: {
                    required,
                },
                state: {
                    required,
                },
            },
        },
    },
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
      getAccount: 'accounts/getAccount',
      uiFlags: 'accounts/getUIFlags',
      currentUser: 'getCurrentUser',
      inboxes: 'inboxes/getInboxes',
      inboxFlags: 'inboxes/getUIFlags',
    }),
    isAllBusinessHoursTaken(){
        return this.business_hours.length >= 8;
    },
   // end computed
  },
  mounted() {
    this.generateTimeInterval();
    // this.fetchDefaultNotificationMsg();
    // this.getMerchant();
    // this.getCatalog();
    this.fetchDefaultNotificationMsg()
    .then(()=>this.getCatalog())
    .then(()=>this.getMerchant());

  },
  watch:{
    // populate intital fields
    currentUser(newValue,oldValue){
        if (newValue !== oldValue) {
            this.getMerchant();
            this.getCatalog();
        }
    },
    // whenever inboxes or catalog changes, call to populate selected inbox
    inboxes(newValue,oldValue){
        if (newValue !== oldValue) {
            this.populateSelectInbox()
        }
    },

    // end watch
  },
  methods: {

    async updateCatalogSetting() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        // console.log(this.$v);
        this.showAlert(this.$t('CATALOGUE.SETTING.ALERT_REQUIRE_FIELD'));
        return;
      }
      try {
        this.isUpdatingSetting = true;
        // remove 1st element which was added due to solve vue multiselect library inside loop
        const usersBusinessHours = [...this.business_hours];
        usersBusinessHours.shift();
        const formattedBusinessHours = usersBusinessHours.map(({ day, open, close }) => ({
            day: day.id.toString(),
            open: open.id,
            close: close.id
        }));
        // Construct the payload
        const payload = {
            phone_number: this.phone_number,
            name: this.name,
            // new_logo_file: this.newUploadedLogoFile,
            custom_attributes: {
                description: this.custom_attributes.description,
                business_hours: formattedBusinessHours,
                address: {
                    ...this.custom_attributes.address,
                    latitude: parseFloat(this.custom_attributes.address.latitude),
                    longitude: parseFloat(this.custom_attributes.address.longitude),
                },
                sst: this.custom_attributes.sst.toString(),
                payment_before_order: this.custom_attributes.payment_before_order,
                georestrict: this.custom_attributes.georestrict,
                delivery: this.custom_attributes.delivery,
                // georestrict: this.restrictedByGeoLocation,
            },
        };

        // append body in formData
        const formDataPayload = new FormData();
        formDataPayload.append('phone_number', payload.phone_number);
        formDataPayload.append('name', payload.name);
        formDataPayload.append('custom_attributes', JSON.stringify( payload.custom_attributes));
        if (this.newUploadedLogoFile) {
            formDataPayload.append('new_logo_file', this.newUploadedLogoFile);
        }


        const {data:accountUpdateResult} = await AccountAPI.updateAccount(formDataPayload);

        // update catalog messages
        const newMsgs = {};
        // MESSAGE_ACTIONS_LIST.map(({id})=>id).forEach(key=>{
        this.message_actions.map(({id})=>id).forEach(key=>{
            if (this.messages[key]?.isRealMsg) {
                newMsgs[key] = {
                    message: this.messages[key].message,
                    template_name: this.messages[key].template_name,
                };
            }
        })
        if (this.catalog) {
            const catalogPayload = {
                id: this.catalog.id,
                inbox_id: this.catalog.inbox_id || this.selectedInbox?.id,
                name: this.catalog.name,
                messages: newMsgs,
            }
            const {data:catalogUpdateResult} = await CatalogAPI.updateCatalogById(this.catalog.id,catalogPayload);
        }

        this.showAlert(this.$t('CATALOGUE.SETTING.ALERT_MSG_SUCCESS'));
      } catch (error) {
          console.log(error);
          this.showAlert(this.$t('CATALOGUE.SETTING.ALERT_MSG_FAIL'));
      }finally{
        this.isUpdatingSetting = false;
      }
    },
    async getMerchant(){
        try {
            if (!this.currentUser.accounts?.length) {
                return
            }
            const merchantName = this.currentUser.accounts[0]?.name;

            if (merchantName) {
                const {data} = await MerchantAPI.getMerchantByName(merchantName);
                this.merchant = data;

                // prefill the field
                this.name = data.name;
                this.phone_number = data.phone;
                this.merchant.logo_url = data.logo_url || placeholderImage;
                this.custom_attributes.address.line1 = data.address?.line1;
                this.custom_attributes.address.line2 = data.address?.line2;
                this.custom_attributes.address.city = data.address?.city;
                this.custom_attributes.address.state = data.address?.state;
                this.custom_attributes.address.postcode = data.address?.postcode;
                this.custom_attributes.address.latitude = data.address?.latitude;
                this.custom_attributes.address.longitude = data.address?.longitude;
                this.custom_attributes.description = data.description;
                this.custom_attributes.sst = data.sst === 'true' ? true:false;
                this.custom_attributes.payment_before_order = data.payment_before_order || false;
                this.custom_attributes.georestrict = data.georestrict || false;
                this.custom_attributes.delivery = data.delivery || false;
                this.tekkis_hook_configured = data.payment_before_order ? true : data.tekkis_hook_configured; // hardly set true if any user previously on the payment


                // this.restrictedByGeoLocation = data.georestrict || false;
                if (data.business_hours) {
                    const initialBusinessHours = data.business_hours?.map(({day,open,close})=>{
                        const dayOption = DAY_NAMES.find(el=>el.id === Number(day))
                        return {
                            day: {...dayOption},
                            open: {id: open,title: open},
                            close: {id: close,title:close},
                        }
                    });
                    this.business_hours = [
                        {day: {},open: {},close: {},},  // 1st empty element to be hide
                        ...initialBusinessHours,
                    ]
                }

                // set geo location on/off
                if (data.address?.latitude || data.address?.longitude) {
                    // this.restrictedByGeoLocation = true;
                }

                this.merchant.shop_url = `${ExternalConfig.SHOP_APP_BASE_URL}/app/accounts/${this.currentUser.account_id}/?name=${encodeURIComponent(this.merchant.name)}`;
            }
        } catch (error) {
            console.log(error);
            this.showAlert('Failed to get merchant');
        }
    },
    async getCatalog(){
        try {
            const {data} = await CatalogAPI.getDefaultCatalog();
            if (!data) {
                return;
            }
            this.catalog = data;

            // populate messages
           const messages = {};
           if (data.messages) {
            //    MESSAGE_ACTIONS_LIST.forEach(action => {
               this.message_actions.forEach(action => {
                   if (data.messages.hasOwnProperty(action.id)) {
                       // Merge existing message with default action
                       // if message exist use that else default
                       const message = data.messages[action.id].message || action.defaultMessage;
                       messages[action.id] = {
                           ...action,
                           ...data.messages[action.id],
                           message,
                           action: action,
                           isRealMsg: true,
                           isEditOpen: false,
                       };

                   } else {
                       // Use the default action
                       messages[action.id] = {
                           ...action,
                           message: action.defaultMessage,
                           action: action,
                           isRealMsg: false,
                           isEditOpen: false,
                       };
                   }
               });

               this.messages = messages;
            }else{
                 // If data.messages is falsy, set this.messages to default values
                // MESSAGE_ACTIONS_LIST.forEach(action => {
                this.message_actions.forEach(action => {
                    messages[action.id] = {
                        ...action,
                        message: action.defaultMessage,
                        action: action,
                        isRealMsg: false,
                        isEditOpen: false,
                    };
                });
                this.messages = messages;
            }

            //  set initial selected inbox based on catalog
            if (data.inbox_id) {
                this.selectedInbox = this.inboxes.find(el=>el.id === data.inbox_id);
            }
        } catch (error) {
            console.log("getCatalog = ",error);
            this.showAlert('error to get catalog');
        }
    },
    addBusinessHour(){

        // add only unique date
        const newDay = BusinessHoursList.find(
            day => !this.business_hours.some(entry => entry.day.id === day.day.id)
        );
        if (newDay) {
            this.business_hours.push(newDay);
        }

        // this.business_hours.push({
        //     day: { id: 1, title: "Monday" },
        //     open: {id:"08:00",title:"08:00"},
        //     close: {id:"22:00",title:"22:00"},
        // })
    },
    openGoogleMapModal(){
        this.showGoogleMap = true;
    },
    closeGoogleMapModal(){
        this.showGoogleMap = false;
    },
    geoRestrictHandler(){
        this.custom_attributes.georestrict = !this.custom_attributes.georestrict;
        if (this.custom_attributes.georestrict) {
            this.openGoogleMapModal()
        }
        // off the delivery option, if geo is on
        if (this.custom_attributes.georestrict) {
            this.custom_attributes.delivery = false;
        }
    },
    deliveryAddressToggle(){
        this.custom_attributes.delivery = !this.custom_attributes.delivery;
        // off the geo option, if delivery is on
        if (this.custom_attributes.delivery) {
            this.custom_attributes.georestrict = false;
        }
    },
    removeBusinessHour(index){
        this.business_hours.splice(index,1)
    },

    toggleMessageHandler(message_id){
        // delete this.messages[message_id];
        const newMessages = {...this.messages}
        newMessages[message_id].isRealMsg = !newMessages[message_id].isRealMsg;
        this.messages = newMessages;
    },
    toggleMessageEditor(message_id){
        // delete this.messages[message_id];
        const newMessages = {...this.messages}
        newMessages[message_id].isEditOpen = !newMessages[message_id].isEditOpen;
        this.messages = newMessages;
    },
    onMessageChangeHandler(message_id,updatedMessage,template_name='',isCannClick=false){
        // console.log({message_id,updatedMessage});
        const newMessages = {...this.messages}
        // set new message
        newMessages[message_id].message = updatedMessage;

        // set if canned command match
        newMessages[message_id].hasSlashCommand = updatedMessage && updatedMessage[0] === '/';
        const hasNextWord = updatedMessage && updatedMessage.includes(' ');
        const isShortCodeActive = newMessages[message_id].hasSlashCommand && !hasNextWord;
        if (isShortCodeActive) {
            newMessages[message_id].mentionSearchKey = updatedMessage.substr(1, updatedMessage.length);
            newMessages[message_id].showMentions = isCannClick? false: true;
        } else {
            newMessages[message_id].mentionSearchKey = '';
            newMessages[message_id].showMentions = false;
            // this.calendar_form.confirmation_msg['message'] = updatedMessage;
        }

        newMessages[message_id].inputChanged = true;
        newMessages[message_id].template_name = template_name;
        this.messages = newMessages;
    },
    populateSelectInbox(){
        const intitalInboxId = this.catalog?.inbox_id
        if (intitalInboxId) {
            const initialInbox = this.inboxes.find(inbox=>inbox.id === intitalInboxId)
            this.selectedInbox = initialInbox;
        }
    },
    generateTimeInterval(){
        const timeIntervals = [];

        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 15) {
                const formattedHour = String(hour).padStart(2, "0");
                const formattedMinute = String(minute).padStart(2, "0");
                const time = `${formattedHour}:${formattedMinute}`;

                const option = { id: time, title: time };

                timeIntervals.push(option);
            }
        }
        // return timeIntervals;
        this.TIME_INTERVAL = timeIntervals;
    },
    triggerShopLogoInput() {
      this.$refs.shopLogoInput.click();
    },
    handleShopLogoChange(event) {
      const file = event.target.files[0];
      if (!file) return;

      // Check file size (2MB limit)
      const maxSize = 2 * 1024 * 1024; // 2MB in bytes
      if (file.size > maxSize) {
        this.showAlert("File exceeds 2MB limit.");
        return;
      }

      const img = new Image();
      const objectUrl = URL.createObjectURL(file);

      img.onload = () => {
        // Check if the image is square
        // if (img.width !== img.height) {
        //   this.showAlert("Image must be square.");
        //   URL.revokeObjectURL(objectUrl);
        //   return;
        // }

        // Resize the image to 600x600
        const canvas = document.createElement("canvas");
        canvas.width = 600;
        canvas.height = 600;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, 600, 600);

        // Set the resized image as preview
        canvas.toBlob((blob) => {
          const resizedObjectUrl = URL.createObjectURL(blob);
          this.newUploadedLogoPreview = resizedObjectUrl;
          this.newUploadedLogoFile = blob;
          URL.revokeObjectURL(objectUrl); // Clean up
        }, "image/jpeg");
      };

      img.onerror = () => {
        this.showAlert("Failed to load image.");
        URL.revokeObjectURL(objectUrl);
      };

      img.src = objectUrl;
    },
    /*
    handleShopLogoChange(event) {
        const file = event.target.files[0];
        if (!file) return;
        if (file) {
            this.newUploadedLogoPreview = URL.createObjectURL(file);
            this.newUploadedLogoFile = file;
        }
    },
    */
   async fetchDefaultNotificationMsg(){
    try {
        this.isDefaultNotificationLoading = true;
        const {data} = await AccountAPI.fetchDefaultOrderTemplateMsg();
        const messageList = Object.keys(data?.templates).map((key) => {
            const template = data.templates[key];
            return {
                id: key,
                title: template.title,
                template_name: template.template_name,
                defaultMessage: template.message
            };
        });
        this.message_actions = messageList;
        this.isDefaultNotificationLoading = false;

    } catch (error) {
        this.showAlert("Unknown error occured");
        this.isDefaultNotificationLoading = false;
    }
   },

    // end methods
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables.scss';
@import '~dashboard/assets/scss/mixins.scss';

.profile--settings {
  padding: 24px;
  overflow: auto;
}

.profile--settings--row {
  @include border-normal-bottom;
  padding: $space-normal;
  .small-3 {
    padding: $space-normal $space-medium $space-normal 0;
  }
  .small-9 {
    padding: $space-normal;
  }
}


.logo_container{
    position: relative;
    width: fit-content;
    margin: auto;
    text-align: center;
    cursor: pointer;
    margin-bottom: 20px;

    .logo{
        width: 80px;
        height: 80px;
    }
    .logo_hint{
        // position: absolute;
        // bottom: 0;
        // left: 50%;
        // transform: translate(-50%, 0);
        // width: 100px;
        // margin: 0;
        // opacity: 0.6;
    }
}

.current-version {
  font-size: var(--font-size-small);
  text-align: center;
  padding: var(--space-normal);
}
.business_hour{
    display: grid;
    grid-template-columns: 1fr 1fr 80px 1fr 80px;
    align-items: center;
    // justify-items: center;

    .margin_bottom{
        margin-bottom: var(--space-normal);
    }
}

.group_section{
    margin: 0 0 1.6rem;
}
.flex_justfy_between{
    display: flex;
    justify-content: space-between;
}
.actionMessages{
    .actionMessage{
        display: grid;
        grid-template-columns: 150px 1fr 50px;
    }
    .actionMessage{
        border: 1px solid var(--s-100);
        padding: 2px;
    }
}

// toogle button design START
.toggle-button {
  background-color: var(--s-200);
  position: relative;
  display: inline-flex;
  height: 19px;
  width: 34px;
  border: 2px solid transparent;
  border-radius: var(--border-radius-large);
  cursor: pointer;
  transition-property: background-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
  flex-shrink: 0;
}

.toggle-button.active {
  background-color: var(--w-500);
}

.toggle-button span {
  --space-one-point-five: 1.5rem;
  height: var(--space-one-point-five);
  width: var(--space-one-point-five);
  display: inline-block;
  background-color: var(--white);
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px,
    rgba(59, 130, 246, 0.5) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  transform: translate(0, 0);
  border-radius: 100%;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
}
.toggle-button span.active {
  transform: translate(var(--space-one-point-five), var(--space-zero));
}
// toogle button design END

.label_wrapper{
    label{
        padding-right: var(--space-small);
    }
}

.notification_header{
    margin-top: var(--space-larger);
    margin-bottom: var(--space-medium);

    .page-title{
        span{
            margin-left: var(--space-small);
        }
    }
}
.notification_inbox_wrap {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: var(--space-small);

    .inbox_select_wrapper div{
        margin: 0;
    }
}

.add_message_wraper{
    text-align: end;
    margin-bottom: 10px;
}


// geo tooltip design start
.geo_info {
    position: relative;
    display: inline-block;
    cursor: pointer;

    .tooltiptext {
        visibility: hidden;
        width: 250px;
        background-color: black;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
        bottom: 125%; // Position the tooltip above the icon
        left: 50%;
        margin-left: -123px; // Use half of the tooltip width to center it
        opacity: 0;
        transition: opacity 0.3s;

        &::after {
            content: "";
            position: absolute;
            top: 100%; // At the bottom of the tooltip
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: black transparent transparent transparent;
        }
    }

    &:hover .tooltiptext {
        visibility: visible;
        opacity: 1;
    }
}
.communal_notify{
    margin: 18px auto;
    width: fit-content;
    color: #6F1DDF;
    background: #f3ecfd;
    padding: 13px;
    // max-width: 560px;
    text-align: center;
}
// geo tooltip design end
</style>


