import { mapGetters } from 'vuex';
import QRCode from 'qrcode-generator';
import placeholderImage from "../assets/images/external/img_placeholder.jpg";

export default {
  computed: {},
  methods: {
    // check image url and return default placeholder image
    async getValidImageUrl(imgUrl) {
        return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => resolve(imgUrl);
            img.onerror = () => resolve(placeholderImage);
            img.src = imgUrl;
        });
    },

    // generate QR Code
    generateQRCode({ text='', errorCorrectionLevel = 'M', sizeMultiplier = 4 }) {
      let qr = QRCode(0, errorCorrectionLevel);
      qr.addData(text);
      qr.make();
      return qr.createDataURL(sizeMultiplier);
    },

    // compress an image
    compressImage(file, {maxSizeKB = 800, initialQuality = 0.9, qualityStep = 0.1}={}){
      return new Promise((resolve, reject) => {
        const compress = (file, quality) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = event => {
              const img = new Image();
              img.src = event.target.result;
              img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0);

                canvas.toBlob(blob => resolve(blob), file.type, quality);
              };
              img.onerror = (err) => reject(err);
            };
            reader.onerror = (err) => reject(err);
          });
        };

        const tryCompress = async (file, quality) => {
          let blob = await compress(file, quality);
          while (blob.size / 1024 > maxSizeKB && quality > 0) {
            quality -= qualityStep;
            blob = await compress(file, quality);
          }
          resolve(blob);
        };

        if (file.size / 1024 <= maxSizeKB) {
          resolve(file);
        } else {
          tryCompress(file, initialQuality).catch(reject);
        }
      });
    },

    // resize an image
    resizeImage(file, {maxWidth = 500, maxHeight = 500}={}){
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob(blob => resolve(blob), file.type);
        };
        img.onerror = (err) => reject(err);
      });
    },

    //   end methods
  },

};
