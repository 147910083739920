import svgPanZoom from 'svg-pan-zoom';

export default () => ({
  data: () => ({
    spz: null,
  }),
  mounted() {
    this.spz = svgPanZoom(this.$refs.diagramRoot, {
      viewportSelector: this.$refs.viewPort,
      center: false,
      preventMouseEventsDefault: this.preventMouseEventsDefault,
      beforePan: this.beforePan,
      beforeZoom: () => this.zoomEnabled,
      maxZoom: 2,
      minZoom: 0.2,
      dblClickZoomEnabled: false,
    });
  },
  beforeDestroy() {
    if (this.spz) {
      this.spz.destroy();
    }
  },
  methods: {
    enableDblClickZoom() {
      if (this.spz) return this.spz.enableDblClickZoom();
      return null;
    },
    disableDblClickZoom() {
      if (this.spz) return this.spz.disableDblClickZoom();
      return null;
    },
    isDblClickZoomEnabled() {
      if (this.spz) return this.spz.isDblClickZoomEnabled();
      return null;
    },
    enableMouseWheelZoom() {
      if (this.spz) return this.spz.enableMouseWheelZoom();
      return null;
    },
    disableMouseWheelZoom() {
      if (this.spz) return this.spz.disableMouseWheelZoom();
      return null;
    },
    isMouseWheelZoomEnabled() {
      if (this.spz) return this.spz.isMouseWheelZoomEnabled();
      return null;
    },
    resetZoom() {
      if (this.spz) return this.spz.resetZoom();
      return null;
    },
    updateBBox() {
      if (this.spz) return this.spz.updateBBox();
      return null;
    },
    fit() {
      if (this.spz) return this.spz.fit();
      return null;
    },
    contain() {
      if (this.spz) return this.spz.contain();
      return null;
    },
    center() {
      if (this.spz) return this.spz.center();
      return null;
    },
  },
});
