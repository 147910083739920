import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../mutation-types';
import StatusAPI from '../../api/status';

const state = {
  records: [],
  uiFlags: {
    fetchingList: false,
  },
};

export const getters = {
  getStatuses(_state) {
    return _state.records;
  },
};

export const actions = {
  async get({ commit }) {
    try {
      const response = await StatusAPI.get();
      commit(types.default.SET_STATUS, response.data);
    } catch (error) {
      commit(types.default.SET_STATUS, []);
    }
  },
};

export const mutations = {
  [types.default.SET_STATUS]: MutationHelpers.set,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
