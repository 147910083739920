<template>
  <div class="medium-12 columns">
    <form id="payment-form" @submit.prevent="handleSubmit">
      <div v-if="!this.modalDataChange">
        <div class="flex-container">
          <div class="flex-child">
            <label>Base Amount</label>
          </div>
          <div class="flex-child text-right">
            <p>
              {{ this.currencyUpper() }}
              {{ this.fetchCalculated(this.baseAmount) }}
            </p>
          </div>
        </div>
        <div class="flex-container">
          <div class="flex-child">
            <label>Tax Amount</label>
          </div>
          <div class="flex-child text-right">
            <p>
              {{ this.currencyUpper() }}
              {{ this.fetchCalculated(this.percentageAmount) }}
            </p>
          </div>
        </div>
        <div class="flex-container">
          <div class="flex-child">
            <label>Total Amount</label>
          </div>
          <div class="flex-child text-right">
            <p>
              {{ this.currencyUpper() }}
              {{ this.fetchCalculated(this.totalAmount) }}
            </p>
          </div>
        </div>
      </div>
      <div id="stripe-payment" />
      <button v-if="!this.modalDataChange" id="submit">
        <div class="pay-btn">
          <woot-button color-scheme="primary" size="small" @click="submit">
            {{ $t('TOP_UP.MODAL.FORM.PAY') }}
          </woot-button>
        </div>
      </button>
      <div id="payment-message" class="hidden"></div>
    </form>
  </div>
</template>
<script>
import alertMixin from '../../../shared/mixins/alertMixin';

export default {
  name: 'StripeCardComponent',
  components: {},
  mixins: [alertMixin],
  props: {
    modalDataChange: {
      type: Boolean,
      required: true,
    },
    elements: {
      type: Object,
      required: true,
    },
    clientSecret: {
      type: String,
      required: true,
    },
    baseAmount: {
      type: String,
      required: true,
    },
    totalAmount: {
      type: String,
      required: true,
    },
    percentageAmount: {
      type: String,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    paymentIntentId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    submit() {},
    currencyUpper() {
      return this.currency.toUpperCase();
    },
    fetchCalculated(amount) {
      return (amount / 100).toFixed(2);
    },
    async handleSubmit() {
      const elements = this.elements;
      const { error } = await window.stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.href,
        },
      });
      if (error.type === 'card_error' || error.type === 'validation_error') {
        this.showAlert(error.message);
      } else {
        this.showAlert('An unexpected error occured.');
      }
    },
  },
};
</script>
<style>
.pay-btn {
  margin-top: 10px;
}
#payment-form {
  padding: 0px;
}
</style>
