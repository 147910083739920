<template>
  <woot-modal size="medium" class="image-section" :show.sync="show" :on-close="onClose">
    <woot-modal-header :header-title="$t('CAMPAIGN.EMAIL_TEMPLATE.ATTCHMENT.LIST.HEADER')" />
    <hr />
    <div class="medium-12 small-12 header-section">
      <div class="search-wrap">
        <fluent-icon icon="search" class="search-icon" />
        <input
          type="text"
          :placeholder="$t('CAMPAIGN.EMAIL_TEMPLATE.SEARCH_INPUT_PLACEHOLDER')"
          class="contact-search"
          :value="searchQuery"
          @keyup.enter="onSearchSubmit"
          @input="onInputSearch"
        />
        <woot-button
          :is-loading="false"
          class="clear"
          :class-names="this.searchQuery !== '' ? 'show' : ''"
          @click="onSearchSubmit"
        >{{ $t('CAMPAIGN.EMAIL_TEMPLATE.SEARCH_BUTTON_TEXT') }}</woot-button>
      </div>
      <woot-button
        @click="openAddAttachmentModal"
      >{{ $t('CAMPAIGN.EMAIL_TEMPLATE.ADD_ATTCHMENT_BUTTON_TEXT') }}</woot-button>
    </div>
    <div class="row images">
      <div class="columns medium-12">
        <h6>{{ $t('CAMPAIGN.EMAIL_TEMPLATE.ATTCHMENT.LIST.SUB_HEADER') }}</h6>
      </div>
      <empty-state
        v-if="attchments.length === 0"
        :title="$t('CAMPAIGN.EMAIL_ATTCHMENTS.INBOXES_NOT_FOUND')"
      />
      <div
        class="columns medium-6 small-12 images-content"
        v-show="attchments.length > 0"
        v-for="attchment in attchments"
        :key="attchment.id"
      >
        <div class="detail">
          <p>{{ attchment.caption ? attchment.caption : '-' }}</p>
          <woot-button
            v-tooltip.top="$t('CAMPAIGN.EMAIL_TEMPLATE.DELETE_BUTTON_TEXT')"
            variant="smooth"
            color-scheme="alert"
            size="small"
            icon="dismiss-circle"
            class-names="grey-btn"
            @click="openDeletePopup(attchment.id)"
          ></woot-button>
        </div>
        <div class="image-wrap" @click="$emit('selectImage', attchment.data_url)">
          <img
            :src="attchment.data_url"
            :alt="attchment.caption ? attchment.caption : attchment.id"
          />
        </div>
      </div>
    </div>
    <add-attchment v-if="isAddAttchmentModalOpen" @close="closeAddAttachmentModal" />
    <woot-delete-modal
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('LABEL_MGMT.DELETE.CONFIRM.TITLE')"
      :message="deleteMessage"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
    />
  </woot-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import EmailTemplateAPI from '../../../../../api/emailTemplates';
import AddAttchment from './AddAttchment.vue';
import EmptyState from '../../../../../components/widgets/EmptyState.vue';

export default {
  name: 'AttchmnetList',
  data() {
    return {
      show: true,
      isFetchingAttchments: false,
      attchments: [],
      selectedAttchmentIdForDelete: null,
      showDeleteConfirmationPopup: false,
      isAddAttchmentModalOpen: false,
      isDeleteingTemplate: false,
      searchQuery: '',
      serverParams: '',
    };
  },
  created() {
    this.getAttachments();
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
    // Delete Modal
    deleteConfirmText() {
      return `${this.$t('CAMPAIGN.DELETE.CONFIRM.YES')}`;
    },
    deleteRejectText() {
      return `${this.$t('CAMPAIGN.DELETE.CONFIRM.NO')}`;
    },
    deleteMessage() {
      return `${this.$t('CAMPAIGN.DELETE.CONFIRM.MESSAGE')}?`;
    },
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    async getAttachments(serverParams) {
      this.isFetchingAttchments = true;
      await EmailTemplateAPI.getAttchments(serverParams)
        .then(response => {
          if (response.data) {
            this.attchments = response.data;
          } else {
            this.attchments = [];
          }
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.isFetchingAttchments = false;
        });
    },
    onInputSearch(event) {
      const newQuery = event.target.value;
      this.searchQuery = newQuery;
    },
    onSearchSubmit() {
      let dataToSend = '';
      if (this.searchQuery) {
        dataToSend = `caption=${this.searchQuery}`;
      }
      this.getAttachments(dataToSend);
    },
    openAddAttachmentModal() {
      this.isAddAttchmentModalOpen = true;
    },
    closeAddAttachmentModal() {
      this.isAddAttchmentModalOpen = false;
      this.getAttachments();
    },
    openDeletePopup(index) {
      this.selectedAttchmentIdForDelete = index;
      this.showDeleteConfirmationPopup = true;
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    async confirmDeletion() {
      this.isDeleteingTemplate = true;
      await EmailTemplateAPI.deleteAttchments(this.selectedAttchmentIdForDelete)
        .then(response => {
          this.closeDeletePopup();
          this.getAttachments();
        })
        .catch(error => {})
        .finally(() => {
          this.isDeleteingTemplate = false;
        });
    },
  },
  components: { AddAttchment, EmptyState },
};
</script>

<style lang="scss" scoped>
.image-section {
  .images {
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    display: flex;
    .images-content {
      padding: 10px;
      .detail {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
      }
      .image-wrap {
        height: 200px;
        width: 100%;
        img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}

.header-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  .search-wrap {
    width: 400px;
    display: flex;
    align-items: center;
    position: relative;
    margin-right: var(--space-small);

    .search-icon {
      position: absolute;
      top: 1px;
      left: var(--space-one);
      height: 3.8rem;
      line-height: 3.6rem;
      font-size: var(--font-size-medium);
      color: var(--b-700);
    }

    .contact-search {
      margin: 0;
      height: 3.8rem;
      width: 100%;
      padding-left: var(--space-large);
      padding-right: 6rem;
      border-color: var(--s-100);
    }

    .button {
      margin-left: var(--space-small);
      height: 3.2rem;
      right: var(--space-smaller);
      position: absolute;
      padding: 0 var(--space-small);
      transition: transform 100ms linear;
      opacity: 0;
      transform: translateX(-1px);
      visibility: hidden;
    }

    .button.show {
      opacity: 1;
      transform: translateX(0);
      visibility: visible;
    }
  }
}
</style>