<template>
  <div class="column padding-top">
    <woot-button
      color-scheme="success"
      class-names="button--fixed-right-top"
      icon="arrow-download"
      :disabled="!expiredCreditReport"
      @click="downloadReport"
    >
      {{ $t('FLOW.DOWNLOAD_REPORT') }}
    </woot-button>
    <report-filter-selector
      v-if="filterItemsList"
      :filter-items-list="filterItemsList"
      :hide-groupby="false"
      @date-range-change="onDateRangeChange"
    />

    <div class="row">
      <div class="report-bar" style="width:100%;">
        <div class="row">
          <div class="column">
            <ExpiredCreditTable
              :is-loading="isFetching"
              :data="expiredCreditReport"
            ></ExpiredCreditTable>
            <div class="loadMore_btn">
              <woot-button
                variant="clear"
                size="small"
                color-scheme="primary"
                :is-loading="isFetching"
                :is-disabled="paginationCompute.isLastPage"
                @click="onPageChange(Number(meta.current_page)+1)"
              >
                {{paginationCompute.btnText}}
              </woot-button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import ExpiredCreditTable from "./ExpiredCreditTable.vue"
import ReportFilterSelector from '../components/FilterSelector';
import format from 'date-fns/format';

export default {
  name:"ExpireCredit",
  components:{
    ReportFilterSelector,
    ExpiredCreditTable,
  },
  data(){
    const {start_date,end_date} = this.getLast7Days();
    return {
      // store dates
      start_date,
      end_date,
    }
  },
  computed:{
    ...mapGetters({
      currentUser: 'getCurrentUser',
      isFetching: 'getIsFetchingUsageReport',
      meta: "getExpireCredit_meta",
      expiredCreditReport: "getExpiredCreditReport",
    }),
    filterItemsList() {
      return this.$store.getters['inboxes/getInboxes'] || [];
    },
    paginationCompute(){
      const totalPages = Math.ceil(this.meta.count / this.meta.pageSize);
      const isLastPage = this.meta.current_page >= totalPages;
      const currentCount = Math.min(this.meta.current_page * this.meta.pageSize, this.meta.count);
      // console.log({totalPages,isLastPage,currentCount});
      // const btnText = `Load More Orders (${currentCount}/${this.meta.count})`;
      const btnText = `${this.$t('ORDER.LIST_TABLE.LOAD_MORE_BTN')} (${currentCount}/${this.meta.count})`;

      return{
        isLastPage,
        btnText,
      }
    },
  },
  mounted(){
    // this.$store.dispatch('fetchExpiredCreditReport',this.getLast7Days())
    // this.onPageChange(1);
  },
  methods:{
    onPageChange(current_page=1,){

      this.$store.dispatch('fetchExpiredCreditReport',{
        current_page,
        start_date: this.start_date,
        end_date: this.end_date,
      })
    },
    onDateRangeChange({ from, to }) {
      const fromDate = format(new Date(from * 1000), 'yyyy-MM-dd');
      const toDate = format(new Date(to * 1000), 'yyyy-MM-dd');
        //   this.$nextTick(() => {
        //     // this.fetchAllData();
        //   });
        this.start_date = fromDate;
        this.end_date = toDate;
        this.$nextTick(()=>{
          this.onPageChange(this.meta.current_page)
        });
        // this.$store.dispatch('fetchExpiredCreditReport',{
        //     start_date: fromDate,
        //     end_date: toDate
        // })
    },
    getLast7Days() {
        const today = new Date();
        const end_date = today.toISOString().split('T')[0];
        const start_date = new Date(today);
        start_date.setDate(today.getDate() - 7);
        const start_date_str = start_date.toISOString().split('T')[0];

        return {
            start_date: start_date_str,
            end_date: end_date
        };
    },
    downloadReport() {
      const dataArray = this.expiredCreditReport;
      const filename = 'expired_credit.csv'

      if (!dataArray || !dataArray.length) {
        console.error("No data available to download.");
        return;
      }

      // Helper function to handle null or undefined values
      function replacer(key, value) {
        return value === null || value === undefined ? '' : value;
      }

      // Extract keys (column headers) from the first object
      const headers = Object.keys(dataArray[0]);

      // Convert array of objects to CSV
      const csvData = [
        headers.join(','),
        ...dataArray.map(row =>
          headers.map(field => JSON.stringify(row[field], replacer)).join(',')
        )
      ].join('\r\n'); // Separate each row with new line


      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });


      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
    // end of methods
  },
}
</script>




<style lang="scss" scoped>
.padding-top {
  padding-top: 1.6rem;
}
.report-bar {
  padding: 1.6rem;
}
.csat--metrics-container {
  background: var(--white);
  margin-bottom: var(--space-two);
  border-radius: var(--border-radius-normal);
  border: 1px solid var(--color-border);
  padding: var(--space-normal);
}
.loadMore_btn button{
  display: block;
  margin: auto;
}
</style>
