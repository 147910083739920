/* eslint no-console: 0 */
/* eslint no-param-reassign: 0 */
/* eslint no-shadow: 0 */
import * as types from '../mutation-types';
import Report from '../../api/usageReport';
import FlowApi from '../../routes/dashboard/settings/bot/FlowApi';
import OrdersAPI from "../../api/orders"
import AccountAPI from "../../api/account"

const state = {
  isFetching: false,
  billingReport: null,
  quotaMessaging: null,
  botsReport: null,
  usagesOrderReport:null,
  meta_usagesOrder: {
    count: 0,
    current_page: 1,
    pageSize: 15,
  },
  expiredCreditReport:null,
  expireCreditMeta: {
    count: 0,
    current_page: 1,
    pageSize: 25,
  }
};

const getters = {
  getBotsReport(_state) {
    return _state.botsReport;
  },
  getBillingReports(_state) {
    return _state.billingReport;
  },
  getQuotaMessaging(_state) {
    return _state.quotaMessaging;
  },
  getIsFetchingUsageReport(_state) {
    return _state.isFetching;
  },
  getUsagesOrder_meta(_state){
    return _state.meta_usagesOrder;
  },
  getUsagesOrderReport(_state){
    return _state.usagesOrderReport;
  },
  getExpiredCreditReport(_state){
    return _state.expiredCreditReport;
  },
  getExpireCredit_meta(_state){
    return _state.expireCreditMeta;
  },
};
export const actions = {
  botsTransaction({ commit }, args) {
    console.log('args', args);
    commit(types.default.TOGGLE_USAGE_BILLING_REPORT_LOADING, true);
    if (args.account_id) {
      FlowApi.getBotsUsage(0, true, args.account_id, args.from, args.to)
        .then(apiResponse => {
          // console.log('bot usage api res', apiResponse, apiResponse.data);
          commit(types.default.SET_BOTS_REPORTS, apiResponse.data);
        })
        .catch(() => {
          commit(types.default.SET_BOTS_REPORTS, null);
        })
        .finally(() => {
          commit(types.default.TOGGLE_USAGE_BILLING_REPORT_LOADING, false);
        });
    } else {
      commit(types.default.SET_BOTS_REPORTS, null);
      commit(types.default.TOGGLE_USAGE_BILLING_REPORT_LOADING, false);
    }
  },
  paidMessaging({ commit }, reportObj) {
    commit(types.default.TOGGLE_USAGE_BILLING_REPORT_LOADING, true);
    Report.getReports(reportObj.apiUrl, reportObj.from, reportObj.to)
      .then(billingReport => {
        let { data } = billingReport;
        commit(types.default.SET_USAGE_REPORTS, data);
      })
      .catch(error => {
        console.error(error);
        commit(types.default.SET_USAGE_REPORTS, null);
      })
      .finally(() => {
        commit(types.default.TOGGLE_USAGE_BILLING_REPORT_LOADING, false);
      });
  },
  quotaMessaging({ commit }, reportObj) {
    commit(types.default.TOGGLE_USAGE_BILLING_REPORT_LOADING, true);
    Report.getReports(reportObj.apiUrl, reportObj.from, reportObj.to)
      .then(accountReport => {
        let { data } = accountReport;
        commit(types.default.SET_QUOTA_REPORTS, data);
      })
      .catch(error => {
        console.error(error);
        commit(types.default.SET_QUOTA_REPORTS, null);
      })
      .finally(() => {
        commit(types.default.TOGGLE_USAGE_BILLING_REPORT_LOADING, false);
      });
  },

  fetchUsagesOrderReport: async function({commit},queryParams){
    try {
      // const {start_date,end_date, current_page,pageSize,searchText,sortBy, sortOrder,} = queryParams;
      commit(types.default.TOGGLE_USAGE_BILLING_REPORT_LOADING, true);
      const {data} = await OrdersAPI.fetchUsagesOrderReport(queryParams);
      if (queryParams.current_page === 1) {
        commit(types.default.SET_USAGES_ORDER_REPORTS, []);
      }
      commit(types.default.SET_USAGES_ORDER_REPORTS, data.data);
      commit(types.default.SET_USAGES_ORDER_META, data.meta);

      commit(types.default.TOGGLE_USAGE_BILLING_REPORT_LOADING, false);
    } catch (error) {
      commit(types.default.TOGGLE_USAGE_BILLING_REPORT_LOADING, false);
    }
  },
  // get expired credit report data
  fetchExpiredCreditReport: async function({commit},{start_date,end_date,current_page=1}){
    try {
      commit(types.default.TOGGLE_USAGE_BILLING_REPORT_LOADING, true);
      const {data} = await AccountAPI.getCreditExpiredReport({start_date,end_date,current_page});
      if (current_page === 1) {
        commit(types.default.SET_EXPIRED_CREDIT_REPORTS, []);
      }

      commit(types.default.SET_EXPIRED_CREDIT_REPORTS, data.data);
      commit(types.default.SET_EXPIRED_CREDIT_META, data.meta);
      commit(types.default.TOGGLE_USAGE_BILLING_REPORT_LOADING, false);
    } catch (error) {
      console.log(error);
      commit(types.default.TOGGLE_USAGE_BILLING_REPORT_LOADING, false);
    }
  },

};

const mutations = {
  [types.default.SET_USAGE_REPORTS](_state, billingReport) {
    _state.billingReport = billingReport;
  },
  [types.default.SET_BOTS_REPORTS](_state, botsReport) {
    _state.botsReport = botsReport;
  },
  [types.default.SET_QUOTA_REPORTS](_state, quotaMessaging) {
    _state.quotaMessaging = quotaMessaging;
  },
  [types.default.TOGGLE_USAGE_BILLING_REPORT_LOADING](_state, flag) {
    _state.isFetching = flag;
  },
  [types.default.SET_USAGES_ORDER_REPORTS](_state, usagesOrdersData) {
    const tempList = usagesOrdersData?.length? _state.usagesOrderReport : [];
    tempList.push(...usagesOrdersData);
    _state.usagesOrderReport = tempList;
  },
  [types.default.SET_USAGES_ORDER_META](_state, newMeta) {
    _state.meta_usagesOrder = {..._state.meta_usagesOrder,...newMeta};
  },
  [types.default.SET_EXPIRED_CREDIT_REPORTS](_state, expiredCreditData) {
    const tempList = expiredCreditData?.length? _state.expiredCreditReport : [];
    tempList.push(...expiredCreditData);
    _state.expiredCreditReport = tempList;
  },
  [types.default.SET_EXPIRED_CREDIT_META](_state, expiredCreditMeta) {
    _state.expireCreditMeta = {..._state.expireCreditMeta,...expiredCreditMeta};
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
