<template>
  <section class="contacts-table-wrap">
    <ve-table
      :fixed-header="true"
      max-height="calc(100vh - 11.4rem)"
      scroll-width="calc(100vw - 30rem)"
      :columns="columns"
      :table-data="tableData"
      :border-around="false"
      :sort-option="sortOption"
    />

    <empty-state
      v-if="showSearchEmptyState"
      :title="$t('CONTACTS_PAGE.LIST.404')"
    />
    <empty-state
      v-else-if="!isLoading && !contacts.length"
      :title="$t('CONTACTS_PAGE.LIST.NO_CONTACTS')"
    />
    <div v-if="isLoading" class="contacts--loader">
      <spinner />
      <span>{{ $t('CONTACTS_PAGE.LIST.LOADING_MESSAGE') }}</span>
    </div>
  </section>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import { VeTable } from 'vue-easytable';
import flag from 'country-code-emoji';
import alertMixin from 'shared/mixins/alertMixin';
import Spinner from 'shared/components/Spinner.vue';
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import timeMixin from 'dashboard/mixins/time';
import FluentIcon from 'shared/components/FluentIcon/DashboardIcon';

export default {
  components: {
    EmptyState,
    Spinner,
    VeTable,
  },
  mixins: [clickaway, timeMixin,alertMixin],
  props: {
    contacts: {
      type: Array,
      default: () => [],
    },
    showSearchEmptyState: {
      type: Boolean,
      default: false,
    },
    onClickContact: {
      type: Function,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    activeContactId: {
      type: [String, Number],
      default: '',
    },
    sortParam: {
      type: String,
      default: 'name',
    },
    sortOrder: {
      type: String,
      default: 'asc',
    },
    cols: {
      type: Array,
      default: () => [],
    },
    selectedRowIds:{
      type: Array,
      default: ()=> []
    },
  },
  data() {
    return {
      sortConfig: {},
      sortOption: {
        sortAlways: true,
        sortChange: params => this.$emit('on-sort-change', params),
      },
      customAttributes: [],
      isDeletingList: []
    };
  },
  computed: {
    attributes() {
      return this.$store.getters['attributes/getAttributesByModel'](
        'contact_attribute'
      );
    },
    tableData() {
      if (this.isLoading) {
        return [];
      }
      // console.log('credit table data', this.contacts, this.cols);
      return this.contacts.map(item => {
        
        let preSet = {
          ...item,
          email: item.email || '---',
          balance_credit: item.balance_credit || 0,
          account_id: item.account_id || '---',
          import_status: item.import_status || '---',
          claimed_credit: item.claimed_credit || 0,
          graduate: item.graduate || '---',
          api_key: item.api_key || '---',
          api_secret: item.api_secret || '---',
          meta_url: item.meta_url || '---',
        };
        return preSet;
      });
    },
    columns() {
      let matches = {};
      
      matches.email = {
        field: 'email',
        key: 'email',
        title: this.$t('CONTACTS_PAGE.LIST.TABLE_HEADER.EMAIL_ADDRESS'),
        align: 'left',
        sortBy: this.sortConfig.email || '',
        width: 240,
        renderBodyCell: ({ row }) => {
          if (row.email)
            return (
              <div class="text-truncate">
                <a
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  href={`mailto:${row.email}`}
                >
                  {row.email}
                </a>
              </div>
            );
          return '---';
        },
      };

      matches.balance_credit = {
        field: 'balance_credit',
        key: 'balance_credit',
        sortBy: this.sortConfig.balance || '',
        title: "Balance",
        align: 'left',
        width: 240,
        renderBodyCell: ({ row }) => {
          if (!isNaN(row.balance_credit))
            return (
              <div class="text-truncate">
                <input 
                    value={row.balance_credit} 
                    type="number" 
                    onKeydown={(e) => this.handleCreditUpdate(e, row)}
                />
                </div>
            );
            
          return '---';
        },
      };

      matches.account_id = {
        field: 'account_id',
        key: 'account_id',
        sortBy: this.sortConfig.balance || '',
        title: "Account Id",
        align: 'left',
        width: 240,
      };

      matches.graduate = {
        field: 'graduate',
        key: 'graduate',
        sortBy: this.sortConfig.balance || '',
        title: "Graduate",
        align: 'left',
        width: 240,
        renderBodyCell: ({ row }) => {
          
          return (
            <div class="text-truncate">
              <select 
                v-model={row.graduate}
                onChange={(e) => this.handleFieldsUpdateOnChange(e,"graduate",row)} 
                value={row.graduate}
              >
                <option value='fresh'>Fresh</option>
                <option value='doing_auto'>Doing Auto</option>
                <option value='completed_auto'>Completed Auto</option>
                <option value='completed_inbox'>Completed Inbox</option>
                <option value='completed_import'>Completed Import</option>
                <option value='completed_broadcast'>Completed Broadcast</option>
              </select>
            </div>
          )
             
        },
      };


      matches.import_status = {
        field: 'import_status',
        key: 'import_status',
        sortBy: this.sortConfig.balance || '',
        title: "Import Status",
        align: 'left',
      };

      matches.claimed_credit = {
        field: 'claimed_credit',
        key: 'claimed_credit',
        sortBy: this.sortConfig.balance || '',
        title: "Claimed Credit",
        align: 'left',
        width: 240,
      };

      

      matches.api_key = {
        field: 'api_key',
        key: 'api_key',
        sortBy: this.sortConfig.balance || '',
        title: "API Key",
        align: 'left',
      };
      matches.api_secret = {
        field: 'api_secret',
        key: 'api_secret',
        sortBy: this.sortConfig.balance || '',
        title: "API Secret",
        align: 'left',
      };
      matches.meta_url = {
        field: 'meta_url',
        key: 'meta_url',
        sortBy: this.sortConfig.balance || '',
        title: "Meta URL",
        align: 'left',
        renderBodyCell: ({ row }) => {
          return (
            <div class="text-truncate">
              <input 
                  value={row.meta_url} 
                  type="url" 
                  onKeydown={(e) => this.handleFieldsUpdate(e,"meta_url",row)}
              />
              </div>
          );
        },
      };

      // delete button 
      matches.action_btn = {
        field: 'action_btn',
        key: 'action_btn',
        sortBy: this.sortConfig.balance || '',
        title: "Action",
        align: 'center',
        width: 120,
        renderBodyCell: ({ row }) => {
          const isDeleting = this.isDeletingList.includes(row.id);
          return (
            <div class="text-truncate">
              <span onClick={()=>console.log('span',row)} >
                <woot-button
                  color-scheme="warning"
                  icon="delete"
                  class="clear"
                  is-loading={isDeleting}
                  onClick={()=>this.deleteRowhandler(row)}
                >
                </woot-button>
              </span>
            </div>
          );
        },
      };

      
      let preSet = [];


    this.cols.forEach(col => {
        if (col.visible) {
            if (col.key in matches) {
                preSet.push(matches[col.key]);
            } 
        }
    });
      
      return preSet;
    },
  },
  watch: {
    sortOrder() {
      this.setSortConfig();
    },
    sortParam() {
      this.setSortConfig();
    },
  },
  mounted() {
    // this.$store.dispatch('statuses/get', 0);
    this.setSortConfig();
  },
  methods: {
    setSortConfig() {
      this.sortConfig = { [this.sortParam]: this.sortOrder };
    },
    async handleCreditUpdate(e,rowData){
      try {
        if (e.keyCode === 13 || e.which === 13 || e.key === 'Enter') {
          const balance_credit = parseFloat(e.target.value);
          await this.$store.dispatch('grantCredit/updateBalance',{balance_credit,email:rowData.email})
          // add a alert
          // add more column "Claimed Credit"
          this.showAlert("Credit updated successfully");
        }
      } catch (error) {
        this.showAlert("Credit updated failed");
        console.log(error);
      }
    },
    handleFieldsUpdate(e,keyName,rowData){
        try {
          if (e.keyCode === 13 || e.which === 13 || e.key === 'Enter') {
            const data = {};
            data[keyName] = e.target.value; 
            this.$store.dispatch('grantCredit/updateMetaUrl',{meta_url:e.target.value, email:rowData.email});
            this.showAlert("Updated successfully");
          }
        } catch (error) {
          console.log(error);
          this.showAlert("Failed to update");
        }
    },
    handleFieldsUpdateOnChange(e,keyName,rowData){
        try {
            this.$store.dispatch('grantCredit/updateMetaInfo',{
              email: rowData.email,
              keyName,
              value: e.target.value,
            });
            this.showAlert("Updated successfully");
        } catch (error) {
          console.log(error);
          this.showAlert("Failed to update");
        }
    },
    async deleteRowhandler(row){
      try {
        this.isDeletingList.push(row.id);
          this.$store.dispatch('grantCredit/deleteACredit',{
              id: row.id,
          });
          const successIndex = this.isDeletingList.indexOf(row.id);
          this.isDeletingList.splice(successIndex,1);
          this.showAlert("Deleted successfully");
        } catch (error) {
          const failedIndex = this.isDeletingList.indexOf(row.id);
          this.isDeletingList.splice(failedIndex,1);
          console.log(error);
          this.showAlert("Failed to delete");
        }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/mixins';

.contacts-table-wrap {
  flex: 1 1;
  height: 100%;
  overflow: hidden;
}

.contacts-table-wrap::v-deep {
  .ve-table {
    padding-bottom: var(--space-large);
  }
  .row--user-block {
    align-items: center;
    display: flex;
    text-align: left;

    .user-block {
      min-width: 0;
    }

    .user-thumbnail-box {
      margin-right: var(--space-small);
    }

    .user-name {
      font-size: var(--font-size-small);
      font-weight: var(--font-weight-medium);
      margin: 0;
      text-transform: capitalize;
    }

    .view-details--button {
      color: var(--color-body);
    }

    .user-email {
      margin: 0;
    }
  }

  .ve-table-header-th {
    padding: var(--space-small) var(--space-two) !important;
    background-color: var(--w-500) !important;
    color: var(--white) !important;
  }

  .ve-table-body-td {
    padding: var(--space-small) var(--space-two) !important;
  }

  .ve-table-header-th {
    font-size: var(--font-size-mini) !important;
  }
  .ve-table-sort {
    top: -4px;
  }
}

.contacts--loader {
  align-items: center;
  display: flex;
  font-size: var(--font-size-default);
  justify-content: center;
  padding: var(--space-big);
}

.cell--social-profiles {
  a {
    color: var(--s-300);
    display: inline-block;
    font-size: var(--font-size-medium);
    min-width: var(--space-large);
    text-align: center;
  }
}
</style>
