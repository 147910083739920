<template>
  <div class="message_container">
    <div>
        <div v-if="cannType === 'template'" class="tmp_select_wrapper">
            <!-- status: all, pending rejected, approved -->
            <multiselect
                v-model="selectedInbox"
                :options="inboxDropdownList"
                :disabled="false"
                class="small-12"
                track-by="id"
                label="name"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                :hide-selected="true"
                :placeholder="$t('MESSAGE_MGMT.ADD.FORM.INBOX_PLACEHOLDER')"
                selected-label
                :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
                :key="'close_' + 'index'"
            />
        </div>

        <!-- <TemplateMsgAlert
        :description="$t('MESSAGE_MGMT.ADD.VERIFY.DESCRIPTION')"
        :title="$t('MESSAGE_MGMT.ADD.VERIFY.DESCRIPTION')"
        :show="isVerifyVisible"
        @close="onCloseVerifyAlert"
        /> -->
    </div>
    <section>
        <div
            v-for="cann in filteredCanned"
            class="template"
            :class="{disable_template:['rejected','pending'].includes(cann.template_status)}"
            @click="onPreviewClick(cann)"
            :key="cann.id"
        >
            <div class="heading">
                <aside>
                    <p class="shortcut">/{{cann.short_code}}</p>
                    <h4 class="title">{{cann.inbox && cann.inbox.name}}</h4>
                </aside>
                <aside>
                    <div><button class="status" :class="cann.template_status">{{statusList[cann.template_status]}}</button></div>
                </aside>
                <woot-button v-if="!['pending'].includes(cann.template_status)"
                class-names="pre_delete"
                variant="clear"
                icon="delete"
                :is-disabled="isDeleting"
                :is-loading="isDeleting"
                @click.stop="onDeleteClick(cann)"
                >
                </woot-button>
            </div>
            <p class="message">{{truncateText(cann.content,350)}}</p>
        </div>
        <!-- <div><pre>cannedMessages = {{cannedMessages}}</pre></div> -->
    </section>


    <!-- empty list  -->
    <div v-if="isCannEmpty" class="empty_box">
        <p>{{ $t('MESSAGE_MGMT.EMPTY_LIST.MESSAGE') }}</p>
        <woot-button
            v-if="cannType === 'template'"
            class="clear"
            class-names="create_btn"
            color-scheme="primary"
            variant="clear"
            @click="onShowTemplateModal"
          >
            {{$t("MESSAGE_MGMT.HEADER_BTN_TXT")}}
        </woot-button>
        <woot-button
            v-if="cannType === 'canned'"
            class="clear"
            class-names="create_btn"
            color-scheme="primary"
            variant="clear"
            @click="onShowAddCannModal"
          >
            {{$t("CANNED_MGMT.HEADER_BTN_TXT")}}
        </woot-button>

    </div>
    <!-- preview message modal -->
    <PreviewTemplateModal
        :show="previewMessageShow"
        :onClose="onClosePreviewModal"
        :cannItem="previewMessage"
    />
    <woot-delete-modal
        :show.sync="showDeleteConfirmationPopup"
        :on-close="closeDeletePopup"
        :on-confirm="confirmDeletion"
        :title="$t('CANNED_MGMT.DELETE.CONFIRM.TITLE')"
        :message="deleteMessage"
        :confirm-text="deleteConfirmText"
        :reject-text="deleteRejectText"
    />

  </div>
</template>

<script>
import TemplateMsgAlert from "./TemplateMsgAlert.vue";
import PreviewTemplateModal from "./PreviewTemplateModal.vue";
import alertMixin from "shared/mixins/alertMixin";
import { mapGetters } from 'vuex';
import WootButton from '../../../../components/ui/WootButton.vue';

export default {
    components: {
        TemplateMsgAlert,
        PreviewTemplateModal,
        WootButton,

    },
    mixins: [
    alertMixin,
  ],
    props: {
        onShowTemplateModal: {
            type: Function,
            default: () => {},
        },
        onShowAddCannModal: {
            type: Function,
            default: () => {},
        },
        searchKey: {
            type: String,
            default: '',
        },
        cannType: {
            type: String,
            default: 'template', // template, canned (tabs id)
        },
    },
    data(){
        return {
            isVerifyVisible: true,
            selectedInbox: {id:0,name:"All",},
            /*
            selectedStatus: {id:"all",name:"All",},
            statusList:[
                {id:"all",name:"All",},
                {id:"approved",name:"Approved",},
                {id:"pending",name:"Pending",},
                {id:"rejected",name:"Rejected",},
            ],
            */
            showDeleteConfirmationPopup: false,
            isDeleting: false,
            deleteItem:{},

            // preview message show
            previewMessageShow:false,
            previewMessage: {},
            statusList:{
                rejected: this.$t('MESSAGE_MGMT.STATUS.REJECTED'),
                approved: this.$t('MESSAGE_MGMT.STATUS.PAAROVED'),
                pending: this.$t('MESSAGE_MGMT.STATUS.PENDING'),
            },

        }
    },
    computed:{
        ...mapGetters({
            inboxes: 'inboxes/getInboxes',
            cannedMessages: 'getCannedResponses',
        }),
        isCannEmpty(){
            return this.filteredCanned.length <= 0;
        },
        inboxDropdownList(){
            if (Array.isArray(this.inboxes)) {
                // filter only whatspp list
                const waInboxes = this.inboxes.filter(inbox => inbox.channel_type === 'Channel::Whatsapp')
                return [{id:0,name:'All',},...waInboxes]
            }
            return[{id:0,name:'All'}]
        },
        filteredCanned(){
            let result = [];
            // filter by template type
            if (this.cannType === "template") {
                result = this.cannedMessages.filter(el=>el.is_template === true);
            }else if (this.cannType === "canned") {
                result = this.cannedMessages.filter(el=>el.is_template === false);
            }

            /*
            // filter by status
            switch (this.selectedStatus.id) {
                case 'approved':
                    result = result.filter(el=>el.template_status === 'approved')
                    break;
                case 'pending':
                    result = result.filter(el=>el.template_status === 'pending')
                    break;
                case 'rejected':
                    result = result.filter(el=>el.template_status === 'rejected')
                    break;

                default:
                    break;
            }
            */
           // filter by inbox
           if (this.selectedInbox.id !== 0 && this.cannType==='template') {
            result = result.filter(el=>el.inbox_id === this.selectedInbox.id)
           }

            // populate inbox
            result = result.map(el=>{
                let inbox = null;
                if (el.inbox_id) {
                    inbox = this.inboxes.find(inEl=>inEl.id === el.inbox_id);
                }
                return {
                    ...el,
                    inbox,
                }
            })

            return result;
        },
        deleteMessage() {
            return `${this.$t('CANNED_MGMT.DELETE.CONFIRM.MESSAGE')} ${this.deleteItem.short_code} ?`;
        },
        deleteConfirmText() {
            return `${this.$t('CANNED_MGMT.DELETE.CONFIRM.YES')} ${this.deleteItem.short_code}`;
        },
        deleteRejectText() {
            return `${this.$t('CANNED_MGMT.DELETE.CONFIRM.NO')} ${this.deleteItem.short_code}`;
        },
        // end computed
    },
    mounted() {
        this.fetchCannedResponses();
    },
    watch: {
        searchKey() {
            this.fetchCannedResponses();
        },
    },
    methods:{
        fetchCannedResponses() {
            this.$store.dispatch('getCannedResponse', { searchKey: this.searchKey });
        },
        onCloseVerifyAlert() {
            this.isVerifyVisible = false;
        },
        onClosePreviewModal() {
            this.previewMessage = {};
            this.previewMessageShow = false;
        },
        onOpenPrevireModal(item){
            this.previewMessage = item;
            this.previewMessageShow = true;
        },
        onPreviewClick(item){
            this.onOpenPrevireModal(item)
        },

        truncateText(text, maxLength) {
            if (text.length > maxLength) {
                return text.slice(0, maxLength) + '...';
            } else {
                return text;
            }
        },
        closeDeletePopup() {
            this.showDeleteConfirmationPopup = false;
            this.deleteItem={}
        },
        onDeleteClick(item) {
            this.deleteItem=item
            this.showDeleteConfirmationPopup = true;
        },
        async confirmDeletion() {
            await this.deleteCannedResponse(this.deleteItem.id);
            this.closeDeletePopup();
        },
        async deleteCannedResponse(id) {
            this.isDeleting = true;
            await this.$store
                .dispatch('deleteCannedResponse', id)
            .then(() => {
                this.showAlert(this.$t('CANNED_MGMT.DELETE.API.SUCCESS_MESSAGE'));
                this.closeDeletePopup();
            })
            .catch(() => {
                this.showAlert(this.$t('CANNED_MGMT.DELETE.API.ERROR_MESSAGE'));
            }).finally(()=>{
                this.isDeleting = false;
            });
        },
        // end methods
    },
}
</script>

<style lang="scss" scoped>
.message_container{
    margin-top: var(--space-normal);
}

.tmp_select_wrapper{
    width: fit-content;
}
.disable_template{
    background-color: #F5F5F5 !important;
    cursor: default;
}
.template{
    background-color: #fff;
    border-radius: 10px;
    padding: var(--space-slab) var(--space-two);
    margin-top: var(--space-normal);
    border: 1px solid #EEEEEE;
    .heading{
        position: relative;
        display: flex;
        gap: 16px;

        .shortcut{
            font-weight: 700;
            font-size: var(--font-size-small);
            color: #8D25F6;
        }
        .title{
            font-weight: 500;
            font-size: var(--font-size-small);
            color: #1F1F1F;
        }
        .status{
            font-size: var(--font-size-mini);
            padding: 5px 12px;
        }
        .rejected{
            color: #E50000;
            background: #FFE5E5;
        }
        .approved{
            color: #24B200;
            background: #EBFCC0;
        }
        .pending{
            color: #FFAA00;
            background: #FFF4E5;
        }
        .pre_delete{
            position: absolute;
            top: 0;
            right: 0;
            background: transparent;
            color: #858585;
        }
    }
    .message{
        font-weight: 500;
        font-size: var(--font-size-small);
        color: #858585;
        line-height: 19px;
    }
}

.empty_box{
    width: 360px;
    font-size: 16px;
    text-align: center;
    margin: auto;
    p{
        margin-top: 130px;
    }
    .create_btn{
        margin-top: 16px;
        color: #8D25F6;
        border: 1px solid #8D25F6;
        &:hover{
            color: #858585;
            border: 1px solid #858585;
        }
    }
}
</style>
