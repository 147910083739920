import axios from 'axios';
var baseUrl = 'https://schedule.peasy.ai';
// var baseUrl = 'http://localhost:7000';

class ScheduleApi {
  constructor() {
    this.baseApiUrl = baseUrl + '/api';
  }

  async exchangeTokens(code) {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: this.baseApiUrl + '/google-exchange-tokens?code=' + code,
    };

    let response = null;
    try {
      response = await axios.request(config);
    } catch (error) {
      console.log('exchange token error');
    }

    return response;
  }

  async updateGoogleToken(payload) {
    let config = {
      method: 'post',
      url: this.baseApiUrl + '/google-update-token',
      data: payload,
    };

    let response = null;
    try {
      response = await axios.request(config);
    } catch (error) {
      console.log('exchange token error');
    }

    return response;
  }
}

export default new ScheduleApi();
