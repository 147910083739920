import types from "../mutation-types";
import CatalogueItemsApi from "../../api/catalogue/catalogue";
import {methodTils} from "../../helper/methodTils"

export const state = {
    catagories_product_sets: [],
    catalog_items_total: [],        // without paginated catalog_items
    catalog_items:[],               // paginated catalog_items
    meta: {
        count: 0,
        currentPage: 1,
        pageSize: 12
    },
    uiFlags: {
        isFetching_catagories: false,
        isCreating_catagories: false,
        isDeleting_catagories: false,
        isUpdating_catagories: false,

        isFetching_catalog_items: false,
        isCreating_catalog_items: false,
        isDeleting_catalog_items: false,
        isUpdating_catalog_items: false,

    },
};

// list of getters
const getters = {
    getCatagories(_state){
        return _state.catagories_product_sets;
    },
    getCatalogItems(_state){
        return _state.catalog_items;
    },
    getUIFlags(_state){
        return _state.uiFlags;
    },
    getMeta(_state){
        return _state.meta;
    },
}

// list of actions

const actions = {
    createCatalogueItem: async function({commit},catalogPayload){
        commit(types.SET_CATALOG_PRODUCT_UI_FLAG,{isCreating_catalog_items: true});
        try {
            const response = await CatalogueItemsApi.createCatalogueItem(catalogPayload);
            const newCatalogues = [response.data,...state.catalog_items,];
            commit(types.SET_CATALOG_ITEMS,newCatalogues);
            commit(types.SET_CATALOG_PRODUCT_UI_FLAG,{isCreating_catalog_items: false});
        } catch (error) {
            commit(types.SET_CATALOG_PRODUCT_UI_FLAG,{isCreating_catalog_items: false});
        }
    },
    updateCatalogueItem: async function({commit},{catalogue_id,catalogue}){
        commit(types.SET_CATALOG_PRODUCT_UI_FLAG,{isUpdating_catalog_items: true});
        try {
            const response = await CatalogueItemsApi.updateCatalogueItem(catalogue_id,catalogue);
            const filteredCatalogues = state.catalog_items.filter(item => item.id !== updatedCatalogueItem.id);
            const newCatalogues = [updatedCatalogueItem, ...filteredCatalogues];
            commit(types.SET_CATALOG_ITEMS,newCatalogues);
            commit(types.SET_CATALOG_PRODUCT_UI_FLAG,{isUpdating_catalog_items: false});
        } catch (error) {
            commit(types.SET_CATALOG_PRODUCT_UI_FLAG,{isUpdating_catalog_items: false});
        }
    },
    deleteCatalogueItem: async function({commit},{catalogue_id}){
        commit(types.SET_CATALOG_PRODUCT_UI_FLAG,{isDeleting_catalog_items: true});
        try {
            const response = await CatalogueItemsApi.deleteCatalogueItemById(catalogue_id);
            commit(types.DELETE_CATALOG_ITEMS,{catalogue_id});
            commit(types.SET_CATALOG_PRODUCT_UI_FLAG,{isDeleting_catalog_items: false});
        } catch (error) {
            commit(types.SET_CATALOG_PRODUCT_UI_FLAG,{isDeleting_catalog_items: false});
        }
    },
    getCatagories: async function({commit},{page,limit,searchQuery,...rest}={}){
        commit(types.SET_CATALOG_PRODUCT_UI_FLAG,{isFetching_catagories: true});
        try {
            const response = await CatalogueItemsApi.getListOfCategories();
            commit(types.SET_CATALOG_CATAGORIES,response.data);
            commit(types.SET_CATALOG_PRODUCT_UI_FLAG,{isFetching_catagories: false});
        } catch (error) {
            commit(types.SET_CATALOG_PRODUCT_UI_FLAG,{isFetching_catagories: false});
        }
    },
    getCatalogProductItems: async function({commit},{page,limit,searchQuery,...rest}={}){
        commit(types.SET_CATALOG_PRODUCT_UI_FLAG,{isFetching_catalog_items: true});
        try {
            const response = await CatalogueItemsApi.getAllCatalogueItems();
            commit(types.SET_CATALOG_ITEMS,response.data);
            commit(types.SET_CATALOG_PRODUCT_UI_FLAG,{isFetching_catalog_items: false});
        } catch (error) {
            commit(types.SET_CATALOG_PRODUCT_UI_FLAG,{isFetching_catalog_items: false});
        }
    },

    getPaginatedCatalogProductItems: async function({commit},{currentPage,pageSize,searchText,categories}={}){
        commit(types.SET_CATALOG_PRODUCT_UI_FLAG,{isFetching_catalog_items: true});
        try {
            commit(types.SET_CATALOG_ITEMS_PAGINATED,{currentPage,pageSize,searchText,categories});
            commit(types.SET_CATALOG_PRODUCT_UI_FLAG,{isFetching_catalog_items: false});
        } catch (error) {
            commit(types.SET_CATALOG_PRODUCT_UI_FLAG,{isFetching_catalog_items: false});
        }
    },

    createCategory: async function({commit,state },categoryPayload){
        commit(types.SET_CATALOG_PRODUCT_UI_FLAG,{isCreating_catagories: true});
        try {
            const response = await CatalogueItemsApi.createCategory(categoryPayload);
            const newCategories = [...state.catagories_product_sets,response.data];
            commit(types.SET_CATALOG_CATAGORIES,newCategories);
            commit(types.SET_CATALOG_PRODUCT_UI_FLAG,{isCreating_catagories: false});
        } catch (error) {
            commit(types.SET_CATALOG_PRODUCT_UI_FLAG,{isCreating_catagories: false});
        }
    },

    deleteCategory: async function({commit,state },deletePayload){
        commit(types.SET_CATALOG_PRODUCT_UI_FLAG,{isDeleting_catagories: true});
        try {
            const response = await CatalogueItemsApi.deleteCategory(deletePayload);
            const newCategories = state.catagories_product_sets.filter(category=>category.id !==deletePayload.id)
            console.log({newCategories});
            commit(types.SET_CATALOG_CATAGORIES,newCategories);
            commit(types.SET_CATALOG_PRODUCT_UI_FLAG,{isDeleting_catagories: false});
        } catch (error) {
            commit(types.SET_CATALOG_PRODUCT_UI_FLAG,{isDeleting_catagories: false});
        }
    },

}


// list of mutations
const mutations = {
    // mutate UI flags
    [types.SET_CATALOG_PRODUCT_UI_FLAG](_state, data) {
        _state.uiFlags = {
          ..._state.uiFlags,
          ...data,
        };
    },

    // mutate categories method
    [types.SET_CATALOG_CATAGORIES]:($state,data)=>{
        console.log("categories list in mutation",data);
        if (Array.isArray(data)) {
            $state.catagories_product_sets = data;
        }else{
            $state.catagories_product_sets = [];
        }
    },

    [types.SET_CATALOG_ITEMS_PAGINATED]:($state,data)=>{
        const {currentPage,pageSize,searchText,categories} = data;

        // Apply filters
        let filteredItems = $state.catalog_items_total;

        if (searchText) {
            const searchRegex = new RegExp(searchText, 'i');
            filteredItems = filteredItems.filter(item =>
                searchRegex.test(item.title || '') || searchRegex.test(item.description || '') || searchRegex.test(item.brand || '')
            );
        }

        if (categories && categories.length > 0) {
            filteredItems = filteredItems.filter(item =>
                categories.every(category => item.categories.some(cat => cat.id === category.id))
            );
        }

        $state.meta = {
            ...$state.meta,
            count: filteredItems.length,
            currentPage: parseInt(currentPage),
            pageSize: parseInt(pageSize),
        };

        // we are considering infinity scroll, not page by page,
        const infinityScrollStartIndex = 0;
        // update items based on pagination
        const startIndex = (currentPage - 1) * parseInt(pageSize);
        const endIndex = startIndex + parseInt(pageSize);
        $state.catalog_items = filteredItems.slice(infinityScrollStartIndex,endIndex);
    },

    // mutate catalog items method
    [types.SET_CATALOG_ITEMS]: async($state,data)=>{

        if (Array.isArray(data)) {
            $state.catalog_items_total = data;
            /*
            $state.catalog_items_total = await Promise.all(data.map(async item => {
                item.image_link = await methodTils.getValidImageUrl(item.image_link);
                return item;
            }));
            */

            $state.meta = {
                ...$state.meta,
                count: data.length,
                currentPage: 1,
            };

            // update items based on pagination
            const pageSize = $state.meta.pageSize || 15;
            const startIndex = ($state.meta.currentPage - 1) * pageSize;
            const endIndex = startIndex + pageSize;
            $state.catalog_items = $state.catalog_items_total.slice(startIndex,endIndex);

        }else{
            $state.catalog_items_total = [];
            $state.catalog_items = [];
            $state.meta = {
                ...$state.meta,
                count: 0,
                currentPage: 1,
            };
        }
    },
    [types.DELETE_CATALOG_ITEMS]: async($state,data)=>{
        const {catalogue_id} = data;
        $state.catalog_items_total = $state.catalog_items_total.filter(el=>el.id !==catalogue_id)
        $state.catalog_items = $state.catalog_items.filter(el=>el.id !==catalogue_id)
    },


}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}