<template>
  <div class="wizard-body small-9 columns">
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.WHATSAPP.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.WHATSAPP.DESC')"
    />
    <div class="medium-8 columns">
      <label>
        {{ $t('INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.LABEL') }}
        <select v-model="provider" @change="onProviderChange">
          <option value="whatsapp_cloud">
            {{ $t('INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.WHATSAPP_CLOUD') }}
          </option>
          <option value="twilio">
            {{ $t('INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.TWILIO') }}
          </option>
          <option value="360dialog">
            {{ $t('INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.360_DIALOG') }}
          </option>
          <!-- <option v-if="isGrantUser" value="vonage"> -->
          <option value="vonage">
            {{ $t('INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.GRANT') }}
          </option>
        </select>
      </label>
    </div>

    <twilio v-if="provider === 'twilio'" type="whatsapp"></twilio>
    <three-sixty-dialog-whatsapp v-else-if="provider === '360dialog'" :selectedProvider="provider" />
    <GrantWhatsapp v-else-if="provider === 'vonage'" />
    <cloud-whatsapp v-else />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PageHeader from '../../SettingsSubPageHeader';
import Twilio from './Twilio';
import ThreeSixtyDialogWhatsapp from './360DialogWhatsapp';
import GrantWhatsapp from './GrantWhatsApp.vue';
import CloudWhatsapp from './CloudWhatsapp';
import channelFactory from "../../../settings/inbox/channel-factory";

export default {
  components: {
    PageHeader,
    Twilio,
    ThreeSixtyDialogWhatsapp,
    GrantWhatsapp,
    CloudWhatsapp,
  },
  data() {
    return {
      provider: 'whatsapp_cloud',
    };
  },
  computed:{
    ...mapGetters({
      currentUser: 'getCurrentUser',
      inboxes: 'inboxes/getInboxes',
    }),
    isGrantUser(){
      console.log("currentUser = ",this.currentUser);
      return this.currentUser.plan_id === 20;
    },
  },

  watch: {
    inboxes: {
      handler (newValue, oldValue){
        this.redirectForDisabledChannel();
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    // clear the grant inbox connect phone steps
    bus.$emit('onInboxProviderSelectedEvent',{providerType: ""})
  },
  methods:{
    redirectForDisabledChannel(){

      const channelKey  = 'whatsapp';
      const isDisableWhatsappInboxCreate = channelFactory.disableInboxCreate(channelKey,this.currentUser,this.inboxes);
      // isDisableWhatsappInboxCreate & currently not creating grant inbox
      // already selected grad option and user is creating the inbox
      if (isDisableWhatsappInboxCreate && this.provider !== 'vonage') {
        console.log('Redirecting...');
        this.$router.push(`/app/accounts/${this.currentUser.account_id}/settings/inboxes/new`);
      }

    },
    onProviderChange(){
      // triger event to push additionl verification steps in grant inbox create process
      const dataToSend = {providerType: this.provider}
      bus.$emit('onInboxProviderSelectedEvent',dataToSend);
    },


  }

};
</script>
