import { frontendURL } from '../../../../helper/URLHelper';

const catalogues = accountId => ({
  parentNav: 'catalogues-nav',
  routes: [
    // 'catalogues_home',
    'catalogues_list',
    'catalogues_orders',
    'catalogue_order_details',
    'catalogue_settings',
    'catalog_reports',
  ],
  menuItems: [
    {
      // add the icon with name as outline in "app\javascript\shared\components\FluentIcon\dashboard-icons.json"
      // real icon name with outline = "contact-card-group-outline"
      icon: 'square',
      label: 'CATALOGUE_LIST',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/catalog/list`),
      toStateName: 'catalogues_list',
    },
    {
      icon: 'contact-card-group',
      label: 'CATELOGUE_ORDERS',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/catalog/orders`),
      toStateName: 'catalogues_orders',
    },
    {
      icon: 'settings',
      label: 'CATELOGUE_SETTINGS',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/catalog/settings`),
      toStateName: 'catalogue_settings',
    },
    {
      icon: 'document',
      label: 'CATELOG_REPORTS',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/catalog/reports`),
      toStateName: 'catalog_reports',
    },

  ],
});

export default catalogues;
