<template>
  <div class="credit-page row">
    <div class="medium-12">
      <balance-header
        header-title="Credit Balance"
        :search-query="searchQuery"
        :on-input-search="onInputSearch"
        :on-search-submit="onSearchSubmit"
        :on-toggle-column-selector="onToggleColumnSelector"
        :onToggleCreate="toggleCreate"
      />
      <div>
        <credits-table
          :contacts="records"
          :show-search-empty-state="showEmptySearchResult"
          :is-loading="uiFlags.isFetching"
          :on-click-contact="openContactInfoPanel"
          :active-contact-id="selectedContactId"
          :sort-config="sortConfig"
          :cols="displayColumns"
          :selectedRowIds="selectedRowIds"
        />
          <table-footer
          :on-page-change="onPageChange"
          :current-page="Number(meta.currentPage)"
          :total-count="meta.count"
        />
      </div>
      <create-grant-credit 
        :isOpen="isCreateModalOpen"
        :onSubmit="createNewCreditHandler"
        @cancel="toggleCreate"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Cookies from 'js-cookie';
import BalanceHeader from './components/BalanceHeader.vue';
import CreditsTable from './components/CreditsTable.vue';
import CreateGrantCredit from './components/CreateGrantCredit.vue';
import TableFooter from 'dashboard/components/widgets/TableFooter';

export default {
    components: {
      BalanceHeader,
      CreditsTable,
      CreateGrantCredit,
      TableFooter,
    },
    // props:{},
    data() {
      return {
        searchQuery: '',
        showColumnSelector: false,
        selectedContactId: '',
        sortConfig: { name: 'asc' },
        cols: [
          { key: 'email', name: 'Email', visible: true, type: 'default' },
          { key: 'balance_credit', name: 'Balance', visible: true, type: 'default' },
          { key: 'claimed_credit', name: 'Claimed Credit', visible: true, type: 'default' },
          { key: 'account_id', name: 'Account Id', visible: true, type: 'default' },
          { key: 'meta_url', name: 'meta URL', visible: true, type: 'default' },
          { key: 'import_status', name: 'Import Status', visible: false, type: 'default' },
          { key: 'claimed_credit', name: 'Claimed Credit', visible: false, type: 'default' },
          { key: 'graduate', name: 'Graduate', visible: true, type: 'default' },
          { key: 'api_key', name: 'API Key', visible: false, type: 'default' },
          { key: 'api_secret', name: 'API Secret', visible: false, type: 'default' },
          { key: 'action_btn', name: 'Action', visible: true, type: 'default' },
        ],
        selectedRowIds:[],
        isCreateModalOpen: false,
      };
    },
    computed: {
      ...mapGetters({
        currentUser: 'getCurrentUser',
        ifAppsumoUser:'ifAppsumoUser',
        records: "grantCredit/getGrantCredits",
        meta: 'grantCredit/getMeta',
        uiFlags: "grantCredit/getGrantCreditUIFlags",
      }),
      showEmptySearchResult() {
        const hasEmptyResults = !!this.searchQuery && this.records.length === 0;
        return hasEmptyResults;
      },
      displayColumns() {
        return this.cols;
      },
    },
    mounted() {
      // this.checkWhatsappQuestionPopupOpen();
      this.fetchGrantList();
    },
    methods: {
      onPageChange(page) {
        console.log({page});
        // search
        this.$store.dispatch('grantCredit/search',{searchQuery:this.searchQuery, page});
      },
      onInputSearch(event) {
        const newQuery = event.target.value;
        const refetchAllContacts = !!this.searchQuery && newQuery === '';
        this.searchQuery = newQuery;
        if (refetchAllContacts) {
          // this.fetchContacts(DEFAULT_PAGE);
        }
      },
      onSearchSubmit() {
        console.log(this.searchQuery);
        if (this.searchQuery) {
          // this.fetchContacts(DEFAULT_PAGE);
          // dispatch search
          this.$store.dispatch('grantCredit/search',{searchQuery:this.searchQuery, page:1})
        }
      },
      onToggleColumnSelector() {
        this.showColumnSelector = !this.showColumnSelector;
      },

      openContactInfoPanel(creditId) {
        console.log("clicked credit id ",creditId);
      },

      fetchGrantList(){
        this.$store.dispatch('grantCredit/get')
      },

      toggleCreate(){
        this.isCreateModalOpen = !this.isCreateModalOpen;
      },

      // create new credit entry
      async createNewCreditHandler(newCredit){
        console.log({newCredit});
        const resultCret = await this.$store.dispatch('grantCredit/createCreditGrant',newCredit);
        console.log({resultCret});
        this.isCreateModalOpen = false;
      },
    
    },
}
</script>



<style lang="scss" scoped>
.credit-page {
  width: 100%;
}

.left-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
