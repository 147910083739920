<template>
  <router-link v-slot="{ href, isActive, navigate }" :to="to" custom>
    <a
      v-tooltip.right="$t(`${name}`)"
      :href="getHref(to, href)"
      class="button clear button--only-icon menu-item"
      :class="{ 'is-active': isActive || isChildMenuActive }"
      :target="getTarget(to)"
      @click="
        () => {
          to.includes('pm-external') ? linkClickHandler() : navigate();
        }
      "
    >
      <fluent-icon v-if="icon.isIcon" :icon="icon.name" />
      <img
        v-if="!icon.isIcon"
        :src="require(`../../../assets/images/menu-icons/${icon.name}`)"
        :alt="`${name}-menu-icon`"
      />
      <span class="show-for-sr">{{ name }}</span>
      <span v-if="count" class="badge warning">{{ count }}</span>
    </a>
  </router-link>
</template>
<script>
import PMConnectAPI from '../../../routes/dashboard/settings/bot/PMConnectAPI';
import Cookies from 'js-cookie';

export default {
  props: {
    to: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    icon: {
      type: Object,
      default: null,
    },
    count: {
      type: String,
      default: '',
    },
    isChildMenuActive: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    linkClickHandler() {
      console.log('pm-connect');
      const user = Cookies.getJSON('user') || {};
      window.location =
        PMConnectAPI.getBaseURL() +
        `/peasy-integrate?stripe_customer_id=${user?.stripe_customer_id}&ps_access_token=${user?.pm_access_token}&ps_account_id=${user?.account_id}`;
    },
    getHref(to, href) {
      if (to.includes('pm-external')) {
        return '#';
      } else if (to.startsWith('https://')) {
        return to;
      } else {
        return href;
      }
    },
    getTarget(to){
      return to.startsWith('https://') ? '_blank' : '_self';
    },
  },
};
</script>
<style lang="scss" scoped>
.button {
  // margin: var(--space-small) 0;
  margin-top: 28px;
  margin-bottom: 8px;
}

.menu-item {
  // display: inline-flex;
  position: relative;
  border-radius: var(--border-radius-large);
  // border: 1px solid transparent;
  color: var(--white);
  filter: invert(91%) sepia(100%) saturate(0%) hue-rotate(161deg)
    brightness(106%) contrast(104%);
  display: flex;
  flex-direction: column;

  &:hover {
    background: unset !important;
    // background: var(--w-25);
    // color: var(--s-600);
  }

  // &:focus {
  //   border-color: var(--w-500);
  // }

  &.is-active {
    filter: invert(92%) sepia(81%) saturate(3100%) hue-rotate(18deg)
      brightness(98%) contrast(107%);
    // background: var(--w-50);
    color: var(--white);
  }
}
.menu-item:last-child {
  filter: unset;
}

.menu-text {
  font-size: 12px;
  font-weight: 500;
  color: red;
  letter-spacing: -0.3px;
  line-height: 140%;
}

.icon {
  font-size: var(--font-size-default);
}

.badge {
  position: absolute;
  right: var(--space-minus-smaller);
  top: var(--space-minus-smaller);
}
</style>
