/* global axios */
import ApiClient from './ApiClient';

class OrdersAPI extends ApiClient {
  constructor() {
    super('orders', { accountScoped: true });
  }

 // get all orders item orders
  getAllOrders(start_date,end_date) {
    return axios.get(`${this.url}/index?start_date=${start_date}&end_date=${end_date}`);
  }
  getOrderById(order_id) {
    return axios.get(`${this.url}/?id=${order_id}`);
  }
  // Update order by oder_id
  updateOrderById(order_id,payloadBody) {
    return axios.patch(`${this.url}/${order_id}`,payloadBody);
  }

  // get report by type
  getReportByType(type,start_date,end_date){
    return axios.get(`${this.url}/report?type=${type}&start_date=${start_date}&end_date=${end_date}`);
  }

  fetchUsagesOrderReport(queryObj={}){
    const params = new URLSearchParams(queryObj).toString();
    return axios.get(`${this.url}/transactions?${params}`);
  }

  refundAmount(order_id,amount){
    return axios.get(`${this.url}/${order_id}/refund_order?amount=${amount}`);
  }

}

export default new OrdersAPI();
