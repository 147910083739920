<template>
    <modal :show.sync="show" :on-close="onClose">
        <div class="column content-box">
            <woot-modal-header
            :header-title="'Refund Confirmation'"
        />
        </div>
        <section class="modal_body">
            <div class="item">
                <label>Order Amount</label>
                <p>{{order.grand_total}} {{order.currency}}</p>
            </div>
            <div class="item">
                <label><span>Paid Amount</span></label>
                <p>{{order.paid_total}} {{order.currency}}</p>
            </div>
            <div class="item refund_field">
                <label>Refund Amount</label>
                <div>
                    <input
                    v-model="refund_amount"
                    type="text"
                    :placeholder="'Refund amount eg. 10.50'"
                    :class="{ error: $v.refund_amount.$error }"
                     @input="onRefundInput"
                    />
                    <p  class="error_msg">{{$v.refund_amount.$error ? 'Only positive amount is required':""}}</p>
                </div>
            </div>

            <div class="modal-footer">
                <div class="medium-12 columns">
                    <woot-submit-button
                    button-text="Confirm"
                    :loading="isRefunding"
                    :disabled="isRefunding"
                    @click="onRefundAmount"
                    />
                    <button class="button clear" @click.prevent="onClose">
                    {{ $t('TEMPLATE_MSG.ADD.CANCEL_BUTTON_TEXT') }}
                    </button>
                </div>
            </div>
        </section>
    </modal>
</template>

<script>
import WootSubmitButton from '../../../../components/buttons/FormSubmitButton.vue';
import Modal from '../../../../components/Modal.vue';
import alertMixin from 'shared/mixins/alertMixin';
import OrderAPI from "../../../../api/orders";
import { required, minLength, numeric , minValue} from 'vuelidate/lib/validators';

export default {
    components: {
        WootSubmitButton,
        Modal,
    },
    mixins: [alertMixin],
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        onClose: {
            type: Function,
            default: () => {},
        },
        onSuccess: {
            type: Function,
            default: () => {},
        },
        order: {
            type: Object,
            default: () => {},
        },
    },
    data(){
        return{
            isRefunding: false,
            refund_amount: this.order?.refund_amount || 0,
        }
    },
    watch:{
        order:{
            handler(newValue, oldValue){
                // set initial refund amount
                this.refund_amount = newValue?.refund_amount || 0;
            },
            immediate: true,
        },
    },
    validations: {
        refund_amount:{
            required: true,
            float(value) {
                return /^(\d+(\.\d+)?|\.\d+)$/.test(value);
            }
        },
    },
    methods:{
        async onRefundAmount(){
            this.$v.$touch();
            if (this.$v.$invalid) {
                console.log(payload,"onRefundAmount");
                return;
            }
            try {
                this.isRefunding = true;
                const result = await OrderAPI.refundAmount(this.order.id,this.refund_amount)
                // dispatch get again
                this.showAlert('Refund successful');
                this.onSuccess();
                this.onClose();
            } catch (error) {
                // console.log(error.response,error.message);
                this.showAlert(error.response?.data?.error||'Failed to refund amount');
            }finally{
                this.isRefunding = true;
            }
        },
        onRefundInput(e){
            let value = event.target.value;
            value = value.replace(/[^0-9.]/g, '');
            console.log({value});
            const decimalIndex = value.indexOf('.');
            if (decimalIndex !== -1) {
                value = value.slice(0, decimalIndex + 1) + value.slice(decimalIndex + 1).replace(/\./g, ''); // Only allow one decimal point
            }
            this.refund_amount = value;
        },
    },
}
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.modal_body{
    min-height: 300px;
    padding: 3.2rem 3.2rem;
}

.item{
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}
.refund_field{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    input{
        margin-bottom: 5px;
        text-align: right;
    }

}

.error_msg{
    // position: absolute;
    color: var(--r-400);
}

</style>

