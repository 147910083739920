<template>
  <transition name="modal-fade">
    <div
      v-if="show"
      :class="modalClassName"
      transition="modal"
      @click="onBackDropClick"
    >
      <div :class="modalContainerClassName" @click.stop>
        <!-- <button class="modal--close" @click="close"> -->
        <button :class="modalCloseClassName" @click="close">
          <fluent-icon :icon="closeIcon" />
        </button>
        <div class="modal_content_wrap">
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    closeOnBackdropClick: {
      type: Boolean,
      default: true,
    },
    show: Boolean,
    onClose: {
      type: Function,
      required: true,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    modalType: {
      type: String,
      default: 'centered',
    },
    closeIcon: {
      type: String,
      default: 'dismiss',
    },
    size: {
      type: String,
      default: '',
    },
    modalMainContainerClass: {
      type: String,
      default: '',
    },
    closeBtnClass: {
      type: String,
      default: '',
    },
  },
  computed: {
    modalContainerClassName() {
      let className = 'modal-container';
      if (this.fullWidth) {
        return `${className} modal-container--full-width`;
      }

      return `${className} ${this.modalMainContainerClass} ${this.size}`;
    },
    modalClassName() {
      const modalClassNameMap = {
        centered: '',
        'right-aligned': 'right-aligned',
      };

      return `modal-mask ${modalClassNameMap[this.modalType] || ''}`;
    },
    modalCloseClassName(){
      return `modal--close ${this.closeBtnClass}`
    },
  },
  mounted() {
    document.addEventListener('keydown', e => {
      if (this.show && e.code === 'Escape') {
        this.onClose();
      }
    });
  },
  methods: {
    close() {
      this.onClose();
    },
    onBackDropClick() {
      if (this.closeOnBackdropClick) {
        this.onClose();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.modal-container--full-width {
  align-items: center;
  border-radius: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.modal-mask.right-aligned {
  justify-content: flex-end;

  .modal-container {
    border-radius: 0;
    height: 100%;
    width: 48rem;
  }
}

</style>

<style lang="scss">
 /* globally additional children container style */
 /* Use ::v-deep to target slot children with scoped styles */
// .modal-container > :nth-child(2) {
//   overflow: auto;
//   max-height: 78vh;
//   // border: 1px solid blue;
// }
.modal_content_wrap{
    overflow: auto;
    max-height: 78vh;

}
</style>
