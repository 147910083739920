<template>
  <woot-modal :show.sync="show" :on-close="onClose">
    <div class="column content-box">
      <woot-modal-header
        :header-title="$t('AGENT_MGMT.ADD.TITLE')"
        :header-content="$t('AGENT_MGMT.ADD.DESC')"
      />

      <form class="row" @submit.prevent="checkPlan()">
        <div class="medium-12 columns">
          <label :class="{ error: $v.agentName.$error }">
            {{ $t('AGENT_MGMT.ADD.FORM.NAME.LABEL') }}
            <input
              v-model.trim="agentName"
              type="text"
              :placeholder="$t('AGENT_MGMT.ADD.FORM.NAME.PLACEHOLDER')"
              @input="$v.agentName.$touch"
            />
          </label>
        </div>
        <div class="medium-12 columns">
          <label :class="{ error: $v.agentType.$error }">
            {{ $t('AGENT_MGMT.ADD.FORM.AGENT_TYPE.LABEL') }}
            <select v-model="agentType">
              <option v-for="role in roles" :key="role.name" :value="role.name">
                {{ role.label }}
              </option>
            </select>
            <span v-if="$v.agentType.$error" class="message">
              {{ $t('AGENT_MGMT.ADD.FORM.AGENT_TYPE.ERROR') }}
            </span>
          </label>
        </div>
        <div class="medium-12 columns">
          <label :class="{ error: $v.agentEmail.$error }">
            {{ $t('AGENT_MGMT.ADD.FORM.EMAIL.LABEL') }}
            <input
              v-model.trim="agentEmail"
              type="text"
              :placeholder="$t('AGENT_MGMT.ADD.FORM.EMAIL.PLACEHOLDER')"
              @input="$v.agentEmail.$touch"
            />
          </label>
        </div>

        <!-- phone number  -->
        <div class="medium-12 columns">
          <div class="row">
            <div class="medium-4 column">
              <label>{{$t('REGISTER.COUNTRY_CODE.LABEL')}}</label>
              <select v-model="selected_country_code" @input="onCountryCodeChange">
                <option
                  v-for="code in phone_code_list"
                  :key="code.name"
                  :value="code.dial_code"
                >
                  {{code.emoji}} {{ code.dial_code }}
                </option>
              </select>
            </div>
            <div class="medium-8 column">
              <woot-input
                v-model.trim="input_phone_number"
                :class="{ error: $v.input_phone_number.$error }"
                type="number"
                :label="$t('AGENT_MGMT.ADD.FORM.PHONE.LABEL')"
                :placeholder="$t('AGENT_MGMT.ADD.FORM.PHONE.PLACEHOLDER')"
                :error="
                  $v.input_phone_number.$error
                    ? $t('AGENT_MGMT.ADD.FORM.PHONE.ERROR')
                    : ''
                "
                @input="onPhoneNumberChange"
                @blur="$v.agentPhone.$touch"
              />
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <div class="medium-12 columns">
            <woot-submit-button
              :disabled="
                $v.agentEmail.$invalid ||
                  $v.agentName.$invalid ||
                  $v.input_phone_number.$invalid ||
                  uiFlags.isCreating
              "
              :button-text="$t('AGENT_MGMT.ADD.FORM.SUBMIT')"
              :loading="uiFlags.isCreating"
            />
            <button class="button clear" @click.prevent="onClose">
              {{ $t('AGENT_MGMT.ADD.CANCEL_BUTTON_TEXT') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </woot-modal>
</template>

<script>
import { required, minLength, email } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import {phone_code_list} from "dashboard/routes/auth/country_code";

export default {
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
    agentsList: {
      required: false,
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      agentName: '',
      agentEmail: '',
      agentPhone: '',
      selected_country_code:"+60",
      input_phone_number:"",
      phone_code_list,
      userPlanDetails: [],
      presentAgents:
        this.agentsList.length > 0
          ? this.agentsList.filter(agent => agent.role === 'agent').length
          : 0,
      agentType: 'agent',
      vertical: 'bottom',
      horizontal: 'center',
      roles: [
        {
          name: 'administrator',
          label: this.$t('AGENT_MGMT.AGENT_TYPES.ADMINISTRATOR'),
        },
        {
          name: 'agent',
          label: this.$t('AGENT_MGMT.AGENT_TYPES.AGENT'),
        },
      ],
      show: true,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'agents/getUIFlags',
    }),
  },
  validations: {
    agentName: {
      required,
      minLength: minLength(1),
    },
    agentEmail: {
      required,
      email,
    },
    input_phone_number: {
      required,
      minLength: minLength(2),
    },
    agentPhone: {
      required,
      minLength: minLength(2),
    },
    agentType: {
      required,
    },
  },

  methods: {
    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
    async addAgent() {
      console.log({phone_number: this.agentPhone,});
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      try {
        await this.$store.dispatch('agents/create', {
          name: this.agentName,
          email: this.agentEmail,
          role: this.agentType,
          phone_number: this.agentPhone,
        });
        this.showAlert(this.$t('AGENT_MGMT.ADD.API.SUCCESS_MESSAGE'));
        this.onClose();
      } catch (error) {
        const {
          response: { data: { error: errorResponse = '' } = {} } = {},
        } = error;
        let errorMessage = '';
        if (error.response.status === 422) {
          // errorMessage = this.$t('AGENT_MGMT.ADD.API.EXIST_MESSAGE');
          errorMessage = error.response?.data?.message;
        } else {
          errorMessage = this.$t('AGENT_MGMT.ADD.API.ERROR_MESSAGE');
        }
        this.showAlert(errorResponse || errorMessage);
      }
    },
    async checkPlan() {
      this.userPlanDetails = await this.$store.dispatch(
        'accounts/fetchAccountInboxes'
      );
      if (this.presentAgents < this.userPlanDetails.max_agents) {
        await this.addAgent();
      } else {
        this.showAlert(this.$t('AGENT_MGMT.MORE_AGENT_ERROR'));
      }
    },
    onCountryCodeChange(event){
      this.selected_country_code = event.target.value;
      this.agentPhone = `${event.target.value}${this.input_phone_number}`;
    },
    onPhoneNumberChange(value){
      this.input_phone_number = value;

      this.agentPhone = `${this.selected_country_code}${value}`;
    },


  },
};
</script>
