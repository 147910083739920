<template>
  <div class="column content-box">
    <woot-button color-scheme="success" class-names="button--fixed-right-top" icon="add-circle" @click="clearAndOpen()">
      {{ $t('CALENDAR_MGMT.ADD_HEADER_BTN_TXT') }}
    </woot-button>

    <!-- <session-header header-title="Meeting Sessions" /> -->

    <sessions-table :sessions="sessions" :refresh="getSessions" :is-loading="isFetchingSessions"
      :currentUserId="currentUserId" :openSessionEditor="openSessionEditor" />
    <woot-modal :show.sync="showAddPopup" size="medium" :on-close="hideAddPopup">
      <CalendarMeetingAdd :on-close="hideAddPopup" :refresh="getSessions" :currentUserId="currentUserId"
        :prefilledForm="prefilledForm" />
    </woot-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import CalendarMeetingAdd from './CalendarMeetingAdd.vue';
import SessionsTable from './SessionsTable.vue';
import CalendarApi from '../../../../api/calendar';
import { popupHelpers } from '../../../../helper/demoAutomationHelper';

export default {
  name: 'calendarView',
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
      currentUserId: 'getCurrentUserID',
    }),
  },
  data() {
    return {
      loading: {},
      showAddPopup: false,
      sessions: [],
      isFetchingSessions: false,
      prefilledForm: null,
    };
  },
  async mounted() {
    this.getSessions();

    // ----------------------------------
    bus.$on('instruct_demo_meeting_event', (data) => {
      const {instructSchedule} = data;
      console.log('instruct_demo_meeting_event in Google Sk',instructSchedule);
      // this.showAddPopup = true;
      // open a new url for the schedule link
      const appointment_url = `https://schedule.peasy.ai/book/user/${this.currentUserId}/${instructSchedule.unique_link}`;
      popupHelpers.openNewTab(appointment_url);

      this.getSessions();
      this.openSessionEditor(instructSchedule);
    });
    // ----------------------------------
  },
  methods: {
    clearAndOpen() {
      this.prefilledForm = null;
      this.openAddPopup();
    },
    openSessionEditor(sessionData) {
      console.log(sessionData,'sessionData match with instructSchedule');
      const preparedObject = {
        meetingTitle: sessionData.title,
        meetingDescription: sessionData.description,
        duration: sessionData.duration,
        business_hours: sessionData.availability,
        maximumMeeting: sessionData.max_meetings,
        maximumBufferTime: sessionData.buffer_time,
        noticeTime: sessionData.notice_time,
        reminder_time: sessionData.reminder_time,
        confirmation_msg: sessionData.confirmation_msg,
        reminder_msg: sessionData.reminder_msg,
        inbox_id: sessionData.inbox_id,
        meetingLink: sessionData.unique_link,
        location: sessionData.location,

        meetingUniqueLink: '-',
        timezone: sessionData.timezone,
        // conference_type: 'google_meet',
        notice_time: sessionData.notice_time,
        id: sessionData.id,
        // reminderMessage: sessionData.reminder_msg.message,
      };
      this.prefilledForm = preparedObject;
      console.log('checker sessionData', sessionData, this.prefilledForm);
      this.openAddPopup();
    },
    async getSessions() {
      try {
        this.isFetchingSessions = true;
        let meetingSessions = await CalendarApi.getAllMeetingSessions();
        if (meetingSessions.data) {
          console.log('sessions94 ', meetingSessions.data);
          this.sessions = meetingSessions.data;
        }
      } catch (error) {
        console.log('error', error);
      } finally {
        this.isFetchingSessions = false;
      }
    },
    openAddPopup() {
      console.log('==========opening popup to add calendar session =======');
      this.showAddPopup = true;
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },
  },
  components: { CalendarMeetingAdd, SessionsTable },
};
</script>
