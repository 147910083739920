<template>
  <div class="column content-box">
    <woot-modal-header
      v-if="isIntegrationPeasy"
      :header-title="$t('INTEGRATION_APPS.PEASY.HEADER')"
      :header-content="$t('INTEGRATION_APPS.PEASY.DESCRIPTION')"
    />
    <woot-modal-header
      v-else
      :header-title="integration.name"
      :header-content="integration.description"
    />
    <formulate-form
      #default="{ hasErrors }"
      v-model="values"
      @submit="submitForm"
    >
      <formulate-input
        v-for="item in formItems"
        :key="item.name"
        v-bind="item"
        :value="item.value"
        :disabled="item.disable"
      />
      <!-- <formulate-input
        :options="brandProfiles"
        type="select"
        name="brand_profile_id"
        :placeholder="$t('INTEGRATION_APPS.ADD.FORM.INBOX.LABEL')"
        :label="$t('INTEGRATION_APPS.ADD.FORM.INBOX.PLACEHOLDER')"
        validation="required"
        validation-name="Inbox"
      /> -->
      <label style="width:100%" class="catalog-label-text" v-if="integration.id !== 'chatgpt' && integration.id !== 'stripe'">
        {{ $t('FLOW.SELECT_BRAND_PROFILE') }}
        <select
          @change="OnBrandProfileChanged()"
          v-model="selectedBrandProfile"
        >
          <option v-for="item in brandProfiles" :key="item.id" :value="item">
            {{ item.brand_name }}
          </option>
        </select>
      </label>
      <formulate-input
        v-if="integration.id === 'stripe'"
        type="text"
        name="secret_api_key"
        :placeholder="$t('INTEGRATION_APPS.ADD.FORM.SECRET_API_KEY.PLACEHOLDER')"
        :label="$t('INTEGRATION_APPS.ADD.FORM.SECRET_API_KEY.LABEL')"
        validation="required"
        v-model="secretApiKey"
      />
      <formulate-input
        v-if="integration.id === 'stripe'"
        type="text"
        name="publishable_key"
        :placeholder="$t('INTEGRATION_APPS.ADD.FORM.PUBLISHABLE_KEY.PLACEHOLDER')"
        :label="$t('INTEGRATION_APPS.ADD.FORM.PUBLISHABLE_KEY.LABEL')"
        validation="required"
        v-model="publishableKey"
      />
      <formulate-input
        v-if="integration.id === 'stripe'"
        :options="currencies"
        type="select"
        name="currency"
        :placeholder="$t('INTEGRATION_APPS.ADD.FORM.CURRENCY.LABEL')"
        :label="$t('INTEGRATION_APPS.ADD.FORM.CURRENCY.PLACEHOLDER')"
        validation="required"
        validation-name="Currency"
        v-model="currency"
      />
      <!-- <formulate-input
        v-if="isHookTypeInbox"
        :options="inboxes"
        type="select"
        name="inbox"
        :value="hook.inbox.id"
        :placeholder="$t('INTEGRATION_APPS.ADD.FORM.INBOX.LABEL')"
        :label="$t('INTEGRATION_APPS.ADD.FORM.INBOX.PLACEHOLDER')"
        validation="required"
        validation-name="Inbox"
        disabled="true"
      /> -->
      <p
        v-if="isIntegrationPeasy"
        v-html="
          $t('INTEGRATION_APPS.PEASY.PEASY_WEBHOOK_URL', {
            baseUrl: basePath,
          })
        "
      />
      <div class="modal-footer">
        <woot-button :disabled="hasErrors" :loading="uiFlags.isCreatingHook">
          {{ $t('INTEGRATION_APPS.ADD.FORM.EDIT') }}
        </woot-button>
        <woot-button class="button clear" @click.prevent="onClose">
          {{ $t('INTEGRATION_APPS.ADD.FORM.CANCEL') }}
        </woot-button>
      </div>
    </formulate-form>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import hookMixin from './hookMixin';
import PMConnectAPI from '../bot/PMConnectAPI';

export default {
  mixins: [alertMixin, hookMixin],
  props: {
    integration: {
      type: Object,
      default: () => ({}),
    },
    hook: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      endPoint: '',
      alertMessage: '',
      values: {},
      brandProfiles: [],
      selectedBrandProfile: null,
      peasyApiKey: null,
      currencies: ['MYR', 'EUR', 'USD', 'AUD'],
      secretApiKey: "",
      publishableKey: "",
      currency: ""
    };
  },
  created() {
    console.log('>>> EditHookUpdated.vue <<<','line-137')
  },
  async mounted() {
    console.log('this.secretApiKey: ', this.secretApiKey);
    let brandProfilesResponse = await PMConnectAPI.getBrandProfileList();
    console.log('brandProfilesResponse', brandProfilesResponse);
    this.brandProfiles = brandProfilesResponse;
    console.log('hook data', this.hook);
    console.log('hook data seting', this.hook?.settings.secret_api_key);
    console.log('this.hook?.settings: ', this.hook?.settings);
    if (this.hook?.settings) {
      this.secretApiKey = this.hook.settings.secret_api_key;
      this.publishableKey = this.hook.settings.publishable_key;
      this.currency = this.hook.settings.currency;
    }
    let mappedProfiles = {};
    brandProfilesResponse.forEach(responseProfile => {
      mappedProfiles[responseProfile.id] = responseProfile.brand_name;
    });

    if (this.hook?.settings?.brand_profile_id) {
      const profileId = this.hook.settings.brand_profile_id;
      const brandName =
        mappedProfiles[`${profileId}`] || 'Brand Profile ' + profileId;
      this.selectedBrandProfile = {
        id: this.hook.settings.brand_profile_id,
        brand_name: brandName,
      };

      this.$nextTick(() => {
        this.OnBrandProfileChanged();
      });
    }
  },
  computed: {
    ...mapGetters({
      uiFlags: 'integrations/getUIFlags',
      websiteInboxes: 'inboxes/getWebsiteInboxes',
      dialogFlowEnabledInboxes: 'inboxes/dialogFlowEnabledInboxes',
      peasyInboxes: 'inboxes/getInboxes',
      basePath: 'accounts/getBasePath',
      currentUser: 'getCurrentUser',
    }),
    inboxes() {
      if (this.isIntegrationPeasy) {
        return this.peasyInboxes
          .filter(inbox => {
            return inbox.brand_profile_id == null;
          })
          .map(inbox => ({ label: inbox.name, value: inbox.id }));
      }
      return this.dialogFlowEnabledInboxes
        .filter(inbox => {
          if (!this.isIntegrationDialogflow) {
            return true;
          }
          return !this.connectedDialogflowInboxIds.includes(inbox.id);
        })
        .map(inbox => ({ label: inbox.name, value: inbox.id }));
    },

    connectedDialogflowInboxIds() {
      if (!this.isIntegrationDialogflow) {
        return [];
      }
      return this.integration.hooks.map(hook => hook.inbox?.id);
    },
    formItems() {
      console.log('form items', this.integration.settings_form_schema);
      // return this.integration.settings_form_schema;
      return [];
    },
    isIntegrationDialogflow() {
      return this.integration.id === 'dialogflow';
    },
    isIntegrationPeasy() {
      return this.integration.id === 'peasy';
    },
  },
  methods: {
    async OnBrandProfileChanged() {
      let genericTokenResponse = await PMConnectAPI.getPMToken();
      let psApiKey = await PMConnectAPI.getPSAPIKey();

      console.log(
        'brand profile changed e',
        this.selectedBrandProfile,
        this.selectedBrandProfile?.id,
        psApiKey,
        genericTokenResponse
      );

      console.log(
        'should get brandkey ',
        this.selectedBrandProfile?.id && psApiKey && genericTokenResponse
      );
      if (this.selectedBrandProfile?.id && psApiKey && genericTokenResponse) {
        let brandKeyResponse = await PMConnectAPI.getBrandProfileKey(
          psApiKey,
          this.selectedBrandProfile?.id,
          genericTokenResponse
        );

        console.log('brandKeyResponse', brandKeyResponse);

        this.peasyApiKey = brandKeyResponse?.key;
      }
    },
    onClose() {
      this.$emit('close');
    },
    buildHookPayload() {
      const hookPayload = {};
      console.log('hookPayload', hookPayload);
      if (this.integration.id === 'stripe') {
        hookPayload.settings = {
          secret_api_key: this.values.secret_api_key,
          publishable_key: this.values.publishable_key,
          currency: this.values.currency,
        };
      } else {
        hookPayload.contact_create_url =
          'https://app.peasy.ai/service/api/webhook/lead/create';
        hookPayload.contact_update_url =
          'https://app.peasy.ai/service/api/webhook/lead/update';
        hookPayload.peasy_api_key = this.peasyApiKey;
        hookPayload.brand_profile_id = this.selectedBrandProfile?.id;
        hookPayload.account_id = this.currentUser?.account_id;
      }

      if (this.isHookTypeInbox && this.values.inbox) {
        hookPayload.inbox_id = parseInt(this.values.inbox);
      }

      return hookPayload;
    },
    async submitForm() {
      try {
        await this.$store.dispatch('integrations/updateHook', {
          hookId: this.hook.id,
          hookData: this.buildHookPayload(),
        });
        await this.$store.dispatch('integrations/get');
        this.alertMessage = this.$t('INTEGRATION_APPS.ADD.API.EDIT_MESSAGE');
        this.onClose();
      } catch (error) {
        const errorMessage = error?.response?.data?.message;
        this.alertMessage =
          errorMessage ||
          this.$t('INTEGRATION_APPS.ADD.API.BRAND_ERROR_MESSAGE');
      } finally {
        this.showAlert(this.alertMessage);
      }
    },
  },
};
</script>
