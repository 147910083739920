<template>
  <woot-modal :show.sync="show" :on-close="onClose">
    <woot-modal-header v-if="msgDetail" :header-title="msgDetail.header" />
    <div class="list-wrapper" v-if="msgDetail">
      <div
        class="row list"
        v-for="(section, sectionIndex) in msgDetail.action.sections"
        :key="sectionIndex"
      >
        <h6>{{ section.title }}</h6>
        <div
          class="column small-12 odr-content"
          v-for="(product, productIndex) in section.product_items"
          :key="productIndex"
        >
          <div class="column small-2 img-card card">
            <bubble-image
              class="food-img"
              url="https://www.pngmart.com/files/23/Food-PNG-Photos.png"
            />
          </div>
          <div class="odr-detail column small-8">
            <h6>{{ product.product_retailer_id }}</h6>
            <!-- <span>MYR {{item.count > 1 ? item.price * item.count : item.price}} {{item.count > 1 ? ` Quantity ${item.count}` : ''}}</span> -->
            <span>{{$t('CONVERSATION.MYR')}} {{ product.price }}</span>
          </div>
          <div class="column small-1 action">
            <woot-button
              v-if="product.count > 0 && selctedItems.length > 0"
              size="small"
              variant="smooth"
              color-scheme="secondary"
              @click="removeFromOrder(product)"
            >-</woot-button>
            <div
              v-if="product.count > 0 && selctedItems.length > 0"
            >{{ product.count > 0 ? product.count : ''}}</div>
          </div>
          <div class="column small-1 add-item">
            <woot-button
              size="small"
              variant="smooth"
              color-scheme="secondary"
              @click="addToOrder(product)"
            >+</woot-button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer w-full" v-if="selctedItems.length > 0">
      <woot-button @click="placeOrder">{{ $t('CONVERSATION.PLACE_ORDER') }}</woot-button>
    </div>
  </woot-modal>
</template>

<script>
import BubbleImage from '../bubble/Image.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'ItemList',
  components: { BubbleImage },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      show: true,
      selctedItems: [],
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
    }),
    msgDetail() {
      if (this.data.content_attributes) {
        return this.data.content_attributes.items[0];
      }
    },
    
  },
  created() {
    this.getProductDetails();
  },
  methods: {
    getProductDetails() {
      let params = { inbox_id: 61, product_retail_ids: this.productRetailIds };

      CatalogueAPI.search(params).then(response => {
        console.log(response);
      });
    },
    onClose() {
      this.$emit('close');
    },
    addToOrder(odrItem) {
      let itemIsPresent = this.selctedItems.findIndex(
        item => item.product_retailer_id == odrItem.product_retailer_id
      );
      if (itemIsPresent == -1) {
        odrItem.count = 1;
        this.selctedItems.push(odrItem);
        // this.selctedItems.push(JSON.parse(JSON.stringify(odrItem)));
      } else {
        this.selctedItems[itemIsPresent].count =
          this.selctedItems[itemIsPresent].count + 1;
      }
    },
    removeFromOrder(odrItem) {
      let itemIsPresent = this.selctedItems.findIndex(
        item => item.product_retailer_id == odrItem.product_retailer_id
      );
      if (
        itemIsPresent !== -1 &&
        this.selctedItems[itemIsPresent].count !== 0
      ) {
        this.selctedItems[itemIsPresent].count =
          this.selctedItems[itemIsPresent].count - 1;
      }
      let orderData = this.selctedItems.filter(item => item.count !== 0);
      this.selctedItems = orderData;
    },
    async placeOrder() {
      const messagePayload = {
        conversationId: this.currentChat.id,
        contentAttributes: Object.assign({}, this.selctedItems),
        message: this.selctedItems,
      };
      console.log('messagePayload', messagePayload);
      try {
        await this.$store.dispatch(
          'createPendingMessageAndSend',
          messagePayload
        );
      } catch (error) {
        console.log('error', error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.subtitle {
  padding: 10px 20px;
}

.list-wrapper {
  padding: 20px;
  height: 600px;
  overflow-y: auto;
  .list {
    margin-top: 10px;
    .odr-content {
      display: flex;
      padding-top: 20px;
      align-items: center;
      .img-card {
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 0;
        .food-img {
          height: 50px;
          width: 80px;
          img {
            height: 100%;
            width: 100%;
          }
        }
      }
      .odr-detail {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 10px;
        text-align: left;
      }
      .action {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .add-item {
        display: flex;
        justify-content: end;
      }
    }
  }
}
.modal-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .detail {
    margin-bottom: 10px;
  }
}
</style>