<template>
  <div class="view-box columns">
    <keep-alive v-if="keepAlive">
      <router-view ></router-view>
    </keep-alive>
    <router-view v-else></router-view>
  </div>
</template>

<script>

export default {
  components: {
    // CreditHeader,
  },
  props: {
    headerTitle: { type: String, default: '' },
    icon: { type: String, default: '' },
    keepAlive: {
      type: Boolean,
      default: true,
    },
    
    showBackButton: {
      type: Boolean,
      default: false,
    },
    backUrl: {
      type: [String, Object],
      default: '',
    },
  },
  
  
};
</script>
