<template>
  <div class="view-box columns bg-light">
      <!-- :header-title="$t(headerTitle)" -->
    <session-header
      button-route="new"
      :icon="icon"
      :header-title="headerTitle"
      :show-back-button="showBackButton"
      :back-url="backUrl"
    />
    <keep-alive v-if="keepAlive">
      <router-view></router-view>
    </keep-alive>
    <router-view v-else></router-view>
  </div>
</template>

<script>
/* eslint no-console: 0 */

import SessionHeader from './SessionHeader.vue';


export default {
  components: {
    SessionHeader,
  },
  props: {
    headerTitle: { type: String, default: '' },
    icon: { type: String, default: '' },
    keepAlive: {
      type: Boolean,
      default: true,
    },
    
    showBackButton: {
      type: Boolean,
      default: false,
    },
    backUrl: {
      type: [String, Object],
      default: '',
    },
  },
  
  
};
</script>
