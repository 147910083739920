<template>
  <woot-modal :show.sync="show" :on-close="onClose">
    <woot-modal-header :header-title="$t('CAMPAIGN.EMAIL_TEMPLATE.LIST.HEADER')" />
    <hr />
    <div class="row">
      <div
        v-if="emailTemplates.length === 0"
        class="columns medium-12"
        style="display: flex; justify-content: center;"
      >
        <woot-button
          @click="addNewTemplate"
        >{{ $t('CAMPAIGN.EMAIL_TEMPLATE.LIST.ADD_BUTTON_TEXT') }}</woot-button>
      </div>
      <div
        class="medium-6 small-12 columns"
        style="padding: 10px;"
        v-show="emailTemplates.length > 0"
        v-for="template in emailTemplates"
        :key="template.id"
        @click="$emit('selectTemplate', {templateHtml: template.templateBody.html})"
      >
        <div class="card">
          <div class="row" style="display: flex; align-items: center; margin-bottom: 10px;">
            <div class="medium-8">
              <span class="fs-small">{{ template.name }}</span>
            </div>
          </div>
          <div style="max-height: 200px; overflow-y: scroll;" v-html="template.templateBody.html"></div>
        </div>
      </div>
    </div>
  </woot-modal>
</template>

<script>
import EmailTemplateAPI from '../../../../../api/emailTemplates';

export default {
  name: 'EmailTemplateList',
  data() {
    return {
      show: true,
      isFetchinigEmailTemplate: false,
      emailTemplates: [],
    };
  },
  created() {
    this.getTemplates();
  },
  methods: {
    addNewTemplate() {
      this.$router.push({ name: 'email_template_add' });
    },
    onClose() {
      this.$emit('close');
    },
    async getTemplates(serverParams) {
      this.isFetchinigEmailTemplate = true;
      await EmailTemplateAPI.getTemplates(serverParams)
        .then(response => {
          let tempData = [];
          if (response.data) {
            response.data.map(data => {
              tempData.push({ ...data, templateBody: JSON.parse(data.body) });
            });
          }
          this.emailTemplates = tempData;
        })
        .catch(error => {})
        .finally(() => {
          this.isFetchinigEmailTemplate = false;
        });
    },
  },
};
</script>

<style>
</style>