<template>
  <div
    class="small-6 medium-4 large-3 columns channel"
    :class="{ inactive: !isActive, disabled_channel:isChannelDisabled }"
    @click="onItemClick"
  >
    <img
      v-if="channel.key === 'facebook'"
      src="~dashboard/assets/images/channels/messenger.png"
    />
    <img
      v-if="channel.key === 'twitter'"
      src="~dashboard/assets/images/channels/twitter.png"
    />
    <img
      v-if="channel.key === 'telegram'"
      src="~dashboard/assets/images/channels/telegram.png"
    />
    <img
      v-if="channel.key === 'api' && !channel.thumbnail"
      src="~dashboard/assets/images/channels/api.png"
    />
    <img
      v-if="channel.key === 'api' && channel.thumbnail"
      :src="channel.thumbnail"
    />
    <img
      v-if="channel.key === 'email'"
      src="~dashboard/assets/images/channels/email.png"
    />
    <img
      v-if="channel.key === 'line'"
      src="~dashboard/assets/images/channels/line.png"
    />
    <img
      v-if="channel.key === 'website'"
      src="~dashboard/assets/images/channels/website.png"
    />
    <img
      v-if="channel.key === 'twilio sms'"
      src="~dashboard/assets/images/channels/sms.png"
    />
    <img
      v-if="channel.key === 'whatsapp'"
      src="~dashboard/assets/images/channels/whatsapp.png"
    />
    <img
      v-if="channel.key === 'whatsapp communal'"
      src="~dashboard/assets/images/channels/whatsapp.png"
    />
    <h3 class="channel__title">
      {{ channel.name }}
    </h3>
  </div>
</template>
<script>
export default {
  props: {
    channel: {
      type: Object,
      required: true,
    },
    enabledFeatures: {
      type: Object,
      required: true,
    },
    limitReached: {
      type: Boolean,
      default: false,
      required: true,
    },
    allowedInboxes: {
      type: Array,
      required: true,
    },
    isChannelDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isActive() {
      const { key } = this.channel;
      if (
        this.allowedInboxes &&
        this.allowedInboxes.length > 0 &&
        !this.limitReached
      ) {
        return this.allowedInboxes.includes(key);
      }
      return false;
    },
  },
  methods: {
    onItemClick() {
      // return if disabled the button
      if (this.isChannelDisabled) {
        console.log('disabled channel ',this.channel.key);
        return;
      }

      if (this.isActive) {
        let channel =
          // eslint-disable-next-line no-nested-ternary
          this.channel.key === 'whatsapp communal'
            ? 'whatsappCommunal'
            : this.channel.key === 'twilio sms'
            ? 'sms'
            : this.channel.key;
        this.$emit('channel-item-click', channel);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.disabled_channel{
  opacity: 0.5;
  cursor: not-allowed;
}

</style>