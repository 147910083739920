<template>
  <div class="wizard-body columns content-box small-9">
    <form class="row" @submit.prevent="connectPhonePin()">
      <div class="medium-12 columns">
        <page-header
          :header-title="$t('INBOX_MGMT.ADD.WHATSAPP.CONNECT_PHONE_STEP.TITLE')"
          :header-content="pageHeader"
        />
      </div>
      <div class="medium-7 columns">
        <div class="medium-12 columns">
            <label :class="{ error: $v.pin.$error }">
                {{ $t('INBOX_MGMT.ADD.WHATSAPP.CONNECT_PHONE_STEP.PIN_LABEL') }}
                <input
                    v-model.trim="pin"
                    :disabled="isPINInputDisabled"
                    type="text"
                    :placeholder="$t('INBOX_MGMT.ADD.WHATSAPP.CONNECT_PHONE_STEP.PIN_INPUT')"
                    @blur="$v.pin.$touch"
                />
                <span v-if="$v.pin.$error" class="message">
                    {{ $t('INBOX_MGMT.ADD.WHATSAPP.CONNECT_PHONE_STEP.PIN_ERR') }}
                </span>
            </label>
        </div>
        <div class="medium-12 columns">
          <woot-submit-button
            :button-text="$t('INBOX_MGMT.ADD.WHATSAPP.CONNECT_PHONE_STEP.BTN')"
            :loading="isCreating"
            :disabled="isPINSubmitBtnDisabled"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
/* eslint no-console: 0 */
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import router from '../../../index';
import PageHeader from '../SettingsSubPageHeader';
import WebChannelAPI from '../../../../api/channel/webChannel';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  mixins: [alertMixin],
  components: {
    PageHeader,
  },
  props: {
    isRedirectToAddAgent: {
      type: Boolean, 
      default: true 
    },
    isHardReloadOnSuccess: {
      type: Boolean, 
      default: false 
    },
  },
  validations: {
    pin:{
        required
    },
    
  },

  data() {
    return {
        pin: "",
        isCreating: false,
        waba_inbox_status: null,
        currentInboxId: null,
    };
  },

  computed: {
    ...mapGetters({
    //   agentList: 'agents/getAgents', inboxId
      currentInboxId() {
        console.log("params",this.$route.params);
        return this.$route.params.inbox_id || this.$route.params.inboxId;
      },
    }),
    isPINInputDisabled() {
        if(this.waba_inbox_status === 0) return true;
        if(this.waba_inbox_status === 1) return false;
    },
    isPINSubmitBtnDisabled() {
        if(this.waba_inbox_status === 0) return true;
        if(this.waba_inbox_status === 1) return false;
        return true;
    },
    pageHeader(){
      let headerText = "";
      if (this.waba_inbox_status === 0) {
        headerText = this.$t('INBOX_MGMT.ADD.WHATSAPP.CONNECT_PHONE_STEP.CONNECT_STATUS_0')
      }else if (this.waba_inbox_status === 1) {
        headerText = this.$t('INBOX_MGMT.ADD.WHATSAPP.CONNECT_PHONE_STEP.CONNECT_STATUS_1')
      }
      return headerText;
    },

  },

  mounted() {
    // this.$store.dispatch('agents/get');
    this.getCurrentWABAstatus();
  },

  methods: {
    async connectPhonePin() {
        console.log({isRedirectToAddAgent:this.isRedirectToAddAgent,reload:this.isHardReloadOnSuccess});
        this.$v.$touch();
        if (this.$v.$invalid) {
            return;
        }
        this.isCreating = true;
        // const inboxId = this.$route.params.inbox_id;
      
        try {
            const inboxId = this.$route.params.inbox_id || this.$route.params.inboxId;
            const bodyPayload = {
                pin: this.pin,
                id: inboxId,
            }
            const {data:connectPhoneResponse} = await WebChannelAPI.connectPhoneWithPin(bodyPayload);
            console.log({connectPhoneResponse});

            // if response.success=false, return
            if (!connectPhoneResponse.success) {
              this.showAlert(this.$t('INBOX_MGMT.ADD.WHATSAPP.CONNECT_PHONE_STEP.MSG.FALSE'));
              return; 
            }
            
            if (connectPhoneResponse.success) {
              this.showAlert(this.$t('INBOX_MGMT.ADD.WHATSAPP.CONNECT_PHONE_STEP.MSG.TRUE'));

              if (this.isRedirectToAddAgent) { 
                router.replace({
                    name: 'settings_inboxes_add_agents',
                    params: {
                        page: 'new',
                        // inbox_id: this.$route.params.inbox_id,
                        inbox_id: inboxId,
                    },
                });
              }
              
              // reload the page which will chek the status=2 and hide connect_phone tab from setting
              if(this.isHardReloadOnSuccess){
                window.location.reload();
              }
            }
        } catch (error) {
            this.showAlert(error.message);
        }
        this.isCreating = false;
    },
    async getCurrentWABAstatus(){
        try {
          const inboxId = this.$route.params.inbox_id || this.$route.params.inboxId;
          console.log({params:this.$route.params,inboxId});
          // initially disable until we fix meta embeded
            const {data:wabaStatusData} = await WebChannelAPI.getWabaInboxStatus(inboxId);
            console.log({wabaStatusData});
            this.waba_inbox_status = wabaStatusData.status;
            
        } catch (error) {
            console.log(error);
            this.showAlert(error.message);
            
        }
    },


  },
};
</script>
