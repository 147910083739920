export default {
  methods: {
    setConditionAttributes(conditions) {
      const allCustomAttributes = this.$store.getters[
        'attributes/getAttributesByModel'
      ](this.attributeModel);

      const customAttributesFormatted = {
        name: this.$t(`${this.filtersFori18n}.GROUPS.CUSTOM_ATTRIBUTES`),
        attributes: allCustomAttributes.map(attr => {
          return {
            key: attr.attribute_key,
            name: attr.attribute_display_name,
          };
        }),
      };
      const allFilterGroups = [
        {
          name: this.$t(`${this.filtersFori18n}.GROUPS.STANDARD_FILTERS`),
          attributes: conditions.map(attribute => {
            return {
              key: attribute.key,
              attributeI18nKey: `CUSTOM_ATTRIBUTE_${attribute.attributeI18nKey}`,
              inputType: attribute.inputType,
              filterOperators: attribute.filterOperators,
              name: attribute.name,
            };
          }),
        },
      ];
      const customAttributeTypes = allCustomAttributes.map(attr => {
        return {
          attributeKey: attr.attribute_key,
          attributeI18nKey: `CUSTOM_ATTRIBUTE_${attr.attribute_display_type.toUpperCase()}`,
          inputType: this.customAttributeInputType(attr.attribute_display_type),
          filterOperators: this.getOperatorTypes(attr.attribute_display_type),
          attributeModel: 'custom_attributes',
        };
      });
      this.filterTypes = [...this.filterTypes, ...customAttributeTypes];
      this.filterGroups = [...allFilterGroups, customAttributesFormatted];
    },
    setAttributeValues(conditions) {
      return [
        {
          name: this.$t(`${this.filtersFori18n}.GROUPS.STANDARD_FILTERS`),
          attributes: conditions.map(attribute => {
            return {
              key: attribute.key,
              attributeI18nKey: `CUSTOM_ATTRIBUTE_${attribute.attributeI18nKey}`,
              inputType: attribute.inputType,
              filterOperators: attribute.filterOperators,
              name: attribute.name,
            };
          }),
        },
      ];
    },
  },
};
