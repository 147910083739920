<template>
  <div class="row content-box full-height">
    <woot-wizard
      class="hide-for-small-only medium-3 columns"
      :global-config="globalConfig"
      :items="finalizedItems"
    />
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';

export default {
  mixins: [globalConfigMixin],
  data() {
    return {
      inboxProviderType: "", 
    };
  },
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
    }),
    items() {
      return this.$t('INBOX_MGMT.CREATE_FLOW').map(item => ({
        ...item,
        body: this.useInstallationName(
          item.body,
          this.globalConfig.installationName
        ),
      }));
    },
    finalizedItems(){
      const items = [...this.items]; 
      const newVonageVerificationStep = {
        title: 'Connect Phone Number',
        route: 'settings_inboxes_verify_waba',
        body: 'Register your phone number to your WhatsApp account'
      }
      const vonageVerificationStepIndex = items.findIndex(item => item.route === newVonageVerificationStep.route);

      // Remove the item if it exists and the provider is not 'vonage'
      if (vonageVerificationStepIndex !== -1) {
        items.splice(vonageVerificationStepIndex, 1);
      }

      // Push the additional item if the provider is 'vonage'
      if (this.inboxProviderType === 'vonage') {
        items.splice(2,0,newVonageVerificationStep);
      }
      return items;
    },
  },
  mounted(){
    bus.$on('onInboxProviderSelectedEvent',(eventData)=>{
      this.inboxProviderType = eventData?.providerType;
    })
  },
};
</script>
