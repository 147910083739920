import types from "../mutation-types";
import MerchantApi from '../../api/merchant'
// import {ExternalConfig} from "../../settings/inbox/channels/constant"
import {ExternalConfig} from "../../../dashboard/routes/dashboard/settings/inbox/channels/constant"

export const state = {
    uiFlags:{
        isFetching: false,
    },
    merchant: {},
};

const getters = {
    getUIFlags(_state){
        return _state.uiFlags;
    },
    getMerchant(_state){
        return _state.merchant;
    },
    isMerchant(_state){
        return !!_state.merchant.name;
    },
}





const actions = {
    fetchMerchant: async function({commit,},{account_id}){
        commit(types.SET_MERCHANT_UI_FLAG,{isFetching: true});
        try {
            const {data} = await MerchantApi.getMerchantByName("");
            // make shop url
            const shop_url = `${ExternalConfig.SHOP_APP_BASE_URL}/app/accounts/${account_id}/?name=${encodeURIComponent(data.name)}`

            commit(types.SET_MERCHANT_DATA, {...data,shop_url});
            commit(types.SET_MERCHANT_UI_FLAG,{isFetching: false});
        } catch (error) {
            console.log(error);
            commit(types.SET_MERCHANT_UI_FLAG,{isFetching: false});
        }
    },
}





const mutations = {
    // mutate UI flags
    [types.SET_MERCHANT_UI_FLAG](_state, data) {
        _state.uiFlags = {
          ..._state.uiFlags,
          ...data,
        };
    },

    // mutate merchant data
    [types.SET_MERCHANT_DATA](_state, data) {
        _state.merchant = data;
    },

}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}