import Facebook from './channels/Facebook';
import Website from './channels/Website';
import Twitter from './channels/Twitter';
import Api from './channels/Api';
import Email from './channels/Email';
import Sms from './channels/Sms';
import Whatsapp from './channels/Whatsapp';
import Line from './channels/Line';
import Telegram from './channels/Telegram';
import WhatsappCommunal from './channels/WhatsappCommunal';

const channelViewList = {
  facebook: Facebook,
  website: Website,
  twitter: Twitter,
  api: Api,
  email: Email,
  sms: Sms,
  whatsapp: Whatsapp,
  line: Line,
  telegram: Telegram,
  whatsappCommunal: WhatsappCommunal,
};

export default {
  create() {
    return {
      props: {
        channel_name: {
          type: String,
          required: true,
        },
      },
      name: 'new-channel-view',
      render(h) {
        return h(channelViewList[this.channel_name] || null);
      },
    };
  },

  disableInboxCreate(channelKey,user,inboxes){
    switch (channelKey) {
      // for the grant user, can create only one whatsapp inbox. disable if created one
      case 'whatsapp':
        const isGrantUser = user.plan_id === 20;
        const existingWhatsappinboxes = inboxes.filter(inbox=>inbox.channel_type === "Channel::Whatsapp");
        if (isGrantUser && existingWhatsappinboxes.length > 0) {
          return true;
        }
        return false;
      
      // case 'newCase':
        // return;
    
      default:
        return false;
    }
  },

};
