import { frontendURL } from '../../../../helper/URLHelper';
// secondary session nav
const credit_balance = accountId => ({
  parentNav: 'credit-balance',
  routes: [
    // 'credit_balance_home',
    'credit_management',
    'grant_list_management',
  ],
  menuItems: [
    
    {
      // add the icon with name as outline in "app\javascript\shared\components\FluentIcon\dashboard-icons.json"
      // real icon name with outline = "contact-card-group-outline"
      icon: 'video-call',  
      label: 'CREDIT_BALANCE',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/credit/credit_balance`),
      toStateName: 'credit_management',
    },
    
    {
      icon: 'contact-card-group',  
      label: 'GRANT_LIST',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/credit/grant_list`),
      toStateName: 'grant_list_management',
    },
    
  ],
});

export default credit_balance;
