<template>
  <div class="row">
    <div class="small-8 columns with-right-space ">
        <div class="sub_tabs">
            <div>
                <ul class="tabs">
                    <li
                    v-for="tab in tabs"
                    class="tabs-title"
                    :class="tab.code === selectedTab ? 'is-active' : ''"
                    :key="tab.key"
                    @click="onSubTabChange(tab.code)"
                    ><a>{{tab.name}}</a></li>
                </ul>
            </div>
        </div>
        <div>
        <!-- add cann response modal copied  -->
        <form ref="form" class="row" @submit.prevent="addTemplateMessage()">
            <div class="medium-12 columns">
                <label :class="{ error: $v.templateName.$error }">
                    {{ $t('TEMPLATE_MSG.ADD.FORM.TEMPLATE_NAME.LABEL') }}
                    <input
                        v-model.trim="templateName"
                        type="text"
                        :placeholder="
                        $t('TEMPLATE_MSG.ADD.FORM.TEMPLATE_NAME.PLACEHOLDER')
                        "
                        @input="$v.templateName.$touch"
                    />
                    <span v-if="$v.templateName.$error" class="message">
                        Invalid template name
                    </span>
                </label>
            </div>
            <div class="medium-12 columns display-inline">
                <div class="medium-4 columns">
                <formulate-input
                    v-model="inbox"
                    :options="this.inboxList()"
                    type="select"
                    name="inbox"
                    :placeholder="$t('TEMPLATE_MSG.ADD.FORM.INBOX.LABEL')"
                    :label="$t('TEMPLATE_MSG.ADD.FORM.INBOX.PLACEHOLDER')"
                    validation="required"
                    validation-name="Inbox"
                />
                </div>
                <div class="medium-4 columns">
                <formulate-input
                    v-model="language"
                    :options="this.languages()"
                    type="select"
                    name="language"
                    :placeholder="$t('TEMPLATE_MSG.ADD.FORM.LANGUAGE.LABEL')"
                    :label="$t('TEMPLATE_MSG.ADD.FORM.LANGUAGE.PLACEHOLDER')"
                    validation="required"
                    validation-name="Language"
                />
                </div>
                <div class="medium-4 columns">
                <formulate-input
                    v-model="templateType"
                    :options="this.templateTypes()"
                    type="select"
                    name="template-type"
                    :placeholder="$t('TEMPLATE_MSG.ADD.FORM.TYPE.LABEL')"
                    :label="$t('TEMPLATE_MSG.ADD.FORM.TYPE.PLACEHOLDER')"
                    validation="required"
                    validation-name="Template Type"
                />
                </div>
            </div>
            <div class="medium-12 columns">
                <label :class="{ error: $v.content.$error }">
                {{ $t('TEMPLATE_MSG.ADD.FORM.CONTENT.LABEL') }}
                <textarea
                    v-model.trim="content"
                    rows="5"
                    type="text"
                    :placeholder="$t('TEMPLATE_MSG.ADD.FORM.CONTENT.PLACEHOLDER')"
                    @input="$v.content.$touch"
                />
                </label>
            </div>
            <div class="medium-12 columns display-inline">
                <label>
                <span>{{ $t('TEMPLATE_MSG.ADD.FORM.IMAGE.LABEL') }}</span>
                <input
                    id="file"
                    ref="file"
                    type="file"
                    accept="image/*, .mp4, .pdf"
                    @change="handleImageUpload"
                />
                </label>
            </div>
            <div class="medium-12 columns">
                <label>
                <span>{{ $t('TEMPLATE_MSG.ADD.FORM.BUTTON.LABEL') }}</span>
                <multiselect
                    v-model="buttonsData"
                    class="small-12"
                    :tag-placeholder="$t('TEMPLATE_MSG.ADD.FORM.BUTTON.LABEL')"
                    :placeholder="$t('TEMPLATE_MSG.ADD.FORM.BUTTON.PLACEHOLDER')"
                    label="name"
                    :max="3"
                    track-by="code"
                    :options="options"
                    :multiple="true"
                    :taggable="true"
                    @tag="addButton"
                />
                </label>
            </div>
            <div class="modal-footer">
                <div class="medium-12 columns">
                <woot-submit-button
                    :disabled="showLoading"
                    :button-text="$t('TEMPLATE_MSG.ADD.FORM.SUBMIT')"
                    :loading="showLoading"
                />
                <button class="button clear" @click.prevent="onClose">
                    {{ $t('TEMPLATE_MSG.ADD.CANCEL_BUTTON_TEXT') }}
                </button>
                </div>
            </div>
            </form>
        </div>
    </div>
    <div class="small-4 columns">
        <div class="col_preview">
            <div class="preview_title">
                <p><b>Message Preview</b></p>
            </div>
            <div>
                <div class="preview_card">
                    <div class="media">
                        <div class="arrow_mark"></div>
                        <template>
                            <div class="file_view_wrapper" v-if="fileType.isImage">
                                <img :src="filePreviewUrl" alt="">
                            </div>
                            <div v-else-if="fileType.isVideo" class="file_view_wrapper">
                                <video  controls autoplay="false">
                                    <source :src="filePreviewUrl" type="video/mp4"> 
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            <div v-else-if="fileType.isPDF" class="file_view_wrapper">
                                <iframe  :src="filePreviewUrl" frameborder="0" width="100%" height="100%">
                                    This browser does not support PDFs. Please download the PDF to view it.
                                </iframe>
                            </div>
                        </template>
                    </div>
                    <div class="content">
                        <p>{{content}}</p>
                        <time>{{getCurrentTime()}}</time>
                    </div>
                    <div v-if="buttonsData.length" class="buttons">
                        <div class="btn_wrapper">
                            <a v-for="buttonEl in buttonsData" :key="buttonEl.code">
                            {{ buttonEl.name }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
  </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';

import WootSubmitButton from '../../../../components/buttons/FormSubmitButton';
import alertMixin from 'shared/mixins/alertMixin';
import { mapGetters } from 'vuex';
import languages from './templateLanguages';
import types from './templateTypes';
import moment from "moment";

const templateNameValidator = (value) =>{
    const regexPattern = /^[a-z0-9]+$/;
    
    return regexPattern.test(value);
}

export default {
    components: {
        WootSubmitButton,
    },
    mixins: [alertMixin],
    props: {
        onClose: {
            type: Function,
            default: () => {},
        },
    },
    data(){
        return {
            // add template form data
            templateName: '',
            content: '',
            showLoading: false,
            file: '',
            inbox: '',
            show: true,
            buttonsData: [],
            options: [],
            templateType: '',
            language: '',
            filePreviewUrl: '',
            
            tabs:[
                {key:0, name:'Add Template Message',code:"add_template_message"},
                
            ],
            selectedTab: 'add_template_message',
        }
    },
    validations: {
        templateName: {
            required,
            minLength: minLength(2),
            templateNameValidator,
        },
        content: {
            required,
        },
        /*
        language: {
            required,
        },
        templateType: {
            required,
        },
        */
    },
    computed: {
    ...mapGetters({
      inboxes: 'inboxes/getInboxes',
      
    }),
    fileType(){
        if (!this.file) {
            return{
                isImage: false,
                isVideo: false,
                isPDF: false
            };
        }
        const isImage = this.file?.type?.startsWith('image');
        const isVideo = this.file?.type?.startsWith('video/mp4');
        const isPDF = this.file?.type?.startsWith('application/pdf');
        console.log({
            type: this.file.type,
            isImage,
            isPDF,
            isVideo,
        });

        return {
            isImage,
            isVideo,
            isPDF,
        }
    },
  },
    methods: {
    inboxList() {
      return this.inboxes
        .filter(inbox => {
          return inbox.channel_type === 'Channel::Whatsapp';
        })
        .map(inbox => ({ label: inbox.name, value: inbox.id }));
    },
    templateTypes() {
      return types;
    },
    languages() {
      return languages;
    },
    resetForm() {
      this.$refs.form.reset();
    },
    async addTemplateMessage() {
        this.$v.$touch();
        if (this.$v.$invalid) {
            return;
        }
      let data = {
        short_code: this.templateName,
        content: this.content,
        inbox_id: this.inbox,
        file: this.file,
        buttonsData: this.buttonsData,
        language: this.language,
        templateType: this.templateType,
      };
      this.showLoading = true;
      let formData = new FormData();
      Object.entries(data).forEach(([key, value]) => {
        if (key === 'buttonsData') {
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, value);
        }
      });
      await this.$store
        .dispatch('accounts/createTemplateMessage', formData)
        .then(() => {
          this.onClose();
          this.showLoading = false;
          this.showAlert(this.$t('TEMPLATE_MSG.ADD.API.SUCCESS_MESSAGE'));
          this.resetForm();
        })
        .catch(error => {
          this.showLoading = false;
          this.showAlert(error.response.data.message);
        });
    },
    handleImageUpload(event) {
      this.file = event.target.files[0];

      if (this.file.size > 1024 * 1024 * 40) {
        alert('File is too big. Please select file of size less then 40mb.');
        this.$refs.file.value = null;
        this.file = '';
        this.filePreviewUrl = '';
      }
      
      if (this.file) {
        if (this.file.type === 'video/mp4') {
            // Create a URL for the video file
            this.filePreviewUrl = URL.createObjectURL(this.file);
        }else{
            // read for image and pdf
            const reader = new FileReader();
            reader.onload = (e) =>{
              this.filePreviewUrl = e.target.result;
            }
            reader.readAsDataURL(this.file);
        }

      }
    },
    addButton(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.options.push(tag);
      this.buttonsData.push(tag);
    },
    onSubTabChange(tabName){
      this.selectedTab = tabName;
    },
    getCurrentTime() {
        const now = moment(); 
        return now.format('h:mm A'); // 8:04 AM
    },
  },
}
</script>

<style lang="scss" scoped>
.display-inline {
  display: inline-flex;
  justify-content: space-between;
}

.col_preview{
    position: relative;
    min-height: 100%;
    // background: #00000082;
    background-image: url('../../../../assets/images/background/whatsapp_bg.jpeg');
    background-size: cover;
}
.col_preview::before{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
}
.preview_title{
    position: relative;
    // margin-left: 10px;
    height: 60px;
    padding: 0 16px;
    display: flex;
    align-items: center;

    background-color: var(--w-500);
    color: var(--white);

    p{
        margin: 0;
    }

}
.preview_card{
    position: relative;
    max-width: 90%;
    margin: 30px auto;
    background-color: #fff;
    padding: 4px;
    border-radius: 4px;
    max-width: 350px;
    min-width: 250px;
    
    .arrow_mark{
        width: 20px;
        height: 20px;
        background: #fff;
        position: absolute;
        top: 0;
        left: -15px;
        clip-path: polygon(0 0, 100% 100%, 100% 0);
    }
    
    .media{
        max-height: 220px;
        overflow: hidden;

        .file_view_wrapper{
            position: relative;
            width: 100%;
            height: 220px;

            img,video,iframe{
                position: absolute;
                width: 100%;
                height: auto;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
            }
        }
    }
    .content{
        position: relative;
        min-height: 10px;
        margin-bottom:  var(--space-small);
        p{
            white-space: pre-wrap;
            margin: 0;
        }
        time{
            position: absolute;
            width: 45px;
            height: 10px;
            right: 0;
            bottom: -5px;
            color: #8492a6;
        }
    }
    .buttons{
        
        .btn_wrapper{
            padding: 4px;

            a{
                display: block;
                text-align: center;
                padding: 5px;
                border-top: 1px solid #ebf0f5;
            }
        }
    }
}
</style>
