<template>
  <div class="filter">
    <div style="display:flex;align-items: center;align-content: center;">
      <select v-model="skillData.method" class="filter__question">
        <option
          v-for="item in requestMethodTypes"
          :key="item.id"
          :value="item.id"
        >
          {{ item.name }}
        </option>
      </select>
      <input
        v-model="skillData.url"
        class="answer--text-input"
        type="text"
        style="margin:0;margin-right: 5px;"
        :placeholder="$t('FLOW.PLACEHOLDERS.REQUEST_URL')"
      />
    </div>

    <div class="column content-box">
      <woot-tabs :index="selectedTabIndex" @change="onClickTabChange">
        <woot-tabs-item
          v-for="tab in tabs"
          :key="tab.key"
          :name="tab.name"
          :show-badge="false"
        />
      </woot-tabs>

      <div class="columns">
        <div v-if="selectedTabIndex == 0" class="column padding-top">
          <textarea
            v-model="skillData.body"
            rows="4"
            class="action-message"
            placeholder="Enter Body JSON Here..."
          ></textarea>
        </div>
        <div v-if="selectedTabIndex == 1" class="column padding-top">
          <textarea
            v-model="skillData.headers"
            rows="4"
            class="action-message"
            placeholder="Enter Headers JSON Here..."
          ></textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    skillData: {
      required: true,
    },
  },
  data() {
    return {
      selectedTabIndex: 0,
    };
  },
  computed: {
    requestMethodTypes() {
      return [
        { id: 'get', name: 'Get' },
        { id: 'post', name: 'Post' },
        { id: 'put', name: 'Put' },
        { id: 'delete', name: 'Delete' },
      ];
    },
    tabs() {
      return [
        {
          key: 0,
          name: 'Body',
        },
        {
          key: 1,
          name: 'Headers',
        },
      ];
    },
  },

  methods: {
    onClickTabChange(index) {
      this.selectedTabIndex = index;
    },
  },
};
</script>
<style lang="scss" scoped>
.bottom-margin {
  margin-bottom: var(--space-small) !important;
}
</style>

<style lang="scss" scoped>
.filter {
  background: var(--color-background);
  padding: var(--space-small);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-medium);
  margin-bottom: 15px;
}

.filter.error {
  background: var(--r-50);
}

.filter-inputs {
  display: flex;
}

.filter-error {
  color: var(--r-500);
  display: block;
  margin: var(--space-smaller) 0;
}

.filter__question,
.filter__operator {
  margin-bottom: var(--space-zero);
  margin-right: var(--space-smaller);
}

.filter__question {
  max-width: 30%;
}

.filter__operator {
  max-width: 20%;
}

.filter__answer--wrap {
  margin-right: var(--space-smaller);
  flex-grow: 1;

  input {
    margin-bottom: 0;
  }
}
.filter__answer {
  &.answer--text-input {
    margin-bottom: var(--space-zero);
  }
}

.filter__join-operator-wrap {
  position: relative;
  z-index: var(--z-index-twenty);
  margin: var(--space-zero);
}

.filter__join-operator {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: var(--space-one) var(--space-zero);

  .operator__line {
    position: absolute;
    width: 100%;
    border-bottom: 2px solid var(--color-border);
  }

  .operator__select {
    position: relative;
    width: auto;
    margin-bottom: var(--space-zero) !important;
  }
}
</style>

<style lang="scss" scoped>
.content-box {
  overflow: unset;
  margin-top: 15px;
}
.table-wrap {
  padding-left: var(--space-small);
}

.woot-table {
  width: 100%;
  margin-top: var(--space-small);
}

.no-items-error-message {
  margin-top: var(--space-larger);
}

.tabs {
  padding-left: 0;
  margin-right: var(--space-medium);
  user-select: none;
}

.item {
  padding-left: 0;
  max-width: 10rem;
  min-width: 8rem;
}

.item-description {
  padding-left: 0;
  max-width: 16rem;
  min-width: 10rem;
}

.key {
  font-family: monospace;
}

::v-deep {
  .tabs-title a {
    font-weight: var(--font-weight-medium);
    padding-top: 0;
  }
}

.padding-top {
  padding-top: 1.6rem;
}
.columns {
  padding: 0 var(--space-smaller);
}
</style>
