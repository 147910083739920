<template>
  <modal :show.sync="show" :on-close="onClose">
    <div class="column content-box">
      <woot-modal-header
        :header-title="$t('ORDER.LIST_TABLE.COLUMN_MODAL.TITLE')"
        :header-content="$t('ORDER.LIST_TABLE.COLUMN_MODAL.CONTENT')"
      />
      <form ref="form">
        <div class="row">
            <div class="medium-12 columns">
              <div v-for="(column, i) in columns" :key="i">
                <label class="custom-checkbox-container">
                  {{ column.name }} <input v-model="column.visible" type="checkbox" />
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
        </div>

        <div class="modal-footer">
          <div class="medium-12 columns footer_btns">
            <!-- 
            <woot-submit-button
              button-text="Select"
            /> 
            -->
            <button class="button clear" @click.prevent="onClose">
              {{ $t('TEMPLATE_MSG.ADD.CANCEL_BUTTON_TEXT') }}
            </button>
          </div>
        </div>

      </form>
    </div>
  </modal>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';
import WootSubmitButton from '../../../../components/buttons/FormSubmitButton.vue';
import Modal from '../../../../components/Modal.vue';
import alertMixin from 'shared/mixins/alertMixin';
import { mapGetters } from 'vuex';


export default {
    components: {
        WootSubmitButton,
        Modal,
    },
    mixins: [alertMixin],
    props: {
        onClose: {
            type: Function,
            default: () => {},
        },
        handleColumnVisiblity: {
            type: Function,
            default: () => {},
        },
        columns:{
            type: Array,
            default: []
        },
    },
    data() {
        return {
          show: true,
        };
    },
    computed: {
        
    },
    mounted(){
        // this.populateInitalFields();
    },
    methods:{
        
        
        // end methods
    },
    

}
</script>

<style scoped lang="scss">

.footer_btns{
    display: flex;
    justify-content: flex-end;
}
</style>