<template>
  <modal
  :show.sync="show"
  :on-close="onClose"
  closeIcon="chevron-left"
  closeBtnClass="modal_box_close_btn"
  modalMainContainerClass="preview_modal"
  >
    <div class="modal_box preview_template_modal">
        <div class="header">
            <h2 class="header_title">{{$t("MESSAGE_MGMT.PREVIEW.TITLE")}}</h2>
        </div>
        <div class="template">
            <div class="heading">
                <aside>
                    <p class="shortcut">/{{cannItem.short_code}}</p>
                    <h4 class="title font_14_5">{{inbox.name}}</h4>
                </aside>
                <aside>
                    <div style="display:flex;">
                        <button class="status" :class="cannItem.template_status">{{statusList[cannItem.template_status]}}</button>
                        <woot-button v-if="!['pending'].includes(cannItem.template_status)"
                        class-names="pre_delete"
                        variant="clear"
                        icon="delete"
                        :is-disabled="isDeleting"
                        :is-loading="isDeleting"
                        @click.stop="onDeleteClick(cannItem)"
                        >
                        </woot-button>
                    </div>
                </aside>
            </div>
            <!-- <div class="btns">
                <button>English</button>
                <button>Authentication</button>
            </div> -->

        </div>
        <section class="col_preview preview">
            <div class="pre_header">
                <h3 class="title">{{$t('MESSAGE_MGMT.ADD.PREVIEW.TITLE')}}</h3>
            </div>
            <div class="pre_message_box">
                <div class="preview_card">
                    <div class="media">
                        <!-- <div class="arrow_mark"></div> -->
                        <template>
                            <div class="file_view_wrapper" v-if="fileType.isImage">
                                <img :src="filePreviewUrl" alt="">
                            </div>
                            <div v-else-if="fileType.isVideo" class="file_view_wrapper">
                                <video  controls autoplay="false">
                                    <source :src="filePreviewUrl" type="video/mp4">
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            <div v-else-if="fileType.isPDF" class="file_view_wrapper">
                                <iframe  :src="filePreviewUrl" frameborder="0" width="100%" height="100%">
                                    This browser does not support PDFs. Please download the PDF to view it.
                                </iframe>
                            </div>
                        </template>
                    </div>
                    <div class="msg_content">
                        <p>{{cannItem.content}}</p>
                        <!-- <p class="placeholder">{{ $t('MESSAGE_MGMT.ADD.PREVIEW.CONTENT_PLACEHOLDER')}}</p> -->
                        <time>{{getCurrentTime()}}</time>
                    </div>
                    <!-- <div v-if="buttonsData.length" class="buttons">
                        <div class="btn_wrapper">
                            <a v-for="buttonEl in buttonsData" :key="buttonEl.code">
                            {{ buttonEl.name }}
                            </a>
                        </div>
                    </div> -->
                </div>
            </div>
        </section>
        <div class="pre_btns">
            <!-- <woot-button v-if="cannItem.template_status !== 'rejected'" class-names="pre_delete" variant="clear"
            :is-disabled="isDeleting"
            :is-loading="isDeleting"
              @click="onDeleteClick(cannItem)">
            {{$t("MESSAGE_MGMT.PREVIEW.BTNS.DELETE")}}
            </woot-button> -->
            <woot-button class-names="pre_close" variant="clear" @click="onClose">
            {{$t("MESSAGE_MGMT.PREVIEW.BTNS.CLOSE")}}
            </woot-button>
        </div>
        <woot-delete-modal
            :show.sync="showDeleteConfirmationPopup"
            :on-close="closeDeletePopup"
            :on-confirm="confirmDeletion"
            :title="$t('CANNED_MGMT.DELETE.CONFIRM.TITLE')"
            :message="deleteMessage"
            :confirm-text="deleteConfirmText"
            :reject-text="deleteRejectText"
        />
    </div>
  </modal>
</template>

<script>
import Modal from '../../../../components/Modal.vue';
import alertMixin from 'shared/mixins/alertMixin';
import moment from "moment";
import { mapGetters } from 'vuex';

export default {
    components: {
        Modal,
    },
    mixins: [alertMixin],
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        onClose: {
            type: Function,
            default: () => {},
        },
        cannItem:{
            type: Object,
            default:{},
        },
    },
    data(){
        return{
            showDeleteConfirmationPopup: false,
            isDeleting: false,
            filePreviewUrl:"",
            buttonsData: [],
            statusList:{
                rejected: this.$t('MESSAGE_MGMT.STATUS.REJECTED'),
                approved: this.$t('MESSAGE_MGMT.STATUS.PAAROVED'),
                pending: this.$t('MESSAGE_MGMT.STATUS.PENDING'),
            },
        }
    },
    computed:{
        ...mapGetters({
            inboxes: 'inboxes/getInboxes',
        }),
        fileType(){
            if (!this.file) {
                return{
                    isImage: false,
                    isVideo: false,
                    isPDF: false
                };
            }
            const isImage = this.file?.type?.startsWith('image');
            const isVideo = this.file?.type?.startsWith('video/mp4');
            const isPDF = this.file?.type?.startsWith('application/pdf');
            return {
                isImage,
                isVideo,
                isPDF,
            }
        },
        deleteMessage() {
            return `${this.$t('CANNED_MGMT.DELETE.CONFIRM.MESSAGE')} ${this.cannItem.short_code} ?`;
        },
        deleteConfirmText() {
            return `${this.$t('CANNED_MGMT.DELETE.CONFIRM.YES')} ${this.cannItem.short_code}`;
        },
        deleteRejectText() {
            return `${this.$t('CANNED_MGMT.DELETE.CONFIRM.NO')} ${this.cannItem.short_code}`;
        },
        inbox(){
            return this.inboxes.find(el=>el.id === this.cannItem?.inbox_id) || ""
        },
        // end computed
    },
    methods:{
        getCurrentTime() {
            const now = moment();
            return now.format('h:mm A'); // 8:04 AM
        },
        confirmDeletion() {
            this.closeDeletePopup();
            this.deleteCannedResponse(this.cannItem.id);
        },
        async deleteCannedResponse(id) {
            this.isDeleting = true;
            await this.$store
                .dispatch('deleteCannedResponse', id)
            .then(() => {
                this.showAlert(this.$t('CANNED_MGMT.DELETE.API.SUCCESS_MESSAGE'));
                this.onClose();
            })
            .catch(() => {
                this.showAlert(this.$t('CANNED_MGMT.DELETE.API.ERROR_MESSAGE'));
            }).finally(()=>{
                this.isDeleting = false;
            });
        },
        closeDeletePopup() {
            this.showDeleteConfirmationPopup = false;
        },
        onDeleteClick() {
            this.showDeleteConfirmationPopup = true;
        },
        // method end
    },
}
</script>


<style lang="scss" scoped>
.preview_template_modal{
    .header{
        margin-left: 20px;
    .header_title{
        font-weight: 700;
        font-size: 20px;
        color: #1F1F1F;
    }
}
}
.preview_template_modal{
    .template{
        border-radius: 10px;
        // padding: var(--space-slab) var(--space-two);
        margin-top: var(--space-normal);
        .heading{
            display: flex;
            justify-content: space-between;
            gap: 16px;

            .shortcut{
                font-weight: 700;
                font-size: var(--font-size-small);
                color: #8D25F6;
            }
            .title{
                font-weight: 500;
                font-size: var(--font-size-small);
                color: #1F1F1F;
                margin-top: 4px;
            }
            .status{
                font-size: var(--font-size-mini);
                padding: 5px 12px;
            }
            .rejected{
                color: #E50000;
                background: #FFE5E5;
            }
            .approved{
                color: #24B200;
                background: #EBFCC0;
            }
            .pending{
                color: #FFAA00;
                background: #FFF4E5;
            }
        }
        .btns{
            display: flex;
            flex-wrap: wrap;
            gap: 12px;
            margin-top: var(--space-slab);
           button{
            font-weight: 500;
            font-size: var(--font-size-small);
            color: #858585;
           }
        }
    }
}

.col_preview{
    position: relative;
    min-height: 100%;
    // background: #00000082;
    background-image: url('../../../../assets/images/background/whatsapp_bg.jpeg');
    background-size: cover;
    margin-top: 24px;
}
.col_preview::before{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.02);
}
.preview{
    .pre_header{
        padding: var(--space-slab);
        background-color: #F2E7FE;
        .title{
            font-weight: 700;
            font-size: var(--font-size-small);
            color: #8D25F6;
        }
    }
    .pre_message_box{
        padding: 20px 12px;
    }
}



.preview_card{
    position: relative;
    // max-width: 90%;
    // margin: 20px 12px;
    background-color: #fff;
    padding: 8px 12px;
    border-radius: 4px;

    .arrow_mark{
        width: 20px;
        height: 20px;
        background: #fff;
        position: absolute;
        top: 0;
        left: -15px;
        clip-path: polygon(0 0, 100% 100%, 100% 0);
    }

    .media{
        max-height: 220px;
        overflow: hidden;

        .file_view_wrapper{
            position: relative;
            width: 100%;
            height: 220px;

            img,video,iframe{
                position: absolute;
                width: 100%;
                height: auto;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
            }
        }
    }
    .msg_content{
        position: relative;
        min-height: 10px;
        margin-bottom:  var(--space-small);
        padding: var(--space-one) var(--space-slab);
        p{
            white-space: pre-wrap;
            margin: 0;
        }
        .placeholder{
            color: #BBBBBB;
            font-size: var(--font-size-small);
            font-weight: 500;
        }
        time{
            position: absolute;
            width: 45px;
            height: 10px;
            right: 0;
            bottom: -5px;
            color: #8492a6;
        }
    }
    .buttons{

        .btn_wrapper{
            padding: 4px;

            a{
                display: block;
                text-align: center;
                padding: 5px;
                border-top: 1px solid #ebf0f5;
            }
        }
    }
}

.pre_btns{
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;
    background: #fff;
    .pre_delete{
        font-weight: 700;
        font-size: var(--font-size-small) !important;
        color: #E50000 !important;
    }
    .pre_close{
        font-weight: 700;
        font-size: var(--font-size-small) !important;
        color: #8D25F6 !important;
    }
}
// preview end


.empty_box{
    width: 360px;
    font-size: 16px;
    text-align: center;
    margin: auto;
    p{
        margin-top: 130px;
    }
    .create_btn{
        margin-top: 16px;
        color: #8D25F6;
        border: 1px solid #8D25F6;
        &:hover{
            color: #858585;
            border: 1px solid #858585;
        }
    }
}
.font_14_7{
    font-size: 14px;
    font-weight: 700;
}
.font_14_5{
    font-size: 14px;
    font-weight: 500;
}
.typo_primary{

}
</style>

<style  lang="scss">
.preview_modal{
    // width: 380px;
    border-radius: 20px;
    padding: 32px 24px !important;
}
.preview_modal .modal_box_close_btn{
    left: 15px;
    right: auto;
    top: 30px;
    padding: 6px;
}
.preview_modal .pre_delete{
    background: transparent;
    color: #858585;
    height: auto;
}
.preview_modal .pre_delete svg{
    background: transparent;
    color: #858585;
    height: auto;
}
</style>