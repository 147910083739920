import { frontendURL } from '../../../helper/URLHelper';
import SessionWrapper from './WrapperSession.vue';
import SessionHome from './SessionHome.vue';

/*
  1) go to "config/sidebaritems/fileNameRoutes.js" and add the route in "menuItems"
  2) now add the Component will be render for the added router, Add it is here with child
   3) add this catelogue.routes.js file to '/dashboard/dashboard.routes.js' file to active this route
*/

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/session'),
      name: 'session_onboarding_home',  // must match with 'toStateName'
      roles: ['administrator', ],
      component: SessionWrapper,
      props: () => {
        return { 
          headerTitle: 'Onboarding',
          icon: 'video-call',
          showBackButton: false,
         };
      },
      children: [
        {
          path: 'schedule-session',
          name: 'schedule_session', // must match this name in session_onboarding.js file in both "routes array" and "menuItems array"
          component: SessionHome,
          roles: ['administrator'],
        },
      ],
      
    },
  ],
};
