<template>
  <div class="column content-box">
    <div class="top-right">
      <woot-button
        color-scheme="success"
        class-names="button--template"
        icon="add-circle"
        :disabled="showAddTemplateWithPreview"
        @click="openAddTemplateWithPreview()"
      >
        {{ $t('CANNED_MGMT.TEMPLATE_BTN_TXT') }}
      </woot-button>
      <!-- Converted to preview component button -->
      <!--       
      <woot-button
        color-scheme="success"
        class-names="button--template"
        icon="add-circle"
        @click="openAddTemplate()"
      >
        {{ $t('CANNED_MGMT.TEMPLATE_BTN_TXT') }}
      </woot-button> 
      -->

      <woot-button
        color-scheme="success"
        class-names="button--template"
        icon="add-circle"
        @click="openAddPopup()"
      >
        {{ $t('CANNED_MGMT.HEADER_BTN_TXT') }}
      </woot-button>
    </div>

    <!-- Add a template with preview feature -->
    <AddTemplateWithPreview 
      v-if="showAddTemplateWithPreview" 
      :on-close="hideAddTemplateWithPreview" 
    />
    <!-- List Canned Response -->
    <div v-else class="row">
      <div class="small-8 columns with-right-space ">

        <div class="sub_tabs">
          <div>
            <ul class="tabs">
              <li
                v-for="tab in tabs"
                class="tabs-title"
                :class="tab.code === selectedTab ? 'is-active' : ''"
                :key="tab.key"
                @click="onSubTabChange(tab.code)"
              ><a>{{tab.name}}</a></li>
            </ul>
          </div>
          <div v-if="isInboxShow">
            <formulate-input
              v-model="selectedInboxId"
              :options="inboxList"
              type="select"
              name="inbox"
            />
          </div>
        </div>

        <p
          v-if="!uiFlags.fetchingList && !filtered_records.length"
          class="no-items-error-message"
        >
          {{ $t('CANNED_MGMT.LIST.404') }}
        </p>
        <woot-loading-state
          v-if="uiFlags.fetchingList"
          :message="$t('CANNED_MGMT.LOADING')"
        />

        <table
          v-if="!uiFlags.fetchingList && filtered_records.length"
          class="woot-table"
        >
          <thead v-if="isInboxShow">
            <!-- Header -->
            <th
              v-for="thHeader in $t('CANNED_MGMT.LIST.TABLE_HEADER_TEMPLATE')"
              :key="thHeader"
            >
              {{ thHeader }}
            </th>
          </thead>
          <thead v-else>
            <!-- Header -->
            <th
              v-for="thHeader in $t('CANNED_MGMT.LIST.TABLE_HEADER')"
              :key="thHeader"
            >
              {{ thHeader }}
            </th>
          </thead>
          <tbody>
            <tr
              v-for="(cannedItem, index) in filtered_records"
              :key="cannedItem.short_code"
            >
              <!-- Short Code  -->
              <td class="short-code">
                {{ cannedItem.short_code }}
              </td>
              <!-- Content -->
              <td>{{ cannedItem.content }}</td>
              <!-- inbox name  -->
              <td v-if="isInboxShow" class="short-code">{{ cannedItem.inboxName }}</td>
              <!-- Action Buttons -->
              <td class="button-wrapper">
                  <!-- hide edit button if it is a template (_sh_) -->
                  <span v-if="cannedItem.is_template" class="empty-canned-edit-btn"></span>
                  <span v-if="!cannedItem.is_template">
                    <woot-button
                      v-if="!cannedItem.is_template"
                      v-tooltip.top="$t('CANNED_MGMT.EDIT.BUTTON_TEXT')"
                      variant="smooth"
                      size="small"
                      color-scheme="secondary"
                      icon="edit"
                      @click="openEditPopup(cannedItem)"
                    >
                    </woot-button>
                  </span>
                <woot-button
                  v-tooltip.top="$t('CANNED_MGMT.DELETE.BUTTON_TEXT')"
                  variant="smooth"
                  color-scheme="alert"
                  size="small"
                  icon="dismiss-circle"
                  class-names="grey-btn btn-width-auto"
                  :is-loading="loading[cannedItem.id]"
                  @click="openDeletePopup(cannedItem, index)"
                >
                </woot-button>
                <div v-if="cannedItem.is_template" class="indicator">
                  <status-indicator
                    :status="cannedItemStatus(cannedItem.template_status)"
                    pulse
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="small-4 columns">
        <span v-html="$t('CANNED_MGMT.SIDEBAR_TXT')"></span>
      </div>
    </div>
    <!-- Add Agent -->
    <woot-modal :show.sync="showAddPopup" :on-close="hideAddPopup">
      <add-canned :on-close="hideAddPopup" />
    </woot-modal>

    <!-- Moved this feature from Modal to <AddTemplateWithPreview> Component -->
    <!-- 
    <woot-modal :show.sync="showAddTemplate" :on-close="hideAddTemplate">
      <add-template :on-close="hideAddTemplate" />
    </woot-modal> 
    -->

    <!-- Edit Canned Response -->
    <woot-modal :show.sync="showEditPopup" :on-close="hideEditPopup">
      <edit-canned
        v-if="showEditPopup"
        :id="selectedResponse.id"
        :edshort-code="selectedResponse.short_code"
        :edcontent="selectedResponse.content"
        :on-close="hideEditPopup"
      />
    </woot-modal>

    <!-- Delete Canned Response -->
    <woot-delete-modal
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('CANNED_MGMT.DELETE.CONFIRM.TITLE')"
      :message="deleteMessage"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import AddCanned from './AddCanned';
import AddTemplate from './AddTemplate';
import AddTemplateWithPreview from './AddTemplateWithPreview.vue';
import EditCanned from './EditCanned';
import { StatusIndicator } from 'vue-status-indicator';

export default {
  components: {
    AddCanned,
    EditCanned,
    AddTemplate,
    AddTemplateWithPreview,
    StatusIndicator,
  },
  data() {
    return {
      loading: {},
      showAddPopup: false,
      showAddTemplate: false,
      showAddTemplateWithPreview: false,
      showEditPopup: false,
      showDeleteConfirmationPopup: false,
      selectedResponse: {},
      cannedResponseAPI: {
        message: '',
      },
      tabs:[
        {key:0, name:'Template Message',code:"template_message"},
        {key:1, name:'Canned Response',code:"canned_response"},
      ],
      selectedTab: 'template_message',
      selectedInboxId: "",
    };
  },
  computed: {
    ...mapGetters({
      // records: 'getCannedResponses',
      allRecords: 'getCannedResponses',
      inboxes: 'inboxes/getInboxes',
      uiFlags: 'getUIFlags',
    }),
    filtered_records(){
      let recordList = this.allRecords;

      // filter by template message or not
      if (this.selectedTab === 'template_message') {
        recordList = this.allRecords.filter(record => record.is_template).map(record=>{
          const inboxName = this.inboxes.find(inbox => inbox.id === record.inbox_id)?.name;
          record.inboxName = inboxName;
          return record;
        });
      }else{
        recordList = this.allRecords.filter(record => !record.is_template);
      }

      // filter by inbox id
      if (this.selectedInboxId && this.selectedTab === 'template_message') {
        recordList = recordList.filter(record => {
          // console.log(this.selectedInboxId," = inside inbox Id = ", record.inbox_id);
          return record.inbox_id === parseInt(this.selectedInboxId)
        });
      }
      return recordList;
    },
    inboxList(){
      const existingList = this.inboxes.map(inbox=>({ label: inbox.name, value: inbox.id }));
      return [{ label: 'All', value: "" },...existingList];
    },
    // Delete Modal
    deleteConfirmText() {
      return `${this.$t('CANNED_MGMT.DELETE.CONFIRM.YES')} ${
        this.selectedResponse.short_code
      }`;
    },
    deleteRejectText() {
      return `${this.$t('CANNED_MGMT.DELETE.CONFIRM.NO')} ${
        this.selectedResponse.short_code
      }`;
    },
    deleteMessage() {
      return `${this.$t('CANNED_MGMT.DELETE.CONFIRM.MESSAGE')} ${
        this.selectedResponse.short_code
      } ?`;
    },
    isInboxShow(){
      return this.selectedTab === 'template_message';
    },
  },
  
  mounted() {
    // Fetch API Call
    this.$store.dispatch('getCannedResponse');
  },
  methods: {
    showAlert(message) {
      // Reset loading, current selected agent
      this.loading[this.selectedResponse.id] = false;
      this.selectedResponse = {};
      // Show message
      this.cannedResponseAPI.message = message;
      bus.$emit('newToastMessage', message);
    },
    // Edit Function
    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },
    openAddTemplate() {
      this.showAddTemplate = true;
    },
    openAddTemplateWithPreview() {
      this.showAddTemplateWithPreview = true;
    },
    hideAddTemplateWithPreview() {
      this.showAddTemplateWithPreview = false;
      this.$store.dispatch('getCannedResponse');
    },
    hideAddTemplate() {
      this.showAddTemplate = false;
      this.$store.dispatch('getCannedResponse');
    },

    // Edit Modal Functions
    openEditPopup(response) {
      this.showEditPopup = true;
      this.selectedResponse = response;
    },
    hideEditPopup() {
      this.showEditPopup = false;
    },

    // Delete Modal Functions
    openDeletePopup(response) {
      this.showDeleteConfirmationPopup = true;
      this.selectedResponse = response;
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    // Set loading and call Delete API
    confirmDeletion() {
      this.loading[this.selectedResponse.id] = true;
      this.closeDeletePopup();
      this.deleteCannedResponse(this.selectedResponse.id);
    },
    deleteCannedResponse(id) {
      this.$store
        .dispatch('deleteCannedResponse', id)
        .then(() => {
          this.showAlert(this.$t('CANNED_MGMT.DELETE.API.SUCCESS_MESSAGE'));
        })
        .catch(() => {
          this.showAlert(this.$t('CANNED_MGMT.DELETE.API.ERROR_MESSAGE'));
        });
    },
    cannedItemStatus(status) {
      switch (status) {
        case 'pending':
          return 'intermediary';
        case 'approved':
          return 'positive';
        case 'rejected':
          return 'negative';
        default:
          return 'intermediary';
      }
    },
    onSubTabChange(tabName){
      console.log(tabName);
      this.selectedTab = tabName;
    },
  },
};
</script>
<style scoped>
.short-code {
  width: 14rem;
}
.top-right {
  position: absolute;
  right: 0px;
  display: inline;
  top: 6.8rem;
}
.indicator {
  margin-left: 10px;
}
.empty-canned-edit-btn{
  width: 22px;
}

.btn-width-auto{
  width: auto !important;
}

.sub_tabs{
  display: flex;
  justify-content: space-between;
}
</style>

