<template>
  <div class="medium-12 columns">
    <div
      v-if="
        registrationFee &&
          this.currentUser.default_currency === 'myr' &&
          this.userBalance > 29
      "
    ></div>
    <div v-else>
      <woot-input
        v-model="topUpAmount"
        :label="
          $t('TOP_UP.MODAL.FORM.ADD.LABEL', {
            currency: this.currentUser.default_currency.toUpperCase(),
          })
        "
        type="text"
        :class="{ error: $v.topUpAmount.$error }"
        :error="$v.topUpAmount.$error ? $t('TOP_UP.MODAL.FORM.ADD.ERROR') : ''"
        :placeholder="$t('TOP_UP.MODAL.FORM.ADD.PLACEHOLDER')"
        @input="onTopUpValueChange"
        @blur="$v.topUpAmount.$touch"
      />
      <div class="flex-container">
        <div class="flex-child">
          <label>Credit</label>
        </div>
        <div class="flex-child text-center">
          <p>{{ this.topUpAmount ? this.topUpAmount : 0 }}</p>
        </div>
      </div>
    </div>
    <div
      v-if="registrationFee && this.currentUser.default_currency === 'myr'"
      class="flex-container"
    >
      <div class="flex-child">
        <label>One time Registration Fee </label>
      </div>
      <div class="flex-child text-center">
        <p>50</p>
      </div>
    </div>
    <div class="flex-container">
      <div class="flex-child">
        <h6>Total</h6>
      </div>
      <div class="flex-child text-center">
        <h6>{{ this.total.toFixed(2) }}</h6>
      </div>
    </div>
    <woot-button color-scheme="primary" size="small" @click="submit">
      {{ $t('TOP_UP.MODAL.FORM.SUBMIT') }}
    </woot-button>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import alertMixin from '../../../shared/mixins/alertMixin';

export default {
  name: 'TopUpAmountContainer',
  mixins: [alertMixin],
  props: {
    modalValue: {
      type: Boolean,
      required: true,
    },
    usdValue: {
      type: Number,
      required: true,
    },
    registrationFee: {
      type: Boolean,
      required: false,
    },
    userBalance: {
      type: Number,
      required: false,
    },
    productId: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      topUpAmount: '',
      registrationValue: 0,
      total: 0,
    };
  },
  validations() {
    const commonValidations = {
      topUpAmount: {
        required,
      },
    };
    return {
      ...commonValidations,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
  },
  mounted() {
    this.total =
      this.registrationFee && this.currentUser.default_currency === 'myr'
        ? 50
        : 0;
  },
  methods: {
    onTopUpValueChange() {
      let total = this.topUpAmount ? this.topUpAmount : 0;
      this.registrationValue =
        this.registrationFee && this.currentUser.default_currency === 'myr'
          ? 50
          : 0;
      this.total = this.registrationValue + parseFloat(total);
    },
    submit() {
      const actualValue = (
        parseFloat(this.topUpAmount) / parseFloat(this.usdValue)
      ).toFixed(2);
      if (
        this.registrationFee &&
        this.userBalance > 29 &&
        this.currentUser.default_currency === 'myr'
      ) {
        this.total = 50;
      }
      if (
        (this.registrationFee &&
          this.userBalance > 29 &&
          this.currentUser.default_currency === 'myr') ||
        (this.currentUser.default_currency === 'usd' &&
          this.topUpAmount >= 20) ||
        (actualValue >= 20.80 && this.currentUser.default_currency === 'myr')
      ) {
        this.$emit(
          'item-click',
          parseFloat(this.total) * 100,
          this.currentUser.default_currency,
          this.topUpAmount
        );
      } else if (this.currentUser.default_currency === 'usd') {
        this.showAlert('Your top-up amount must be more than USD 20.');
      } else {
        this.showAlert(
          `Your top-up amount must be more than ${this.currentUser.default_currency.toUpperCase()} ${Math.round(
            20.83 * this.usdValue
          )}`
        );
      }
    },
  },
};
</script>
<style>
.flex-container {
  display: flex;
  margin-bottom: 10px;
}
.flex-child {
  flex: 1;
}
</style>
