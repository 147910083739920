<template>
  <div class="profile--settings--row row">
    <div class="columns small-3">
      <h4 class="block-title">Calendar Setting</h4>
    </div>
    <div class="columns small-9 medium-5">
      <!-- <div class="google-calendar">
        <div>{{ googleOauthCompleted.length }} of 6 Accouts</div>
        <div class="ml-auto" style="cursor:pointer" @click="googleOauth">
          + Add Google Account
        </div>
      </div> -->
      <div
        v-if="primaryCalendar && !primaryCalendar.access_token"
        class="calendar-primary-div"
      >
        <div class="calendar-primary-div--left">
          <div>
            <thumbnail src="/dashboard/images/calendar/google-logo.svg" />
          </div>
          <div class="display-primary-email-div">
            <h6>Connect to Google Calendar account</h6>
            <p>Account not connected</p>
          </div>
        </div>
        <div
          class="calendar-primary-div--disconnect-button"
          @click="googleOauth"
          v-if="!isLoading"
        >
          Connect
        </div>

        <spinner v-else size="large" />
      </div>
      <div
        v-if="primaryCalendar && primaryCalendar.access_token"
        class="calendar-primary-div"
      >
        <div class="calendar-primary-div--left">
          <div>
            <thumbnail src="/dashboard/images/calendar/google-logo.svg" />
          </div>
          <div class="display-primary-email-div">
            <h6>Connected to Google Calendar account</h6>
            <p>{{ primaryCalendar.calendar_user_id }}</p>
          </div>
        </div>
        <div
          v-if="!isLoading"
          class="calendar-primary-div--disconnect-button"
          @click="handleDisconnect"
        >
          Disconnect
        </div>
        <spinner v-else size="large" />
      </div>
      <!-- <div class="check-for-conflicts">
        <h3 class="block-title">Check for conflicts</h3>
        <p>
          Select the calendar account you would like to check for conflicts.
        </p>
      </div> -->
      <div
        v-if="
          primaryCalendar &&
            primaryCalendar.access_token &&
            conflictingCalendars &&
            conflictingCalendars.length > 0
        "
      >
        <label> Select Conflicting Calendars </label>

        <multiselect
          v-model="selectedCalendars"
          track-by="id"
          label="name"
          :placeholder="'Select Conflicting Calendars'"
          :multiple="true"
          selected-label
          :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
          deselect-label=""
          :max-height="160"
          :options="conflictingCalendars"
        />
        <woot-button
          color-scheme="primary"
          :disabled="isUpdatingCalendars"
          @click="updateConflictingCalendars"
        >
          Save
        </woot-button>
        <spinner v-if="isUpdatingCalendars" size="large" />
      </div>

      <!-- <multiselect
        ref="tagInput"
        v-model="brandIds"
        :placeholder="
          $t('ATTRIBUTES_MGMT.ADD.FORM.TYPE.BRAND_PROFILE.PLACEHOLDER')
        "
        label="brandProfileId"
        track-by="brandProfileId"
        no-options="Please enter the brand profile id"
        :class="{ invalid: isMultiselectInvalid }"
        :options="options"
        :multiple="true"
        :taggable="true"
        @close="onTouch"
        @tag="addTagValue"
        @remove="removeValue"
      /> -->
    </div>
  </div>
</template>

<script>
import CalendarApi from '../../../../api/calendar';
let tokenClient;
const DISCOVERY_DOC = [
  'https://people.googleapis.com/$discovery/rest?version=v1',
  'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
];

const API_KEY = 'AIzaSyAI7NOznWzmddY0lzT5-BwW6ltZrLjgs2o';
const SCOPES =
  'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/calendar';
const CLIENT_ID =
  '80637436768-voi8d1nl1ql1rlqet9e9i5i533k07pji.apps.googleusercontent.com';

import { mapGetters } from 'vuex';
import Thumbnail from '../../../../components/widgets/Thumbnail.vue';
import alertMixin from 'shared/mixins/alertMixin';
import axios from 'axios';
import { accountIdFromPathname } from '../../../../helper/URLHelper';
import ScheduleApi from './ScheduleApi';
import Spinner from 'shared/components/Spinner.vue';
import {getUserAttributes,instructSteps,incrementUserStepAttributes} from "../../../../helper/demoAutomationHelper";

export default {
  components: {
    Thumbnail,
    Spinner,
  },
  mixins: [alertMixin],
  // props: {
  //   currentUserId: {
  //     type: Object,
  //     default: null,
  //   },
  // },
  data() {
    return {
      // googleOauthCompleted: [],
      primaryCalendar: {},
      isLoading: false,
      prevCalendar: null,
      conflictingCalendars: [],
      selectedCalendars: [],
      isUpdatingCalendars: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      currentUserId: 'getCurrentUserID',
    }),
  },
  watch: {},
  async mounted() {
    window.gapi.load('client', this.gapiLoaded);
    // To get google auth code
    tokenClient = window.google.accounts.oauth2.initCodeClient({
      client_id: CLIENT_ID,
      scope: SCOPES,
      ux_mode: 'popup',
      // access_type
      callback: response => {
        if (response?.code) {
          this.exchangeToken(response.code);
        } else {
          this.isLoading = false;
        }
      },
      error_callback: error => {
        this.isLoading = false;
        this.showAlert(error);
      },
    });
  },
  methods: {
    gapiLoaded() {
      window.gapi.client.init({
        apiKey: API_KEY,
        discoveryDocs: DISCOVERY_DOC,
      });

      this.getCalendar();
    },
    async getCalendar() {
      try {
        this.isLoading = true;
        // Check if connected and enabled
        let getCalendarResponse = await CalendarApi.getCalendar();
        console.log('getCalendarResponse', getCalendarResponse);
        if (getCalendarResponse?.data) {
          this.prevCalendar = getCalendarResponse?.data;
          if (getCalendarResponse?.data?.enabled === true) {
            this.primaryCalendar = getCalendarResponse.data;
          }
          this.prevCalendar = getCalendarResponse.data;
          window.gapi.auth.setToken({
            access_token: getCalendarResponse.data.access_token,
          });

          // Set previously selected calendars
          const cals = getCalendarResponse.data;
          console.log('cals', cals.conflict_calendar);
          try {
            if (Array.isArray(cals.conflict_calendar)) {
              this.selectedCalendars = cals.conflict_calendar;
            } else {
              const validJson = JSON.parse(cals.conflict_calendar);
              this.selectedCalendars = validJson;
            }
          } catch (error) {
            console.log('Error while parsing calendars', error);
          }

          // Get list of all calendars
          const allCalendars = await this.listCalendar();
          console.log('allCalendars', allCalendars);
          if (allCalendars) this.conflictingCalendars = allCalendars;
          return getCalendarResponse.data.access_token;
        }
      } catch (error) {
        console.log('get calendar error', error);
      } finally {
        this.isLoading = false;
      }
      return false;
    },

    async updateConflictingCalendars() {
      const payload = {};
      payload.calendar_user_id = this.primaryCalendar.calendar_user_id;
      payload.primary_calendar = this.primaryCalendar.primary_calendar;
      payload.refresh_token = this.primaryCalendar.refresh_token;
      payload.resource_id = this.primaryCalendar.resource_id;
      payload.access_token1 = this.primaryCalendar.access_token;
      payload.conflict_calendar = this.selectedCalendars;
      this.isUpdatingCalendars = true;
      const reposnse = await CalendarApi.updateCalendar(payload);
      if (reposnse && reposnse.status === 200)
        this.showAlert('Conflicting calendars updated');

      this.isUpdatingCalendars = false;
    },

    async unSubscribeCalendarEvent(retry = false, accessToken) {
      if (!this.primaryCalendar || !this.primaryCalendar?.resource_id) {
        return false;
      }

      const [resourceId, channelId] = this.primaryCalendar.resource_id.split(
        '@'
      );
      const payload = {
        id: channelId,
        resourceId: resourceId,
      };
      const headers = {
        Authorization:
          'Bearer ' + accessToken || this.primaryCalendar.access_token,
        'Content-Type': 'application/json',
      };

      try {
        // Unsubscribe to Google push notification
        await axios.post(
          'https://www.googleapis.com/calendar/v3/channels/stop',
          payload,
          { headers }
        );
        const token = accessToken || this.primaryCalendar.access_token;
        window.gapi.auth.setToken({
          access_token: token,
        });
        window.google.accounts.oauth2.revoke(token);

        window.gapi.client.setToken('');
        return true;
      } catch (error) {
        if (error.response.status === 401 && !retry) {
          const tokenUpdated = await ScheduleApi.updateGoogleToken({
            userId: this.currentUserId,
          });

          if (tokenUpdated) {
            // Fetch calendar again
            const access_token = await this.getCalendar();

            console.log('new call for unsubscribe');
            // try unsubscribing one more time
            this.unSubscribeCalendarEvent(true, access_token);
          }
        }
        return true;
      }
    },

    getAllCalenders() {
      return [
        { name: 'Afghanistan', id: 'AF' },
        { name: 'Åland Islands', id: 'AX' },
      ];
    },
    subscribeToCalendarEvent(email, access_token, refresh_token) {
      const token = window.gapi.client.getToken();

      const { pathname } = window.location;
      const accountId = accountIdFromPathname(pathname);

      let channelId = email.replaceAll('@', '=');
      channelId = channelId.replaceAll('.', '_');
      channelId =
        'PEASY_GOOGLE_CHANNEL-' +
        accountId +
        '-' +
        this.currentUserId +
        '-' +
        channelId;
      console.log('subscribing to ', channelId);
      channelId = channelId + '-' + Date.now().toString();

      const date = new Date();

      const payload = {
        id: channelId,
        type: 'web_hook',
        address: 'https://schedule.peasy.ai/api/notifications',
        calendarId: 'primary',
        expiration: new Date(date.setMonth(date.getMonth() + 1)).getTime(),
      };
      const headers = {
        Authorization: 'Bearer ' + token.access_token,
        'Content-Type': 'application/json',
      };

      // Subscribe to Google push notification
      axios
        .post(
          'https://www.googleapis.com/calendar/v3/calendars/' +
            email +
            '/events/watch',
          payload,
          { headers }
        )
        .then(async response => {
          console.log('subscribed to events', response);
          // All done
          const resourceId = response.data.resourceId;
          const calendarCalendarPayload = {};
          calendarCalendarPayload.calendar_user_id = email;
          calendarCalendarPayload.primary_calendar = 'primary';
          calendarCalendarPayload.access_token = token.access_token;
          calendarCalendarPayload.refresh_token = refresh_token;
          calendarCalendarPayload.resource_id = resourceId + '@' + channelId;
          calendarCalendarPayload.enabled = true;
          // calendarCalendarPayload.conflict_calendar = [{ test: 'tester' }];

          console.log('calendarCalendarPayload', calendarCalendarPayload);
          try {
            if (this.prevCalendar) {
              await CalendarApi.updateCalendar(calendarCalendarPayload);
            } else {
              await CalendarApi.createCalendar(calendarCalendarPayload);
            }
            this.primaryCalendar = calendarCalendarPayload;
            
            
            // ------start popup perform
            // increase step based on scheduling path for instruct popup
            const attributes = getUserAttributes();
            const {schedulingSteps} = instructSteps;
            console.log(attributes.path,schedulingSteps.path," || ", attributes.step, schedulingSteps.selectInboxIdStep,schedulingSteps,attributes);

            if (attributes.path === schedulingSteps.path && attributes.step === schedulingSteps.selectInboxIdStep) {
              // increase step+1
            const {isError:isStepErr,data:stepData,errMsg:stepErrMsg} = await incrementUserStepAttributes(schedulingSteps.connectGoogleAccount);
              if (isStepErr) {
                this.showAlert(stepErrMsg);
              }
              this.$router.push(`/app/accounts/${this.currentUser.account_id}/calendar/list`)
            }
            // ------end popup perform
            
            this.isLoading = false;
            this.showAlert('Calendar connected!');
          } catch (error) {
            console.log('Error creating calendar ', error);
            this.showAlert('Failed to connect calendar!');
            this.isLoading = false;
          }
        })
        .catch(error => {
          console.log('Error subscribing ', error);
          this.showAlert('Failed to connect calendar!');
          this.isLoading = false;
        });
    },

    modifyCalendarResponse(arr, ...props) {
      let results = [];
      arr.map(calendarObject => {
        const x = props.reduce(function(result, prop) {
          result[prop] = calendarObject[prop];
          return result;
        }, {});
        x['name'] = x['summary'];
        delete x['summary'];
        results.push(x);
      });
      return results;
    },

    async listCalendar() {
      let that = this;
      let request = await window.gapi.client.calendar?.calendarList.list();
      if (request && request.result) {
        let calendars = request.result.items;
        const calendarsBrief = that.modifyCalendarResponse(
          calendars,
          'id',
          'summary'
        );
        return calendarsBrief;
      }
      // 401 Authorisation
      const tokenUpdated = await ScheduleApi.updateGoogleToken({
        userId: this.currentUserId,
      });

      if (tokenUpdated) {
        console.log('tokenUpdated', tokenUpdated);
        this.getCalendar();
      } else {
        this.showAlert('Failed to fetch calendars');
      }
      return [];
    },
    // Load the API and make an API call.  Display the results on the screen.
    getProfileDetails(access_token, refresh_token) {
      window.gapi.client.people.people
        .get({
          resourceName: 'people/me',
          'requestMask.includeField': 'person.emailAddresses,person.names',
        })
        .then(response => {
          this.subscribeToCalendarEvent(
            response.result.emailAddresses[0].value,
            access_token,
            refresh_token
          );
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    async exchangeToken(code) {
      const response = await ScheduleApi.exchangeTokens(code);
      if (response) {
        const { access_token, refresh_token } = response.data;
        window.gapi.auth.setToken({
          access_token: access_token,
        });
        this.getProfileDetails(access_token, refresh_token);
      } else {
        console.log('Failed token exchange');
        this.isLoading = false;
      }
    },
    googleOauth() {
      this.isLoading = true;
      tokenClient.requestCode({
        prompt: 'consent',
        access_type: 'offline',
      });
    },
    async handleDisconnect() {
      // Handle error
      if (!this.primaryCalendar?.access_token) {
        this.showAlert('Could not disconnect');
        this.primaryCalendar = {};
        return;
      }

      this.isLoading = true;

      try {
        const unsubscribed = await this.unSubscribeCalendarEvent();
        if (!unsubscribed) {
          this.showAlert('Could not unsubscribe');
          this.isLoading = false;

          return;
        }

        console.log('unsubscribed', unsubscribed);

        const calendarCalendarPayload = {};
        calendarCalendarPayload.calendar_user_id = this.prevCalendar.calendar_user_id;
        calendarCalendarPayload.primary_calendar = this.primaryCalendar.primary_calendar;
        calendarCalendarPayload.enabled = false;

        try {
          await CalendarApi.updateCalendar(calendarCalendarPayload);

          this.primaryCalendar = {};
          this.showAlert('Disconnected successfully');
        } catch (error) {
          this.showAlert('Error disconnecting : ' + error);
        } finally {
          this.isLoading = false;
        }

        this.primaryCalendar = {};
      } catch (error) {
        this.showAlert('Error disconnecting.');
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables.scss';

@import '~dashboard/assets/scss/mixins.scss';
.google-calendar {
  display: flex !important;
  width: 100%;
  font-size: $font-size-small;
  .ml-auto {
    margin-left: auto !important;
  }
}
.calendar-primary-div {
  display: flex;
  width: 100%;
  border: 1px solid #a2a2a2;
  margin: 15px 0px !important;
  padding: 15px !important;
  justify-content: space-between;
  align-items: center !important;
  .calendar-primary-div--left {
    width: fit-content;
    display: flex;
    justify-content: left;
    padding: 5px !important;

    .display-primary-email-div {
      word-break: break-all;
      margin-left: 5px !important;
    }
  }
  .calendar-primary-div--disconnect-button {
    padding: 1rem !important;
    border: 1px solid #a2a2a2;
    border-radius: 5px !important;
    font-size: $font-size-small;
    font-weight: $font-weight-black;
    cursor: pointer;
  }
}
.check-for-conflicts {
  margin: 2rem 0px !important;
  .block-title {
    font-size: 20px !important;
  }
}

.calendar-multiselect-wrapper {
  display: flex;
}
</style>
