<template>
  <div class="filters">
    <div
      class="filter"
      :class="{
        error:
          (v.values.$dirty && v.values.$error) ||
          (attributeKey === 'custom_event' &&
            ((v.days.$dirty && v.days.$error) ||
              (v.custom_event_name.$dirty && v.custom_event_name.$error))),
      }"
    >
      <div class="filter-inputs">
        <select
          v-if="groupedFilters"
          v-model="attributeKey"
          class="filter__question"
          :class="reOrderLabels == 'true'?'order4':''"
          @change="resetFilter()"
        >
          <optgroup
            v-for="(group, i) in filterGroups"
            :key="i"
            :label="group.name"
          >
            <option
              v-for="attribute in group.attributes"
              :key="attribute.key"
              :value="attribute.key"
            >
              {{ attribute.name }}
            </option>
          </optgroup>
        </select>

        <select
          v-else
          v-model="attributeKey"
          class="filter__question"
          :class="reOrderLabels == 'true'?'order4':''"
          @change="resetFilter()"
        >
          <option
            v-for="attribute in filterAttributes"
            :key="attribute.key"
            :value="attribute.key"
          >
            {{ attribute.name }}
          </option>
        </select>

        <div v-if="reOrderLabels == 'true'" :class="reOrderLabels == 'true'?'order2':''" class="days" >
          <label class="days_label">{{$t('CAMPAIGN.ADD.CUSTOM.DAYS')}}</label>
        </div>

        <div
          v-if="attributeKey === 'custom_event'"
          class="filter__answer--wrap"
        >
          <input
            v-model="custom_event_name"
            type="text"
            class="answer--text-input"
            placeholder="Enter event name"
          />
        </div>

        <select v-model="filterOperator" class="filter__operator" :class="reOrderLabels == 'true'?'order3':''">
          <option
            v-for="(operator, o) in operators"
            :key="o"
            :value="operator.value"
          >
            {{ $t(`FILTER.OPERATOR_LABELS.${operator.value}`) }}
          </option>
        </select>

        <div v-if="showUserInput" class="filter__answer--wrap">
          <div
            v-if="inputType === 'multi_select'"
            class="multiselect-wrap--small"
          >
            <multiselect
              v-model="values"
              track-by="id"
              label="name"
              :placeholder="'Select'"
              :multiple="true"
              selected-label
              :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
              deselect-label=""
              :max-height="160"
              :options="dropdownValues"
              :allow-empty="false"
            />
          </div>
          <div
            v-else-if="inputType === 'search_select'"
            class="multiselect-wrap--small"
          >
            <multiselect
              v-model="values"
              track-by="id"
              label="name"
              :placeholder="'Select'"
              selected-label
              :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
              deselect-label=""
              :max-height="160"
              :options="dropdownValues"
              :allow-empty="false"
              :option-height="104"
            />
          </div>
          <div v-else-if="inputType === 'date'" class="multiselect-wrap--small">
            <input
              v-model="values"
              type="date"
              :editable="false"
              class="answer--text-input datepicker"
            />
          </div>
          <div
            v-else-if="attributeKey === 'custom_event'"
            class="multiselect-wrap--small"
          >
            <input
              v-model="values"
              type="number"
              min="1"
              class="answer--text-input"
              placeholder="Number of times"
            />
          </div>
          <input
            v-else-if="reOrderLabels == 'true' && value.filter_operator != 'equal_to'"
            v-model="values"
            type="number"
            class="answer--text-input"
            placeholder="Enter value"
            @input="handleDayNumberChange"
          />
          <span v-else-if="reOrderLabels == 'true' && value.filter_operator == 'equal_to'"></span>
          <input
            v-else
            v-model="values"
            type="text"
            class="answer--text-input"
            placeholder="Enter value"
          />
        </div>


        <span :class="reOrderLabels == 'true'?'d-none':''">
          <woot-button
            icon="dismiss"
            variant="clear"
            color-scheme="secondary"
            @click="removeFilter"
          />
        </span>
      </div>
      
      <div
        v-if="attributeKey === 'custom_event'"
        class="filter-inputs date-range "
      >
        <multiselect
          v-model="customDropDownValue"
          track-by="id"
          label="name"
          :placeholder="'Select'"
          selected-label
          :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
          deselect-label=""
          :max-height="160"
          :options="customDropDown"
          :value="customDropDown[0]"
          :allow-empty="false"
          :option-height="104"
        />
        <div class="filter__answer--wrap">
          <woot-date-range-picker
            v-if="customDropDownValue.id === 'in_between'"
            show-range
            :value="customDateRange"
            :confirm-text="$t('REPORT.CUSTOM_DATE_RANGE.CONFIRM')"
            :placeholder="$t('REPORT.CUSTOM_DATE_RANGE.PLACEHOLDER')"
            @change="onChange"
          />
          <input
            v-if="customDropDownValue.id === 'in_the_past'"
            v-model="days"
            type="number"
            min="1"
            class="answer--text-input"
            placeholder="Number of days"
          />
        </div>
      </div>
      <p
        v-if="
          v.values.$dirty && v.values.$error && attributeKey !== 'custom_event'
        "
        class="filter-error"
      >
        {{ $t('FILTER.EMPTY_VALUE_ERROR') }}
      </p>
      <p
        v-if="
          attributeKey === 'custom_event' &&
            ((v.days.$dirty && v.days.$error) ||
              (v.custom_event_name.$dirty && v.custom_event_name.$error))
        "
        class="filter-error"
      >
        {{ $t('FILTER.ALL_VALUE_ERROR') }}
      </p>
    </div>
    <div v-if="showQueryOperator" class="filter__join-operator">
      <hr class="operator__line" />
      <select v-model="query_operator" class="operator__select">
        <option value="and">
          {{ $t('FILTER.QUERY_DROPDOWN_LABELS.AND') }}
        </option>
        <option value="or">
          {{ $t('FILTER.QUERY_DROPDOWN_LABELS.OR') }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import WootDateRangePicker from '../../../components/ui/DateRangePicker.vue';
import getUnixTime from 'date-fns/getUnixTime';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import fromUnixTime from 'date-fns/fromUnixTime';
export default {
  components: {
    WootDateRangePicker,
  },
  props: {
    value: {
      type: Object,
      default: () => null,
    },
    filterAttributes: {
      type: Array,
      default: () => [],
    },
    inputType: {
      type: String,
      default: 'plain_text',
    },
    operators: {
      type: Array,
      default: () => [],
    },
    dropdownValues: {
      type: Array,
      default: () => [],
    },
    showQueryOperator: {
      type: Boolean,
      default: false,
    },
    v: {
      type: Object,
      default: () => null,
    },
    showUserInput: {
      type: Boolean,
      default: true,
    },
    groupedFilters: {
      type: Boolean,
      default: false,
    },
    filterGroups: {
      type: Array,
      default: () => [],
    },
    customAttributeType: {
      type: String,
      default: '',
    },
    reOrderLabels: {
      type: String,
      default: "false",
    },
    customDropDownValue:{
      // _._
      type : Object,
      default: {},
    },
    customEventDateDaysValue:{
      type: [Array,Number],
      default: [new Date(), new Date()],
    },
  },
  data() {
    return {
      currentDateRangeSelection: this.$t('REPORT.DATE_RANGE')[0],
      customDateRange: [new Date(), new Date()],
      customDateRangeValue: [
        getUnixTime(startOfDay(new Date())),
        getUnixTime(endOfDay(new Date())),
      ],
      customDropDown: [
        {
          id: 'in_the_past',
          name: 'In the past',
        },
        {
          id: 'in_between',
          name: 'In between',
        },
      ],
      // customDropDownValue: '',
    };
  },
  computed: {
    to() {
      return this.toCustomDate(this.customDateRange[1]);
    },
    from() {
      return this.fromCustomDate(this.customDateRange[0]);
    },
    attributeKey: {
      get() {
        if (!this.value) return null;
        return this.value.attribute_key;
      },
      set(value) {
        const payload = this.value || {};
        this.$emit('input', { ...payload, attribute_key: value });
      },
    },
    filterOperator: {
      get() {
        if (!this.value) return null;
        return this.value.filter_operator;
      },
      set(value) {
        const payload = this.value || {};
        this.$emit('input', { ...payload, filter_operator: value });
      },
    },
    values: {
      get() {
        if (!this.value) return null;
        return this.value.values;
      },
      set(value) {
        const payload = this.value || {};
        this.$emit('input', { ...payload, values: value });
      },
    },
    custom_event_name: {
      get() {
        if (!this.value) return null;
        return this.value.custom_event_name;
      },
      set(value) {
        const payload = this.value || {};
        this.$emit('input', { ...payload, custom_event_name: value });
      },
    },
    days: {
      get() {
        if (!this.value) return null;
        return this.value.days;
      },
      set(value) {
        const payload = this.value || {};
        // eslint-disable-next-line radix
        this.$emit('input', { ...payload, days: [parseInt(value)] });
      },
    },
    query_operator: {
      get() {
        if (!this.value) return null;
        return this.value.query_operator;
      },
      set(value) {
        const payload = this.value || {};
        this.$emit('input', { ...payload, query_operator: value });
      },
    },
    custom_attribute_type: {
      get() {
        if (!this.customAttributeType) return '';
        return this.customAttributeType;
      },
      set() {
        const payload = this.value || {};
        this.$emit('input', {
          ...payload,
          custom_attribute_type: this.customAttributeType,
        });
      },
    },
  },
  watch: {
    customAttributeType: {
      handler(value) {
        if (
          value === 'conversation_attribute' ||
          value === 'contact_attribute'
        ) {
          // eslint-disable-next-line vue/no-mutating-props
          this.value.custom_attribute_type = this.customAttributeType;
          // eslint-disable-next-line vue/no-mutating-props
        } else this.value.custom_attribute_type = '';
      },
      immediate: true,
    },
  },
  mounted(){
    if (this.attributeKey === 'custom_event') {
      if (Array.isArray(this.customEventDateDaysValue) && this.customEventDateDaysValue.length === 2) {
        
        const customEventDateDaysValueFormatted = this.customEventDateDaysValue.map((timestamp) => {
          return fromUnixTime(timestamp);
        });

        this.customDateRange = customEventDateDaysValueFormatted;
        this.customDateRangeValue = customEventDateDaysValueFormatted.map((date) => {
          return [getUnixTime(startOfDay(date)), getUnixTime(endOfDay(date))];
        });

        this.onChange(customEventDateDaysValueFormatted);
        
      } else if (!isNaN(this.customEventDateDaysValue)) {
        this.days = this.customEventDateDaysValue;
      }
    }
  },
  methods: {
    onDateRangeChange() {
      this.customDateRangeValue[0] = this.from;
      this.customDateRangeValue[1] = this.to;
    },
    onChange(value) {
      this.customDateRange = value;
      this.onDateRangeChange();
      const payload = this.value || {};
      this.$emit('input', { ...payload, days: this.customDateRangeValue });
    },
    fromCustomDate(date) {
      return getUnixTime(startOfDay(date));
    },
    toCustomDate(date) {
      return getUnixTime(endOfDay(date));
    },
    removeFilter() {
      this.$emit('removeFilter');
    },
    resetFilter() {
      this.$emit('resetFilter');
    },
    handleDayNumberChange(e){
      // Only allow positive integers and disallow decimals
      this.values = parseInt(e.target.value) > 0 ? parseInt(e.target.value) : 0;
    },
  },
};
</script>
<style lang="scss" scoped>
.filter {
  background: var(--color-background);
  padding: var(--space-small);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-medium);
}

.filter.error {
  background: var(--r-50);
}

.filter-inputs {
  display: flex;
}
.date-range {
  margin-top: 10px;
}
.date-range .multiselect {
  width: 55%;
  margin-bottom: 16px;
}
.date-range .filter__answer--wrap input {
  margin: 0 0 16px 4px;
  padding: 22px 15px;
  width: 320px;
}
.filter-error {
  color: var(--r-500);
  display: block;
  margin: var(--space-smaller) 0;
}

.filter__question,
.filter__operator {
  margin-bottom: var(--space-zero);
  margin-right: var(--space-smaller);
}

.filter__question {
  max-width: 30%;
}

.filter__operator {
  max-width: 20%;
}

.filter__answer--wrap {
  margin-right: var(--space-smaller);
  flex-grow: 1;

  input {
    margin-bottom: 0;
  }
}
.filter__answer {
  &.answer--text-input {
    margin-bottom: var(--space-zero);
  }
}

.filter__join-operator-wrap {
  position: relative;
  z-index: var(--z-index-twenty);
  margin: var(--space-zero);
}

.filter__join-operator {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: var(--space-one) var(--space-zero);

  .operator__line {
    position: absolute;
    width: 100%;
    border-bottom: 2px solid var(--color-border);
  }

  .operator__select {
    position: relative;
    width: auto;
    margin-bottom: var(--space-zero) !important;
  }
}

.multiselect {
  margin-bottom: var(--space-zero);
}


.order2{
  order: 2;
}
.order3{
  order: 3;
}
.order4{
  order: 4;
}
.d-none{
  display: none;
}
.days{
  display: flex;
  align-items: center;
  margin: 0 4px;
}
.days_label{
  height: 22px;
}
</style>
