import { frontendURL } from '../../../../helper/URLHelper';

const sessions = accountId => ({
  parentNav: 'onboarding-sessions',
  routes: [
    // 'session_onboarding_home',
    'schedule_session',
  ],
  menuItems: [
    {
      // add the icon with name as outline in "app\javascript\shared\components\FluentIcon\dashboard-icons.json"
      // real icon name with outline = "contact-card-group-outline"
      icon: 'video-call',  
      label: 'SESSION_ONBOARD',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/session/schedule-session`),
      toStateName: 'schedule_session',
    },
    /*
    {
      icon: 'contact-card-group',  
      label: 'SESSION_ONBOARD',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/session`),
      toStateName: 'session_onboarding_home',
    },
    */
  ],
});

export default sessions;
