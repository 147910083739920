<template>
<div>
  <button
    class="create_temp_msg_btn"
    @click.stop="onCreateTemplateBtn"
    :style="{
    top: getTopPadding(-3) + 'rem', position: getPosition(),
    ['z-index']: isCreateShow?101:100,
    }"
  >
    {{$t('MESSAGE_MGMT.CREATE_BTN')}}
    </button>
  <ul
    v-if="items.length"
    class="vertical dropdown menu mention--box"
    :style="{ top: getTopPadding() + 'rem', position: getPosition() }"
  >
    <template>
      <div>
      <li
        v-for="(item, index) in items"
        :id="`mention-item-${index}`"
        :key="item.key"
        :class="{
          active: index === selectedIndex,
          pending_cann: item.template_status === 'pending',
          cannItemWrap: true,
        }"
        @mouseover="onHover(index)"
      >
        <a class="cann_full_show" :class="{pending_cann: item.template_status === 'pending'}" @click.stop="!['pending', 'rejected'].includes(item.template_status) ? onListItemSelection(index) : onPendingClick(index, $event)">
          <strong>{{ item.label }} <span class="status" :class="{[item.template_status]:true}">{{statusList[item.template_status] || ""}}</span> </strong>
          <span> {{ item.description }}</span>
        </a>
        <woot-button
          class-names="preview_btn"
          variant="clear"
          icon="previeweye"
          @click.stop="onPreviewClickHandler(item)"
        >
        {{$t("MESSAGE_MGMT.SHORT_CREATE.PREVIEW_BTN")}}
        </woot-button>
      </li>

      </div>
    </template>

    <!-- <template v-else>
      <li
      v-for="(item, index) in items"
      :id="`mention-item-${index}`"
      :key="item.key"
      :class="{ active: index === selectedIndex }"
      @click="onListItemSelection(index)"
      @mouseover="onHover(index)"
    >
      <a class="text-truncate">
        <strong>{{ item.label }}</strong> - {{ item.description }}
      </a>
      </li>
    </template> -->
    <CreateTemplateMessageModal
        :onClose="onCloseTemplateModal"
        :show="addTemplateMsgShow"
        :onCreateSuccess="onSuccessTemplateCreate"
    />
    <ReviewNotifyModal
        :show="reviewNotifyShow"
        :onClose="onCloseReviewNotifyModal"
        :onOkay="onReviewNotifyOkay"
    />
    <PreviewTemplateModal
        :show="previewMessageShow"
        :onClose="onClosePreviewModal"
        :cannItem="previewMessage"
    />
  </ul>
</div>
</template>

<script>
import { mapGetters } from "vuex";
import PreviewTemplateModal from '../../../../dashboard/routes/dashboard/settings/messages/PreviewTemplateModal.vue';
import CreateTemplateMessageModal from '../../../../dashboard/routes/dashboard/settings/messages/CreateTemplateMessage.vue';
import ReviewNotifyModal from '../../../../dashboard/routes/dashboard/settings/messages/ReviewNotifyModal.vue';



export default {
   components: {
    PreviewTemplateModal,
    CreateTemplateMessageModal,
    ReviewNotifyModal,
  },
  props: {
    items: {
      type: Array,
      default: () => {},
    },
    topRem: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedIndex: 0,
      statusList:{
        rejected: this.$t('MESSAGE_MGMT.STATUS.REJECTED'),
        approved: this.$t('MESSAGE_MGMT.STATUS.PAAROVED'),
        pending: this.$t('MESSAGE_MGMT.STATUS.PENDING'),
      },
      previewMessageShow:false,
      previewMessage:{},
      addTemplateMsgShow: false,
      reviewNotifyShow: false,
    };
  },
  computed: {
    ...mapGetters({
      cannedMessages: 'getCannedResponses',
    }),
    isCreateShow(){
      return !this.previewMessageShow && !this.addTemplateMsgShow && !this.reviewNotifyShow;
    },
  },
  watch: {
    items(newItems) {
      if (newItems.length < this.selectedIndex + 1) {
        this.selectedIndex = 0;
      }
    },
  },
  mounted() {
    document.addEventListener('keydown', this.keyListener);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.keyListener);
  },
  methods: {
    getTopPadding(extra=0) {
      if (this.items.length >= 4 && this.topRem !== '') {
        return this.topRem + (this.items.length * 2.8 + 1.7);
      }
      if (this.topRem !== '') {
        return this.topRem;
      }
      if (this.items.length <= 4) {
        return -(this.items.length * 2.8 + 1.7);
      }
      // return -14;
      return -14 + extra;
    },
    getPosition() {
      if (this.topRem !== '') {
        return 'inherit';
      }
      return 'absolute';
    },
    isUp(e) {
      return e.keyCode === 38 || (e.ctrlKey && e.keyCode === 80); // UP, Ctrl-P
    },
    isDown(e) {
      return e.keyCode === 40 || (e.ctrlKey && e.keyCode === 78); // DOWN, Ctrl-N
    },
    isEnter(e) {
      return e.keyCode === 13;
    },
    keyListener(e) {
      if (this.isUp(e)) {
        if (!this.selectedIndex) {
          this.selectedIndex = this.items.length - 1;
        } else {
          this.selectedIndex -= 1;
        }
      }
      if (this.isDown(e)) {
        if (this.selectedIndex === this.items.length - 1) {
          this.selectedIndex = 0;
        } else {
          this.selectedIndex += 1;
        }
      }
      if (this.isEnter(e)) {
        // this.onMentionSelect();
      }
      this.$el.scrollTop = 28 * this.selectedIndex;
    },
    onHover(index) {
      this.selectedIndex = index;
    },
    onListItemSelection(index) {
      this.selectedIndex = index;
      this.onMentionSelect();
    },
    onMentionSelect() {
      this.$emit('mention-select', this.items[this.selectedIndex]);
    },
    isPending(item){
      return item.template_status === 'pending'
    },
    onPreviewClickHandler(item){
      if (this.onPreviewClick) {
        this.onPreviewClick(item)
      }
      if (window.bus?.$emit) {
        // bus.$emit('MENTION_PREVIEW_CLICK_EVENT',item);
      }
    },
    onPendingClick(index,event){
      event.stopPropagation();
      event.preventDefault();
      console.log(index,event);
    },
    onPreviewClick(cannItem){
      console.log("click",cannItem);
      // look for the cann item by id
      const cann = this.cannedMessages.find(el=>el.id === cannItem.id)
      this.previewMessage = cann;
      this.previewMessageShow = true;
    },
    onClosePreviewModal(){
      this.previewMessageShow = false;
    },
    onCloseTemplateModal(){
      this.addTemplateMsgShow = false
    },
    onSuccessTemplateCreate(){
      this.onCloseTemplateModal();
      this.$store.dispatch('getCannedResponse', { searchKey: "" });
      this.reviewNotifyShow = true;
    },
    onCloseReviewNotifyModal(){
      this.reviewNotifyShow = false;
    },
    onReviewNotifyOkay(){
      this.onCloseReviewNotifyModal();
    },
    onCreateTemplateBtn(){
      console.log("onCreateTemplateBtn");
      this.addTemplateMsgShow = true;
    },
  },
};
</script>

<style scoped lang="scss">
.mention--box {
  background: var(--white);
  border-bottom: var(--space-small) solid var(--white);
  border-top: 1px solid var(--color-border);
  left: 0;
  max-height: 14rem;
  overflow: auto;
  padding-top: var(--space-zero);
  position: inherit;
  width: 100%;
  z-index: 100;

  .active a {
    background: var(--w-500);
  }
}
.cann_full_show{
  font-size: 14px;
  color: #1F1F1F !important;
  background: #fff !important;
  border-top: 1px solid #EEEEEE;
  padding: 12px;
  line-height: 19px;

  strong{
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    color: #8D25F6;
  }
  .rejected{
    padding: 5px 12px;
    color: #E50000;
    background: #FFE5E5;
    white-space: nowrap;
  }
  .approved{
    padding: 5px 12px;
    color: #24B200;
    background: #EBFCC0;
    white-space: nowrap;
  }
  .pending{
    padding: 5px 12px;
    color: #FFAA00;
    background: #FFF4E5;
    white-space: nowrap;
  }

  span{
    display: block;
    margin-top: 2px;
    font-weight: 500;
    color: #1F1F1F;
    line-height: 19.6px;
  }
}
.preview_btn{
  display: flex;
  cursor: pointer;
}
.cannItemWrap{
  border-bottom: 1px solid #EEEEEE !important;
}
.pending_cann{
  cursor: default;
  background-color: #F5F5F5 !important;
}
.create_temp_msg_btn{
    position: absolute;
    z-index: 101;
    left: 0;
    background: #fff;
    padding: 12px;
    width: 100%;
    color: #8D25F6;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    padding-left: 12px;
    border-top: 1px solid #EEEEEE;
    border-bottom: 1px solid #EEEEEE;
    cursor: pointer;
    &:hover{
      background: #8D25F6;
      color: #fff;
    }
    span{
      font-weight: 500;
    }
  }
</style>
