<template>
  <div class="column content-box">
    <div class="row">
      <div class="empty-wrapper">
        <woot-loading-state
          v-if="uiFlags.isFetching"
          :message="$t('INTEGRATION_APPS.FETCHING')"
        />
      </div>

      <div class="small-12 columns integrations-wrap">
        <div v-if="!isBrandProfileFetching" class="row integrations">
          <div
            v-for="item in integrationsList"
            :key="item.id"
            class="small-12 columns integration"
          >
            <integration-item
              :integration-id="item.id"
              :integration-logo="item.logo"
              :integration-name="item.name"
              :integration-description="item.description"
              :integration-enabled="item.hooks.length"
              :brandProfilesList="brandProfilesList"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import IntegrationItem from './IntegrationItem';
import PMConnectAPI from '../bot/PMConnectAPI';

export default {
  components: {
    IntegrationItem,
  },
  data(){
    return {
      brandProfilesList: false, // [] or false or null; [] when get response
      isBrandProfileFetching:false,
    }
  },
  computed: {
    ...mapGetters({
      uiFlags: 'labels/getUIFlags',
      integrationsList: 'integrations/getAppIntegrations',
    }),
  },
  mounted() {
    this.$store.dispatch('integrations/get');
    this.checkPeasyConnection();
  },
  methods:{
    // get list of connected brandProfiles
    async checkPeasyConnection() {
      try {
        this.isBrandProfileFetching = true;
        const PM_TOKEN = PMConnectAPI.getPMToken();
        if (PM_TOKEN) {
          const brandProfilesResponse = await PMConnectAPI.getBrandProfileList();
          if (brandProfilesResponse) {
            // token exists and is valid
            this.brandProfilesList = brandProfilesResponse;
          } else {
            // token is expired or invalid
            // localStorage.removeItem('pm-auth-token');
            this.brandProfilesList = false;
          }
        } 
      } catch (error) {
        console.log(error);
      }
      this.isBrandProfileFetching = false;
    },

  },

};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
.empty-wrapper {
  margin: var(--space-zero) auto;
}
</style>
