<template>
  <mention-box
    :items="items"
    :top-rem="top"
    :isPreview="isPreview"
    :onPreviewClick="onPreviewClick"
    @mention-select="handleMentionClick"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import MentionBox from '../mentions/MentionBox.vue';

export default {
  components: { MentionBox },
  props: {
    searchKey: {
      type: String,
      default: '',
    },
    top: {
      type: String,
      default: '',
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
    onPreviewClick: {
      type: Function,
      default: ()=>{},
    },
    isHideRejectItem: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      cannedMessages: 'getCannedResponses',
    }),
    items() {
      // filter based on status
      let result = this.cannedMessages;
      if (this.isHideRejectItem) {
        result = result.filter(el=>el.template_status !== 'rejected')
      }
      return result.map(cannedMessage => ({
        ...cannedMessage,
        id: cannedMessage.id,
        label: cannedMessage.short_code,
        key: cannedMessage.short_code,
        description: cannedMessage.content,
        isTemplate: cannedMessage.is_template,
        template_status: cannedMessage.template_status,
        inbox_id: cannedMessage.inbox_id,
      }));
    },
  },
  watch: {
    searchKey() {
      this.fetchCannedResponses();
    },
  },
  mounted() {
    this.fetchCannedResponses();
  },
  methods: {
    fetchCannedResponses() {
      this.$store.dispatch('getCannedResponse', { searchKey: this.searchKey });
    },
    handleMentionClick(item = {}) {
      let name = item.isTemplate ? item.key : '';
      this.$emit('click', item.description, name);
    },
  },
};
</script>
