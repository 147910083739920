<template>
  <div  :style="{ height: index === 0 ? '0' : 'auto', overflow: index === 0 ? 'hidden' : 'visible' }">
    <div>
            <!-- :options="getDayOptions()" -->
        <multiselect
            v-model="business_hour.day"
            :options="filteredDayOptions(index)"
            class="small-12"
            track-by="id"
            label="title"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="false"
            :hide-selected="true"
            placeholder="Pick a business day"
            selected-label
            :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
            :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
            :key="'day_' + index"
        />
    </div>

    <div>
            <!-- :options="[...TIME_INTERVAL]" -->
        <multiselect
            v-model="business_hour.open"
            :options="filteredOpenOptions"
            class="small-12"
            track-by="id"
            label="title"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="false"
            :hide-selected="true"
            placeholder="Pick opening hour"
            selected-label
            :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
            :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
            :key="'open_' + index"
        />
    </div>
    <div class="margin_bottom" style="text-align: center;"><p>To</p></div>
    <div>
            <!-- :options="[...TIME_INTERVAL]" -->
        <multiselect
            v-model="business_hour.close"
            :options="filteredCloseOptions"
            class="small-12"
            track-by="id"
            label="title"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="false"
            :hide-selected="true"
            placeholder="Pick closing hour"
            selected-label
            :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
            :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
            :key="'close_' + index"
        />
    </div>


    <div style="min-height:40px;">
        <woot-button
            variant="smooth"
            size="small"
            color-scheme="alert"
            icon="delete"
            @click="removeBusinessHourHandler(index)"
        >
        </woot-button>
    </div>
    </div>
</template>

<script>
export default {
    props:{
        business_hour:{
            type: Object,
            default:{
                day: {},
                open: {},
                close: {},
            }
        },
        business_hours:{
            type: Array,
            default:[]
        },
        TIME_INTERVAL:{
            type: Array,
            default: []
        },
        DAY_NAMES:{
            type: Array,
            default: []
        },
        index:{
            type: Number,
            default: 0
        },
        removeBusinessHour:{
            type: Function,
            default: (index)=>{}
        },

    },
    computed: {
        getDayOptions() {
            return function(index) {
                return [...this.DAY_NAMES];
            };
        },
        // getTimeOptions() {
        //     return function(index) {
        //         return [...this.TIME_INTERVAL];
        //     };
        // },
         filteredOpenOptions() {
            // Return all time intervals except the last one
            return this.TIME_INTERVAL.slice(0, this.TIME_INTERVAL.length - 1);
        },
        filteredCloseOptions() {
            const openTime = this.business_hour.open ? this.business_hour.open.id : null;
            if (!openTime) return this.TIME_INTERVAL;
            return this.TIME_INTERVAL.filter(time => time.id > openTime);
        },
    },
    watch: {
        'business_hour.open': {
            handler(newOpen) {
                this.watchBusinessOpenChange(newOpen);
            },
            immediate: true,
        },
        'business_hour.close': {
            handler(newClose) {
                this.watchBusinessCloseChange(newClose);
            },
            immediate: true,
        },
    },
    methods:{
        removeBusinessHourHandler(index){
            console.log({index});
            this.removeBusinessHour(index)
        },
        filteredDayOptions(index) {
            // Get the list of selected day IDs, excluding the current index
            const selectedDays = this.business_hours
                .filter((_, i) => i !== index)
                .map(bh => bh.day.id);

            // Return days that have not been selected yet
            return this.DAY_NAMES.filter(day => !selectedDays.includes(day.id));
        },
        watchBusinessOpenChange(newOpen){
            if (newOpen && this.business_hour.close && newOpen.id >= this.business_hour.close.id) {
                // If open time is greater than or equal to close time, reset close time
                const nextCloseOption = this.TIME_INTERVAL.find(time => time.id > newOpen.id);
                if (nextCloseOption) {
                    this.business_hour.close = nextCloseOption;
                }
            }
        },
        watchBusinessCloseChange(newClose){
            if (newClose && this.business_hour.open && newClose.id <= this.business_hour.open.id) {
                // If close time is less than or equal to open time, reset open time
                const previousOpenOption = this.TIME_INTERVAL.slice().reverse().find(time => time.id < newClose.id);
                if (previousOpenOption) {
                    this.business_hour.open = previousOpenOption;
                }
            }
        },

    },
}
</script>

<style>

</style>