<template>
  <woot-modal :show.sync="show" :on-close="onClose">
    <woot-modal-header :header-title="$t('CAMPAIGN.EMAIL_TEMPLATE.ATTCHMENT.TITLE')" />
    <form class="row" @submit.prevent="saveAttchment">
      <div class="medium-12 columns">
        <woot-input
          v-model="name"
          :label="$t('CAMPAIGN.EMAIL_TEMPLATE.ATTCHMENT.FORM.ADD.NAME.LABEL')"
          type="text"
          :error="
            $v.name.$error ? $t('CAMPAIGN.EMAIL_TEMPLATE.ATTCHMENT.FORM.ADD.NAME.ERROR') : ''
          "
          :class="{ error: $v.name.$error }"
          :placeholder="$t('CAMPAIGN.EMAIL_TEMPLATE.ATTCHMENT.FORM.ADD.NAME.PLACEHOLDER')"
          @blur="$v.name.$touch"
        />
        <div class="medium-12 columns display-inline">
          <label>
            <span>{{ $t('CAMPAIGN.EMAIL_TEMPLATE.ATTCHMENT.FORM.ADD.FILE.LABEL') }}</span>
            <input id="file" ref="file" type="file" accept="image/*" @change="handleImageUpload" />
          </label>
        </div>
        <div v-show="previewImage">
          <img :src="previewImage" alt="image-preview" />
        </div>
        <div class="modal-footer">
          <woot-button
            :disabled="isButtonDisabled"
          >{{ isAddingAttchment ? $t('CAMPAIGN.EMAIL_TEMPLATE.ATTCHMENT.SAVING') : $t('CAMPAIGN.EMAIL_TEMPLATE.ATTCHMENT.SAVE_BUTTON') }}</woot-button>
          <woot-button
            variant="clear"
            @click.prevent="onClose"
          >{{ $t('CAMPAIGN.EMAIL_TEMPLATE.ATTCHMENT.CANCEL_BUTTON') }}</woot-button>
        </div>
      </div>
    </form>
  </woot-modal>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';
import EmailTemplateAPI from '../../../../../api/emailTemplates';

export default {
  name: 'AddAttchment',
  data() {
    return {
      show: true,
      name: '',
      file: '',
      isAddingAttchment: false,
      previewImage: null,
    };
  },
  computed: {
    isButtonDisabled() {
      return this.$v.name.$invalid;
    },
  },
  validations: {
    name: {
      required,
      minLength: minLength(1),
    },
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    handleImageUpload(event) {
      this.file = event.target.files[0];

      if (this.file.size > 1024 * 1024 * 14) {
        alert('File is too big. Please select file of size less then 14mb.');
        this.$refs.file.value = null;
        this.file = '';
        return;
      }
      const reader = new FileReader();
      reader.onload = event => {
        this.previewImage = event.target.result;
      };
      reader.readAsDataURL(this.file);
    },
    async saveAttchment() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.isAddingAttchment = true;
      let formData = new FormData();
      formData.append('caption', this.name);
      formData.append('file', this.file);
      await EmailTemplateAPI.addAttchment(formData)
        .then(response => {
          this.$emit('close');
        })
        .catch(error => {
          console.log('error', error);
        })
        .finally(() => {
          this.isAddingAttchment = false;
        });
    },
  },
};
</script>

<style>
</style>