import ApiClient from './ApiClient';

class AssignedAgents extends ApiClient {
  constructor() {
    super('', { accountScoped: true });
  } 

  getAssignedAgent(){
    return axios.get(`${this.url}/assigned_agent`);
  }
}

export default new AssignedAgents();
