<template>
    <woot-modal :show.sync="isOpen" :on-close="onCancel" modal-type="right-aligned">
        <div class="column content-box">
            <woot-modal-header
                header-title="Create New Grant"
                header-content="Add basic information details about the new grant."
            />
            <form class="contact--form" @submit.prevent="handleSubmit">
                <div class="row">
                    <div class="columns">
                        <label :class="{ error: $v.email.$error }">
                          {{ $t('CONTACT_FORM.FORM.EMAIL_ADDRESS.LABEL') }}
                          <input
                              v-model.trim="email"
                              type="text"
                              placeholder="Enter the email address of the grant"
                              @input="$v.email.$touch"
                          />
                        </label>

                        <label :class="{ error: $v.balance_credit.$error }">
                          Balance Credit amount
                          <input
                              v-model.trim="balance_credit"
                              type="text"
                              placeholder="Enter the credit amount"
                              @input="$v.balance_credit.$touch"
                          />
                        </label>
                    </div>
                </div>
                
                <div class="modal-footer">
                  <div class="medium-12 columns">
                    <woot-submit-button
                      :loading="inProgress"
                      :button-text="$t('CONTACT_FORM.FORM.SUBMIT')"
                    />
                    <button class="button clear" @click.prevent="onCancel">
                      {{ $t('CONTACT_FORM.FORM.CANCEL') }}
                    </button>
                  </div>
                </div>
            </form>
        </div>
  </woot-modal>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import {
  DuplicateContactException,
  ExceptionWithMessage,
} from 'shared/helpers/CustomErrors';
import { required } from 'vuelidate/lib/validators';

import { isPhoneE164OrEmpty } from 'shared/helpers/Validators';

export default {
  mixins: [alertMixin],
  props: {
    isOpen: {
        type: Boolean,
        default: false,
    },
    inProgress: {
      type: Boolean,
      default: false,
    },
    // credit: {
    //   type: Object,
    //   default: () => ({}),
    // },
    onSubmit: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      email: '',
      balance_credit: '',
    };
  },
  validations: {
    balance_credit: {
      required,
    },
    email: {
      required,
    },
  },

  watch: {},
  mounted() {},
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    onSuccess() {
      this.$emit('success');
    },
    async handleSubmit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }
      try {
        const newGrant = {
          email: this.email,
          balance_credit: this.balance_credit,
        }
        await this.onSubmit(newGrant);
        this.onSuccess();
        // this.showAlert(this.$t('CONTACT_FORM.SUCCESS_MESSAGE'));
        this.showAlert(this.$t('Grant created successfully'));
      } catch (error) {
        if (error instanceof ExceptionWithMessage) {
          this.showAlert(error.data);
        } else {
          // this.showAlert(this.$t('CONTACT_FORM.ERROR_MESSAGE'));
          this.showAlert(this.$t('Something went wrong'));
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.contact--form {
  padding: var(--space-normal) var(--space-large) var(--space-large);

  .columns {
    padding: 0 var(--space-smaller);
  }
}

.input-group-label {
  font-size: var(--font-size-small);
}
</style>
