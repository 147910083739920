<template>
  <div class="column content-box onboard_container">
    <section class="row">
      <div class="column content-box">

        <!-- whatsapp verification -->
        <section class="business_container verify_container" v-if="!currentUser.confirmed">
          <div class="banner_heading">
              <h3 class="page-title">{{$t('ARRANGE.VERIFY.TITLE')}}</h3>
          </div>
          <div>
            <p style="margin:0;">{{$t('ARRANGE.VERIFY.MSG_PART_1')}}</p>
            <p><b>{{currentUser.user_phone_number}}</b> {{$t('ARRANGE.VERIFY.MSG_PART_2')}}</p>
            <woot-button class="wp_btn" @click="handleOpenWhatsApp">{{$t('ARRANGE.VERIFY.WHATSAPP_BTN')}}</woot-button>
            <div class="resend_box">
              <p style="margin:0;">{{$t('ARRANGE.VERIFY.RESEND_TEXT')}}</p>
              <woot-button
              class="resend_btn"
              :classNames="isResendEnabled ? '' : 'btn_mute'"
              :disabled="!isResendEnabled || isReSending"
              @click="handleResendLink"
              >
              {{ isResendEnabled ? $t('ARRANGE.VERIFY.RESEND_BTN') : `${$t('ARRANGE.VERIFY.RESEND_BTN')} (${countdown}s)` }}
              </woot-button>
            </div>
          </div>
        </section>

        <section v-else class="business_container">
          <div class="banner_heading">
              <h3 class="page-title"> {{$t('ARRANGE.BUSINESS_TYPE.TITLE')}}</h3>
          </div>
          <div>
            <p class="sub-block-title sub_title">{{$t('ARRANGE.BUSINESS_TYPE.CUSTOMER_LEVEL')}}</p>
            <RadioList
              :options="businessTypeOprions"
              v-model="selectedBysinessTypeIndex"
              @change="onBusinessTypeChange"
            />
          </div>
          <div>
            <label for="">{{$t('ARRANGE.BUSINESS_TYPE.INDUSTRY_LEVEL')}}</label>
            <multiselect
              v-model="selectedService"
              :options="services"
              track-by="id"
              label="industry"
              :disabled="false"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="false"
              :hide-selected="true"
              :tag-placeholder="$t('INBOX_MGMT.SETTINGS_POPUP.CATALOGUES')"
              :taggable="true"
              :placeholder="$t('INBOX_MGMT.SETTINGS_POPUP.CATALOGUES.CATEGORY_SELECT_PLACEHOLDER')"
              selected-label
              :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
              :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
            />
          </div>
          <div>
            <woot-input
              v-model.trim="average_order"
              type="number"
              :class="{ error: $v.average_order.$error }"
              :label="$t('ARRANGE.BUSINESS_TYPE.ORDER_LEVEL')"
              :placeholder="'500'"
              :error="
                $v.average_order.$error
                  ? $t('ARRANGE.BUSINESS_TYPE.ORDER_ERR')
                  : ''
              "
              @blur="$v.average_order.$touch"
            />
          </div>
          <div class="btn_wrap">
            <woot-button
              color-scheme="primary"
              :is-loading="isLoading"
              :is-disabled="isLoading"
              @click="onBusinessSubmit"
            >
              {{$t('ARRANGE.BUSINESS_TYPE.BTN')}}
            </woot-button>
          </div>
        </section>

      </div>
    </section>
  </div>
</template>

<script>
import { required, minLength, email } from 'vuelidate/lib/validators';
import {businessTypes,services} from "./constant"
import RadioList from './RadioList.vue';
import alertMixin from 'shared/mixins/alertMixin';
import AccountAPI from "../../../api/account"
import { mapGetters } from 'vuex';
import {getParamsFromUrl, frontendURL} from "../../../helper/URLHelper"
import {SIGNUP_FLOW} from "../../../helper/demoAutomationHelper"
import endPoints from '../../../api/endPoints';
import Button from '../../../components/buttons/Button.vue';
import WootButton from '../../../components/ui/WootButton.vue';

export default {
  components: {
    RadioList,
    WootButton,
  },
  mixins: [alertMixin],
  data() {
    return {
      selectedBysinessTypeIndex: 1,
      selectedService: null,
      services,
      isLoading: false,
      average_order: 0,
      countdown: 180, // 3 minutes countdown
      isResendEnabled: false,
      isReSending: false,
    };
  },
  computed:{
    ...mapGetters({
      currentUser: 'getCurrentUser',
      userPlan: 'accounts/getUserPlan',
      getCurrentAccount: 'accounts/getAccount',
    }),
    businessTypeOprions(){
      return businessTypes.map(el=>({...el,label:el.title,description: el.info}))
    },
  },
  mounted() {
    this.startCountdown();
    bus.$on('WhatsAppVerificationBusEvent', (data) => {
      console.log("verify res = ",data);
      if (data?.confirmed) {
        // if already have sector, then go to home
        if(this.currentUser.sector){
          window.location = `/app/accounts/${this.currentUser.account_id}/`
          return
        }
        window.location.reload()
      }
    });
    this.updateQueryParamsIfNeeded();
  },
  watch: {
    countdown(newCountdown) {
      if (newCountdown <= 0) {
        this.isResendEnabled = true;
      }
    },
  },
  validations: {
    average_order: {
      required,
      isNumeric(value){
        return /^[0-9]+$/.test(value);
      }
    },
  },
  methods: {
    async onBusinessSubmit(){
      const query = getParamsFromUrl();
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (!this.selectedService) {
        this.showAlert(this.$t('ARRANGE.BUSINESS_TYPE.INDUSTRY_REQUIRE_ERR'));
        return
      }

      try {
        this.isLoading = true;

        const business = businessTypes[this.selectedBysinessTypeIndex]
        const contactAttributes = {
          distribution: business.id,
          sector: this.selectedService[business.id],
          industry: this.selectedService.industry,
          average_order:  parseInt(this.average_order),
        }
        const {data:attrRes} = await AccountAPI.setCustomAttributeToAdminContact(contactAttributes);
        await this.$store.dispatch('accounts/get'); // get updated account
        const nextsignupflow = {
            account_id:this.currentUser.account_id,
            isNewSignup:true,
            current_step: SIGNUP_FLOW.FLOWS.select_plan
          }
        SIGNUP_FLOW.setFlow(nextsignupflow)

        // this.$router.push({
        //   path: `/app/accounts/${this.currentUser.account_id}/settings/subscription-plans`,
        //   query: {...query}
        // });
        // window.location = frontendURL(`accounts/${this.currentUser.account_id}/settings/subscription-plans`,query)
        window.location = frontendURL(endPoints('subscriptionPlans').url(this.currentUser.account_id),query)

        this.showAlert(this.$t('ARRANGE.BUSINESS_TYPE.SUBMIT_SUCCESS_MSG'));
      } catch (error) {
        console.log(error);
        this.showAlert(this.$t('ARRANGE.BUSINESS_TYPE.SUBMIT_FAIL_MSG'));
      } finally{
        this.isLoading = false;
      }

    },
    onBusinessTypeChange(index) {
      console.log('Selected business type:', index);
    },
    handleOpenWhatsApp() {
      const message = this.$t('ARRANGE.VERIFY.WHATSAPP_RESEND_SUCCESS_MSG');
      const phone = this.currentUser.phone_number;
      const url = `whatsapp://send?text=${encodeURIComponent(message)}&phone=${encodeURIComponent(phone)}`;
      window.location.href = url;  // This will trigger WhatsApp to open
    },
    async handleResendLink() {
      if (!this.isResendEnabled || this.isReSending) return;

      this.isReSending = true;
      try {
        await AccountAPI.resendVerificationCode();
        this.countdown = 180;  // Reset countdown to 3 minutes
        this.isResendEnabled = false;
        this.showAlert(this.$t('ARRANGE.VERIFY.RESEND_SUCCESS'));
      } catch (error) {
        console.error(error);
        this.showAlert(error.message || "Failed to send verification code.");
      } finally {
        this.isReSending = false;
      }
    },
    startCountdown() {
      const interval = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown -= 1;
        } else {
          clearInterval(interval);  // Stop the countdown when it reaches 0
        }
      }, 1000);
    },
    updateQueryParamsIfNeeded() {
    const query = { ...this.$route.query };
    const signup_flow = SIGNUP_FLOW.getFlow();
    if (signup_flow?.buy_now_plan_id && signup_flow?.isNewSignup && !query.buy_now_plan_id) {
      query.buy_now_plan_id = signup_flow.buy_now_plan_id;
      console.log({query});
      this.$router.push({ path: this.$route.path, query });
    }
  }
    // end method
  },
}
</script>

<style scoped lang="scss">
.verify_container{
  .wp_btn{
    font-weight: 700;
    color: var(--w-500);
    background: transparent;
    margin: 25px 0;
  }
  .resend_box{
    display: flex;
    justify-items: center;
    align-items: center;

    .resend_btn{
      background: transparent;
      color: var(--color-body);
    }
    .btn_mute {
      color: #000;
    }
  }
}
.business_container{
  min-height: 100vh;
  width: fit-content;
  margin: auto;

  .sub_title{
    font-weight: 600;
    margin-top: 16px;
  }
  .btn_wrap{
    margin-top: 20px;
  }
}

</style>