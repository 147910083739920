<template>
  <div class="audio-wave-wrapper" />
</template>

<script>
import alertMixin from '../../../../shared/mixins/alertMixin';

import { format, addSeconds } from 'date-fns';
import { AUDIO_FORMATS } from 'shared/constants/messages';
import { WavHeader, Mp3Encoder } from 'lamejs';

export default {
  name: 'WootAudioRecorder',
  mixins: [alertMixin],
  props: {
    audioRecordFormat: {
      type: String,
      default: AUDIO_FORMATS.OGG,
    },
  },
  data() {
    return {
      audioWav: {
        numberOfChannels: undefined,
        btwLength: undefined,
        btwArrBuff: undefined,
        btwView: undefined,
        btwChannels: [],
        btwIndex: undefined,
        btwSample: undefined,
        btwOffset: undefined,
        btwPos: undefined,
      },
      mediaRecorder: undefined,
      mediaStream: null,
      audioChunks: [],
      secondsElapsed: 0,
      recordingDateStarted: new Date(0),
      initialTimeDuration: '00:00',
    };
  },
  computed: {
    isRecording() {
      return this.mediaRecorder && this.mediaRecorder.state === 'recording';
    },
    isChrome() {
      return !MediaRecorder.isTypeSupported(AUDIO_FORMATS.OGG);
    },
    audioMimeType() {
      return this.isChrome ? AUDIO_FORMATS.WEBM : AUDIO_FORMATS.MPEG;
    },
  },
  mounted() {
    this.fireProgressRecord(this.initialTimeDuration);
    navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
      this.mediaStream = stream;
      this.mediaRecorder = new MediaRecorder(stream);
      this.mediaRecorder.start();
      this.startRecord();

      if (this.mediaRecorder) {
        this.mediaRecorder.ondataavailable = event => {
          this.audioChunks.push(event.data);
          this.progressRecord();
        };

        this.mediaRecorder.onerror = event => {
          this.deviceError(event.error);
        };

        this.mediaRecorder.onpause = this.pauseRecord;
        this.mediaRecorder.onstop = this.stopRecord;
      }
    });
  },
  beforeDestroy() {
    this.mediaRecorder = null;
    this.mediaStream.getTracks().forEach(track => track.stop());
  },
  methods: {
    startRecord() {
      setInterval(() => {
        if (this.isRecording) {
          this.secondsElapsed += 1;
        }
      }, 1000);
      this.fireStateRecorderChanged('recording');
    },
    pauseRecord() {
      this.fireStateRecorderChanged('stopped');
    },
    stopRecord() {
      const blob = new Blob(this.audioChunks, { type: this.audioMimeType });
      if (this.isChrome) {
        const audioContext = new AudioContext();
        const fileReader = new FileReader();

        fileReader.onloadend = () => {
          const arrayBuffer = fileReader.result;

          audioContext.decodeAudioData(arrayBuffer, audioBuffer => {
            this.audioBufferToWav(audioBuffer);
          });
        };
        fileReader.readAsArrayBuffer(blob);
      } else {
        this.makeFile(blob);
      }
      this.audioChunks = [];
    },
    audioBufferToWav(aBuffer) {
      this.audioWav.numberOfChannels = aBuffer.numberOfChannels;
      this.audioWav.btwLength =
        aBuffer.length * this.audioWav.numberOfChannels * 2 + 44;
      this.audioWav.btwArrBuff = new ArrayBuffer(this.audioWav.btwLength);
      this.audioWav.btwView = new DataView(this.audioWav.btwArrBuff);
      this.audioWav.btwChannels = [];
      this.audioWav.btwOffset = 0;
      this.audioWav.btwPos = 0;

      this.setUint32(0x46464952); // "RIFF"
      this.setUint32(this.audioWav.btwLength - 8); // file length - 8
      this.setUint32(0x45564157); // "WAVE"
      this.setUint32(0x20746d66); // "fmt " chunk
      this.setUint32(16); // length = 16
      this.setUint16(1); // PCM (uncompressed)
      this.setUint16(this.audioWav.numberOfChannels);
      this.setUint32(aBuffer.sampleRate);
      this.setUint32(aBuffer.sampleRate * 2 * this.audioWav.numberOfChannels); // avg. bytes/sec
      this.setUint16(this.audioWav.numberOfChannels * 2); // block-align
      this.setUint16(16); // 16-bit
      this.setUint32(0x61746164); // "data" - chunk
      this.setUint32(this.audioWav.btwLength - this.audioWav.btwPos - 4); // chunk length

      for (
        this.audioWav.btwIndex = 0;
        this.audioWav.btwIndex < aBuffer.numberOfChannels;
        this.audioWav.btwIndex += 1
      ) {
        this.audioWav.btwChannels.push(
          aBuffer.getChannelData(this.audioWav.btwIndex)
        );
      }

      while (this.audioWav.btwPos < this.audioWav.btwLength) {
        for (
          this.audioWav.btwIndex = 0;
          this.audioWav.btwIndex < this.audioWav.numberOfChannels;
          this.audioWav.btwIndex += 1
        ) {
          this.audioWav.btwSample = Math.max(
            -1,
            Math.min(
              1,
              this.audioWav.btwChannels[this.audioWav.btwIndex][
                this.audioWav.btwOffset
                ]
            )
          );
          this.audioWav.btwSample =
            // eslint-disable-next-line no-bitwise
            (0.5 + this.audioWav.btwSample < 0
              ? this.audioWav.btwSample * 32768
              : this.audioWav.btwSample * 32767) | 0; // scale to 16-bit signed int
          this.audioWav.btwView.setInt16(
            this.audioWav.btwPos,
            this.audioWav.btwSample,
            true
          ); // write 16-bit sample
          this.audioWav.btwPos += 2;
        }
        this.audioWav.btwOffset += 1;
      }

      let wavHdr = WavHeader.readHeader(new DataView(this.audioWav.btwArrBuff));

      // Stereo
      let data = new Int16Array(
        this.audioWav.btwArrBuff,
        wavHdr.dataOffset,
        wavHdr.dataLen / 2
      );
      let leftData = [];
      let rightData = [];
      for (let i = 0; i < data.length; i += 2) {
        leftData.push(data[i]);
        rightData.push(data[i + 1]);
      }
      const left = new Int16Array(leftData);
      const right = new Int16Array(rightData);

      if (wavHdr.channels === 2) {
        return this.wavToMp3(wavHdr.channels, wavHdr.sampleRate, left, right);
      }
      // MONO
      return this.wavToMp3(wavHdr.channels, wavHdr.sampleRate, data);
    },
    setUint32(data) {
      this.audioWav.btwView.setUint32(this.audioWav.btwPos, data, true);
      this.audioWav.btwPos += 4;
    },
    setUint16(data) {
      this.audioWav.btwView.setUint16(this.audioWav.btwPos, data, true);
      this.audioWav.btwPos += 2;
    },
    wavToMp3(channels, sampleRate, left, right = null) {
      const buffer = [];
      const mp3Encoder = new Mp3Encoder(channels, sampleRate, 128);

      let remaining = left.length;
      const samplesPerFrame = 1152;

      for (let i = 0; remaining >= samplesPerFrame; i += samplesPerFrame) {
        let mp3Buffer;
        if (!right) {
          let mono = left.subarray(i, i + samplesPerFrame);
          mp3Buffer = mp3Encoder.encodeBuffer(mono);
        } else {
          const leftChunk = left.subarray(i, i + samplesPerFrame);
          const rightChunk = right.subarray(i, i + samplesPerFrame);
          mp3Buffer = mp3Encoder.encodeBuffer(leftChunk, rightChunk);
        }
        if (mp3Buffer.length > 0) {
          buffer.push(new Int8Array(mp3Buffer));
        }
        remaining -= samplesPerFrame;
      }

      const d = mp3Encoder.flush();
      if (d.length > 0) {
        buffer.push(new Int8Array(d));
      }

      const blob = new Blob(buffer, { type: AUDIO_FORMATS.MP3 });
      this.makeFile(blob);
    },
    makeFile(blob) {
      const file = new File(
        [blob],
        `${(Math.random() + 1).toString(36).substring(7)}.mp3`,
        {
          type: AUDIO_FORMATS.MP3,
        }
      );

      this.fireRecorderBlob(file);
    },
    progressRecord() {
      this.fireProgressRecord(this.formatTimeProgress());
    },
    stopAudioRecording() {
      this.mediaRecorder.stop();
    },
    deviceError(error) {
      const deviceErrorName = error?.name;
      if (
        deviceErrorName?.includes('NotSupportedError') ||
        deviceErrorName?.includes('SecurityError')
      ) {
        this.showAlert(
          this.$t('CONVERSATION.REPLYBOX.TIP_AUDIORECORDER_PERMISSION')
        );
        this.fireStateRecorderChanged('notallowederror');
      } else {
        this.showAlert(
          this.$t('CONVERSATION.REPLYBOX.TIP_AUDIORECORDER_ERROR')
        );
      }
    },
    formatTimeProgress() {
      return format(
        addSeconds(
          new Date(this.recordingDateStarted.getTimezoneOffset() * 1000 * 60),
          this.secondsElapsed
        ),
        'mm:ss'
      );
    },
    fireRecorderBlob(blob) {
      this.$emit('finish-record', {
        name: blob.name,
        type: blob.type,
        size: blob.size,
        file: blob,
      });
    },
    fireStateRecorderChanged(state) {
      this.$emit('state-recorder-changed', state);
    },
    fireProgressRecord(duration) {
      this.$emit('state-recorder-progress-changed', duration);
    },
  },
};
</script>

<style lang="scss">
.audio-wave-wrapper {
  display: none;
}

.video-js .vjs-control-bar {
  background-color: transparent;
}
</style>
