<template>
  <section class="contacts-table-wrap">
    <ve-table
      :fixed-header="true"
      max-height="calc(100vh - 11.4rem)"
      :columns="columns"
      :table-data="tableData"
      :border-around="false"
    />

    <empty-state
      v-if="!isLoading && !botsReport"
      title="There are no available records"
    />
    <div v-if="isLoading" class="contacts--loader">
      <spinner />
      <span>{{ $t('FLOW.LOADING') }}</span>
    </div>
  </section>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import { VeTable } from 'vue-easytable';

import Spinner from 'shared/components/Spinner.vue';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import timeMixin from 'dashboard/mixins/time';

export default {
  components: {
    EmptyState,
    Spinner,
    VeTable,
  },
  mixins: [clickaway, timeMixin],
  props: {
    botsReport: {
      type: Object,
      default: () => null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    tableData() {
      if (this.isLoading) {
        return [];
      }
      if (this.botsReport != null && this.botsReport.tableData) {
        let tableData = this.botsReport.tableData;

        if (this.botsReport.grand_total) {
          let summary = {};
          summary.quantity = 'Total';
          summary.total = this.botsReport.grand_total;
          tableData.push(summary);
        }
        return tableData;
      }
      return [];
    },
    columns() {
      return [
        {
          field: 'bot_id',
          key: 'bot_id',
          title: 'Bot ID',
          align: 'left',
        },
        {
          field: 'unit_price',
          key: 'unit_price',
          title: 'Rate (USD)',
          align: 'center',
        },
        {
          field: 'quantity',
          key: 'quantity',
          title: 'Quantity',
          align: 'center',
        },
        {
          field: 'total',
          key: 'total',
          title: 'Total (USD)',
          align: 'center',
        },
      ];
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/mixins';

.contacts-table-wrap {
  flex: 1 1;
  height: 100%;
  overflow: hidden;
}
.ve-table {
  .ve-table-container {
    overflow-y: hidden !important;
  }
}

.contacts-table-wrap::v-deep {
  .ve-table {
    padding-bottom: var(--space-large);
  }
  .row--user-block {
    align-items: center;
    display: flex;
    text-align: left;

    .user-block {
      min-width: 0;
    }

    .user-thumbnail-box {
      margin-right: var(--space-small);
    }

    .user-name {
      font-size: var(--font-size-small);
      font-weight: var(--font-weight-medium);
      margin: 0;
      text-transform: capitalize;
    }

    .view-details--button {
      color: var(--color-body);
    }

    .user-email {
      margin: 0;
    }
  }

  .ve-table-header-th {
    padding: var(--space-small) var(--space-two) !important;
    background-color: var(--w-500) !important;
    color: var(--white) !important;
  }

  .ve-table-body-td {
    padding: var(--space-small) var(--space-two) !important;
  }

  .ve-table-header-th {
    font-size: var(--font-size-mini) !important;
  }
  .ve-table-sort {
    top: -4px;
  }
}

.contacts--loader {
  align-items: center;
  display: flex;
  font-size: var(--font-size-default);
  justify-content: center;
  padding: var(--space-big);
}

.cell--social-profiles {
  a {
    color: var(--s-300);
    display: inline-block;
    font-size: var(--font-size-medium);
    min-width: var(--space-large);
    text-align: center;
  }
}
</style>
