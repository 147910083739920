<template>
  <div class="column content-box page_container">

      <section class="row header_filter">
        <div>
           <input
            v-model="searchText"
            type="text"
            class="search_field font_normal"
            :placeholder="$t('ORDER.LIST_TABLE.SEARCH_PLACEHOLDER')"
            @keyup.enter="searchOrders"
          >
        </div>
        <div>
          <multiselect
                v-model="selectedOrderStatuses"
                :options="OrderStatusList"
                class="small-12 font_normal"
                track-by="id"
                label="title"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :hide-selected="true"
                :placeholder="$t('ORDER.LIST_TABLE.STATUS_SELECT_PLACEHOLDER')"
                selected-label
                :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
                @select="applyFilterByStatuses($event)"
                @remove="applyFilterRemoveStatus($event)"
            />
        </div>
        <div>
          <date-range-picker
            :confirm-text="$t('ORDER.LIST_TABLE.DATE_SELECT_CONFIRM_BTN')"
            :placeholder="$t('ORDER.LIST_TABLE.DATE_SELECT_PLACEHOLDER')"
            class="font_normal"
            :value="dateRange"
            @change="handleDateChange"
            :disabledDate="disableFilterDate"
          />
        </div>
        <div class="">
          <woot-button
            class="margin-right-small clear"
            color-scheme="secondary"
            data-testid="create-new-contact"
            icon="filter"
            @click="onClickSelectColumn"
          >
            {{ $t('CONTACTS_PAGE.SELECT_COLUMNS') }}
          </woot-button>
        </div>
      </section>

      <section>
        <order-table
          :orders="orders"
          :cols="cols"
          :isLoading="uiFlags.isFetching"
          :catalog="catalog"
          :onClickOrder="onClickOrder"
          :onSelectOrderStatus="onOrderStatusChange"
          @on-sort-change="onSortChange"
        />
        <div class="loadMore_btn">
          <woot-button
            variant="clear"
            size="small"
            color-scheme="primary"
            :is-loading="uiFlags.isFetching"
            :is-disabled="paginationCompute.isLastPage"
            @click="onPageChange(Number(meta.currentPage)+1)"
          >
            {{paginationCompute.btnText}}
          </woot-button>
        </div>
      </section>

      <woot-modal :show.sync="showSelectColumnModal" :on-close="onCloseSelectColumn">
        <SelectColumnModal
          :on-close="onCloseSelectColumn"
          :columns="cols"
          :handleColumnVisiblity="toggleColSelect"
        />
      </woot-modal>


  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DateRangePicker from "../../../../../dashboard/components/ui/DateRangePicker.vue"
import OrderTable from './OrderTable.vue';
import SelectColumnModal from './SelectColumnModal.vue';
import OrderAPI from "../../../../api/orders"
import alertMixin from 'shared/mixins/alertMixin';
import {ORDER_STATUSES} from "./order_constant"
import CatalogAPI from "../../../../api/catalogue/catalog";
// import OrderCard from "./OrderCard.vue";
import MerchantAPI from "../../../../api/merchant";


export default {
  components: {
    DateRangePicker,
    OrderTable,
    SelectColumnModal,
    // OrderCard,
  },
  mixins: [alertMixin],
  data() {
      const endDate = new Date(); // Current date
      const startDate = new Date();
      startDate.setMonth(startDate.getMonth() - 1); // One month ago
    return {
      searchText: '',
      selectedOrderStatuses: [],
      OrderStatusList: ORDER_STATUSES,
      dateRange: [startDate,endDate],
      cols: [
        { key: 'order_id', name: 'Order Id', visible: true, type: 'default' },
        { key: 'order_status', name: 'Order Status', visible: true, type: 'default' },
        { key: 'order_total', name: 'Order Total', visible: false, type: 'default' },
        { key: 'grand_total', name: 'Order Amount', visible: true, type: 'default' },
        { key: 'paid_total', name: 'Paid Amount', visible: true, type: 'default' },
        { key: 'refund_amount', name: 'Refunded Amount', visible: true, type: 'default' },
        { key: 'name', name: 'Name', visible: true, type: 'default' },
        { key: 'phone_number', name: 'Phone Number', visible: true, type: 'default' },
        { key: 'created_at', name: 'Created At', visible: true, type: 'default' },

        { key: 'line1', name: 'Line1', visible: true, type: 'default' },
        { key: 'line2', name: 'Line2', visible: false, type: 'default' },
        { key: 'city', name: 'City', visible: true, type: 'default' },
        { key: 'state', name: 'State', visible: false, type: 'default' },
        { key: 'postalCode', name: 'PostalCode', visible: false, type: 'default' },
        { key: 'country', name: 'Country', visible: false, type: 'default' },
      ],
      sortInfo:{},
      // selectedOrder: null,
      showSelectColumnModal: false,
      catalog: {},
      merchant: {},
      // selectedOrderStatusID: 'pending',
      isMerchantLoading:false,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: "orders/getUIFlags",
      meta: "orders/getMeta",
      orders: "orders/getPaginatedOrders",
    }),
    paginationCompute(){
      const totalPages = Math.ceil(this.meta.count / this.meta.pageSize);
      const isLastPage = this.meta.currentPage >= totalPages;
      const currentCount = Math.min(this.meta.currentPage * this.meta.pageSize, this.meta.count);
      // const btnText = `Load More Orders (${currentCount}/${this.meta.count})`;
      const btnText = `${this.$t('ORDER.LIST_TABLE.LOAD_MORE_BTN')} (${currentCount}/${this.meta.count})`;

      return{
        isLastPage,
        btnText,
      }
    },



    // end of computed
  },
  mounted() {
    this.$store.dispatch("orders/getOrders",{
      start_date: this.formatStartEndDate(this.dateRange[0],this.dateRange[1]).start_date,
      end_date: this.formatStartEndDate(this.dateRange[0],this.dateRange[1]).end_date
    });
    this.getCatalog();
    this.getMerchant();
  },

  methods: {
    onPageChange(page,option={}) {
      // console.log("ck",{statusFil:this.selectedOrderStatuses});

      this.$store.dispatch("orders/getPaginatedOrders",{
        currentPage:page,
        pageSize:this.meta.pageSize,
        searchText: this.searchText,
        sortBy: this.sortInfo.sortBy,
        sortOrder: this.sortInfo.sortOrder,
        statuesList: option.statuesList || this.selectedOrderStatuses.map(el=>el.id)
      })
    },
    onSortChange(params){
      const {order_id,...rest} = params;
      const newParams = {...rest,id:order_id}
      let newSort = {
        sortBy: 'id',  // default sort
        sortOrder: 'desc'
      }
      Object.keys(newParams).forEach(key=>{
        if (newParams[key]) {
          newSort.sortBy = key;
          newSort.sortOrder = newParams[key];
        }
      })
      this.sortInfo = newSort;
      this.onPageChange(1)
    },
    searchOrders() {
      this.onPageChange(1)
    },
    filterOrders() {

    },
    disableFilterDate(date,currentValues){
      const today = new Date();
      const threeMonthsAgo = new Date();
      threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3); // 3 months ago

      if (date > today) {
        return true;
      }

      if (date < threeMonthsAgo) {
        return true;
      }
      return false;
    },
    formatStartEndDate(startDate,endDate){
      const providedStartDate = new Date(startDate);
      const providedEndDate = new Date(endDate);
      const option = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }
      const formattedStartDate = providedStartDate.toLocaleDateString('fr-CA', option);
      const formattedEndDate = providedEndDate.toLocaleDateString('fr-CA', option);

      return {
        start_date: formattedStartDate,
        end_date: formattedEndDate,
      };
    },
    applyFilterByStatuses(selectedOption){
      const statuesList = [...this.selectedOrderStatuses,selectedOption].map(el=>el.id);
      this.onPageChange(1,{statuesList})
    },
    applyFilterRemoveStatus(selectedOption){
      const statuesList = this.selectedOrderStatuses
      .filter(el=>el.id !== selectedOption.id)
      .map(el=>el.id);
      this.onPageChange(1,{statuesList})
    },
    async handleDateChange(dateRange) {
      this.dateRange = dateRange

      const date = this.formatStartEndDate(dateRange[0],dateRange[1]);

      await this.$store.dispatch("orders/getOrders",{
        start_date: date.start_date,
        end_date: date.end_date
      });

      this.onPageChange(1)
    },
    onClickOrder(order){
      // console.log({order,existOrder:this.selectedOrder});
     this.$router.push({name:"catalogue_order_details",params:{order_id:order.id}})

    },
    onClickSelectColumn(){
      this.showSelectColumnModal = true
    },
    onCloseSelectColumn(){
      this.showSelectColumnModal = false
    },
    toggleColSelect(col,index){
      // console.log({col,index});
    },
    async onOrderStatusChange(order,order_status){
      // console.log({order,order_status});
      try {
        const payload = {order_status}
        // const {data} = await OrderAPI.updateOrderById(order.id,payload);
        // dispatch update order
        await this.$store.dispatch("orders/UpdateOrderById",{order_id:order.id,payload});

        this.showAlert(this.$t('ORDER.LIST_TABLE.STATUS_UPDATE.SUCCESS_MSG'));
      } catch (error) {
        console.log(error);
        this.showAlert(this.$t('ORDER.LIST_TABLE.STATUS_UPDATE.FAIL_MSG'));
      }
    },
    async getCatalog(){
      try {
        const {data:catalog} = await CatalogAPI.getDefaultCatalog();
        this.catalog = catalog;
      } catch (error) {
        console.log(error);
      }
    },

    async getMerchant(){
      try {
        this.isMerchantLoading = true;
        const {data} = await MerchantAPI.getMerchantByName();
        this.merchant = data;
      } catch (error) {

      }
      this.isMerchantLoading = false
    },
    // end methods
  },
}
</script>

<style scoped lang="scss">
@import '~widget/assets/scss/variables.scss';

.page_container{
  min-height: calc(100vh - 70px);
}

.header_filter div:nth-child(1){
  flex-grow: 1;

  .search_field{
    height: 4.4rem;
  }
}
.loadMore_btn button{
  display: block;
  margin: auto;
}
.font_normal{
  font-size: 14px;
}

// order redesign
.statuses{
  display: flex;
  li{
    font-size: var(--font-size-small);
    // padding: var(--space-small);
    border-radius: var(--border-radius-normal);

    button{
      color: var(--s-600);
      font-weight: 500;
    }

    &:hover{
      background: var(--w-25);
      cursor: pointer;
      button{
        color: var(--w-500);
      }
      border-color: var(--w-25);
    }
  }
  .active{
    background: var(--w-25);
    button{
      color: var(--w-500);
    }
  }
}
/*
.orders{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  gap: 1rem;
  list-style: none;
  @media screen and (max-width:768px) {
    grid-template-columns: repeat(1);
  }
  .text-right{
    text-align: right;
  }
}
*/
</style>

<style>
.font_normal .multiselect__tags .multiselect__single, .font_normal input{
  font-size: 14px;
}
</style>