<template>
  <div class="column">
    <woot-modal-header
      v-if="this.registrationFee"
      :header-title="$t('TOP_UP.MODAL.UPDATETITLE')"
      :header-content="$t('TOP_UP.MODAL.UPDATECONTENT')"
    />
    <woot-modal-header
      v-else
      :header-title="$t('TOP_UP.MODAL.TITLE')"
      :header-content="$t('TOP_UP.MODAL.CONTENT')"
    />
    <div class="row modal-content">
      <top-up-amount-container
        v-if="this.modalDataChange"
        :modal-value="this.modalValue"
        :usd-value="this.usdAmount"
        :registration-fee="this.registrationFee"
        :user-balance="userBalance"
        :product-id="this.productId"
        @item-click="submitModal"
      />
      <stripe-card-component
        :modal-data-change="this.modalDataChange"
        :payment-intent-id="this.paymentIntentId"
        :elements="this.elements"
        :client-secret="this.clientSecretId"
        :base-amount="this.baseAmount"
        :total-amount="this.totalAmount"
        :percentage-amount="this.percentageAmount"
        :currency="this.currency"
      />
    </div>
  </div>
</template>

<script>
import TopUpAmountContainer from './TopUpAmountComponent';
import StripeAPI from '../../api/stripe';
import StripeCardComponent from './StripeCardComponent';
import alertMixin from '../../../shared/mixins/alertMixin';

export default {
  name: 'AddTopUpContainer',
  components: { StripeCardComponent, TopUpAmountContainer },
  mixins: [alertMixin],
  props: {
    modalValue: {
      type: Boolean,
      required: true,
    },
    registrationFee: {
      type: Boolean,
      required: false,
    },
    userBalance: {
      type: Number,
      required: false,
    },
    productId: {
      type: String,
      default: '',
      required: false,
    },
    oldPlanId: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      modalDataChange: true,
      elements: null,
      clientSecretId: '',
      paymentIntentId: '',
      totalAmount: '',
      baseAmount: '',
      percentageAmount: '',
      currency: '',
      usdAmount: 1,
    };
  },
  async mounted() {
    await StripeAPI.InitializeStripeCredentials();
    this.usdAmount = await this.$store.dispatch('accounts/fetchUsdPrice');
  },
  methods: {
    async submitModal(total, currency, topUpAmount) {
      this.baseAmount = total;
      this.currency = currency;
      let taxResponse = await StripeAPI.fetchTaxAmount(currency);
      if (taxResponse.status === 200) {
        const totalAmount = this.calculateTaxableAmount(
          taxResponse.tax_percentage,
          total
        );
        let response = await StripeAPI.createPaymentIntent(
          totalAmount.toString(),
          currency,
          topUpAmount,
          this.productId
        );
        this.totalAmount = totalAmount;
        if (response.status === 200) {
          this.paymentIntentId = response.payment_intent_id;
          this.createStripeElement(response.client_secret);
          window.dataLayer.push({
            event: 'ps_topup_successful',
            ps_topup_amount: topUpAmount || '',
          });
          console.log('dataLayer updated', window.dataLayer);
        } else {
          this.showAlert(response.message);
          window.dataLayer.push({
            event: 'ps_topup_attempt',
            ps_topup_amount: topUpAmount || '',
          });
          console.log('dataLayer updated', window.dataLayer);
        }
      } else {
        this.showAlert('Not able to process you request at this moment.');
      }
    },
    createStripeElement(clientSecretId) {
      const appearance = {
        theme: 'stripe',
      };
      const elements = window.stripe.elements({
        appearance: appearance,
        clientSecret: clientSecretId,
      });
      this.elements = elements;
      this.clientSecretId = clientSecretId;
      const paymentElement = elements.create('payment');
      paymentElement.mount('#stripe-payment');
      setTimeout(() => {
        this.modalDataChange = false;
      }, 1000);
    },
    calculateTaxableAmount(taxPercentage, total) {
      let amount = (total * taxPercentage) / 100;
      this.percentageAmount = amount;
      let totalAmount = parseFloat(total) + parseFloat(amount);
      return Math.round(totalAmount);
    },
  },
};
</script>
<style>
.flex-container {
  display: flex;
  margin-bottom: 10px;
}
.flex-child {
  flex: 1;
}
</style>
