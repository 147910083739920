/* global axios */
import ApiClient from './ApiClient';

class MerchantAPI extends ApiClient {
  constructor() {
    super('merchant', { accountScoped: true });
  }

 // get all orders item orders  
  getMerchantByName(merchantName) {
    return axios.get(`${this.url}/?name=${merchantName}`);
  }
  
  // update order info by order id 

}

export default new MerchantAPI();
