<template>
  <loading-state :message="$t('CONFIRM_EMAIL')"></loading-state>
</template>
<script>
import LoadingState from '../../components/widgets/LoadingState';
import Auth from '../../api/auth';
import { DEFAULT_REDIRECT_URL } from '../../constants';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    LoadingState,
  },
  mixins: [alertMixin],
  props: {
    confirmationToken: {
      type: String,
      default: '',
    },
  },
  mounted() {
    this.confirmToken();
  },
  methods: {
    async confirmToken() {
      try {
        const {data:resPassVerify} = await Auth.verifyPasswordToken({
          confirmationToken: this.confirmationToken,
        });
        if (resPassVerify?.data?.onelink) {
          window.location = resPassVerify?.data?.onelink;
          return
        }
        window.location = DEFAULT_REDIRECT_URL;
      } catch (error) {
        if (error?.data?.message) {
          this.showAlert(error.data.message);
        }
        window.location = DEFAULT_REDIRECT_URL;
      }
    },
  },
};
</script>
