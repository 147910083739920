<template>
  <div class="grant-list-container row">
    <div class="medium-12">
      <grant-header
        header-title="Grant List"
        :search-query="searchQuery"
        :on-input-search="onInputSearch"
        :on-search-submit="onSearchSubmit"
      />
        
      <div>
        <grant-table
          :grants="records"
          :show-search-empty-state="showEmptySearchResult"
          :is-loading="uiFlags.isFetching"
          :sortConfig="sortConfig"
          :cols="displayColumns"
        />
<!-- 
          <table-footer
          :on-page-change="onPageChange"
          :current-page="Number(meta.currentPage)"
          :total-count="meta.count"
        />
-->
      </div> 
      

    </div>
  </div>
</template>

<script>
import GrantHeader from "./GrantHeader.vue";
import GrantTable from "./GrantTable.vue";
import GrantAPI from "../../../../api/grants";

export default {
    components: {
        GrantHeader,
        GrantTable,
    },
    data(){
        return{
            searchQuery: '',
            sortConfig: { name: 'asc' },
            cols: [
                { key: 'email', name: 'Email', visible: true, type: 'default' },
                { key: 'balance_credit', name: 'Balance', visible: true, type: 'default' },
            ],

            // move this to computed mapGetters to get data from vueX
            records: [],
            uiFlags:{
                isFetching: false,
            },
        }
    },
    computed:{
        showEmptySearchResult() {
            const hasEmptyResults = !!this.searchQuery && this.records.length === 0;
            return hasEmptyResults;
        },
        displayColumns() {
            return this.cols;
        },
    },
    mounted(){},
    methods: {
        onInputSearch(event) {
        const newQuery = event.target.value;
        const refetchAllGrantList = !!this.searchQuery && newQuery === '';
        this.searchQuery = newQuery;
        if (refetchAllGrantList) {
          // this.fetchGrantList(DEFAULT_PAGE);
        }
      },
      async onSearchSubmit() {
        console.log(this.searchQuery);
        try {
          this.uiFlags.isFetching = true;
          if (this.searchQuery) {
              console.log("search the grrant now = ",this.searchQuery);
              const {data:searcResult} = await GrantAPI.grantListByPhone(this.searchQuery);
              console.log({grantListByphone:searcResult});
              if (Array.isArray(searcResult)) {
                this.records = searcResult;
              }else{
                this.records = [];
              }
          }
          
          this.uiFlags.isFetching = false;
        } catch (error) {
          console.log(error);
          this.uiFlags.isFetching = false;
        }
      },
    



    },
}
</script>

<style lang="scss" scoped> 
.grant-list-container {
  width: 100%;
}

</style>