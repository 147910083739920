<template>
  <modal :show.sync="show" :on-close="onClose">
    <div class="column content-box">
      <woot-modal-header
        :header-title="$t('TEMPLATE_MSG.ADD.TITLE')"
        :header-content="$t('TEMPLATE_MSG.ADD.DESC')"
      />
      <form ref="form" class="row" @submit.prevent="addTemplateMessage()">
        <div class="medium-12 columns">
          <label :class="{ error: $v.templateName.$error }">
            {{ $t('TEMPLATE_MSG.ADD.FORM.TEMPLATE_NAME.LABEL') }}
            <input
              v-model.trim="templateName"
              type="text"
              :placeholder="
                $t('TEMPLATE_MSG.ADD.FORM.TEMPLATE_NAME.PLACEHOLDER')
              "
              @input="$v.templateName.$touch"
            />
          </label>
        </div>
        <div class="medium-12 columns display-inline">
          <div class="medium-4 columns">
            <formulate-input
              v-model="inbox"
              :options="this.inboxList()"
              type="select"
              name="inbox"
              :placeholder="$t('TEMPLATE_MSG.ADD.FORM.INBOX.LABEL')"
              :label="$t('TEMPLATE_MSG.ADD.FORM.INBOX.PLACEHOLDER')"
              validation="required"
              validation-name="Inbox"
            />
          </div>
          <div class="medium-4 columns">
            <formulate-input
              v-model="language"
              :options="this.languages()"
              type="select"
              name="language"
              :placeholder="$t('TEMPLATE_MSG.ADD.FORM.LANGUAGE.LABEL')"
              :label="$t('TEMPLATE_MSG.ADD.FORM.LANGUAGE.PLACEHOLDER')"
              validation="required"
              validation-name="Language"
            />
          </div>
          <div class="medium-4 columns">
            <formulate-input
              v-model="templateType"
              :options="this.templateTypes()"
              type="select"
              name="template-type"
              :placeholder="$t('TEMPLATE_MSG.ADD.FORM.TYPE.LABEL')"
              :label="$t('TEMPLATE_MSG.ADD.FORM.TYPE.PLACEHOLDER')"
              validation="required"
              validation-name="Template Type"
            />
          </div>
        </div>
        <div class="medium-12 columns">
          <label :class="{ error: $v.content.$error }">
            {{ $t('TEMPLATE_MSG.ADD.FORM.CONTENT.LABEL') }}
            <textarea
              v-model.trim="content"
              rows="5"
              type="text"
              :placeholder="$t('TEMPLATE_MSG.ADD.FORM.CONTENT.PLACEHOLDER')"
              @input="$v.content.$touch"
            />
          </label>
        </div>
        <div class="medium-12 columns display-inline">
          <label>
            <span>{{ $t('TEMPLATE_MSG.ADD.FORM.IMAGE.LABEL') }}</span>
            <input
              id="file"
              ref="file"
              type="file"
              accept="image/*, .mp4, .pdf"
              @change="handleImageUpload"
            />
          </label>
        </div>
        <div class="medium-12 columns">
          <label>
            <span>{{ $t('TEMPLATE_MSG.ADD.FORM.BUTTON.LABEL') }}</span>
            <multiselect
              v-model="buttonsData"
              class="small-12"
              :tag-placeholder="$t('TEMPLATE_MSG.ADD.FORM.BUTTON.LABEL')"
              :placeholder="$t('TEMPLATE_MSG.ADD.FORM.BUTTON.PLACEHOLDER')"
              label="name"
              :max="3"
              track-by="code"
              :options="options"
              :multiple="true"
              :taggable="true"
              @tag="addButton"
            />
          </label>
        </div>
        <div class="modal-footer">
          <div class="medium-12 columns">
            <woot-submit-button
              :disabled="showLoading"
              :button-text="$t('TEMPLATE_MSG.ADD.FORM.SUBMIT')"
              :loading="showLoading"
            />
            <button class="button clear" @click.prevent="onClose">
              {{ $t('TEMPLATE_MSG.ADD.CANCEL_BUTTON_TEXT') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';

import WootSubmitButton from '../../../../components/buttons/FormSubmitButton';
import Modal from '../../../../components/Modal';
import alertMixin from 'shared/mixins/alertMixin';
import { mapGetters } from 'vuex';
import languages from './templateLanguages';
import types from './templateTypes';

export default {
  components: {
    WootSubmitButton,
    Modal,
  },
  mixins: [alertMixin],
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      templateName: '',
      content: '',
      showLoading: false,
      file: '',
      inbox: '',
      show: true,
      buttonsData: [],
      options: [],
      templateType: '',
      language: '',
    };
  },
  validations: {
    templateName: {
      required,
      minLength: minLength(2),
    },
    language: {
      required,
    },
    content: {
      required,
    },
    templateType: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      inboxes: 'inboxes/getInboxes',
    }),
  },
  methods: {
    inboxList() {
      return this.inboxes
        .filter(inbox => {
          return inbox.channel_type === 'Channel::Whatsapp';
        })
        .map(inbox => ({ label: inbox.name, value: inbox.id }));
    },
    templateTypes() {
      return types;
    },
    languages() {
      return languages;
    },
    resetForm() {
      this.$refs.form.reset();
    },
    async addTemplateMessage() {
      let data = {
        short_code: this.templateName,
        content: this.content,
        inbox_id: this.inbox,
        file: this.file,
        buttonsData: this.buttonsData,
        language: this.language,
        templateType: this.templateType,
      };
      this.showLoading = true;
      let formData = new FormData();
      Object.entries(data).forEach(([key, value]) => {
        if (key === 'buttonsData') {
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, value);
        }
      });
      await this.$store
        .dispatch('accounts/createTemplateMessage', formData)
        .then(() => {
          this.onClose();
          this.showLoading = false;
          this.showAlert(this.$t('TEMPLATE_MSG.ADD.API.SUCCESS_MESSAGE'));
          this.resetForm();
        })
        .catch(error => {
          this.showLoading = false;
          this.showAlert(error.response.data.message);
        });
    },
    handleImageUpload(event) {
      this.file = event.target.files[0];

      if (this.file.size > 1024 * 1024 * 40) {
        alert('File is too big. Please select file of size less then 40mb.');
        this.$refs.file.value = null;
        this.file = '';
      }
    },
    addButton(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.options.push(tag);
      this.buttonsData.push(tag);
    },
  },
};
</script>
<style>
.display-inline {
  display: inline-flex;
  justify-content: space-between;
}
</style>
