import { frontendURL } from '../../../../helper/URLHelper';

const primaryMenuItems = accountId => [
  {
    icon: { name: 'chats-circle.svg', isIcon: false },
    key: 'conversations',
    label: 'CONVERSATIONS',
    toState: frontendURL(`accounts/${accountId}/dashboard`),
    toStateName: 'home',
    roles: ['administrator', 'agent'],
  },
  {
    icon: { name: 'agents.svg', isIcon: false },
    key: 'contacts',
    label: 'CONTACTS',
    toState: frontendURL(`accounts/${accountId}/contacts`),
    toStateName: 'contacts_dashboard',
    roles: ['administrator'],
  },
  {
    icon: { name: 'dashboard.svg', isIcon: false },
    key: 'reports',
    label: 'REPORTS',
    toState: frontendURL(`accounts/${accountId}/reports`),
    toStateName: 'settings_account_reports',
    roles: ['administrator'],
  },
  {
    icon: { name: 'rule.svg', isIcon: false },
    key: 'campaigns',
    label: 'CAMPAIGNS',
    toState: frontendURL(`accounts/${accountId}/campaigns`),
    toStateName: 'settings_account_campaigns',
    roles: ['administrator'],
  },
  {
    icon: { name: 'configuration.svg', isIcon: false },
    key: 'settings',
    label: 'SETTINGS',
    toState: frontendURL(`accounts/${accountId}/settings`),
    toStateName: 'settings_home',
    roles: ['administrator', 'agent'],
  },
  {
    icon: { name: 'question-mark.svg', isIcon: false },
    key: 'docs',
    label: 'DOCUMENTS',
    toState: `https://peasy.ai/docs/peasysales`,
    toStateName: 'documentation',
    roles: ['administrator', 'agent'],
  },
  {
    icon: { name: 'onboarding.svg', isIcon: false },
    key: 'onboarding-session',
    label: 'ONBOARDING',
    // label: 'SESSION',
    // toState: frontendURL(`accounts/${accountId}/session`),
    toState: frontendURL(`accounts/${accountId}/session/schedule-session`),
    toStateName: 'boarding_session',
    roles: ['administrator'],
  },
  {
    icon: { name: 'creditcard-toolbox.svg', isIcon: false },
    key: 'credit_balance',
    label: 'CREDIT BALANCE',
    toState: frontendURL(`accounts/${accountId}/credit/credit_balance`),
    toStateName: 'credit_home',
    roles: ['administrator',],
  },
  {
    icon: { name: 'task-list-square-right.svg', isIcon: false },
    key: 'catalogues',
    label: 'CATALOG',
    toState: frontendURL(`accounts/${accountId}/catalog/list`),
    toStateName: 'catalogues_state',
    roles: ['administrator',],
  },
  {
    icon: { name: 'pm-icon.png', isIcon: false },
    key: 'external-link',
    label: 'Peasy Marketing',
    toState: 'pm-external',
    toStateName: 'external_link',
    roles: ['administrator'],
  },
];

export default primaryMenuItems;
