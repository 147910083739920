import { frontendURL } from '../../../helper/URLHelper';
import CreditWrapper from './WrapperCredit.vue';
import CreditHome from './CreditHome.vue';
import GrantList from './GrantList/GrantList.vue';

/*
  1) go to "config/sidebaritems/fileNameRoutes.js" and add the route in "menuItems"
  2) now add the Component will be render for the added router, Add it is here with child
   3) add this catelogue.routes.js file to '/dashboard/dashboard.routes.js' file to active this route
*/

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/credit'),
      name: 'credit_management_home',          // must match with 'toStateName'
      roles: ['administrator', ],
      component: CreditWrapper,
      props: () => {
        return { 
          headerTitle: 'Credit Manage',
          icon: 'video-call',
          showBackButton: false,
         };
      },
      children: [
        {
          path: 'credit_balance',
          name: 'credit_management', // must match this name in credit_balance.js file in both "routes array" and "menuItems array"
          component: CreditHome,
          roles: ['administrator'],
        },
        {
          path: 'grant_list',
          name: 'grant_list_management', 
          component: GrantList,
          roles: ['administrator'],
        },
      ],
      
    },
  ],
};
