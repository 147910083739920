<template>
  <section class="contacts-table-wrap">
    <ve-table
      :fixed-header="true"
      max-height="calc(100vh - 11.4rem)"
      scroll-width="calc(100vw - 30rem)"
      :columns="columns"
      :table-data="tableData"
      :border-around="false"
      :sort-option="sortOption"
    />

    <empty-state
      v-if="showSearchEmptyState"
      :title="$t('CONTACTS_PAGE.LIST.404')"
    />
    <empty-state
      v-else-if="!isLoading && !grants.length"
      :title="$t('CONTACTS_PAGE.LIST.NO_CONTACTS')"
    />
    <div v-if="isLoading" class="contacts--loader">
      <spinner />
      <span>{{ $t('CONTACTS_PAGE.LIST.LOADING_MESSAGE') }}</span>
    </div>
  </section>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import { VeTable } from 'vue-easytable';
import Spinner from 'shared/components/Spinner.vue';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import timeMixin from 'dashboard/mixins/time';

export default {
    components: {
        EmptyState,
        Spinner,
        VeTable,
    },
    mixins: [clickaway, timeMixin],
    props: {
        grants: {
            type: Array,
            default: () => [],
        },
        showSearchEmptyState: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        sortConfig: {
            type: Object,
            default: {email:""},
        },
        sortOrder: {
            type: String,
            default: 'asc',
        },
        cols: {
            type: Array,
            default: () => [],
        },
    },
    data(){
        return{}
    },
    computed: {
        tableData() {
            if (this.isLoading) {
                return [];
            }
            // console.log('credit table data', this.contacts, this.cols);
            return this.grants.map(item => {
                let preSet = {
                    ...item,
                    email: item.email || '---',
                    balance_credit: item.balance_credit || 0,
                };
                return preSet;
            });
        },
        columns(){
            let matches = {};

            matches.email = {
                field: 'email',
                key: 'email',
                title: this.$t('CONTACTS_PAGE.LIST.TABLE_HEADER.EMAIL_ADDRESS'),
                align: 'left',
                sortBy: this.sortConfig?.email || '',
                width: 240,
                renderBodyCell: ({ row }) => {
                if (row.email)
                    return (
                    <div class="text-truncate">
                        <a
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                        href={`mailto:${row.email}`}
                        >
                        {row.email}
                        </a>
                    </div>
                    );
                return '---';
                },
            };

            matches.balance_credit = {
                field: 'balance_credit',
                key: 'balance_credit',
                sortBy: this.sortConfig?.balance || '',
                title: "Balance",
                align: 'left',
                width: 240,
                renderBodyCell: ({ row }) => {
                if (!isNaN(row.balance_credit))
                    return (
                        <div class="text-truncate">
                            <p>{row.balance_credit}</p>
                        </div>
                    );
                return '---';
                },
            };

            let preSet = [];

            this.cols.forEach(col => {
                if (col.visible) {
                    if (col.key in matches) {
                        preSet.push(matches[col.key]);
                    } 
                }
            });
            
            return preSet;
        },

    },
    // watch: {},
    // mounted: {},
    // methods: {},

}
</script>



<style lang="scss" scoped>
@import '~dashboard/assets/scss/mixins';

.contacts-table-wrap {
  flex: 1 1;
  height: 100%;
  overflow: hidden;
}

.contacts-table-wrap::v-deep {
  .ve-table {
    padding-bottom: var(--space-large);
  }
  .row--user-block {
    align-items: center;
    display: flex;
    text-align: left;

    .user-block {
      min-width: 0;
    }

    .user-thumbnail-box {
      margin-right: var(--space-small);
    }

    .user-name {
      font-size: var(--font-size-small);
      font-weight: var(--font-weight-medium);
      margin: 0;
      text-transform: capitalize;
    }

    .view-details--button {
      color: var(--color-body);
    }

    .user-email {
      margin: 0;
    }
  }

  .ve-table-header-th {
    padding: var(--space-small) var(--space-two) !important;
    background-color: var(--w-500) !important;
    color: var(--white) !important;
  }

  .ve-table-body-td {
    padding: var(--space-small) var(--space-two) !important;
  }

  .ve-table-header-th {
    font-size: var(--font-size-mini) !important;
  }
  .ve-table-sort {
    top: -4px;
  }
}

.contacts--loader {
  align-items: center;
  display: flex;
  font-size: var(--font-size-default);
  justify-content: center;
  padding: var(--space-big);
}

.cell--social-profiles {
  a {
    color: var(--s-300);
    display: inline-block;
    font-size: var(--font-size-medium);
    min-width: var(--space-large);
    text-align: center;
  }
}
</style>
