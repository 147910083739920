var CryptoJS = require('crypto-js');
var API_KEY = 'z67XchrN68JW';
var globalUserId = null;
// var baseUrl = 'http://devchat.peasy.ai:8000';
var baseUrl = 'https://flowbot.peasy.ai';
// var baseUrl = 'http://localhost:8000';

const { fetch: originalFetch } = window;

// Intercept

window.fetch = async (...args) => {
  let [resource, config] = args;

  try {
    let url = new URL(resource);

    if (url.origin !== baseUrl) {
      const response = await originalFetch(resource, config);

      return response;
    }
  } catch (error) {
    const response = await originalFetch(resource, config);

    return response;
  }

  let myHeaders;
  let toSend = {};
  if (config?.headers) {
    myHeaders = config.headers;
  } else {
    myHeaders = new Headers();
  }

  if (globalUserId) {
    toSend.userid = globalUserId;
  } else {
    toSend.userid = 'UNKOWN';
  }
  // console.log('CryptoJS', CryptoJS);
  let encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(toSend),
    API_KEY
  ).toString();
  myHeaders.append('Authorization', encrypted);

  config.headers = myHeaders;

  const response = await originalFetch(resource, config);

  if (!response.ok) {
    throw new Error(`${response.statusText}`);
  }
  return response;
};

class FlowApi {
  constructor() {
    this.baseUrl = baseUrl + '/';
    this.staticUrl = this.baseUrl + 'static/';
  }

  getImgUrl(imageName, botId) {
    return this.staticUrl + botId + '/' + imageName;
  }

  async copyUpload(botId, url) {
    let response = {};

    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

    let urlencoded = new URLSearchParams();
    urlencoded.append('url', url);
    urlencoded.append('botid', botId);

    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow',
      mode: 'cors',
    };

    await fetch(this.baseUrl + 'copy-upload', requestOptions)
      .then(res => res.text())
      .then(uploadRes => {
        let parsed = JSON.parse(uploadRes);

        if (parsed.status === 'ok') {
          response.status = 'ok';
          response.data = parsed;
        } else {
          response.status = 'error';
          response.message = parsed.message;
        }
      })
      .catch(error => {
        response.status = 'error';
        response.message = error;
      });

    return response;
  }

  async getFlowById(flowid, userId) {
    globalUserId = userId;
    let requestOptions = {
      method: 'GET',
      redirect: 'follow',
      mode: 'cors',
    };
    let response = {};

    let q = this.baseUrl + 'get-bot?botid=' + flowid;

    if (userId) {
      q = q + '&userId=' + userId;
    }

    await fetch(q, requestOptions)
      .then(res => res.text())
      .then(result => {
        result = JSON.parse(result);

        response.status = 'ok';
        response.data = result;
      })
      .catch(error => {
        response.status = 'error';
        response.message = error;
      });

    return response;
  }

  async uploadFile(botId, file) {
    let response = {};

    var formdata = new FormData();
    formdata.append('botid', botId);
    formdata.append('file', file, file.name);
    let requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
      mode: 'cors',
    };

    await fetch(this.baseUrl + 'upload', requestOptions)
      .then(res => res.text())
      .then(uploadRes => {
        let parsed = JSON.parse(uploadRes);

        response.status = 'ok';
        response.data = parsed;
      })
      .catch(error => {
        response.status = 'error';
        response.message = error;
      });

    return response;
  }

  async exportFlow(botId, flowData, template = null) {
    let response = {};

    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

    let urlencoded = new URLSearchParams();
    urlencoded.append('botid', botId);

    if (template === null) {
      urlencoded.append('flowdata', JSON.stringify(flowData));
    } else {
      urlencoded.append('template', template);
    }

    let requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow',
      mode: 'cors',
    };

    await fetch(this.baseUrl + 'update-bot', requestOptions)
      .then(res => res.text())
      .then(result => {
        response.status = 'ok';
        response.data = result;
      })
      .catch(error => {
        response.status = 'error';
        response.message = error;
      });

    return response;
  }

  async createFlow(userId, name, template, accountId) {
    globalUserId = userId;

    let myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

    let urlencoded = new URLSearchParams();
    urlencoded.append('userid', userId);
    urlencoded.append('name', name);
    urlencoded.append('template', template.id);
    urlencoded.append('accountid', accountId);

    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow',
      mode: 'cors',
    };

    let response = {};

    await fetch(this.baseUrl + 'create-bot', requestOptions)
      .then(res => {
        if (res.ok) {
          res.text();
        } else {
          throw Error('Error');
        }
      })
      .then(result => {
        response.status = 'ok';
        response.data = result;
      })
      .catch(error => {
        response.status = 'error';
        response.message = error;
      });

    return response;
  }

  async deleteFlow(botid) {
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

    let urlencoded = new URLSearchParams();
    urlencoded.append('botid', botid);

    let requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow',
      mode: 'cors',
    };

    let response = {};
    await fetch(this.baseUrl + 'delete-bot', requestOptions)
      .then(res => res.text())
      .then(result => {
        result = JSON.parse(result);
        response.status = 'ok';
        response.data = result;
      })
      .catch(error => {
        response.status = 'error';
        response.message = error;
      });

    return response;
  }

  async getTemplates() {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      mode: 'cors',
    };

    let response = {};
    await fetch(this.baseUrl + 'get-templates', requestOptions)
      .then(res => res.text())
      .then(result => {
        response = JSON.parse(result);

        response.status = 'ok';
        response.data = response;
      })
      .catch(error => {
        response.status = 'error';
        response.message = error;
      });

    return response;
  }

  async getBotsByUserId(userid, mSort, accountid) {
    globalUserId = userid;

    let requestOptions = {
      method: 'GET',
      redirect: 'follow',
      mode: 'cors',
    };

    let response = {};
    await fetch(
      `${this.baseUrl}get-bots?userid=${userid}&accountid=${accountid}&mSort=${mSort}`,
      requestOptions
    )
      .then(res => res.text())
      .then(result => {
        response = JSON.parse(result);

        response.status = 'ok';
        response.data = response;
      })
      .catch(error => {
        response.status = 'error';
        response.message = error;
      });

    return response;
  }

  async getBotsUsage(userid, mSort, accountid, from, to) {
    globalUserId = userid;

    let requestOptions = {
      method: 'GET',
      redirect: 'follow',
      mode: 'cors',
    };

    let response = {};
    await fetch(
      `${this.baseUrl}bots-usage?userid=${userid}&accountid=${accountid}&mSort=${mSort}&from=${from}&to=${to}`,
      requestOptions
    )
      .then(res => res.text())
      .then(result => {
        result = JSON.parse(result);
        response.status = 'ok';

        response.data = result;
      })
      .catch(error => {
        response.status = 'error';
        response.message = error;
      });

    return response;
  }

  async getIntegratedBrandProfiles(accountid) {
    let requestOptions = {
      method: 'GET',
      redirect: 'follow',
      mode: 'cors',
    };

    let response = {};
    await fetch(
      `${this.baseUrl}get-integrated-brand-profiles?accountid=${accountid}`,
      requestOptions
    )
      .then(res => res.text())
      .then(result => {
        result = JSON.parse(result);
        response.status = 'ok';
        response.data = result;
      })
      .catch(error => {
        response.status = 'error';
        response.message = error;
      });

    return response;
  }
}

export default new FlowApi();
