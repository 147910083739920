/* global axios */

import ApiClient from '../ApiClient';

class CatalogApi extends ApiClient {
    constructor() {
        super('catalog', { accountScoped: true });
    }

    //   get account's default catalog 
    getDefaultCatalog() {
        return axios.get(`${this.url}`);
    }
    // get a specific catalog 
    getCatalogById(catalogId) {
        return axios.get(`${this.url}`, {
        params: { catalog_id: catalogId },
        });
    }

    // update a specific catalog 
    updateCatalogById(catalogId,payload) {
        return axios.patch(`${this.url}/${catalogId}`,payload);
    }
  
  



}

export default new CatalogApi();
