<template>
  <div class="filter">
    <div style="display:flex;align-items: center;align-content: center;">
      <select v-model="content.type" class="filter__question">
        <option v-for="item in conditionTypes" :key="item.id" :value="item.id">
          {{ item.name }}
        </option>
      </select>

      <input
        v-if="content.type == 'condition-event_params'"
        v-model="content.paramName"
        class="answer--text-input"
        type="text"
        style="margin:0;margin-right: 5px;"
        :placeholder="$t('FLOW.PLACEHOLDERS.PARAM_NAME')"
      />

      <input
        v-if="content.type == 'condition-api-response'"
        v-model="content.propertyName"
        class="answer--text-input"
        type="text"
        style="margin:0;margin-right: 5px;"
        :placeholder="$t('FLOW.PLACEHOLDERS.PROPERTY_NAME')"
      />

      <select
        v-if="content.type == 'condition-contact'"
        v-model="content.attrType"
        class="filter__question"
      >
        <option v-for="item in attrTypes" :key="item.id" :value="item">
          {{ item.name }}
        </option>
      </select>

      <select
        v-if="
          content.type == 'condition-contact' &&
            content.attrType.id == 'standard' &&
            content.operator.id != 'op-has-property'
        "
        v-model="content.attr"
        class="filter__question"
      >
        <option v-for="item in contactAttributes" :key="item.id" :value="item">
          {{ item.name }}
        </option>
      </select>

      <input
        v-if="
          content.type == 'condition-contact' &&
            content.attrType.id == 'custom' &&
            content.operator.id != 'op-has-property'
        "
        v-model="content.customAttr"
        class="answer--text-input"
        type="text"
        style="margin:0;margin-right: 5px;"
        :placeholder="$t('FLOW.PLACEHOLDERS.CUSTOM_ATTRIBUTE')"
      />

      <input
        v-if="content.type == 'condition-variable'"
        v-model="content.name"
        class="answer--text-input"
        type="text"
        style="margin:0;margin-right: 5px;"
        placeholder="Variable Name"
      />
      <select
        v-if="content.type != 'condition-raw'"
        v-model="content.operator"
        class="filter__question"
      >
        <option
          v-for="item in responseOperatorOptions"
          :key="item.id"
          :value="item"
        >
          {{ item.name }}
        </option>
      </select>
      <select
        v-if="
          content.type == 'condition-contact' &&
            content.attrType.id == 'standard' &&
            content.operator.id == 'op-has-property'
        "
        v-model="content.attr"
        class="filter__question"
      >
        <option v-for="item in contactAttributes" :key="item.id" :value="item">
          {{ item.name }}
        </option>
      </select>
      <input
        v-if="
          content.type == 'condition-contact' &&
            content.attrType.id == 'custom' &&
            content.operator.id == 'op-has-property'
        "
        v-model="content.customAttr"
        class="answer--text-input"
        type="text"
        style="margin:0;margin-right: 5px;"
        :placeholder="$t('FLOW.PLACEHOLDERS.CUSTOM_ATTRIBUTE')"
      />
      <!-- 
      <select
        v-else-if="
          content.type == 'condition-contact' &&
            content.attrType.id == 'standard'
        "
        v-model="content.operator"
        class="filter__question"
      >
        <option
          v-for="item in responseOperatorOptions"
          :key="item.id"
          :value="item"
        >
          {{ item.name }}
        </option>
      </select> -->

      <!-- <select
        v-else-if="
          content.type == 'condition-contact' && content.attrType.id == 'custom'
        "
        v-model="content.operator"
        class="filter__question"
      >
        <option
          v-for="item in responseOperatorAltOptions"
          :key="item.id"
          :value="item"
        >
          {{ item.name }}
        </option>
      </select> -->

      <select
        v-if="
          content.type != 'condition-variable' &&
            content.type != 'condition-raw' &&
            content.operator &&
            !content.operator.id.includes('op-x') &&
            !(
              content.type == 'condition-contact' &&
              content.operator.id == 'op-has-property'
            )
        "
        v-model="content.operandB"
        class="filter__question"
        placeholder="Select..."
      >
        <option v-for="item in operandBOptions" :key="item.id" :value="item">
          {{ item.name }}
        </option>
      </select>

      <input
        v-if="
          content.operator &&
            !content.operator.id.includes('op-x') &&
            !(
              content.type == 'condition-contact' &&
              content.operator.id == 'op-has-property'
            )
        "
        v-model="content.value"
        class="answer--text-input"
        type="text"
        style="margin:0"
        placeholder="Enter a value"
      />
      <woot-button
        icon="dismiss"
        variant="clear"
        color-scheme="secondary"
        @click="$emit('delete-condition', group, index, condId)"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      required: true,
    },
    index: {
      required: true,
    },
    group: {},
    condId: {},
  },

  computed: {
    attrTypes() {
      return [
        { id: 'standard', name: 'Standard' },
        { id: 'custom', name: 'Custom' },
      ];
    },

    contactAttributes() {
      return [
        { id: 'name', name: 'Name' },
        { id: 'email', name: 'Email' },
        { id: 'phone_number', name: 'Phone Number' },
        { id: 'identifier', name: 'Identifier' },
        { id: 'status', name: 'Status' },
      ];
    },

    conditionTypes() {
      return [
        { id: 'condition-response', name: 'Response' },
        { id: 'condition-label', name: 'Label' },
        { id: 'condition-variable', name: 'Variable' },
        { id: 'condition-contact', name: 'Contact' },
        { id: 'condition-event_name', name: 'Event Name' },
        { id: 'condition-event_params', name: 'Event Params' },
        { id: 'condition-raw', name: 'Raw Expression' },
        { id: 'condition-api-response', name: 'API Response' },
      ];
    },
    responseOperatorOptions() {
      return [
        { id: 'op-contains', name: 'Contains' },
        { id: 'op-does-not-contain', name: 'Does not contain' },
        { id: 'op-equal', name: 'Equal' },
        { id: 'op-not-equal', name: 'Not Equal' },
        { id: 'op-gt', name: 'Greater than >' },
        { id: 'op-lt', name: 'Less than <' },
        { id: 'op-gte', name: 'Greater than or Equal >=' },
        { id: 'op-lte', name: 'Less than or Equal <=' },
        { id: 'op-x-null', name: 'is Null' },
        { id: 'op-x-not-null', name: 'is not Null' },
        { id: 'op-x-empty', name: 'is Empty' },
        { id: 'op-x-not-empty', name: 'is not Empty' },
        { id: 'op-has-property', name: 'has Property' },
      ];
    },

    operandBOptions() {
      return [
        { id: 'op-text', name: 'Text' },
        { id: 'op-variable', name: 'Variable' },
      ];
    },
  },

  methods: {},
};
</script>
<style lang="scss" scoped>
.bottom-margin {
  margin-bottom: var(--space-small) !important;
}
</style>

<style lang="scss" scoped>
.filter {
  background: var(--color-background);
  padding: var(--space-small);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-medium);
  margin-bottom: 15px;
}

.filter.error {
  background: var(--r-50);
}

.filter-inputs {
  display: flex;
}

.filter-error {
  color: var(--r-500);
  display: block;
  margin: var(--space-smaller) 0;
}

.filter__question,
.filter__operator {
  margin-bottom: var(--space-zero);
  margin-right: var(--space-smaller);
}

.filter__question {
  max-width: 30%;
}

.filter__operator {
  max-width: 20%;
}

.filter__answer--wrap {
  margin-right: var(--space-smaller);
  flex-grow: 1;

  input {
    margin-bottom: 0;
  }
}
.filter__answer {
  &.answer--text-input {
    margin-bottom: var(--space-zero);
  }
}

.filter__join-operator-wrap {
  position: relative;
  z-index: var(--z-index-twenty);
  margin: var(--space-zero);
}

.filter__join-operator {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: var(--space-one) var(--space-zero);

  .operator__line {
    position: absolute;
    width: 100%;
    border-bottom: 2px solid var(--color-border);
  }

  .operator__select {
    position: relative;
    width: auto;
    margin-bottom: var(--space-zero) !important;
  }
}
</style>
