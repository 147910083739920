export const OPERATOR_TYPES_1 = [
    {
      value: 'equal_to',
      label: 'Equal to',
    },
    {
      value: 'not_equal_to',
      label: 'Not equal to',
    },
  ];
  
  export const OPERATOR_TYPES_2 = [
    {
      value: 'equal_to',
      label: 'Equal to',
    },
    {
      value: 'not_equal_to',
      label: 'Not equal to',
    },
    {
      value: 'contains',
      label: 'Contains',
    },
    {
      value: 'does_not_contain',
      label: 'Does not contain',
    },
  ];
  
  export const OPERATOR_TYPES_3 = [
    {
      value: 'equal_to',
      label: 'Equal to',
    },
    {
      value: 'not_equal_to',
      label: 'Not equal to',
    },
    {
      value: 'is_present',
      label: 'Is present',
    },
    {
      value: 'is_not_present',
      label: 'Is not present',
    },
  ];
  
  export const OPERATOR_TYPES_4 = [
    {
      value: 'equal_to',
      label: 'Equal to',
    },
    {
      value: 'not_equal_to',
      label: 'Not equal to',
    },
    {
      value: 'is_present',
      label: 'Is present',
    },
    {
      value: 'is_not_present',
      label: 'Is not present',
    },
    {
      value: 'is_greater_than',
      label: 'Is greater than',
    },
    {
      value: 'is_less_than',
      label: 'Is less than',
    },
  ];
  
  export const OPERATOR_TYPES_5 = [
    {
      value: 'is_greater_than',
      label: 'Is greater than',
    },
    {
      value: 'is_less_than',
      label: 'Is less than',
    },
    {
      value: 'days_before',
      label: 'Is x days before',
    },
  ];
  
  export const OPERATOR_TYPES_6 = [
    {
      value: 'equal_to',
      label: 'Equal to',
    },
    {
      value: 'not_equal_to',
      label: 'Not equal to',
    },
    {
      value: 'contains',
      label: 'Contains',
    },
    {
      value: 'does_not_contain',
      label: 'Does not contain',
    },
    {
      value: 'starts_with',
      label: 'Starts With',
    },
  ];
  