<template>
  <div class="column content-box">
    <nav>
      <ul class="campaignNav">
        <li 
          class="campaignNav_item"
          :class="campaignActiveNav == 'one_off' ? 'campaignNav_item campaignNav_itemActive' : ''" 
          @click="campaignNavToggle('one_off')"
        >
          {{$t('SIDEBAR.ONE_OFF')}}
        </li>
        <li 
          class="campaignNav_item"
          :class="campaignActiveNav == 'running' ? 'campaignNav_item campaignNav_itemActive' : ''" 
          @click="campaignNavToggle('running')"
        >
          {{$t('SIDEBAR.ONGOING')}}
        </li>
      </ul>
    </nav>
    
    <div>
      <campaigns-table
        :campaigns="campaigns"
        :show-empty-result="showEmptyResult"
        :is-loading="uiFlags.isFetching"
        :campaign-type="type"
        :isCampaignType="campaignActiveNav"
        @on-edit-click="openEditPopup"
        @on-delete-click="openDeletePopup"
      />
    </div>  
    
    <div>
      <table-footer
          :on-page-change="onPageChange"
          :current-page="Number(meta.currentPage)"
          :total-count="meta.count"
        />
    </div> 



    <woot-modal :show.sync="showEditPopup" :on-close="hideEditPopup">
      <edit-campaign
        :selected-campaign="selectedCampaign"
        @on-close="hideEditPopup"
      />
    </woot-modal>
    <woot-delete-modal
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('CAMPAIGN.DELETE.CONFIRM.TITLE')"
      :message="$t('CAMPAIGN.DELETE.CONFIRM.MESSAGE')"
      :confirm-text="$t('CAMPAIGN.DELETE.CONFIRM.YES')"
      :reject-text="$t('CAMPAIGN.DELETE.CONFIRM.NO')"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import campaignMixin from 'shared/mixins/campaignMixin';
import CampaignsTable from './CampaignsTable';
import EditCampaign from './EditCampaign';
import timeMixin from 'dashboard/mixins/time';
import TableFooter from 'dashboard/components/widgets/TableFooter';


export default {
  components: {
    CampaignsTable,
    EditCampaign,
    TableFooter,
  },
  mixins: [alertMixin, campaignMixin, timeMixin],
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showEditPopup: false,
      selectedCampaign: {},
      showDeleteConfirmationPopup: false,
      campaignActiveNav:'one_off',
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'campaigns/getUIFlags',
      meta: 'campaigns/getMeta',
      labelList: 'labels/getLabels',
    }),
    
    campaigns() {
      // only running campaign
      if (this.campaignActiveNav == 'running') {
        const runninCampaignList = this.$store.getters['campaigns/getAllCampaigns']
                  .filter(campainRecord=>campainRecord.campaign_type === 'running')
                  .map(item => {
                    return {
                      ...item,
                      url: item.trigger_rules.url,
                      timeOnPage: item.trigger_rules.time_on_page,
                      scheduledAt: item.scheduled_at
                        ? this.messageStamp(new Date(item.scheduled_at), 'h:mm a')
                        : '---',
                    };
                  });
        return runninCampaignList;
      }
      
      // all campaign
      const allcampaigns = this.$store.getters['campaigns/getCampaigns'](this.campaignType).map(item => {
        return {
          ...item,
          url: item.trigger_rules.url,
          timeOnPage: item.trigger_rules.time_on_page,
          scheduledAt: item.scheduled_at
            ? this.messageStamp(new Date(item.scheduled_at), 'LLL d, h:mm a')
            : '---',
        };
      });
      
      return allcampaigns;
    },
    showEmptyResult() {
      const hasEmptyResults =
        !this.uiFlags.isFetching && this.campaigns.length === 0;
      return hasEmptyResults;
    },
  },
  methods: {
    openEditPopup(response) {
      const { row: campaign } = response;
      this.selectedCampaign = campaign;
      this.showEditPopup = true;
    },
    hideEditPopup() {
      this.showEditPopup = false;
    },
    openDeletePopup(response) {
      this.showDeleteConfirmationPopup = true;
      this.selectedCampaign = response;
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    confirmDeletion() {
      this.closeDeletePopup();
      const {
        row: { id },
      } = this.selectedCampaign;
      this.deleteCampaign(id);
    },
    async deleteCampaign(id) {
      try {
        await this.$store.dispatch('campaigns/delete', id);
        this.showAlert(this.$t('CAMPAIGN.DELETE.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('CAMPAIGN.DELETE.API.ERROR_MESSAGE'));
      }
    },
    campaignNavToggle(campaignType){
      this.campaignActiveNav = campaignType;
    },
     onPageChange(page) {
        console.log({page});
        // search
        this.$store.dispatch('campaigns/getPageByCampaigns',{page});
    },

  },
};
</script>

<style scoped lang="scss">
.button-wrapper {
  display: flex;
  justify-content: flex-end;
  padding-bottom: var(--space-one);
}

.content-box .page-top-bar::v-deep {
  padding: var(--space-large) var(--space-large) var(--space-zero);
}

.campaignNav{
  display: flex;
  margin: 0;
  list-style: none;
  gap: 1rem;
  padding: var(--space-small);
  font-weight: var(--font-weight-medium);
  border-radius: var(--border-radius-normal);
  color: var(--s-600);
  line-height: var(--space-two);
  font-size: var(--font-size-small);
}
.campaignNav_item{
  border-color: var(--w-25);
  padding: var(--space-small);
  cursor: pointer;

  &:hover{
    background: var(--w-25);
    color: var(--w-500);
  }
}
.campaignNav_itemActive{
  background: var(--w-25);
  color: var(--w-500);
}
</style>
