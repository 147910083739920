/* global axios */
import ApiClient from './ApiClient';

class GrantAPI extends ApiClient {
  constructor() {
    super('grants', { accountScoped: true });
  }

  // get grant credit list
  getGrantCreditList(){
    return axios.get(`${this.url}`);
  }

  createGrant({email,balance_credit}){
    return axios.post(`${this.url}`,{email,balance_credit});
  }

  // update credit by email
  updateCreditByEmail(email,balance_credit){
    return axios.patch(`${this.url}/update`,{email,balance_credit});
  }
  updateMetaUrlByEmail(email,meta_url){
    console.log({email,meta_url});
    return axios.patch(`${this.url}/update`,{email,meta_url});
  }
  updateGrantInfo(email,keyName,value){
    console.log('called API fn');
    console.log({email,keyName, value});
    const data = {email}
    data[keyName] = value;
    return axios.patch(`${this.url}/update`,{...data});
  }

  checkMetaUrl(inbox_id){
    // /meta_url?inbox_id=2
    return axios.get(`${this.url}/meta_url?inbox_id=${inbox_id}`);
  }
  checkWabaFinalization(inbox_id){
    // /meta_url?inbox_id=2
    return axios.get(`${this.url}/waba_finalized?inbox_id=${inbox_id}`);
  }
  activeWabaFinalize(inbox_id){
    // /meta_url?inbox_id=2
    return axios.post(`${this.url}/finalize_waba`,{inbox_id});
  }

  // get grants search by phone number {phone: "+6012345"}
  grantListByPhone(phone){
    return axios.get(`${this.url}/search`,{params:{phone}});
  }
  // delete a grant 
  deleteSingleGrant(id){
    return axios.delete(`${this.url}/destroy`,{params:{id}});
  }
  
}

export default new GrantAPI();
