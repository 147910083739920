<template>
  <form class="row" @submit.prevent="createChannel()">
    <div class="medium-8 columns">
      <label :class="{ error: $v.inboxName.$error }">
        {{ $t('INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.LABEL') }}
        <input
          v-model.trim="inboxName"
          type="text"
          :placeholder="$t('INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.PLACEHOLDER')"
          @blur="$v.inboxName.$touch"
        />
        <span v-if="$v.inboxName.$error" class="message">
          {{ $t('INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.ERROR') }}
        </span>
      </label>
    </div>

    <div class="medium-8 columns">
      <div>
        <label>{{$t('REGISTER.COUNTRY_CODE.LABEL')}}</label>
        <select 
          @input="onCountryCodeChange" 
          v-model="selected_country_code" 
        >
          <option
            v-for="code in phone_code_list"
            :key="code.name"
            :value="code.code"
          >
            {{code.emoji}} {{ code.dial_code }}
          </option>
        </select>
      </div>
      <div>
        <label :class="{ error: $v.phoneNumber.$error }">
          {{ $t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.LABEL') }}
          <input
            type="text"
            :placeholder="$t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.PLACEHOLDER')"
            @input="onPhoneNumberChange"
            @blur="$v.phoneNumber.$touch"
          />
          <span v-if="$v.phoneNumber.$error" class="message">
            {{ $t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.ERROR') }}
          </span>
        </label>
      </div>
    </div>

<!--
    <div class="medium-8 columns">
      <label :class="{ error: $v.waba_profile_name.$error }">
        {{ $t('INBOX_MGMT.ADD.WHATSAPP.WABA_PROFILE.LABEL') }}
        <input
          v-model.trim="waba_profile_name"
          type="text"
          :placeholder="$t('INBOX_MGMT.ADD.WHATSAPP.WABA_PROFILE.PLACEHOLDER')"
          @blur="$v.waba_profile_name.$touch"
        />
        <span v-if="$v.waba_profile_name.$error" class="message">
          {{ $t('INBOX_MGMT.ADD.WHATSAPP.WABA_PROFILE.ERROR') }}
        </span>
      </label>
    </div> 
    -->

    <div class="medium-12 columns">
    <!-- 
      <woot-submit-button
        :disabled="isWabaApiLoading"
        :loading="uiFlags.isCreating"
        :button-text="$t('INBOX_MGMT.ADD.WHATSAPP.SUBMIT_BUTTON')"
      />
    -->
      <!-- Register WABA API button  -->
      <woot-submit-button
        :disabled="isCreateLoading"
        :loading="isCreateLoading"
        button-text="Create Inbox"
        type="button"
        @click="launchWhatsAppSignup"
      /> 

    </div>
  </form>
</template>


<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { required } from 'vuelidate/lib/validators';
import router from '../../../../index';
import { isPhoneE164OrEmpty, isNumber } from 'shared/helpers/Validators';
import {demoAutoSteps,getContactAttributes,updateDemoOnBoardDataLayer} from "../../../../../helper/demoAutomationHelper";
import {ExternalConfig} from "./constant";
import {phone_code_list, getCountryDialCode} from "../../../../auth/country_code";
import {popupHelpers, attributeHelpers} from "../../../../../helper/demoAutomationHelper"
import WebChannelAPI from '../../../../../api/channel/webChannel';

export default {
  mixins: [alertMixin],
  data() {
    return {
      isCreateLoading: false,
      inboxName: '',
      phoneNumber: '',
      waba_profile_name:"",
      phone_number_id:"",
      waba_id:"",
      code_id:"",

      // phone number with code
      selected_country_code:"MY",
      input_phone_number:"",
      phone_code_list,

      // WABA whatsapp api registration 
      isWabaApiLoading: false,
      isFBSDKInitialized: false,
      facebook_app_id :  ExternalConfig.FB_APP_ID,
      fbapp_config_id : ExternalConfig.FB_APP_CONFIG_ID,
    };
  },
  computed: {
    ...mapGetters({ 
      uiFlags: 'inboxes/getUIFlags',
      currentUser: 'getCurrentUser',
    }),
  },
  mounted() {
    this.initializeFBSDK();
    window.addEventListener('message', this.sessionInfoListener);
  },
  beforeDestroy() {
    window.removeEventListener('message', this.sessionInfoListener);
  },
  validations: {
    inboxName: { required },
    // waba_profile_name: { required },
    phoneNumber: { required, isPhoneE164OrEmpty },
  },
  methods: {
    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      try {
        this.isCreateLoading = true;
  
       const grantInboxPayload = {
          name: this.inboxName,
          channel: {
            type: 'whatsapp',
            phone_number: this.phoneNumber,
            provider: 'vonage',
            provider_config: {
              code_id: this.code_id,
            },
          },
        }
        
        const whatsappChannel = await this.$store.dispatch(
          'inboxes/createChannel',
          // 'inboxes/createEmbeddedChannel',
          {...grantInboxPayload}
        );
        // if we don't want the query param, then user eventtrigger bus.$emit('onSkipWhatsappUrlRedirectEvent',dataToSend)
        // keep the query for inbox_id to prevent redirect to wait for attribute update
        this.$router.push({path: this.$route.fullPath, query: {inbox_id: whatsappChannel.id} });

        // setup instruction tutorial start
        // check if user onboard path is inbox and match the step, update the step to create whats app channel
        const attributes = getContactAttributes();
        const {whatsAppInbox,lead,schedule} = demoAutoSteps;
        const isWhatsAppInboxPath = attributes['onboard_path'] === whatsAppInbox.path;
        const isInboxAddOpen = attributes[whatsAppInbox.stepName] === whatsAppInbox.actions.inboxAdd.open_step;
        // console.log(attributes,whatsAppInbox,isWhatsAppInboxPath,isInboxAddOpen);
        if (isWhatsAppInboxPath && isInboxAddOpen) {
          window.$peasy.setCustomAttributes({[whatsAppInbox.stepName]:whatsAppInbox.actions.inboxAgentAdd.open_step});
          updateDemoOnBoardDataLayer({[whatsAppInbox.stepName]:whatsAppInbox.actions.inboxAgentAdd.open_step});
        }
        // setup instruction tutorial end

        // START: update custom_attribute onboard_class value
        const {peasy_sale} = attributeHelpers.onboardClassValues;
        await attributeHelpers.update_onboard_class_value(this.currentUser,peasy_sale.createInbox)
        // END: update custom_attribute onboard_class value
        

        // check status:0,1,2 and do redirect
        const {data:wabaStatusData} = await WebChannelAPI.getWabaInboxStatus(whatsappChannel.id);
         
        if (wabaStatusData.status === 2) {
          // skip the verification page, and go to add agent
          router.replace({
            name: 'settings_inboxes_add_agents',
            params: {
              page: 'new',
              inbox_id: whatsappChannel.id,
            },
            query: {
              inbox_id: whatsappChannel.id,
            },
          });
          return;
        }
        // if status=0 or 1; got to verification page
        router.replace({
          name: 'settings_inboxes_verify_waba',
          params: {
            page: 'new',
            inbox_id: whatsappChannel.id,
          },
          query: {
            inbox_id: whatsappChannel.id,
          },
        });
      } catch (error) {
        console.log(error);
        this.isCreateLoading = false;
        // this.phone_number_id = '';
        // this.waba_id = '';
        this.code_id = '';
        this.showAlert(this.$t('INBOX_MGMT.ADD.WHATSAPP.API.ERROR_MESSAGE'));
      }
    },
    launchWhatsAppSignup() {
        console.log("isFBSDKInitialized",this.isFBSDKInitialized);
        // form validation before continue
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }

        // Conversion tracking code
        fbq && fbq('trackCustom', 'WhatsAppOnboardingStart', {appId: this.facebook_app_id, feature: 'whatsapp_embedded_signup'});

        // Launch Facebook login
        FB.login((response) => {
          console.log({response});
            if (response.authResponse) {
              console.log({authResponse:response.authResponse});
              const code = response.authResponse.code;
              this.code_id = code;
              this.createChannel(); 
            } else {
              this.code_id = '';
              console.log('User cancelled login or did not fully authorize.');
              // show alert toast
              this.showAlert("Whatsapp API registration is rejected and can't create inbox");
            }
        }, {
            config_id: this.fbapp_config_id, // configuration ID goes here
            response_type: 'code',    
            override_default_response_type: true, 
            extras: {
              sessionInfoVersion: 2,  //  Receive Session Logging Info
              setup: {
                // Prefilled data can go here
              }
            }
        });
    },
    initializeFBSDK() {
      return new Promise((resolve, reject) => {
        window.fbAsyncInit = function () {
          try {
            FB.init({
              appId: this.facebook_app_id,
              cookie: true,
              xfbml: true,
              version: 'v18.0',
            });
            this.isFBSDKInitialized = true;
            resolve();
          } catch (error) {
            console.log(error);
          }
        }.bind(this);

        // Load the JavaScript SDK asynchronously
        const js = document.createElement('script');
        js.id = 'facebook-jssdk';
        js.src = 'https://connect.facebook.net/en_US/sdk.js';
        js.defer = true;
        js.async = true;
        js.onload = () => {
          if (!this.isFBSDKInitialized) {
            reject(new Error('Facebook SDK initialization failed.'));
          }
        };
        document.getElementsByTagName('head')[0].appendChild(js);
      });
    },
    sessionInfoListener (event) {
      console.log({event});
        if (event.origin !== "https://www.facebook.com") return;
        try {
            const data = JSON.parse(event.data);
            console.log(data);
            if (data.type === 'WA_EMBEDDED_SIGNUP') {
              // if user finishes the Embedded Signup flow
              if (data.event === 'FINISH') {
                  const {phone_number_id, waba_id} = data.data;
                  console.log({phone_number_id,waba_id});
                  this.phone_number_id = phone_number_id;
                  this.waba_id = waba_id;
                  // this.createChannel();
              }else {
                // if user cancels the Embedded Signup flow
                const{current_step} = data.data;
              }
            }
        } catch (error) {
            // Don’t parse info that’s not a JSON
            console.log('Non JSON Response', {event});
            this.phone_number_id = '';
            this.waba_id = '';
        }
    },
     onCountryCodeChange(event){
      this.selected_country_code = event.target.value;
      this.phoneNumber = `${getCountryDialCode(event.target.value)}${this.input_phone_number}`;
    },
    onPhoneNumberChange(event){
      this.input_phone_number = event.target.value;
      this.phoneNumber = `${getCountryDialCode(this.selected_country_code)}${event.target.value}`;
    },
     


  },
};
</script>

<style>

</style>