<template>
  <div class="column content-box">
    <woot-modal-header
      :header-title="$t('CAMPAIGN.ADD.TITLE')"
      :header-content="$t('CAMPAIGN.ADD.DESC')"
    />
    <form class="row" @submit.prevent>
      <div class="medium-12 columns">
        <label :class="{ error: $v.selectedInbox.$error }">
          <div
            style="display: flex; justify-content: space-between; align-items: end;"
            :style="isEmailSelectedInbox ? 'margin-bottom: 10px' : ''"
          >
            {{ $t('CAMPAIGN.ADD.FORM.INBOX.LABEL') }}
            <woot-button
              v-if="isEmailSelectedInbox"
              @click="openEmailTemplateListModal"
            >{{ $t('CAMPAIGN.ADD.SELECT_TEMPLATE_BUTTON_TEXT') }}</woot-button>
          </div>
          <select v-model="selectedInbox" @change="onChangeInbox($event)">
            <option v-for="item in inboxes" :key="item.name" :value="item">{{ item.name }}</option>
          </select>
          <span
            v-if="$v.selectedInbox.$error"
            class="message"
          >{{ $t('CAMPAIGN.ADD.FORM.INBOX.ERROR') }}</span>
        </label>

        <woot-input
          v-model="title"
          :label="$t('CAMPAIGN.ADD.FORM.TITLE.LABEL')"
          type="text"
          :class="{ error: $v.title.$error }"
          :error="$v.title.$error ? $t('CAMPAIGN.ADD.FORM.TITLE.ERROR') : ''"
          :placeholder="$t('CAMPAIGN.ADD.FORM.TITLE.PLACEHOLDER')"
          @blur="$v.title.$touch"
        />

        <label v-if="isOngoingType" class="editor-wrap">
          {{ $t('CAMPAIGN.ADD.FORM.MESSAGE.LABEL') }}
          <woot-message-editor
            v-model="message"
            class="message-editor"
            :class="{ editor_warning: $v.message.$error }"
            :placeholder="$t('CAMPAIGN.ADD.FORM.MESSAGE.PLACEHOLDER')"
            @blur="$v.message.$touch"
          />
          <span
            v-if="$v.message.$error"
            class="editor-warning__message"
          >{{ $t('CAMPAIGN.ADD.FORM.MESSAGE.ERROR') }}</span>
        </label>

        <label v-else :class="{ error: $v.message.$error }">
          {{ $t('CAMPAIGN.ADD.FORM.MESSAGE.LABEL') }}
          <div
            v-html="selectedTemplateHtml"
            v-if="selectedTemplateHtml"
            style="height: 300px; overflow-y: scroll; margin-bottom: 10px;"
          />
          <canned-response
            v-if="showMentions && hasSlashCommand && !isEmailSelectedInbox"
            v-on-clickaway="hideMentions"
            top="37"
            :search-key="mentionSearchKey"
            @click="replaceText"
          />
          <textarea
            v-if="!selectedTemplateHtml"
            v-model="message"
            rows="5"
            type="text"
            :placeholder="$t('CAMPAIGN.ADD.FORM.MESSAGE.PLACEHOLDER')"
            @blur="$v.message.$touch"
          />
          <span v-if="$v.message.$error" class="message">{{ $t('CAMPAIGN.ADD.FORM.MESSAGE.ERROR') }}</span>
        </label>

        <section>
          <label
            v-if="isOnOffType"
            :class="{ error: $v.appliedFilters.$error }"
          >{{ $t('CAMPAIGN.ADD.FORM.AUDIENCE.LABEL') }}</label>
          <div class="medium-12 columns filters-wrap">
            <filter-input-box
              v-for="(filter, i) in appliedFilters"
              :key="i"
              v-model="appliedFilters[i]"
              :filter-groups="filterGroups"
              :grouped-filters="true"
              :input-type="getInputType(appliedFilters[i].attribute_key)"
              :operators="getOperators(appliedFilters[i].attribute_key)"
              :dropdown-values="getDropdownValues(appliedFilters[i].attribute_key)
                "
              :show-query-operator="i !== appliedFilters.length - 1"
              :show-user-input="showUserInput(appliedFilters[i].filter_operator)
                "
              :v="$v.appliedFilters.$each[i]"
              reOrderLabels = "false"
              @resetFilter="resetFilter(i, appliedFilters[i])"
              @removeFilter="removeFilter(i)"
            />
            <div class="filter-actions mt-4">
              <woot-button
                icon="add"
                color-scheme="success"
                variant="smooth"
                size="small"
                @click="appendNewFilter"
              >{{ $t('CONTACTS_FILTER.ADD_NEW_FILTER') }}</woot-button>
            </div>
          </div>
        </section>



        <section>
          <label for="">Type</label>
          <select v-model="campaign_type">
            <option :value="'one_off'"> {{$t('SIDEBAR.ONE_OFF')}} </option>
            <option :value="'running'">{{$t('SIDEBAR.ONGOING')}}</option>
          </select>
        </section>

        <section v-if="campaign_type == 'running'">
          <label
            v-if="isOnOffType"
            :class="{ error: $v.appliedFilters.$error }"
          >
            {{ $t('CAMPAIGN.ADD.FORM.AUDIENCE.CUSTOM_LABEL') }}
          </label>
          <div class="medium-12 columns filters-wrap">
            <filter-input-box
              v-for="(filter, i) in [appliedFilters[0]]"
              :key="i"
              v-model="campaign_repeat_at"
              :filter-groups="onGoingDateFilterGroups"
              :grouped-filters="true"
              input-type=""
              :operators="onGoingOperators"
              :dropdown-values="getDropdownValues(appliedFilters[i].attribute_key)"
              :show-query-operator="false"
              :show-user-input="showUserInput(appliedFilters[i].filter_operator) ? true:true"
              :v="$v.appliedFilters.$each[i]"
              reOrderLabels = "true"
            />
          </div>
        </section>

        <label
          v-if="isOngoingType"
          :class="{ error: $v.selectedSender.$error }"
        >
          {{ $t('CAMPAIGN.ADD.FORM.SENT_BY.LABEL') }}
          <select v-model="selectedSender">
            <option
              v-for="sender in sendersAndBotList"
              :key="sender.name"
              :value="sender.id"
            >{{ sender.name }}</option>
          </select>
          <span
            v-if="$v.selectedSender.$error"
            class="message"
          >{{ $t('CAMPAIGN.ADD.FORM.SENT_BY.ERROR') }}</span>
        </label>

        <label v-if="campaign_type == 'one_off'">
          {{ $t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.LABEL') }}
          <woot-date-time-picker
            :value="scheduledAt"
            :disable-dates="0"
            :confirm-text="$t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.CONFIRM')"
            :placeholder="$t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.PLACEHOLDER')"
            @change="onChange"
          />
        </label>

        <label v-if="campaign_type == 'running'">
          {{ $t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.LABEL') }}
          <woot-date-time-picker
            :value="scheduledAt"
            :disable-dates="0"
            :confirm-text="$t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.CONFIRM')"
            :placeholder="$t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.PLACEHOLDER')"
            isTimeOnly="true"
            @change="onChange"
          />
        </label>

        <woot-input
          v-if="isOngoingType"
          v-model="endPoint"
          :label="$t('CAMPAIGN.ADD.FORM.END_POINT.LABEL')"
          type="text"
          :class="{ error: $v.endPoint.$error }"
          :error="$v.endPoint.$error ? $t('CAMPAIGN.ADD.FORM.END_POINT.ERROR') : ''
            "
          :placeholder="$t('CAMPAIGN.ADD.FORM.END_POINT.PLACEHOLDER')"
          @blur="$v.endPoint.$touch"
        />
        <woot-input
          v-if="isOngoingType"
          v-model="timeOnPage"
          :label="$t('CAMPAIGN.ADD.FORM.TIME_ON_PAGE.LABEL')"
          type="text"
          :class="{ error: $v.timeOnPage.$error }"
          :error="$v.timeOnPage.$error
            ? $t('CAMPAIGN.ADD.FORM.TIME_ON_PAGE.ERROR')
            : ''
            "
          :placeholder="$t('CAMPAIGN.ADD.FORM.TIME_ON_PAGE.PLACEHOLDER')"
          @blur="$v.timeOnPage.$touch"
        />
        <label v-if="isOngoingType">
          <input v-model="enabled" type="checkbox" value="enabled" name="enabled" />
          {{ $t('CAMPAIGN.ADD.FORM.ENABLED') }}
        </label>
        <label v-if="isOngoingType">
          <input
            v-model="triggerOnlyDuringBusinessHours"
            type="checkbox"
            value="triggerOnlyDuringBusinessHours"
            name="triggerOnlyDuringBusinessHours"
          />
          {{ $t('CAMPAIGN.ADD.FORM.TRIGGER_ONLY_BUSINESS_HOURS') }}
        </label>
      </div>

      <div class="modal-footer">
        <woot-button
          :is-loading="uiFlags.isCreating"
          @click.prevent="addCampaign"
        >{{ $t('CAMPAIGN.ADD.CREATE_BUTTON_TEXT') }}</woot-button>
        <woot-button
          variant="clear"
          @click.prevent="onClose"
        >{{ $t('CAMPAIGN.ADD.CANCEL_BUTTON_TEXT') }}</woot-button>
      </div>
    </form>
    <email-template-list
      v-if="isOpenEmailTemplateListModal"
      @close="closeEmailTemplateListModal"
      @selectTemplate="selectTemplate"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, url, minLength, requiredIf } from 'vuelidate/lib/validators';
import alertMixin from 'shared/mixins/alertMixin';
import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor';
import campaignMixin from 'shared/mixins/campaignMixin';
import WootDateTimePicker from 'dashboard/components/ui/DateTimePicker.vue';
import countries from '../../../../../shared/constants/countries';
import filterMixin from 'shared/mixins/filterMixin';
import { filterAttributeGroups } from '../../contacts/contactFilterItems';
import * as OPERATORS from '../../../../components/widgets/FilterInput/FilterOperatorTypes';
import FilterInputBox from '../../../../components/widgets/FilterInput/Index.vue';
import filterQueryGenerator from '../../../../helper/filterQueryGenerator';
import CannedResponse from '../../../../components/widgets/conversation/CannedResponse';
import { mixin as clickaway } from 'vue-clickaway';
import EmailTemplateList from './EmailTemplates/EmailTemplateList.vue';
import {attributeHelpers} from "../../../../helper/demoAutomationHelper";

export default {
  components: {
    WootDateTimePicker,
    WootMessageEditor,
    FilterInputBox,
    CannedResponse,
    EmailTemplateList,
  },

  mixins: [alertMixin, campaignMixin, filterMixin, clickaway],
  props: {
    initialFilterTypes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      title: '',
      appliedFilters: [],
      filterGroups: [],
      message: '',
      mentionSearchKey: '',
      channel_type: '',
      templateName: '',
      showMentions: false,
      hasSlashCommand: false,
      filterTypes: this.initialFilterTypes,
      selectedSender: 0,
      selectedInbox: null,
      isEmailSelectedInbox: false,
      isOpenEmailTemplateListModal: false,
      endPoint: '',
      timeOnPage: 10,
      show: true,
      enabled: true,
      filterAttributeGroups,
      allCustomAttributes: [],
      triggerOnlyDuringBusinessHours: false,
      scheduledAt: null,
      senderList: [],
      attributeModel: 'contact_attribute',
      filtersFori18n: 'CONTACTS_FILTER',
      campaign_type: 'one_off',  // _._
      campaign_repeat_at:{},  
      onGoingOperators: [
        { label: "", value: "equal_to"},
        { label: "", value: "is_greater_than"},
        { label: "", value: "is_lesser_than"},
      ],
      onGoingDateFilterGroups: [],
      selectedTemplateHtml: '',
    };
  },

  validations() {
    const commonValidations = {
      title: {
        required,
      },
      message: {
        required,
      },
      selectedInbox: {
        required,
      },
      appliedFilters: {
        required,
        $each: {
          values: {
            required: requiredIf(prop=>{
              return !(prop.filter_operator === 'is_present' || prop.filter_operator === 'is_not_present');
            }),
          },
        },
      },
    };
    if (this.isOngoingType) {
      return {
        ...commonValidations,
        selectedSender: {
          required,
        },
        endPoint: {
          required,
          minLength: minLength(7),
          url,
        },
        timeOnPage: {
          required,
        },
      };
    }
    return {
      ...commonValidations,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      uiFlags: 'campaigns/getUIFlags',
      audienceList: 'labels/getLabels',
      getAppliedContactFilters: 'contacts/getAppliedContactFilters',
      contactStatus: 'accounts/getContactStatus',
    }),
    inboxes() {
      if (this.isOngoingType) {
        return this.$store.getters['inboxes/getWebsiteInboxes'];
      }
      return this.$store.getters['inboxes/getOneOffInboxes'];
    },
    hasAppliedFilters() {
      return this.getAppliedContactFilters.length;
    },
    sendersAndBotList() {
      return [
        {
          id: 0,
          name: 'Bot',
        },
        ...this.senderList,
      ];
    },
  },
  watch: {
    message(updatedMessage) {
      this.hasSlashCommand = updatedMessage[0] === '/';
      const hasNextWord = updatedMessage.includes(' ');
      const isShortCodeActive = this.hasSlashCommand && !hasNextWord;
      if (this.hasSlashCommand && this.selectedInbox == null) {
        this.showAlert('Please select inbox first.');
      } else if (
        this.hasSlashCommand &&
        this.selectedInbox != null &&
        this.channel_type !== 'Channel::Whatsapp'
      ) {
        this.showAlert(
          'Please select 360 whatsapp inbox to use canned response.'
        );
      }
      if (
        isShortCodeActive &&
        this.selectedInbox != null &&
        this.channel_type === 'Channel::Whatsapp'
      ) {
        this.mentionSearchKey = updatedMessage.substr(1, updatedMessage.length);
        this.showMentions = true;
      } else {
        this.mentionSearchKey = '';
        this.showMentions = false;
      }
    },
    campaign_repeat_at(newRepeatAt,oldRepeatAt){
      if (newRepeatAt.filter_operator == 'equal_to') {
        this.campaign_repeat_at.values = 0;
      }
    }
  },
  mounted() {
    this.setFilterAttributes();
    if (this.getAppliedContactFilters.length) {
      this.appliedFilters = [...this.getAppliedContactFilters];
    } else {
      this.appliedFilters.push({
        attribute_key: 'name',
        filter_operator: 'equal_to',
        values: '',
        query_operator: 'and',
        attribute_model: 'standard',
      });
    }
    // call to get 'date' type attribute filter group
    this.getTimeFilterGroup();
    // set the intial campaign repeat
    if (this.onGoingDateFilterGroups.length) {
      // this.campaign_repeat_at = this.onGoingDateFilterGroups[0];
      this.campaign_repeat_at = {
        filter_operator: "equal_to",
        values: 0,
        attribute_key: this.onGoingDateFilterGroups[0].attributes[0].key,
      };
    }
  },
  methods: {
    replaceText(message, templateName) {
      setTimeout(() => {
        this.message = message;
        this.templateName = templateName;
      }, 100);
    },
    hideMentions() {
      this.showMentions = false;
    },
    onClose() {
      this.$emit('on-close');
    },
    onChange(value) {
      this.scheduledAt = value;
    },
    customAttributeInputType(key) {
      switch (key) {
        case 'date':
          return 'date';
        default:
          return 'plain_text';
      }
    },
    getOperatorTypes(key) {
      switch (key) {
        case 'list':
          return OPERATORS.OPERATOR_TYPES_1;
        case 'text':
          return OPERATORS.OPERATOR_TYPES_3;
        case 'number':
          return OPERATORS.OPERATOR_TYPES_1;
        case 'link':
          return OPERATORS.OPERATOR_TYPES_1;
        case 'date':
          return OPERATORS.OPERATOR_TYPES_4;
        case 'checkbox':
          return OPERATORS.OPERATOR_TYPES_1;
        default:
          return OPERATORS.OPERATOR_TYPES_1;
      }
    },
    getAttributeModel(key) {
      const type = this.filterTypes.find(filter => filter.attributeKey === key);
      return type.attributeModel;
    },
    getInputType(key) {
      const type = this.filterTypes.find(filter => filter.attributeKey === key);
      return type.inputType;
    },
    getOperators(key) {
      const type = this.filterTypes.find(filter => filter.attributeKey === key);
      return type.filterOperators;
    },
    getDropdownValues(type) {
      switch (type) {
        case 'country_code':
          return countries;
        case 'status_id':
          return this.contactStatus;
        default:
          return undefined;
      }
    },
    showUserInput(operatorType) {
      return !(
        operatorType === 'is_present' || operatorType === 'is_not_present'
      );
    },
    resetFilter(index, currentFilter) {
      this.appliedFilters[index].filter_operator = this.filterTypes.find(
        filter => filter.attributeKey === currentFilter.attribute_key
      ).filterOperators[0].value;
      this.appliedFilters[index].values = '';
    },
    removeFilter(index) {
      if (this.appliedFilters.length <= 1) {
        this.showAlert(this.$t('CONTACTS_FILTER.FILTER_DELETE_ERROR'));
      } else {
        this.appliedFilters.splice(index, 1);
      }
    },
    appendNewFilter() {
      this.appliedFilters.push({
        attribute_key: 'name',
        filter_operator: 'equal_to',
        values: '',
        query_operator: 'and',
      });
    },
    clearFilters() {
      this.$emit('clearFilters');
      this.onClose();
    },
    selectTemplate(selectedTemplateObj) {
      this.selectedTemplateHtml = selectedTemplateObj.templateHtml;
      this.message = selectedTemplateObj.templateHtml;
      this.closeEmailTemplateListModal();
    },
    openEmailTemplateListModal() {
      this.isOpenEmailTemplateListModal = true;
    },
    closeEmailTemplateListModal() {
      this.isOpenEmailTemplateListModal = false;
    },
    async onChangeInbox(e) {
      try {
        if (this.selectedInbox.hasOwnProperty('email')) {
          this.isEmailSelectedInbox = true;
        } else {
          this.isEmailSelectedInbox = false;
          this.selectedTemplateHtml = '';
          this.message = '';
          const response = await this.$store.dispatch('inboxMembers/get', {
            inboxId: this.selectedInbox.id,
          });
          const inbox = await this.$store.getters['inboxes/getInbox'](
            this.selectedInbox.id
          );
          this.channel_type = inbox.channel_type;
          const {
            data: { payload: inboxMembers },
          } = response;
          this.senderList = inboxMembers;
        }
      } catch (error) {
        const errorMessage =
          error?.response?.message || this.$t('CAMPAIGN.ADD.API.ERROR_MESSAGE');
        this.showAlert(errorMessage);
      }
    },
    getCampaignDetails() {
      let campaignDetails = null;
      if (this.isOngoingType) {
        campaignDetails = {
          title: this.title,
          message: this.message,
          inbox_id: this.selectedInbox.id,
          sender_id: this.selectedSender || null,
          enabled: this.enabled,
          trigger_only_during_business_hours:
            // eslint-disable-next-line prettier/prettier
            this.triggerOnlyDuringBusinessHours,
          trigger_rules: {
            url: this.endPoint,
            time_on_page: this.timeOnPage,
          },
        };
      } else {
        campaignDetails = {
          title: this.title,
          message: this.message,
          template_name: this.templateName,
          inbox_id: this.selectedInbox.id,
          scheduled_at: this.scheduledAt,
          audience: filterQueryGenerator(this.appliedFilters).payload,
        };
      }
      // add the campaign type ongoing data to send to database
      if (this.campaign_type == 'running') {
        // campaignDetails.repeat_at = this.campaign_repeat_at;
        campaignDetails.repeat_at = {
          filter_operator: this.campaign_repeat_at.filter_operator,
          values: this.campaign_repeat_at.values,
          attribute_key: this.campaign_repeat_at.attribute_key,
        };
      }
      campaignDetails.campaign_type = this.campaign_type;
      return campaignDetails;
    },
    async addCampaign() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      try {
        const campaignDetails = this.getCampaignDetails();
        await this.$store.dispatch(
          'campaigns/create',
          JSON.parse(JSON.stringify(campaignDetails))
        );
        
        // Start: if grant user, update onboard_class attribute value
        const {peasy_sale} = attributeHelpers.onboardClassValues;
        await attributeHelpers.update_onboard_class_value(this.currentUser,peasy_sale.createBroadcast)
        // End: update onboarding_class attribute

        this.showAlert(this.$t('CAMPAIGN.ADD.API.SUCCESS_MESSAGE'));
        this.onClose();
      } catch (error) {
        const errorMessage =
          error?.response?.message || this.$t('CAMPAIGN.ADD.API.ERROR_MESSAGE');
        this.showAlert(errorMessage);
      }
    },
    getTimeFilterGroup(){
      
      let allCustomAttributes = this.$store.getters[
        'attributes/getAttributesByModel'
      ](this.attributeModel);
      
      const customAttributesFormattedForDate = {
        name: this.$t(`${this.filtersFori18n}.GROUPS.CUSTOM_ATTRIBUTES`),
        attributes: allCustomAttributes.map(attr => {
          return {
            key: attr.attribute_key,
            name: attr.attribute_display_name,
            attribute_display_type: attr.attribute_display_type,
          };
        }).filter(attrDate =>attrDate.attribute_display_type === "date"),
      };
      
      const filterGroupsDate = [customAttributesFormattedForDate];
      
      this.onGoingDateFilterGroups = filterGroupsDate;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .ProseMirror-woot-style {
  height: 8rem;
}

.z-index-canned-response {
  z-index: auto;
  top: 10rem;
  padding-left: 30px;
  padding-right: 30px;
}

.filters-wrap {
  padding: var(--space-normal);
  border-radius: var(--border-radius-large);
  border: 1px solid var(--color-border);
  background: var(--color-background-light);
  margin-bottom: var(--space-normal);
}

.filter-actions {
  margin-top: var(--space-normal);
}

.event_wrapper {
  select {
    margin: var(--space-zero);
  }

  .info-message {
    font-size: var(--font-size-mini);
    color: var(--s-500);
    text-align: right;
  }

  margin-bottom: var(--space-medium);
}
</style>
