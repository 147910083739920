<template>
  <div class="wizard-body small-12 medium-9 columns height-auto">
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.AUTH.TITLE')"
      :header-content="
        useInstallationName(
          $t('INBOX_MGMT.ADD.AUTH.DESC'),
          globalConfig.installationName
        )
      "
    />
    <div class="row channels">
      <channel-item
        v-for="channel in channelList"
        :key="channel.key"
        :channel="channel"
        :enabled-features="enabledFeatures"
        :allowed-inboxes="allowedInboxes"
        :limit-reached="limitReached"
        :isChannelDisabled="channelFactory.disableInboxCreate(channel.key,currentUser,inboxes)"
        @channel-item-click="initChannelAuth"
      />
    </div>
    <stripe-component
      :modal-value="this.topUpModal"
      @close-modal="closeModal"
    ></stripe-component>
  </div>
</template>

<script>
import ChannelItem from 'dashboard/components/widgets/ChannelItem';
import router from '../../../index';
import PageHeader from '../SettingsSubPageHeader';
import { mapGetters } from 'vuex';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import StripeComponent from '../../../../components/stripe/StripeComponent';
import stripeMixin from '../../../../../shared/mixins/stripeMixin';
import alertMixin from '../../../../../shared/mixins/alertMixin';
import channelFactory from "./channel-factory";

export default {
  components: {
    ChannelItem,
    PageHeader,
    StripeComponent,
  },
  mixins: [globalConfigMixin, stripeMixin, alertMixin],
  data() {
    return {
      enabledFeatures: {},
      userInboxesDetails: {},
      allowedInboxes: [],
      limitReached: false,
      top_up: {},
      topUpModal: false,
    };
  },
  computed: {
    account() {
      return this.$store.getters['accounts/getAccount'](this.accountId);
    },
    channelList() {
      const { apiChannelName, apiChannelThumbnail } = this.globalConfig;
      return [
        { key: 'website', name: 'Website' },
        { key: 'facebook', name: 'Messenger' },
        { key: 'twitter', name: 'Twitter' },
        { key: 'whatsapp', name: 'WhatsApp' },
        { key: 'twilio sms', name: 'SMS' },
        { key: 'email', name: 'Email' },
        { key: 'whatsapp communal', name: 'WhatsApp Communal' },
        {
          key: 'api',
          name: apiChannelName || 'API',
          thumbnail: apiChannelThumbnail,
        },
        { key: 'telegram', name: 'Telegram' },
        { key: 'line', name: 'Line' },
      ];
    },
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      globalConfig: 'globalConfig/get',
      currentUser: 'getCurrentUser',
      inboxes: 'inboxes/getInboxes',
    }),
    channelFactory(){
      return channelFactory;
    }
  },
  async mounted() {
    await this.initializeEnabledFeatures();
    this.top_up = await this.$store.dispatch('accounts/fetchTopUpDetails');
    await this.checkStripeSuccess();
  },
  methods: {
    async initializeEnabledFeatures() {
      await this.$store.dispatch('accounts/get', this.accountId);
      this.enabledFeatures = this.account.features;
      this.userInboxesDetails = await this.$store.dispatch(
        'accounts/fetchAccountInboxes'
      );
      this.allowedInboxes = this.userInboxesDetails.allowable_inboxes;
      this.limitReached = this.userInboxesDetails.inbox_limit_reached;
    },
    async initChannelAuth(channel) {
      if (
        this.top_up.amount === 0 &&
        (channel === 'whatsapp' || channel === 'whatsappCommunal')
      ) {
        this.topUpModal = true;
      } else {
        const params = {
          page: 'new',
          sub_page: channel,
        };
        router.push({ name: 'settings_inboxes_page_channel', params });
      }
    },
    closeModal() {
      this.topUpModal = false;
    },
   

  },
};
</script>
<style scoped>
.height-auto {
  height: auto;
}
</style>
