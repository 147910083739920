<template>
  <div class="column padding-top">
    <report-filter-selector
      v-if="filterItemsList"
      :filter-items-list="filterItemsList"
      :hide-groupby="false"
      @date-range-change="onDateRangeChange"
    />
    <div class="row csat--metrics-container">
      <csat-metric-card
        v-for="metric in metrics"
        :key="metric.NAME"
        :label="metric.NAME"
        :info-text="metric.NAME"
        :value="metric.KEY"
        :top_up_expired="top_up_expired"
        :showTopupMsg="metric.showTopupMsg"
      />
    </div>
    <div class="row">
      <div class="report-bar" style="width:100%;">
        <div class="row">
          <div class="column">
            <h4>Usage Breakdown</h4>
            <usage-table
              :is-loading="isFetching"
              :billing-report="billingReport"
            ></usage-table>
          </div>
          <div class="column">
            <h4>Top-up History</h4>
            <topup-history-table
              :is-loading="isFetching"
              :topup-history="topupHistory"
            ></topup-history-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ReportFilterSelector from '../components/FilterSelector';
import UsageTable from './UsageTable';
import TopupHistoryTable from './TopupHistoryTable';
import format from 'date-fns/format';
import CsatMetricCard from '../components/CsatMetricCard';

export default {
  name: 'PaidMessaging',
  components: {
    ReportFilterSelector,
    UsageTable,
    TopupHistoryTable,
    CsatMetricCard,
  },
  data() {
    return {
      from: 0,
      to: 0,
      rows: [],
    };
  },
  computed: {
    ...mapGetters({
      billingReport: 'getBillingReports',
      isFetching: 'getIsFetchingUsageReport',
    }),
    filterItemsList() {
      return this.$store.getters['inboxes/getInboxes'] || [];
    },
    topupHistory() {
      if (this.billingReport == null) return [];
      return this.billingReport.top_up_history;
    },
    metrics() {
      const reportKeys = ['Total TopUp', 'Usage', 'Available Balance'];
      const reportKeysFields = [
        'total_top_up_balance',
        'total_price',
        'remaining_top_up_balance',
      ];
      return reportKeys.map((key, index) => ({
        NAME: key,
        KEY:
          this.billingReport != null
            ? this.billingReport[reportKeysFields[index]]
            : 0,
        DESC: '(USD)',
        showTopupMsg: (key === 'Available Balance') && !!this.billingReport?.top_up_expired?.credit_expiry_date,
      }));
    },
    top_up_expired(){
      return this.billingReport?.top_up_expired;
    },
  },
  mounted() {
    // this.fetchAllData();
  },
  methods: {
    fetchAllData() {
      this.$store.dispatch('paidMessaging', {
        apiUrl: 'top_up_billing',
        from: this.from,
        to: this.to,
      });
    },
    onDateRangeChange({ from, to }) {
      this.from = format(new Date(from * 1000), 'yyyy-MM-dd');
      this.to = format(new Date(to * 1000), 'yyyy-MM-dd');
      this.$nextTick(() => {
        this.fetchAllData();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.padding-top {
  padding-top: 1.6rem;
}
.report-bar {
  padding: 1.6rem;
}
.csat--metrics-container {
  background: var(--white);
  margin-bottom: var(--space-two);
  border-radius: var(--border-radius-normal);
  border: 1px solid var(--color-border);
  padding: var(--space-normal);
}
</style>
