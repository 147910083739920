<template>
    <div v-if="show" class="verify verify_template_alert">
        <h2 class="title">{{title}}</h2>
        <p class="description">{{description}}</p>
        <woot-button
            icon="dismiss"
            color-scheme="secondary"
            :variant="clear"
            classNames="verify_close_btn"
            @click="closeVerify"
        ></woot-button>
    </div>
</template>

<script>
export default {
    props: {
        show: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: 'Default Title'
        },
        description: {
            type: String,
            default: 'Default Description'
        }
    },
    methods: {
        closeVerify() {
            this.$emit('close');
        }
  }
}
</script>

<style lang="scss" scoped>
.verify{
    position: relative;
    margin-top: 24px;
    padding: 12px;
    background-color: #F2E7FE;
    .title{
        font-weight: 700;
        font-size: 14px;
        color: #8D25F6;
    }
    .description{
        font-weight: 500;
        font-size: 12px;
        color: #1F1F1F;
    }
    .verify_close_btn{
        position: absolute;
        top: 0;
        right: 0;
        background-color: transparent;
        // #858585
    }
}
</style>



<style lang="scss">
// globally apply
.verify_template_alert .verify_close_btn{
    color: #858585;
}
.verify_template_alert .verify_close_btn:hover{
    color: #858585;
}
</style>

