export default {
  methods: {
    checkStripeSuccess() {
      const clientSecret = new URLSearchParams(window.location.search).get(
        'payment_intent_client_secret'
      );

      if (!clientSecret) {
        return;
      }

      window.stripe
        .retrievePaymentIntent(clientSecret)
        .then(async ({ paymentIntent }) => {
          switch (paymentIntent.status) {
            case 'succeeded':
              this.showAlert('Payment succeeded!');
              break;
            case 'processing':
              this.showAlert('Your payment is processing.');
              break;
            case 'requires_payment_method':
              this.showAlert(
                'Your payment was not successful, please try again.'
              );
              break;
            default:
              this.showAlert('Something went wrong.');
              break;
          }
        });
    },
  },
};
