<template>
  <div class="column content-box">
    <woot-button
      color-scheme="success"
      class-names="button--fixed-right-top"
      icon="add-circle"
      @click="openAddPopup"
    >
      {{ buttonText }}
    </woot-button>
    <campaign v-if="currentPath.includes('one_off')" />
    <email-template v-if="currentPath.includes('email_templates')" />
    <woot-modal :show.sync="showAddPopup" :on-close="hideAddPopup">
      <add-campaign 
        :initial-filter-types="contactFilterItems" 
        @on-close="hideAddPopup" 
      />
    </woot-modal>
  </div>
</template>

<script>
import campaignMixin from 'shared/mixins/campaignMixin';
import Campaign from './Campaign.vue';
import AddCampaign from './AddCampaign';
import EmailTemplate from './EmailTemplates/Index.vue';
import contactFilterItems from '../../contacts/contactFilterItems';

export default {
  components: {
    Campaign,
    AddCampaign,
    EmailTemplate
  },
  mixins: [campaignMixin],
  data() {
    return {
      showAddPopup: false,
      contactFilterItems: contactFilterItems.map(filter => ({
        ...filter,
        attributeName: this.$t(
          `CONTACTS_FILTER.ATTRIBUTES.${filter.attributeI18nKey}`
        ),
      })),
    };
  },
  computed: {
    buttonText() {
      if (this.isOngoingType) {
        return this.$t('CAMPAIGN.HEADER_BTN_TXT.ONGOING');
      } else if (this.isOnOffType) {
        return this.$t('CAMPAIGN.HEADER_BTN_TXT.ONE_OFF');
      } else {
        return this.$t('CAMPAIGN.HEADER_BTN_TXT.EMAIL_TEMPLATES');
      }
    },
    currentPath() {
      return this.$route.path;
    }
  },
  mounted() {
    // this.$store.dispatch('campaigns/get');
    this.$store.dispatch('campaigns/getPageByCampaigns',{page:1});
  },
  methods: {  
    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup() {
      // window.location.reload();
      this.showAddPopup = false;
    },
  },
};
</script>
