<template>
  <div class="content-box">
    <div class="row" style="align-items: center;">
      <div class="medium-6 small-12 columns" style="display: flex;">
        <woot-input
          :label="$t('CAMPAIGN.ADD.EMAIL_TEMPLATE_FORM.NAME.LABEL')"
          :placeholder="$t('CAMPAIGN.ADD.EMAIL_TEMPLATE_FORM.NAME.PLACEHOLDER')"
          type="text"
          v-model="form.name"
          :class="{ error: $v.form.name.$error }"
          :error="
            $v.form.name.$error
              ? $t('CAMPAIGN.ADD.EMAIL_TEMPLATE_FORM.NAME.ERROR')
              : ''
          "
        />
        <div class="medium-3 small-6 columns" style="margin-left: 10px;">
          <label>{{ $t('CAMPAIGN.ADD.EMAIL_TEMPLATE_FORM.CATEGORY.LABEL') }}</label>
          <select class="business_hours_select" v-model="form.category">
            <option
              value
              disabled
              selected
            >{{ $t('CAMPAIGN.ADD.EMAIL_TEMPLATE_FORM.CATEGORY.PLACEHOLDER') }}</option>
            <option
              v-for="(category, index) in categories"
              :key="index"
              :value="category"
            >{{ category }}</option>
          </select>
        </div>
      </div>
      <div class="medium-6 small-12 columns">
        <div
          style="display:flex;justify-content: end; padding-left: 32px;padding-right: 32px; margin-bottom: 20px;"
        >
          <!-- <woot-button
            v-on:click="saveDesign"
            style="margin-right: 10px;"
          >{{ isSavingDesign ? $t('CAMPAIGN.ADD.EMAIL_TEMPLATE_FORM.SAVEING_DESIGN') : $t('CAMPAIGN.ADD.EMAIL_TEMPLATE_FORM.SAVE_DESIGN') }}</woot-button> -->
          <woot-button
            v-on:click="exportHtml"
          >{{ isPublishing ? $t('CAMPAIGN.ADD.EMAIL_TEMPLATE_FORM.PUBLISHING') : $t('CAMPAIGN.ADD.EMAIL_TEMPLATE_FORM.PUBLISH') }}</woot-button>
        </div>
      </div>
    </div>
    <EmailEditor
      ref="emailEditor"
      v-on:load="editorLoaded"
      v-on:ready="editorReady"
      :min-height="'100vh'"
      style="height: 100vh;"
    />
    <attchment-list
      ref="imageList"
      v-show="isShowingAttchmentList"
      @close="closeAttachmentListModal"
      @selectImage="selectImage"
    />
  </div>
</template>

<script>
import { EmailEditor } from 'vue-email-editor';
import EmailTemplateAPI from '../../../../../api/emailTemplates';
import AttchmentList from './AttchmentList.vue';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'EditTemplate',
  components: {
    EmailEditor,
    AttchmentList,
  },
  data() {
    return {
      categories: [],
      isPublishing: false,
      isSavingDesign: false,
      form: {
        id: '',
        category: '',
        status: 0,
        template_type: 0,
        body: '',
        name: '',
      },
      templateDetail: null,
      isShowingAttchmentList: false,
      selectImageFn: null,
      editorLoadedState: false,
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
    },
  },
  watch: {
    templateDetail(newVal) {
      if (this.editorLoadedState && newVal) {
        this.$refs.emailEditor.editor.loadDesign(newVal.tempalateBody.design);
      }
    },
  },
  computed: {
    currentRouteParams() {
      this.form.id = this.$route.params.templateId;
      return this.$route.params;
    },
  },
  created() {
    this.getCategories();
    this.getTemplateDetail();
  },
  methods: {
    async getCategories() {
      await EmailTemplateAPI.getCategories().then(response => {
        this.categories = response.data;
      });
    },
    async getTemplateDetail() {
      let templateId = this.currentRouteParams.templateId;
      await EmailTemplateAPI.getTemplateDetail(templateId).then(response => {
        let data = response.data;
        this.templateDetail = { ...data, tempalateBody: JSON.parse(data.body) };
        this.form.name = data.name;
        this.form.category = data.category;
        this.form.body = JSON.parse(data.body);
      });
    },
    selectImage(url) {
      this.selectImageFn({ url });
      this.closeAttachmentListModal();
    },
    openAttachmentListModal() {
      this.isShowingAttchmentList = true;
    },
    closeAttachmentListModal() {
      this.isShowingAttchmentList = false;
    },
    editorLoaded() {
      this.editorLoadedState = true;
      if (this.templateDetail) {
        this.$refs.emailEditor.editor.loadDesign(
          this.templateDetail.tempalateBody.design
        );
      }
    },
    editorReady() {
      this.$refs.emailEditor.editor.registerCallback(
        'selectImage',
        (data, done) => {
          this.openAttachmentListModal();
          this.selectImageFn = done;
        }
      );
    },
    saveDesign() {
      this.$refs.emailEditor.editor.exportHtml(data => {
        this.form.body = JSON.stringify(data);
        this.form.status = 0;
        this.editTemplate('withoutHtml');
      });
    },
    exportHtml() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$refs.emailEditor.editor.exportHtml(data => {
        this.form.body = JSON.stringify(data);
        this.form.status = 1;
        this.editTemplate('withHtml');
      });
    },
    async editTemplate(flag) {
      let dataTosend = this.form;
      if (flag === 'withHtml') {
        this.isPublishing = true;
      } else {
        this.isSavingDesign = true;
      }
      await EmailTemplateAPI.editTemplate(dataTosend)
        .then(response => {
          this.$router.push({ name: 'email_templates' });
        })
        .catch(error => {
          console.log('errror', error);
        })
        .finally(() => {
          if (flag === 'withHtml') {
            this.isPublishing = false;
          } else {
            this.isSavingDesign = false;
          }
        });
    },
  },
};
</script>