<template>
  <div class="column content-box">
    <woot-modal-header :header-title="$t('FLOW.CREATE_NEW_FLOW')" />

    <form class="row" @submit.prevent>
      <div class="medium-12 columns">
        <woot-input
          v-model="title"
          :label="$t('FLOW.FLOW_NAME')"
          type="text"
          :placeholder="$t('FLOW.PLACEHOLDERS.FLOW_NAME')"
        />

        <label>
          {{ $t('FLOW.SELECT_TEMPLATE') }}
          <!-- edit this part to make scrollable layout _._` -->
          <div class="flow-templates">
            <div 
              class="flow-template" 
              v-bind:class="selectedTemplate.id == item.id ? 'selectedTemplate' : ''" 
              v-for="item in templateOptions" 
              @click="onTemplateClick(item)"  
              :key="item.id"
            >
              <h2 v-bind:class="selectedTemplate.id == item.id ? 'selectedTemplate' : ''" >{{item.name}}</h2>
            </div>
          </div>
          <!-- <select v-model="selectedTemplate" class="operator__select">
            <option
              v-for="item in templateOptions"
              :key="item.id"
              :value="item"
            >
              {{ item.name }}
            </option>
          </select> -->
        </label>
      </div>

      <div class="modal-footer">
        <woot-button
          :disabled="isCreating"
          :is-loading="isCreating"
          @click.prevent="allDone"
        >
          {{ $t('FLOW.CREATE') }}
        </woot-button>
        <woot-button variant="clear" @click.prevent="onClose">
          {{ $t('FLOW.CANCEL') }}
        </woot-button>
      </div>
    </form>
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import FlowApi from '../../FlowApi';

export default {
  mixins: [alertMixin],
  props: {
    userId: {
      required: true,
    },
    accountId: {
      required: true,
    },
  },
  data() {
    return {
      title: '',
      isCreating: false,
      selectedTemplate: { id: 'default', name: 'Empty' },
      templateOptions: [{ id: 'default', name: 'Empty' }],
    };
  },
  async mounted() {
    console.log('MOUNTED');
    let response = await FlowApi.getTemplates();

    console.log('templates res', response);

    if (response.status === 'ok') {
      response.data.forEach(element => {
        this.templateOptions.push({
          id: element.botid,
          name: element.name,
        });
      });
    } else {
      this.showAlert(this.$t('FLOW.FAIL_TO_LOAD_TEMPLATES'));
    }
  },

  methods: {
    onUpdateListValue(value, item, type) {
      item[type] = value;
    },
    async allDone() {
      let name = this.title;

      if (typeof name === 'string' && name.trim().length === 0) {
        this.showAlert(this.$t('FLOW.INVALID_NAME'));
        return;
      }
      if (!this.userId || !this.accountId) {
        return;
      }

      this.isCreating = true;

      let response = await FlowApi.createFlow(
        this.userId,
        name,
        this.selectedTemplate,
        this.accountId
      );

      if (response.status === 'ok') {
        this.$emit('create-flow', true);
      } else {
        this.$emit('create-flow', false);
      }

      this.isCreating = false;
      // this.$emit('create-flow', this.title, this.selectedTemplate);
    },

    onClose() {
      this.$emit('on-close');
    },
    onTemplateClick(template){
      this.selectedTemplate = template;
    },
  },
};
</script>



<style lang="scss" scoped>
.flow-templates {
  max-height: 500px;
  overflow-y: scroll;
}
.flow-template {
  border: 1px solid var(--s-75);
  padding: 8px;
  margin: 4px 0;
  border-radius: var(--border-radius-normal);
}
.flow-template h2{
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.8;
}

// scrollbar design 
.flow-templates::-webkit-scrollbar {
  width: 10px;
}

.flow-templates::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.flow-templates::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

.flow-templates {
  scrollbar-width: 10px;
}

.flow-templates::-webkit-scrollbar-corner {
  background-color: #f1f1f1;
}
.selectedTemplate{
  color: var(--w-500);
  background: var(--w-25);
}
</style>



