<template>
  <woot-modal size="medium" :show.sync="show" :on-close="onClose">
    <woot-modal-header :header-title="$t('CAMPAIGN.CATALOGUE_LIST.HEADER')" class="modal-header">
      <woot-button :disabled="selectedProducts.length === 0" @click="sendItems">{{ $t('CAMPAIGN.CATALOGUE_LIST.ATTCH_BUTTON_TEXT') }}</woot-button>
    </woot-modal-header>
    <div class="row header-section">
      <div class="columns medium-6 small-12">
        <div class="search-wrap">
          <fluent-icon icon="search" class="search-icon" />
          <input
            type="text"
            :placeholder="$t('CAMPAIGN.EMAIL_TEMPLATE.SEARCH_INPUT_PLACEHOLDER')"
            class="contact-search"
            :value="searchQuery"
            @keyup.enter="onSearchSubmit($event, 'title')"
            @input="onInputSearch"
          />
          <woot-button
            :is-loading="false"
            class="clear"
            :class-names="this.searchQuery !== '' ? 'show' : ''"
            @click="onSearchSubmit($event,'title')"
          >{{ $t('CAMPAIGN.EMAIL_TEMPLATE.SEARCH_BUTTON_TEXT') }}</woot-button>
        </div>
      </div>
      <div class="columns medium-4 small-12">
        <label>{{ $t('CAMPAIGN.CATALOGUE_LIST.CATEGORY_SELECT_LABEL') }}</label>
        <select
          v-model="category"
          class="category--filter"
          placeholder="Select category"
          @change="onSearchSubmit($event,'category')"
        >
          <option value>All</option>
          <option v-for="(value, index) in categories" :key="index" :value="value">{{ value }}</option>
        </select>
      </div>
    </div>
    <div class="invisible-checkboxes">
      <p>{{ $t('CAMPAIGN.CATALOGUE_LIST.SUB_HEADER') }}</p>
      <div class="row" v-if="catalogItems.length  > 0">
        <div class="column medium-4 catalogue-detail" v-for="(item) in catalogItems" :key="item.id">
          <input
            type="checkbox"
            name="selectCatalogue"
            :value="item.retailer_id"
            :id="`catalogue-item-${item.retailer_id}`"
            @change="changeSelectBox"
          />
          <label class="checkbox-alias" :for="`catalogue-item-${item.retailer_id}`">
            <img class="image" :src="item.image_link" :alt="item.title" />
            <span>{{ item.title }}</span>
            <p>{{ item.description }}</p>
          </label>
        </div>
      </div>
    </div>
  </woot-modal>
</template>

<script>
import CatalogueAPI from '../../../../api/inbox/catalogue';

export default {
  name: 'AllCatalogues',
  props: {
    inbox: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      show: true,
      searchQuery: '',
      category: '',
      selectedProducts: [],
      catalogItems: [],
      categories: [],
    };
  },
  created() {
    this.getCatalogueItems();
    this.getProductSets();
  },
  methods: {
    getProductSets() {
      CatalogueAPI.getProductSets(this.inbox.id).then(response => {
        this.categories = response.data.map(set => set.title);
      });
    },
    getCatalogueItems() {
      CatalogueAPI.get(this.inbox.id)
        .then(response => {
          this.catalogItems = response.data;
        })
        .catch(error => {
          console.log('error', error);
        });
    },
    onClose() {
      this.$emit('close');
    },
    onSearchSubmit(event, searchBy) {
      let params = {};
      if (searchBy === 'category') {
        this.searchQuery = '';
        this.category = event.target.value;
        params = { inbox_id: this.inbox.id, categories: this.category };
      } else if (searchBy === 'title') {
        this.category = '';
        params = { inbox_id: this.inbox.id, title: this.searchQuery };
      }
      if (
        (this.searchQuery.length > 0 || this.category.length > 0) &&
        searchBy.length > 0
      ) {
        CatalogueAPI.search(params)
          .then(response => {
            this.catalogItems = response.data;
          })
          .catch(error => {
            console.log('error', error);
          });
      } else {
        this.getCatalogueItems();
      }
    },
    onInputSearch(event) {
      const newQuery = event.target.value;
      this.searchQuery = newQuery;
    },
    changeSelectBox(event) {
      let checked = event.target.checked;
      let productIsExists = this.selectedProducts.some(
        item => item.product_retail_id === event.target.value
      );
      if (checked && !productIsExists) {
        let data = { product_retail_id: event.target.value };
        this.selectedProducts.push(data);
      } else {
        if (productIsExists) {
          this.selectedProducts = this.selectedProducts.filter(
            item => item.product_retail_id !== event.target.value
          );
        }
      }
    },
    async sendItems() {
      console.log('selectedProducts', this.selectedProducts);
      let data = {
        items: [
          {
            header: 'Check these out!',
            body:
              'We have personally tailored these recommendations especially for you!',
            action: {
              catalog_id: this.catalogItems[0].catalog_id,
              sections: [
                {
                  title: 'Recommendations',
                  product_items: this.selectedProducts,
                },
              ],
            },
            footer: 'Promo especially for you',
          },
        ],
      };
      this.$emit('close', data);
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox-alias {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  transition: all 250ms ease-out;
  cursor: pointer;
  margin: unset;
  padding: 10px;
  .image {
    height: auto;
    width: 200px;
    margin-bottom: 10px;
    border-radius: 10px;
  }
}
.catalogue-detail {
  padding: 10px;
  position: relative;
  box-sizing: border-box;
}

.invisible-checkboxes {
  margin-left: 20px;
  margin-right: 20px;
  p {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.invisible-checkboxes input[type='checkbox'] {
  display: block;
  position: absolute;
  z-index: 10;
  top: 25px;
  left: 25px;
}

.invisible-checkboxes input[type='checkbox']:checked + .checkbox-alias {
  background-color: var(--w-25);
  border-radius: 20px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
}
.header-section {
  padding: 20px 30px;
  justify-content: space-between;
  align-items: end;
  .category--filter {
    margin: unset;
  }
  .search-wrap {
    width: 400px;
    display: flex;
    align-items: center;
    position: relative;
    margin-right: var(--space-small);

    .search-icon {
      position: absolute;
      top: 1px;
      left: var(--space-one);
      height: 3.8rem;
      line-height: 3.6rem;
      font-size: var(--font-size-medium);
      color: var(--b-700);
    }

    .contact-search {
      margin: 0;
      height: 3.8rem;
      width: 100%;
      padding-left: var(--space-large);
      padding-right: 6rem;
      border-color: var(--s-100);
    }

    .button {
      margin-left: var(--space-small);
      height: 3.2rem;
      right: var(--space-smaller);
      position: absolute;
      padding: 0 var(--space-small);
      transition: transform 100ms linear;
      opacity: 0;
      transform: translateX(-1px);
      visibility: hidden;
    }

    .button.show {
      opacity: 1;
      transform: translateX(0);
      visibility: visible;
    }
  }
}
</style>