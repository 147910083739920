<template>
  <g
    @mouseenter="mouseEnter"
    @mouseleave="mouseLeave"
    @mousedown="$emit('mousedown', $event)"
  >
    <g>
      <path
        v-for="(n, index) in link.breakPoints.length + 1"
        :key="'bg' + String(index)"
        :d="linkCurve(index)"
        :style="largeStrokeStyle"
        stroke-width="12"
        fill="none"
        @click="addBreakPoint($event, index)"
        @mouseenter="mouseEnterPath($event, index)"
        @mouseleave="mouseLeavePath"
      />
      <path
        v-for="(n, index) in link.breakPoints.length + 1"
        ref="currentPath"
        :key="index"
        :d="linkCurve(index)"
        :style="strokeStyle"
        stroke-width="2"
        fill="none"
        @click="addBreakPoint($event, index)"
        @mouseenter="mouseEnterPath($event, index)"
        @mouseleave="mouseLeavePath"
      />
    </g>

    <!-- <g v-if="link.breakPoints.length > 1">
      <path
        v-for="index in link.breakPoints.length"
        :key="'x' + String(index - 1)"
        :d="
          curve3(
            index - 1,
            link.breakPoints[index - 1],
            link.breakPoints[index]
          )
        "
        :style="largeStrokeStyle"
        stroke-width="12"
        fill="none"
        @click="addBreakPoint($event, index - 1)"
        @mouseenter="mouseEnterPath($event, index)"
        @mouseleave="mouseLeavePath"
      />
      <path
        v-for="index in link.breakPoints.length"
        ref="currentPath"
        :key="index - 1"
        :d="
          curve3(
            index - 1,
            link.breakPoints[index - 1],
            link.breakPoints[index]
          )
        "
        :style="strokeStyle"
        stroke-width="2"
        fill="none"
        @click="addBreakPoint($event, index - 1)"
        @mouseenter="mouseEnterPath($event, index)"
        @mouseleave="mouseLeavePath"
      />
    </g> -->

    <!-- <path
      ref="currentPath"
      v-if="link.breakPoints.length > 0"
      :d="curve2"
      :style="strokeStyle"
      stroke-width="2"
      fill="none"
      @click="addBreakPoint"
    /> -->

    <circle
      v-for="(point, index) in link.breakPoints"
      :key="index"
      :cx="point.x"
      :cy="point.y"
      r="4"
      stroke="#5D5F6E"
      stroke-width="2"
      fill="#5D5F6E"
      @mousedown.stop="onDragStart($event, index)"
      @mouseup.prevent="onDragEnd"
      @contextmenu.prevent.stop="onRightClick($event, index)"
    />

    <svg
      height="100"
      width="100"
      style="background-color:green"
      :x="deleteX"
      :y="deleteY"
      :visibility="[isHovered && !isDraggingBreakPoint ? 'visible' : 'hidden']"
      fill="#555"
      class="del"
      @click="$emit('deleteLink', $event)"
      @mouseenter="mouseEnterPath($event, null)"
      @mouseleave="mouseLeavePath"
    >
      <circle
        cx="16"
        cy="16"
        r="14"
        stroke="#555"
        stroke-width="1"
        fill="white"
        class="del"
      />

      <svg
        height="100"
        width="100"
        style="background-color:green"
        x="8"
        y="8"
        fill="#555"
        class="del"
        @click="$emit('deleteLink', $event)"
      >
        <path
          d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"
          class="del"
        />
        <path
          fill-rule="evenodd"
          d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
          class="del"
        />
      </svg>
    </svg>
  </g>
</template>
<script>
import Link from '../models/Link';
import Node from '../models/Node';

export default {
  name: 'DiagramLink',
  props: {
    link: { type: Link, required: true },
    nodeStart: { type: Node, required: true },
    nodeEnd: { type: Node, required: true },
    selected: { type: Boolean, default: false },
    // hovered: { type: Boolean, default: false },
  },

  data: () => ({
    show: false,
    deleteX: 0,
    deleteY: 0,
    isDraggingBreakPoint: false,
    isHovered: false,
  }),

  computed: {
    binX: {
      get() {
        return this.deleteX;
      },
      set(newX) {
        this.deleteX = newX;
      },
    },
    binY: {
      get() {
        return this.deleteY;
      },
      set(newY) {
        this.deleteY = newY;
      },
    },
    startCoordinates() {
      return this.nodeStart.getPortCoordinates('out', this.link.start_port);
    },
    endCoordinates() {
      return this.nodeEnd.getPortCoordinates('in', this.link.end_port);
    },
    x1() {
      return this.startCoordinates.x - 2;
    },

    y1() {
      return this.startCoordinates.y;
    },

    x2() {
      return this.endCoordinates.x + 1;
    },

    y2() {
      return this.endCoordinates.y;
    },

    // curve() {
    //   let x1 = Math.trunc(this.x1);
    //   let y1 = Math.trunc(this.y1);
    //   let x2 = Math.trunc(this.x2);
    //   let y2 = Math.trunc(this.y2);

    //   let distance = Math.trunc(4 * Math.sqrt(Math.abs(x1 - x2))) + 10;

    //   if (!this.link.breakPoints || !this.link.breakPoints.length) {
    //     return `M${x1},${y1} C${x1 + distance},${y1} ${x2 -
    //       distance},${y2} ${x2},${y2}`;
    //   }

    //   let pre = `M${x1} ${y1}`;

    //   this.link.breakPoints.forEach(e => {
    //     pre += ` L ${e.x} ${e.y}`;
    //   });
    //   pre += ` ${x2} ${y2}`;
    //   return pre;
    // },

    strokeStyle() {
      if (this.selected) {
        return this.hovered ? 'stroke:#555;' : 'stroke:##555;';
      }
      return this.hovered ? 'stroke:#555;' : 'stroke:#5D5F6E;';
      // return 'stroke:#000;';
    },

    // largeStrokeStyle() {
    //   if (this.hovered) {
    //     return this.hovered
    //       ? 'stroke:rgba(225,225,225,0.2);'
    //       : 'stroke:rgba(0,0,0,0.4);';
    //   }
    //   return this.hovered && false
    //     ? 'stroke:rgba(0,0,0,0.0);'
    //     : 'stroke:rgba(0,0,0,0);';
    // },
    largeStrokeStyle() {
      return 'stroke:rgba(0,0,0,0);';
    },
  },

  methods: {
    linkCurve(index) {
      const breakPoints = this.link.breakPoints.length;
      let pre = '';
      if (breakPoints === 0) {
        // no breaking points so we draw normal curve
        let x1 = Math.trunc(this.x1);
        let y1 = Math.trunc(this.y1);
        let x2 = Math.trunc(this.x2);
        let y2 = Math.trunc(this.y2);
        let distance = Math.trunc(4 * Math.sqrt(Math.abs(x1 - x2))) + 10;
        if (!this.link.breakPoints || !this.link.breakPoints.length) {
          return `M${x1},${y1} C${x1 + distance},${y1} ${x2 -
            distance},${y2} ${x2},${y2}`;
        }
        pre = `M${x1} ${y1}`;
        this.link.breakPoints.forEach(e => {
          pre += ` L ${e.x} ${e.y}`;
        });
        pre += ` ${x2} ${y2}`;
        return pre;
      }
      if (index === 0) {
        let a = this.link.breakPoints[0];
        let x1 = Math.trunc(this.x1);
        let y1 = Math.trunc(this.y1);
        let x2 = Math.trunc(a.x);
        let y2 = Math.trunc(a.y);
        pre = `M${x1} ${y1}`;
        pre += ` L ${x2} ${y2}`;
        return pre;
      }

      if (index === breakPoints) {
        let a = this.link.breakPoints[breakPoints - 1];
        let x1 = Math.trunc(a.x);
        let y1 = Math.trunc(a.y);
        let x2 = Math.trunc(this.x2);
        let y2 = Math.trunc(this.y2);
        pre = `M${x1} ${y1}`;
        pre += ` L ${x2} ${y2}`;
        return pre;
      }
      let a = this.link.breakPoints[index - 1];
      let b = this.link.breakPoints[index];
      let x1 = Math.trunc(a.x);
      let y1 = Math.trunc(a.y);
      let x2 = Math.trunc(b.x);
      let y2 = Math.trunc(b.y);
      pre = `M${x1} ${y1}`;
      pre += ` L ${x2} ${y2}`;
      return pre;
    },
    curve3(i, a, b) {
      console.log('curve3', i);
      let pre = '';
      if (i === 0) {
        if (this.link.breakPoints.length === 1) {
          let x1 = Math.trunc(this.x1);
          let y1 = Math.trunc(this.y1);
          let x2 = Math.trunc(a.x);
          let y2 = Math.trunc(a.y);
          let x3 = Math.trunc(this.x2);
          let y3 = Math.trunc(this.y2);
          pre = `M${x1} ${y1}`;
          pre += ` L ${x2} ${y2}`;
          pre += ` L ${x3} ${y3}`;
        } else {
          let x1 = Math.trunc(this.x1);
          let y1 = Math.trunc(this.y1);
          let x2 = Math.trunc(a.x);
          let y2 = Math.trunc(a.y);
          let x3 = Math.trunc(b.x);
          let y3 = Math.trunc(b.y);
          pre = `M${x1} ${y1}`;
          pre += ` L ${x2} ${y2}`;
          pre += ` L ${x3} ${y3}`;
        }
      } else if (i === this.link.breakPoints.length - 1) {
        let x1 = Math.trunc(a.x);
        let y1 = Math.trunc(a.y);
        let x2 = Math.trunc(this.x2);
        let y2 = Math.trunc(this.y2);
        pre = `M${x1} ${y1}`;
        pre += ` L ${x2} ${y2}`;
      } else {
        let x1 = Math.trunc(a.x);
        let y1 = Math.trunc(a.y);
        let x2 = Math.trunc(b.x);
        let y2 = Math.trunc(b.y);
        pre = `M${x1} ${y1}`;
        pre += ` L ${x2} ${y2}`;
      }

      return pre;
    },
    onRightClick(e, index) {
      this.isDraggingBreakPoint = false;

      // console.log('delete', index);
      this.link.breakPoints.splice(index, 1); // 2nd parameter means remove one item only
    },
    onDragStart(e, index) {
      this.isDraggingBreakPoint = true;
      this.clicked = true;
      // console.log('e', e.x, e.y);
      this.$emit('drag-start', {
        link: this.link,
        eX: e.x,
        eY: e.y,
        index,
      });
    },

    onDragEnd() {
      this.isHovered = false;
      this.isDraggingBreakPoint = false;
    },
    addBreakPoint(e, index) {
      // if (index && this.link.breakPoints.length > 1) {
      //   index += 1;
      // }
      this.$emit('add-break-point', {
        link: this.link,
        eX: e.x,
        eY: e.y,
        insertAt: index,
      });
      this.clicked = false;
    },
    mouseLeavePath() {
      this.isHovered = false;
    },

    mouseEnterPath(event, index) {
      this.isHovered = true;
      let currentSubPath = null;
      if (this.$refs.currentPath) {
        if (Array.isArray(this.$refs.currentPath)) {
          currentSubPath = this.$refs.currentPath[index];
        } else {
          currentSubPath = this.$refs.currentPath;
        }
        if (currentSubPath) {
          let len = currentSubPath.getTotalLength();
          let total = Math.trunc(len);
          total /= 1.5;
          let cords = currentSubPath.getPointAtLength(total);
          this.binX = cords.x - 10;
          this.binY = cords.y - 15;
        }

        // this.delete = cords.y;
      }
      // this.hovered = true;
      // this.$emit('mouseenter', event);
    },

    mouseEnter() {
      this.isHovered = true;
    },

    mouseLeave() {
      // this.isHovered = false;
      // this.hovered = false;
    },
  },
};
</script>
