import types from "../mutation-types";
import GrantCreditAPI from '../../api/grants';

export const state = {
    meta: {
        count: 0,
        currentPage: 1,
        pageSize: 15
    },
    main_records: [],
    records: [],
    uiFlags: {
        isFetching: false,
        isCreating: false,
        isUpdating: false,
        isDeleting: false,
        // isFetchingItem: false,
    },
    // sortOrder: [],
    // appliedFilters: [],
};

export const getters ={
    getGrantCredits($state){
        return $state.records;
    },
    getGrantCreditUIFlags($state) {
        return $state.uiFlags;
    },
    getMeta: $state => {
        return $state.meta;
    },
    getUIFlags($state) {
        return $state.uiFlags;
    },
};

export const actions ={
    search: async({commit},{searchQuery='',page = 1, pageSize = 15}={}) =>{
        console.log('in cmt function = ',searchQuery);
        commit(types.SEARCH_GRANT_CREDIT, {searchQuery,page,pageSize});
    },
    get: async({commit},{page=1}={}) =>{
        commit(types.SET_GRANT_CREDIT_UI_FLAG,{isFetching: true});
        try {
            const {data} = await GrantCreditAPI.getGrantCreditList();
            console.log('grantList',data);
            commit(types.SET_GRANT_CREDITS, data);
            commit(types.SET_GRANT_CREDIT_UI_FLAG,{isFetching: false});
        } catch (error) {
            commit(types.SET_GRANT_CREDIT_UI_FLAG,{isFetching: false});
        }
    },

    // create new grant credit
    createCreditGrant: async({commit},{email,balance_credit})=>{
        commit(types.SET_GRANT_CREDIT_UI_FLAG,{isCreating: true});
        try {
            const {data} = await GrantCreditAPI.createGrant({email,balance_credit});
            console.log(data,"Create response");
            // replace the updated data to records 
            commit(types.CREATE_GRANT_CREDIT, data);
            commit(types.SET_GRANT_CREDIT_UI_FLAG,{isCreating: false});
            return data;
        } catch (error) {
            commit(types.SET_GRANT_CREDIT_UI_FLAG,{isCreating: false});
            return error;
        }
    },

    // update credit balance by email
    updateBalance: async({commit},{email,balance_credit})=>{
        commit(types.SET_GRANT_CREDIT_UI_FLAG,{isUpdating: true});
        console.log(5);
        try {
            const {data} = await GrantCreditAPI.updateCreditByEmail(email,balance_credit);
            // replace the updated data to records 
            commit(types.UPDATE_GRANT_CREDIT, data);
            commit(types.SET_GRANT_CREDIT_UI_FLAG,{isUpdating: false});
        } catch (error) {
            commit(types.SET_GRANT_CREDIT_UI_FLAG,{isUpdating: false});
        }
    },
    updateMetaUrl: async({commit},{email,meta_url})=>{
        commit(types.SET_GRANT_CREDIT_UI_FLAG,{isUpdating: true});
        console.log({a:5, meta_url});
        try {
            const bodyBata = {email,meta_url};

            console.log({bodyBata});
            
            const {data} = await GrantCreditAPI.updateMetaUrlByEmail(email,meta_url);
            console.log({respp:data});
            // replace the updated data to records 
            commit(types.UPDATE_GRANT_CREDIT, data);
            
            commit(types.SET_GRANT_CREDIT_UI_FLAG,{isUpdating: false});
        } catch (error) {
            commit(types.SET_GRANT_CREDIT_UI_FLAG,{isUpdating: false});
        }
    },
    updateMetaInfo: async({commit},{email,keyName,value})=>{
        commit(types.SET_GRANT_CREDIT_UI_FLAG,{isUpdating: true});
        try {
            console.log({email,keyName,value},GrantCreditAPI.updateGrantInfo);
            
            const {data} = await GrantCreditAPI.updateGrantInfo(email,keyName,value);
            console.log({respp:data});
            // replace the updated data to records 
            commit(types.UPDATE_GRANT_CREDIT, data);
            
            commit(types.SET_GRANT_CREDIT_UI_FLAG,{isUpdating: false});
        } catch (error) {
            commit(types.SET_GRANT_CREDIT_UI_FLAG,{isUpdating: false});
        }
    },
    deleteACredit: async({commit},{id})=>{
        commit(types.SET_GRANT_CREDIT_UI_FLAG,{isDeleting: true});
        try {
            console.log(id);
            const {data} = await GrantCreditAPI.deleteSingleGrant(id);
            console.log({deletedData:data});
            // replace the updated data to records 
            commit(types.DELETE_GRANT_CREDIT, {row:data,id});
            
            commit(types.SET_GRANT_CREDIT_UI_FLAG,{isDeleting: false});
        } catch (error) {
            commit(types.SET_GRANT_CREDIT_UI_FLAG,{isDeleting: false});
        }
    },
    
};

export const mutations ={
    // flag mutation
    [types.SET_GRANT_CREDIT_UI_FLAG]($state,data){
        $state.uiFlags = {
            ...$state.uiFlags,
            ...data
        };
    },

    // grant records mutation
    [types.SET_GRANT_CREDITS]:($state,data)=>{
        console.log("datain mu", data);
        if (Array.isArray(data)) {
            $state.main_records = data;

            $state.meta = {
                ...$state.meta,
                count: data.length,
                currentPage: 1,
            };

            // Update records based on pagination
            const pageSize = $state.meta.pageSize || 15;
            const startIndex = ($state.meta.currentPage - 1) * pageSize;
            const endIndex = startIndex + pageSize;
            $state.records = $state.main_records.slice(startIndex, endIndex);
            console.log($state,{startIndex,endIndex, list: $state.records});
        }else{
            $state.records = [];
            $state.main_records = [];
            $state.meta = {
                ...$state.meta,
                count: 0,
                currentPage: 1,
            };
        }
    },

    // create new grant to records mutation
    [types.CREATE_GRANT_CREDIT]:($state,data)=>{
        if (data.email) {
            $state.main_records.unshift(data);
            // $state.records.unshift(data);
        }
    },

    // update by email to records mutation
    [types.UPDATE_GRANT_CREDIT]:($state,data)=>{
        if (data.email) {
            const index = $state.records.findIndex(el=> el.email === data.email);
            if (index !== -1) {
                $state.records.splice(index,1,data);
                $state.main_records.splice(index,1,data);
            }
        }
    },
    
    // delete by id to records mutation
    [types.DELETE_GRANT_CREDIT]:($state,data)=>{
        if (data.id) {
            const index = $state.records.findIndex(el=> el.id === data.id);
            if (index !== -1) {
                $state.records.splice(index,1,data);
                $state.main_records.splice(index,1,data);
            }
        }
    },
    
    // search mutation
    [types.SEARCH_GRANT_CREDIT]:($state,queryObj)=>{
        const {searchQuery:searchString,page,pageSize} = queryObj;
        const startIndex = (page - 1) * pageSize;
        const endIndex = startIndex + pageSize;

        if (searchString) {
            const newList = $state.main_records.filter(creditEl=>{
                for(const key in creditEl){
                    const value = creditEl[key]?.toString()?.toLowerCase();
                    if (value && value.includes(searchString.toLowerCase())) {
                        return true;   
                    }
                }
                return false;   
            })
            
            // Update records based on pagination
            $state.records = newList.slice(startIndex, endIndex);
            $state.meta = {
                count: newList.length,
                currentPage: page,
            };

        }else{
            // If there's no search query, set records to a slice of main_records for pagination
            $state.records = $state.main_records.slice(startIndex, endIndex);
            // Update meta information for pagination
            $state.meta = {
                count: $state.main_records.length,
                currentPage: page,
            };
        }

    },  

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
