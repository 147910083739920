function generateRandomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomString = '';
  
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters.charAt(randomIndex);
    }
  
    return randomString;
}
function generateUniqueId(length) {
    const timestamp = Date.now().toString(36).toUpperCase();
    let uniqueId = '';
  
    while (uniqueId.length < length ) {
      uniqueId += Math.random().toString(36).substring(2).toUpperCase();
    }
  
    uniqueId = timestamp + uniqueId ;
  
    
    // console.log(generateRandomString(3) + uniqueId);
    return generateRandomString(3) + uniqueId;
}

function createQuestionNodes(questionsArray) {
    return questionsArray.map((question, index) => {
        const questionId = `content-${generateUniqueId(7,index)}`;
        const transformedQuestion = {
            id: questionId,
            title: questionId,
            size: {
                width: 160,
                height: 120,
            },
            coordinates: {
                x: -1203.5 + 500 * index,
                y: -115,
            },
            data: {
                color: "#f3f3f3",
                type: "content",
                contents: [
                    {
                        value: "",
                        type: "card",
                        // title: "Budget",
                        // title: question.question,
                        title: question.title,
                        subtitle: question.question,
                        file: "",
                        actionButtons: question.options.map((option) => ({
                        buttonTitle: option.name,
                        selectedAction: {
                            id: "act-btn-say",
                            name: "Say Something",
                        },
                        buttonValue: option.name,
                        })),
                    },
                ],
                customName: `Qualification-Lead-question-${index + 1}`,
                waitForUserMessage: true,
            },
            portsIn: {
                [`port-in-${questionId}`]: "",
            },
            portsOut: {
                [`port-out-${questionId}`]: "",
            },
        };
        // console.log({index,ct:question.acceptableOptions.length});
        return {
            question_id: question.question_id,
            bad_count: question.options.length - question.acceptableOptions.length,
            good_count: question.acceptableOptions.length,
            question_node: transformedQuestion,
        };
    });
}


function createActionNodes(goodOptions,customNodeName='answer') {
        const conditionUniqId = generateUniqueId(7);
        const conditionId = `condition-${conditionUniqId}`;
        const portInId = `port-in-${conditionId}`;
        const portOutOtherwiseId = `port-out-condition-otherwise-${conditionId}`;
        const outPortIdList = [];
        const contents = goodOptions.map((option,index) => {
            const condOutPortId = `condition-outport-${conditionUniqId}-${generateUniqueId(7)}`;
            outPortIdList.push(condOutPortId);
            return {
                type: "condition-response",
                operator: {
                  id: "op-equal",
                  name: "Equal",
                },
                operandB: {
                  id: "op-text",
                  name: "Text",
                },
                attrType: {
                  id: "standard",
                  name: "Standard",
                },
                attr: {
                  id: "name",
                  name: "Name",
                },
                value: option.name,
                name: "",
                condId: condOutPortId,
                customAttr: "",
                paramName: "",
                propertyName: "",
            }
        });
        
        
      
        contents.unshift({
          type: "otherwise-condition",
          condId: portOutOtherwiseId,
        });

        const portOutKeys = {};
        outPortIdList.forEach(el=>portOutKeys[el] = '');

        return {
            id: conditionId,
            title: conditionId,
            size: {
              width: 160,
              height: 120,
            },
            coordinates: {
              x: -258.5 + Math.floor(Math.random() * (500-200+1)) + 200,
              y: -84,
            },
            data: {
              color: "#f3f3f3",
              type: "condition",
              contents: contents,
              customName: customNodeName,
            },
            portsIn: {
              [portInId]: "",
            },
            portsOut: {
              ...portOutKeys,
              [portOutOtherwiseId]: "",
            },
        };
}

function transformQualificationQuestions(qualificationQuestions) {
    const transformedQuestions = [];

    qualificationQuestions.forEach((question,index)=>{
        const goodOptions = question.acceptableOptions;
        const conditionNodes = createActionNodes(goodOptions,`Answer-${index+1}`);
        
        // transformedQuestions.push(...conditionNodes);
        transformedQuestions.push({
            question_id: question.question_id,
            question: question.question,
            actionNode: conditionNodes,
        });
    })

    return transformedQuestions;
}

function insertLinkListInLinks(mainNodeData,LinkList) {
    // add Links to links property
    LinkList.forEach(linkEl=>{
        mainNodeData.links[linkEl.id] = linkEl;
    })
    
    return mainNodeData;
}
function insertQuestionAndAnsNodes(mainNodeData,questionNodes,conditionNodes) {
    // console.log(questionNodes);
    // add condition nodes
    questionNodes.forEach(questionEl=>{
        mainNodeData.nodes[questionEl.question_node.id] = questionEl.question_node;
    })
    conditionNodes.forEach(conditionEl=>{
        mainNodeData.nodes[conditionEl.actionNode.id] = conditionEl.actionNode;
    })
    return mainNodeData;
}
const findNodeInfoByNodeName = (mainNodeLinkData,nodeKeyProperty) =>{
    const node = mainNodeLinkData.nodes[nodeKeyProperty];
    return {
        id: node.id,
        title: node.title,
        portsIn: node.portsIn,
        portsOut: node.portsOut,
    }
}
const findNodeInfoByCustomName = (mainNodeLinkData,customName) =>{
   
    for (const nodeKey of Object.keys(mainNodeLinkData.nodes)) {
        // console.log(customName,' => ',mainNodeLinkData.nodes[nodeKey].data.customName);
        if (mainNodeLinkData.nodes[nodeKey].data.customName === customName) {
            return mainNodeLinkData.nodes[nodeKey];
        }
        
    }
}

const updateNodeLinkByStart_id = (rawNodeLinks,start_id,new_end_id,new_end_port) =>{
    const links = rawNodeLinks.links;
    Object.keys(links).forEach(linkId=>{
        if (links[linkId].start_id === start_id) {
            rawNodeLinks.links[linkId].end_id = new_end_id;
            rawNodeLinks.links[linkId].end_port = new_end_port;
        }
    })
}
/*
const createNewLink = (startNode,startNodePortOutIndex=0,endNode,endNodePortInIndex=0) =>{
    return {
        id: generateUniqueId(26),
        start_id: startNode.id,
        start_port: Object.keys(startNode.portsOut)[startNodePortOutIndex],
        end_id: endNode.id,              
        end_port: Object.keys(endNode.portsIn)[endNodePortInIndex],  
        breakPoints: []
    }
}
*/
const connectLinks =(rawNodeLinks,questionNodes,conditionNodes)=>{
    const linkList = [];
    const dq_nodeName = 'disqualify-lead'
    const dq_Node = findNodeInfoByCustomName(rawNodeLinks,dq_nodeName);
    const ql_nodeName = 'Qualify-LEAD'
    const ql_Node = findNodeInfoByCustomName(rawNodeLinks,ql_nodeName);
    // console.log({ql_Node,dq_Node});

    questionNodes.forEach((questionEl,index)=>{
        const {bad_count,good_count,question_node,question_id} = questionEl;
        // console.log({t:questionNodes.length,index});
        // console.log({good_count, bad_count,question_id});

        const actionNodeOfQuestion = conditionNodes.find(conditionEl=>conditionEl.question_id === question_id);
        const othrtwiseCondId = actionNodeOfQuestion.actionNode.data.contents.find(el=>el.type === 'otherwise-condition')?.condId;
        const actionResponseOutCondIds = actionNodeOfQuestion.actionNode.data.contents.filter(el=>el.type === 'condition-response').map(el=>el.condId);
        
        // if it is first element
        if (index === 0) {
            // ________question port 
            // enter link in 1st question part
            // (i)  look for actionNode start_id: "CONDITION-JANJW" and update the end_id/end_port of th link with question1 port/id
            const previousNodeKeyProperty = "condition-JanJW";
            const actionNode = findNodeInfoByNodeName(rawNodeLinks,previousNodeKeyProperty);
            const question_1_PortIn = {
                new_end_id: question_node.id,
                new_end_port: Object.keys(question_node.portsIn)[0],
            }
            updateNodeLinkByStart_id(rawNodeLinks,actionNode.id,question_1_PortIn.new_end_id,question_1_PortIn.new_end_port);

            // (ii) create and insert a new link which connect question_1 with actionNodeId
            linkList.push({
                id: generateUniqueId(26),
                start_id: question_node.id,  
                start_port: Object.keys(question_node.portsOut)[0],
                end_id: actionNodeOfQuestion.actionNode.id,                      
                end_port: Object.keys(actionNodeOfQuestion.actionNode.portsIn)[0],  
                breakPoints: []
            })

            // action ports 
            if (good_count > 0) {
                // go to otherwise -> DQ
                linkList.push({
                    id: generateUniqueId(26),
                    start_id: actionNodeOfQuestion.actionNode.id,
                    start_port: othrtwiseCondId,     // other wise port
                    end_id: dq_Node.id,           
                    end_port:  Object.keys(dq_Node.portsIn)[0],  
                    breakPoints: []
                })

                // got to resposnsePorts -> nextQ/QL
                if (questionNodes.length === 1) {
                    // if 1st element is the last element
                    // go to response -> QL
                    actionResponseOutCondIds.forEach(condId=>{
                        linkList.push({
                            id: generateUniqueId(26),
                            start_id: actionNodeOfQuestion.actionNode.id,
                            start_port: condId,  // response ports
                            end_id: ql_Node.id,              
                            end_port: Object.keys(ql_Node.portsIn)[0],  
                            breakPoints: []
                        })
                    })
                    
                }else{
                    // go to response -> next_question
                    const nextQuestionNode = questionNodes[index+1].question_node;
                    actionResponseOutCondIds.forEach(condId=>{
                        linkList.push({
                            id: generateUniqueId(26),
                            start_id: actionNodeOfQuestion.actionNode.id,
                            start_port: condId,  // response ports
                            end_id: nextQuestionNode.id,              
                            end_port: Object.keys(nextQuestionNode.portsIn)[0],  
                            breakPoints: []
                        })
                    })
                }
            } else{
                // go to otherwise -> DQ
                linkList.push({
                    id: generateUniqueId(26),
                    start_id: actionNodeOfQuestion.actionNode.id,
                    start_port: othrtwiseCondId,
                    end_id: dq_Node.id,           
                    end_port:  Object.keys(dq_Node.portsIn)[0],  
                    breakPoints: []
                })
            }

            
            

            // if it is last element
        }else if (questionNodes.length === (index + 1)) {
            // question port
            linkList.push({
                id: generateUniqueId(26),
                start_id: question_node.id,
                start_port:  Object.keys(question_node.portsOut)[0],
                end_id: actionNodeOfQuestion.actionNode.id,              
                end_port: Object.keys(actionNodeOfQuestion.actionNode.portsIn)[0],  
                breakPoints: []
            })

            // action ports
            if (good_count > 0) {
                // go to otherwise -> DQ
                linkList.push({
                    id: generateUniqueId(26),
                    start_id: actionNodeOfQuestion.actionNode.id,
                    start_port: othrtwiseCondId,
                    end_id: dq_Node.id,              
                    end_port: Object.keys(dq_Node.portsIn)[0],  
                    breakPoints: []
                })

                // go to responses -> QL
                actionResponseOutCondIds.forEach(condId=>{
                    linkList.push({
                        id: generateUniqueId(26),
                        start_id: actionNodeOfQuestion.actionNode.id,
                        start_port: condId,  // rest port
                        end_id: ql_Node.id,              
                        end_port: Object.keys(ql_Node.portsIn)[0],  
                        breakPoints: []
                    })
                })

            }else{
                // go to otherwise -> DQ
                linkList.push({
                    id: generateUniqueId(26),
                    start_id: actionNodeOfQuestion.actionNode.id,
                    start_port: othrtwiseCondId,
                    end_id: dq_Node.id,              
                    end_port: Object.keys(dq_Node.portsIn)[0],  
                    breakPoints: []
                })
            }
            
            
        }else{
            // else all
            const nextQuestionNode = questionNodes[index+1].question_node;
            
            // question port
            linkList.push({
                id: generateUniqueId(26),
                start_id: question_node.id,  
                start_port: Object.keys(question_node.portsOut)[0],
                end_id: actionNodeOfQuestion.actionNode.id,                      
                end_port: Object.keys(actionNodeOfQuestion.actionNode.portsIn)[0],  
                breakPoints: []
            })
            
            // action ports 
            if (good_count > 0) {
                // go to otherwise -> DQ
                linkList.push({
                    id: generateUniqueId(26),
                    start_id: actionNodeOfQuestion.actionNode.id,
                    start_port: othrtwiseCondId,
                    end_id: dq_Node.id,              
                    end_port: Object.keys(dq_Node.portsIn)[0],  
                    breakPoints: []
                })

                // go to responses -> next_Q
                actionResponseOutCondIds.forEach(condId=>{
                    linkList.push({
                        id: generateUniqueId(26),
                        start_id: actionNodeOfQuestion.actionNode.id,
                        start_port: condId,  // rest port
                        end_id: nextQuestionNode.id,              
                        end_port: Object.keys(nextQuestionNode.portsIn)[0],  
                        breakPoints: []
                    })
                })
            }

        }
    })
    // finally insert the link connections
    insertLinkListInLinks(rawNodeLinks,linkList);
    return rawNodeLinks;
    
}

// update lead card options
const updateLeadDelegation = (mainNodeLinkData,customName,cardInfo) =>{
    const tempData = {...mainNodeLinkData}
    for (const nodeKey of Object.keys(tempData.nodes)) {
        if (tempData.nodes[nodeKey].data.customName === customName) {
            tempData.nodes[nodeKey].data.contents[0].title = cardInfo.attribute;
            tempData.nodes[nodeKey].data.contents[0].subtitle = cardInfo.subtitle;
            // update the actionButtons
            tempData.nodes[nodeKey].data.contents[0].actionButtons = cardInfo.options.map(btn=>{
                return{
                    buttonTitle: btn.buttonValue,
                    selectedAction: {
                        id: "act-btn-say",
                        name: "Say Something",
                    },
                    buttonValue: btn.buttonValue,
                }
            });
        }
        
    }
    return tempData;
}

// update lead card's action and conditions

const updateLeadAction = (mainNodeLinkData,customName,customValue) =>{
    const tempData = {...mainNodeLinkData}
    for (const nodeKey of Object.keys(tempData.nodes)) {
        if (tempData.nodes[nodeKey].data.customName === customName) {
            // console.log(customName,' upting delegate action -> ',tempData.nodes[nodeKey].data.contents[1]?.customAttr);
            if (tempData.nodes[nodeKey].data.contents[0].customAttr) {
                tempData.nodes[nodeKey].data.contents[0].customAttr = customValue;
            }else if (tempData.nodes[nodeKey].data.contents[1].customAttr) {
                tempData.nodes[nodeKey].data.contents[1].customAttr = customValue;
            }
        }
        
    }
    return tempData;
}


// delete unnecessary links and nodes
const deleteUnnecessaryLinksNode = (rawNodeLinks,unnecessaryNodeNameList=[]) =>{
    const newNodeLinks = {...rawNodeLinks};
    const deletedIdList = [];

    // delete the  node if the (start_id || end_id) === node.id
    unnecessaryNodeNameList.forEach((unnecessaryNodeName,index)=>{
        // find the node.id
        Object.keys(newNodeLinks.nodes).forEach(nodeKey=>{
            // console.log(newNodeLinks.nodes[nodeKey].data);
            if (newNodeLinks.nodes[nodeKey].data?.customName?.toLocaleLowerCase() === unnecessaryNodeName.toLocaleLowerCase()) {
                // don't delete first node link since it will connect our new node
                if (index !== 0) {
                    deletedIdList.push(newNodeLinks.nodes[nodeKey].id);
                }
                delete newNodeLinks.nodes[nodeKey];
            }
        })
    })
    // console.log({deletedIdList});

    // delete the link also if the start_id/end_id exist in "deletedIdList"
    Object.keys(newNodeLinks.links).forEach(linkKey=>{
        const start_id = newNodeLinks.links[linkKey].start_id;
        const end_id = newNodeLinks.links[linkKey].end_id;
        const hasStartId = deletedIdList.includes(start_id);
        const hasEndId = deletedIdList.includes(end_id);
        // console.log({hasStartId,hasEndId});
        if (hasStartId || hasEndId) {
            delete newNodeLinks.links[linkKey];
        }
    })
    
    return newNodeLinks;

}


const createLinkInQualifyLeadAndMsg = (mainNodeLinkData) =>{
    const tempNodeLinkData = {...mainNodeLinkData};
    const qualifiedLeadCustomName = 'Qualify-LEAD';
    const qualifyLeadInfo = findNodeInfoByCustomName(tempNodeLinkData,qualifiedLeadCustomName);
    const qualifiedResMsgCustomName = 'content-ysUpm';
    const qualifyMsgResInfo = findNodeInfoByCustomName(tempNodeLinkData,qualifiedResMsgCustomName);
    // console.log(qualifyLeadInfo.portsOut);
    const linkList = [
        {
            id: generateUniqueId(26),
            start_id: qualifyLeadInfo.id,
            start_port: Object.keys(qualifyLeadInfo.portsOut)[0],  // rest port
            end_id: qualifyMsgResInfo.id,              
            end_port: Object.keys(qualifyMsgResInfo.portsIn)[0],  
            breakPoints: []
        }
    ];
    insertLinkListInLinks(tempNodeLinkData,linkList);
    return tempNodeLinkData;
}


const nodeNameToBeDeleted = [
    'Qualification-question-1',
    'ANSWEr-1',
    'prequerynode',
    "QUALIFICATION-QUESTION-2",
    "answer-2",
    "qualifying-question-3",
    "lastconditionode"
];


export function modifyBotNodesByUserQuestions(rawNodeLinksData,questionList,leadCardOption) {
    // const mainNodeData = {...exportedBot};
    // console.log({questionList,leadCardOption},"modifyBotNodesByUserQuestions");
    try {
        // const mainNodeData = {...exportedBot};

        const cleanedNodeLinkData = deleteUnnecessaryLinksNode(rawNodeLinksData,nodeNameToBeDeleted);

        const questionNodes = createQuestionNodes(questionList);
        const conditionNodes = transformQualificationQuestions(questionList);
        
        // insert the nodes and actions into raw data
        const newNodesData = insertQuestionAndAnsNodes(cleanedNodeLinkData,questionNodes,conditionNodes);

        // update the link connection
        const updatedLinksData = connectLinks(newNodesData,questionNodes,conditionNodes);

        if (leadCardOption.isEquallyType) {
            //  delete the leadCardNodes and link directly with node='CONTENT-YSUPM'
            const deletedDelegationNodes = ['Lead-delegation','condition-10spE','delegate-ACTION',];
            // delete operation
            const deletedNodeRes = deleteUnnecessaryLinksNode(updatedLinksData,deletedDelegationNodes);
            // make new link between 'Qualify-LEAD' -> 'content-ysUpm'
            const newLinkedNodeData = createLinkInQualifyLeadAndMsg(deletedNodeRes);
            return newLinkedNodeData;
            
        }else{
            // update the lead card's node, action and condition
            const leadDelegationCustomName = 'Lead-delegation';
            const newUpdatedLeadData = updateLeadDelegation(updatedLinksData,leadDelegationCustomName,leadCardOption);

            const leadActionCustomName = 'delegate-ACTION';
            const newUpdateActionData = updateLeadAction(newUpdatedLeadData,leadActionCustomName,leadCardOption.attribute);

            const leadConditionCustomName = 'condition-10spE';
            const newUpdatedExportData = updateLeadAction(newUpdateActionData,leadConditionCustomName,leadCardOption.attribute);
            return newUpdatedExportData;
        }
        
    } catch (error) {
        console.log(error);
        return false;
    }
}
