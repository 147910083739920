<template>
  <div class="column content-box">
    <woot-modal-header :header-title="$t('FLOW.DELETE_PROMPT_MESSAGE')" />

    <form class="row" @submit.prevent>
      <div class="medium-12 columns"></div>

      <div class="modal-footer">
        <woot-button
          color-scheme="alert"
          :disabled="isDeleting"
          :is-loading="isDeleting"
          @click.prevent="allDone"
        >
          {{ $t('FLOW.DELETE') }}
        </woot-button>
        <woot-button
          variant="clear"
          color-scheme="secondary"
          @click.prevent="onClose"
        >
          {{ $t('FLOW.CANCEL') }}
        </woot-button>
      </div>
    </form>
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import FlowApi from '../../FlowApi';

export default {
  mixins: [alertMixin],

  props: {
    bot: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isDeleting: false,
    };
  },

  methods: {
    async allDone() {
      let bot = this.bot;

      if (!bot.botid) {
        return;
      }

      this.isDeleting = true;

      let response = await FlowApi.deleteFlow(bot.botid);

      if (response.status === 'ok') {
        this.$emit('delete-flow', true);
      } else {
        this.$emit('delete-flow', false);
      }

      this.isDeleting = false;
    },
    onClose() {
      this.$emit('on-close');
    },
  },
};
</script>
