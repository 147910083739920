import { Bar } from 'vue-chartjs';

const fontFamily =
  '-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif';

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    labels: {
      fontFamily,
    },
  },
  scales: {
    xAxes: [
      {
        barPercentage: 1.1,
        // stacked: true, // if want stacked bar chart
        ticks: {
          fontFamily,
        },
        gridLines: {
          drawOnChartArea: false,
        },
      },
    ],
    yAxes: [
      {
        // stacked: true,  // if want stacked bar chart
        ticks: {
          fontFamily,
          beginAtZero: true,
        },
        gridLines: {
          drawOnChartArea: false,
        },
      },
    ],
  },
};

export default {
  extends: Bar,
  // props: ['collection'],
  props: {
    collection: {
      type: Array,
      required: true,
    },
    stacked: {
      type: Boolean,
      default: false, // Default is not stacked
    },
  },
  mounted() {
    // this.renderChart(this.collection, chartOptions);
    this.renderChart(this.collection, this.getChartOptions());
  },
  watch: {
    collection: {
      deep: true,
      handler(newCollection) {
        // this.renderChart(newCollection, chartOptions);
        this.renderChart(newCollection, this.getChartOptions());
      },
    },
  },
  methods:{
    getChartOptions(){
      return {
        ...chartOptions,
        scales:{
          xAxes: [
            {
              ...chartOptions.scales.xAxes[0],
              stacked: this.stacked,
            },
          ],
          yAxes: [
            {
              ...chartOptions.scales.yAxes[0],
              stacked: this.stacked,
            },
          ],
        },
      }
    },
  },
};
