<template>
  <div class="communal_QR">
    <QRCode v-if="currentUser && inbox" :isOnlyQR="true" :shop_url="whatsappUrl" />
    <div class="info">
        <p class="font_medium">{{$t('COMMUNAL_WP_SETTING.WHATSAPP_NUMBER')}} {{inbox.phone_number}}</p>
        <p class="font_medium">{{$t('COMMUNAL_WP_SETTING.UNIQUE_CODE')}}{{currentUser.account_id}}</p>
        <woot-button @click="copyWhatsappLink">
            {{$t('COMMUNAL_WP_SETTING.SHARE_WHATSAPP_LINK')}}
        </woot-button>
    </div>
    <div class="notes_box">
        <p class="font_nomal">{{$t('COMMUNAL_WP_SETTING.NOTE_LABEL')}}</p>
        <ul class="font_nomal alpha_list">
            <li>{{$t('COMMUNAL_WP_SETTING.NOTES.NOTE_1')}}</li>
            <li>{{$t('COMMUNAL_WP_SETTING.NOTES.NOTE_2')}}</li>
            <li>
                {{$t('COMMUNAL_WP_SETTING.NOTES.NOTE_3_1')}}{{currentUser.account_id}}
                {{$t('COMMUNAL_WP_SETTING.NOTES.NOTE_3_2')}}
            </li>
        </ul>
    </div>
  </div>
</template>

<script>
import QRCode from "../../catalogue/settings/QRCode.vue";
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';

export default {
    components:{
        QRCode
    },
      mixins: [alertMixin],
    props:{
        inbox:{
            type: Object,
            default: {}
        },
    },
    computed: {
        ...mapGetters({
            currentUser: 'getCurrentUser',
            // merchantUiFlag: 'merchant/getUIFlags',
            // merchant: 'merchant/getMerchant',
            // isMerchant: 'merchant/isMerchant',
        }),
        whatsappUrl(){
            return `https://wa.me/${ this.inbox.phone_number}?text=PZ:${this.currentUser.account_id}`;
        },
    },
    mounted(){
        // this.$store.dispatch('merchant/fetchMerchant');
    },
    methods: {
        async copyWhatsappLink() {
            try {
                // Construct WhatsApp share URL
                // const whatsappUrl = `https://wa.me/${ this.inbox.phone_number}?text=PZ:${this.currentUser.account_id}`;

                // Copy to clipboard
                await navigator.clipboard.writeText(this.whatsappUrl);
                this.showAlert(this.$t('COMMUNAL_WP_SETTING.COPY_WP_SHARE_LINK_SUCCESS'));
            } catch (error) {
                console.error('Failed to copy WhatsApp link:', error);
            }
        },
        // end methods
    },

}
</script>

<style lang="scss" scoped>
    .font_medium{
        font-size: 16px;
        font-weight: 500;
        line-height: 23px;
    }
    .font_nomal{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }
    .info{
        text-align: center;
    }
    .notes_box{
        margin-top: 40px;
    }
    .alpha_list{
        list-style-type: lower-alpha;
    }
</style>

<style>
.communal_QR .qr_code_card{
    margin-bottom: 10px;
}
</style>