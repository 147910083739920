<template>
  <section class="orders-table-wrap">
    <ve-table
      :fixed-header="true"
      max-height="100%"
      :columns="columns"
      :table-data="tableData"
      :border-around="false"
      :sort-option="sortOption"
    />

  </section>
</template>

<script>
import { VeTable } from 'vue-easytable';
import Spinner from 'shared/components/Spinner.vue';
import CannedResponse from '../../../../components/widgets/conversation/CannedResponse.vue';
import { mapGetters } from "vuex";



export default {
    components:{
        VeTable,
        Spinner,
        CannedResponse,
    },
    props:{
        actionMessages:{
            type: Object,
            default: () => {}
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        cols: {
            type: Array,
            default: () => [],
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        // onClickDelete: {
        //     type: Function,
        //     default: (action_id) => {},
        // },
        toggleMessage: {
          type: Function,
          default: (action_id) => {},
        },
        toggleMessageEditor: {
          type: Function,
          default: (action_id) => {},
        },
        onMessageChange: {
          type: Function,
          default: (action_id,message) => {},
        },


    },
    data(){
        return {
          sortConfig: {},
          sortOption: {
            sortAlways: true,
            sortChange: params => this.$emit('on-sort-change', params),
          },
        }
    },
    computed:{
        ...mapGetters({
          cannedMessages: 'getCannedResponses',
        }),
        tableData(){
            // console.log(this.cols,this.orders);
            if (this.isLoading) {
                return [];
            }
            const formattedArrayData = [];
            for(const key in this.actionMessages){
                if (Object.hasOwnProperty.call(this.actionMessages,key)) {
                    formattedArrayData.push(this.actionMessages[key])
                }
            }
            // console.log({formattedArrayData});
            const formattedOrders = formattedArrayData.map(message=>{
                const preSet = {
                    ...message,
                    action_id: message.action.id,
                    // message: message.message,
                    message: message.message,
                    title: message.action.title
                    // action: 'action button',
                }
                return preSet;
            })
            // console.log({formattedOrders});
            return formattedOrders
        },
        columns(){
            let matches = {};

            matches.action_id = {
                field: 'action_id',
                key: 'action_id',
                title: 'When',
                width: 240,
                fixed: 'left',
                align: 'left',
                renderBodyCell: ({row})=>{
                    return <span>{row.action.title}</span>
                }
            }
            matches.message = {
                field: 'message',
                key: 'message',
                title: 'Message',
                align: "left",
                renderBodyCell: ({row})=>{
                  const a= this.$t('MESSAGE_MGMT.CREATE_BTN')
                  // toggleMessageEditor
                  // make a edit icon btn and a textarea to edit the message
                  let messageValue = row.inputChanged
                    ? row.message
                    : row.message ? row.message : row.defaultMessage;
                  return <div>
                    {
                      row.isEditOpen && <div>
                          <textarea
                            value={messageValue}
                            class="font_normal"
                            placeholder={"Write message"}
                            onInput={(e)=>this.onMessageChange(row.action_id,e.target.value,false)}
                          ></textarea>
                          <div class="cann_replay">
                          
                          {
                            row.showMentions && row.hasSlashCommand && <canned-response
                                // v-if="showMentions && hasSlashCommand"
                                top="37"
                                search-key={row.mentionSearchKey}
                                onClick={(message,template_name)=>this.onMessageChange(row.action_id,message,template_name,true)}
                                isPreview={true}
                                onPreviewClick={(item)=>console.log(item)}
                                isHideRejectItem={true}
                            />
                          }

                          </div>
                        </div>
                    }

                    {
                      !row.isEditOpen &&
                      <div>
                        {
                          row.isRealMsg
                          ? <span>{row.message}</span>
                          : <span>
                              <i>{ row.message ? row.message : row.defaultMessage}</i>
                            </span>
                        }
                        <woot-button
                          variant="smooth"
                          size="small"
                          color-scheme="secondary"
                          class-names="grey-btn"
                          icon="edit"
                          onClick={()=>this.toggleMessageEditor(row.action_id)}
                        ></woot-button>
                      </div>
                    }

                  </div>
                }
            }
            matches.action = {
                field: 'action',
                key: 'action',
                title: 'Activate',
                width: 150,
                renderBodyCell: ({row})=>{
                    // return <span>{row.order_id}</span>
                    return (
                      <button
                        type="button"
                        class={`toggle-button ${row.isRealMsg ? "active" : ""}`}
                        role="switch"
                        aria-checked={row.isRealMsg}
                        onClick={() => this.toggleMessage(row.action_id)}
                      >
                        <span
                            aria-hidden="true"
                            class={row.isRealMsg ? "active" : ""}
                        ></span>
                      </button>
                    )
                }
            }
            /*
            matches.action = {
                field: 'action',
                key: 'action',
                title: 'Action',
                renderBodyCell: ({row})=>{
                    // return <span>{row.order_id}</span>
                    return (
                      <woot-button
                          variant="smooth"
                            size="small"
                            color-scheme="alert"
                            icon="delete"
                            // onClick={() => this.onClickDelete(row.action_id)}
                            onClick={() => this.toggleMessage(row.action_id)}
                      >
                      {row.order_id}
                      </woot-button>
                    )
                }
            }
            */


            const preSet = [];
            this.cols.forEach(col=>{
                if (col.visible) {
                    if (col.key in matches) {
                        preSet.push(matches[col.key])
                    }
                }
            })
            return preSet;
        },
        // end computed
    },
    mounted(){
      bus.$on('MENTION_PREVIEW_CLICK_EVENT',(data)=>{
        this.onPreviewClick(data);
      })
    },
    watch:{},
    methods:{

      // end method
    },
}
</script>



<style lang="scss" scoped>
@import '~dashboard/assets/scss/mixins';

.orders-table-wrap {
  flex: 1 1;
  height: 100%;
  overflow: hidden;
}

.orders-table-wrap::v-deep {
  .ve-table {
    padding-bottom: var(--space-large);
  }

  .ve-table-header-th {
    padding: var(--space-small) var(--space-two) !important;
    background-color: var(--w-500) !important;
    color: var(--white) !important;
  }

  .ve-table-body-td {
    padding: var(--space-small) var(--space-two) !important;
  }

  .ve-table-header-th {
    font-size: var(--font-size-mini) !important;
  }
  .ve-table-sort {
    top: -4px;
  }
  .status_wrapper select{
    padding-left: 8px;
  }
}

.orders--loader {
  align-items: center;
  display: flex;
  font-size: var(--font-size-default);
  justify-content: center;
  padding: var(--space-big);
}




// toogle button design START
.toggle-button {
  background-color: var(--s-200);
  position: relative;
  display: inline-flex;
  height: 19px;
  width: 34px;
  border: 2px solid transparent;
  border-radius: var(--border-radius-large);
  cursor: pointer;
  transition-property: background-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
  flex-shrink: 0;
}

.toggle-button.active {
  background-color: var(--w-500);
}

.toggle-button span {
  --space-one-point-five: 1.5rem;
  height: var(--space-one-point-five);
  width: var(--space-one-point-five);
  display: inline-block;
  background-color: var(--white);
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px,
    rgba(59, 130, 246, 0.5) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  transform: translate(0, 0);
  border-radius: 100%;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
}
.toggle-button span.active {
  transform: translate(var(--space-one-point-five), var(--space-zero));
}
// toogle button design END
.font_normal{
  font-size: 14px;
}
.cann_replay{
  .create_temp_msg_btn{
    position: relative;
    // top: -18rem;
    top: var(--top-create-cann-padding);
    z-index: 101;
    background: #fff;
    padding: 12px;
    width: 100%;
    left: 0;
    color: #8D25F6;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    padding-left: 12px;
    border-top: 1px solid #EEEEEE;
    border-bottom: 1px solid #EEEEEE;
    cursor: pointer;
    &:hover{
      background: #8D25F6;
      color: #fff;
    }
    span{
      font-weight: 500;
    }
  }

}
</style>









