<template>
  <div class="column padding-top">
    <woot-button
      color-scheme="success"
      class-names="button--fixed-right-top"
      icon="arrow-download"
      @click="downloadReport"
    >
      {{ $t('FLOW.DOWNLOAD_REPORT') }}
    </woot-button>
    <report-filter-selector
      v-if="filterItemsList"
      :filter-items-list="filterItemsList"
      :hide-groupby="false"
      @date-range-change="onDateRangeChange"
    />

    <div class="row">
      <div class="report-bar" style="width:100%;">
        <div class="row">
          <div class="column">
            <h4>Usage Breakdown</h4>
            <bots-table
              :is-loading="isFetching"
              :bots-report="botsReport"
            ></bots-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ReportFilterSelector from '../components/FilterSelector';
import BotsTable from './BotTable';
import format from 'date-fns/format';
import auth from '../../../../../api/auth';

export default {
  name: 'BotsTransactions',
  components: {
    ReportFilterSelector,
    BotsTable,
  },
  data() {
    return {
      from: 0,
      to: 0,
      rows: [],
      account_id: null,
      user_id: null,
    };
  },
  computed: {
    ...mapGetters({
      botsReport: 'getBotsReport',
      isFetching: 'getIsFetchingUsageReport',
      currentUser: 'getCurrentUser',
    }),
    filterItemsList() {
      return this.$store.getters['inboxes/getInboxes'] || [];
    },
    topupHistory() {
      if (this.botsReport == null) return [];
      return this.botsReport;
    },
    reportDownloadable() {
      if (this.botsReport && this.botsReport.tableData) {
        return true;
      }
      return false;
    },
  },

  mounted() {
    this.fetchAllData();
  },
  methods: {
    downloadReport() {
      let csv = 'Bot Id,Rate,Quantity,Total(USD)\n';

      if (this.botsReport && this.botsReport.tableData) {
        this.botsReport.tableData.forEach(row => {
          let bot_id = row.bot_id ? row.bot_id : '';
          let unit_price = row.unit_price ? row.unit_price : '';
          let quantity = row.quantity ? row.quantity : '';
          let total = row.total ? row.total : '';
          if (bot_id) {
            csv += `${bot_id},${unit_price},${quantity},${total}`;
            csv += '\n';
          }
        });
      }

      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      let today = new Date();
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0');
      const yyyy = today.getFullYear();

      today = dd + '-' + mm + '-' + yyyy;
      anchor.download = `bot-transactions-${today}.csv`;
      anchor.click();
    },
    async fetchAllData() {
      const user = await auth.getCurrentUser();
      if (user?.account_id) {
        this.$store.dispatch('botsTransaction', {
          account_id: user.account_id,
          from: this.from,
          to: this.to,
        });
      }
    },
    onDateRangeChange({ from, to }) {
      this.from = format(new Date(from * 1000), 'yyyy-MM-dd');
      this.to = format(new Date(to * 1000), 'yyyy-MM-dd');
      this.$nextTick(() => {
        this.fetchAllData();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.padding-top {
  padding-top: 1.6rem;
}
.report-bar {
  padding: 1.6rem;
}
.csat--metrics-container {
  background: var(--white);
  margin-bottom: var(--space-two);
  border-radius: var(--border-radius-normal);
  border: 1px solid var(--color-border);
  padding: var(--space-normal);
}
</style>
