<template>
  <div class="column content-box">
    <woot-modal-header
      v-if="isIntegrationPeasy"
      :header-title="$t('INTEGRATION_APPS.PEASY.HEADER')"
      :header-content="$t('INTEGRATION_APPS.PEASY.DESCRIPTION')"
    />
    <woot-modal-header
      v-else
      :header-title="integration.name"
      :header-content="integration.description"
    />
    <formulate-form #default="{ hasErrors }" v-model="values" @submit="submitForm">
      <formulate-input
        v-for="item in formItems"
        :key="item.name"
        v-bind="item"
        :value="item.value"
        :disabled="item.disable"
      />
      <!-- <formulate-input
        :options="brandProfiles"
        type="select"
        name="brand_profile_id"
        :placeholder="$t('INTEGRATION_APPS.ADD.FORM.INBOX.LABEL')"
        :label="$t('INTEGRATION_APPS.ADD.FORM.INBOX.PLACEHOLDER')"
        validation="required"
        validation-name="Inbox"
      />-->
      <label
        style="width:100%"
        class="catalog-label-text"
        v-if="integration.id !== 'chatgpt' && integration.id !== 'stripe'"
      >
        {{ $t('FLOW.SELECT_BRAND_PROFILE') }}
        <select
          @change="OnBrandProfileChanged()"
          v-model="selectedBrandProfile"
        >
          <option v-for="item in brandProfiles" :key="item.id" :value="item">{{ item.brand_name }}</option>
        </select>
      </label>
      <formulate-input
        v-if="integration.id === 'stripe'"
        type="text"
        name="secret_api_key"
        :placeholder="$t('INTEGRATION_APPS.ADD.FORM.SECRET_API_KEY.PLACEHOLDER')"
        :label="$t('INTEGRATION_APPS.ADD.FORM.SECRET_API_KEY.LABEL')"
        validation="required"
      />
      <formulate-input
        v-if="integration.id === 'stripe'"
        type="text"
        name="publishable_key"
        :placeholder="$t('INTEGRATION_APPS.ADD.FORM.PUBLISHABLE_KEY.PLACEHOLDER')"
        :label="$t('INTEGRATION_APPS.ADD.FORM.PUBLISHABLE_KEY.LABEL')"
        validation="required"
      />
      <formulate-input
        v-if="integration.id === 'stripe'"
        :options="currencies"
        type="select"
        name="currency"
        :placeholder="$t('INTEGRATION_APPS.ADD.FORM.CURRENCY.LABEL')"
        :label="$t('INTEGRATION_APPS.ADD.FORM.CURRENCY.PLACEHOLDER')"
        validation="required"
        validation-name="Currency"
      />
      <formulate-input
        v-if="integration.id === 'chatgpt'"
        type="text"
        name="api_key"
        :placeholder="$t('INTEGRATION_APPS.ADD.FORM.API_KEY.PLACEHOLDER')"
        :label="$t('INTEGRATION_APPS.ADD.FORM.API_KEY.LABEL')"
        validation="required"
      />
      <!-- <formulate-input
        v-if="isHookTypeInbox"
        :options="inboxes"
        type="select"
        name="inbox"
        :placeholder="$t('INTEGRATION_APPS.ADD.FORM.INBOX.LABEL')"
        :label="$t('INTEGRATION_APPS.ADD.FORM.INBOX.PLACEHOLDER')"
        validation="required"
        validation-name="Inbox"
      /> -->
      <p
        v-if="isIntegrationPeasy"
        v-html="
          $t('INTEGRATION_APPS.PEASY.PEASY_WEBHOOK_URL', {
            baseUrl: basePath,
          })
        "
      />
      <div class="modal-footer">
        <woot-button
          :disabled="hasErrors"
          :loading="uiFlags.isCreatingHook"
        >{{ $t('INTEGRATION_APPS.ADD.FORM.SUBMIT') }}</woot-button>
        <woot-button
          class="button clear"
          @click.prevent="onClose"
        >{{ $t('INTEGRATION_APPS.ADD.FORM.CANCEL') }}</woot-button>
      </div>
    </formulate-form>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import hookMixin from './hookMixin';
import PMConnectAPI from '../bot/PMConnectAPI';

export default {
  mixins: [alertMixin, hookMixin],
  props: {
    integration: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      endPoint: '',
      alertMessage: '',
      values: {},
      brandProfiles: [],
      selectedBrandProfile: null,
      peasyApiKey: null,
      currencies: ['MYR', 'EUR', 'USD', 'AUD'],
    };
  },
  async mounted() {
    // only do if integration.id == peasy
    if (this.integration.id === 'peasy') {
      let brandProfilesResponse = await PMConnectAPI.getBrandProfileList();
      console.log('brandProfilesResponse', brandProfilesResponse);
      this.brandProfiles = brandProfilesResponse;
    }
  },
  computed: {
    ...mapGetters({
      uiFlags: 'integrations/getUIFlags',
      websiteInboxes: 'inboxes/getWebsiteInboxes',
      dialogFlowEnabledInboxes: 'inboxes/dialogFlowEnabledInboxes',
      peasyInboxes: 'inboxes/getInboxes',
      basePath: 'accounts/getBasePath',
      currentUser: 'getCurrentUser',
    }),
    inboxes() {
      if (this.isIntegrationPeasy) {
        return this.peasyInboxes
          .filter(inbox => {
            return inbox.brand_profile_id == null;
          })
          .map(inbox => ({ label: inbox.name, value: inbox.id }));
      }
      return this.dialogFlowEnabledInboxes
        .filter(inbox => {
          if (!this.isIntegrationDialogflow) {
            return true;
          }
          return !this.connectedDialogflowInboxIds.includes(inbox.id);
        })
        .map(inbox => ({ label: inbox.name, value: inbox.id }));
    },

    connectedDialogflowInboxIds() {
      if (!this.isIntegrationDialogflow) {
        return [];
      }
      return this.integration.hooks.map(hook => hook.inbox?.id);
    },
    formItems() {
      console.log('form items', this.integration.settings_form_schema);
      // return this.integration.settings_form_schema;
      return [];
    },
    isIntegrationDialogflow() {
      return this.integration.id === 'dialogflow';
    },
    isIntegrationPeasy() {
      return this.integration.id === 'peasy';
    },
  },
  methods: {
    async OnBrandProfileChanged() {
      let genericTokenResponse = await PMConnectAPI.getPMToken();

      console.log(
        'brand profile changed e',
        this.selectedBrandProfile?.id,
        this.currentUser?.access_token,
        genericTokenResponse?.key
      );

      if (
        this.selectedBrandProfile?.id &&
        this.currentUser?.access_token &&
        genericTokenResponse?.key
      ) {
        let brandKeyResponse = await PMConnectAPI.getBrandProfileKey(
          this.currentUser?.access_token,
          this.selectedBrandProfile?.id,
          genericTokenResponse?.key
        );

        this.peasyApiKey = brandKeyResponse?.key;
      }
    },
    onClose() {
      this.$emit('close');
    },
    buildHookPayload() {
      const hookPayload = {};

      if (this.integration.id == 'chatgpt') {
        hookPayload.account_id = this.currentUser?.account_id;
        hookPayload.app_id = this.integration.id;
        hookPayload.settings = JSON.stringify({
          api_key: this.values.api_key,
        });
      } else if (this.integration.id == 'stripe') {
        hookPayload.app_id = this.integration.id;
        hookPayload.settings = {
          secret_api_key: this.values.secret_api_key,
          publishable_key: this.values.publishable_key,
          currency: this.values.currency,
        };
      } else {
        hookPayload.account_id = this.currentUser?.account_id;
        hookPayload.contact_create_url =
          'https://app.peasy.ai/service/api/webhook/lead/create';
        hookPayload.contact_update_url =
          'https://app.peasy.ai/service/api/webhook/lead/update';
        hookPayload.peasy_api_key = this.peasyApiKey;
        hookPayload.brand_profile_id = this.selectedBrandProfile?.id;
      }

      if (this.isHookTypeInbox && this.values.inbox) {
        hookPayload.inbox_id = this.values.inbox;
      }

      console.log('hookPayload', hookPayload);

      return hookPayload;
    },
    async submitForm() {
      try {
        await this.$store.dispatch(
          'integrations/createHook',
          this.buildHookPayload()
        );
        this.alertMessage = this.$t('INTEGRATION_APPS.ADD.API.SUCCESS_MESSAGE');
        await this.$store.dispatch('inboxes/get');
        this.onClose();
      } catch (error) {
        const errorMessage = error?.response?.data?.message;
        this.alertMessage =
          errorMessage ||
          this.$t('INTEGRATION_APPS.ADD.API.BRAND_ERROR_MESSAGE');
      } finally {
        this.showAlert(this.alertMessage);
      }
    },
  },
};
</script>
