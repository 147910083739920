import placeholderImage from "../assets/images/external/img_placeholder.jpg";

export const methodTils = {
    async getValidImageUrl(imgUrl) {
        return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => resolve(imgUrl);
            img.onerror = () => resolve(placeholderImage);
            img.src = imgUrl;
        });
    },
};
