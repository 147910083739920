<template>
    <div v-if="isOpen" class="event_wrapper">
        <modal
            :show.sync="isOpen"
            :on-close="closeModal"
            closeBtnClass="event_close_btn"
            modalMainContainerClass=""
        >
            <div class="event">
                <a :href="contest.landing_page_url" target="_blank">
                    <img :src="contest.poster_url" alt="">
                </a>
                <!-- <a class="join_btn enent_btn_wrap" :href="contest.landing_page_url" target="_blank">
                    <woot-button class="event_join_btn">Join Now </woot-button>
                </a> -->
                <div v-if="!contest.account_joined">
                    <label class="term_condition" for="">
                        <spinner v-if="isJoining" size="small" />
                        <input v-else type="checkbox" name="" id=""  @change="onCheckAccept">
                        <p>
                            {{$t('CONTEST_SETTING.I_JOIN')}}
                            <a :href="contest.term_url" target="_blank">{{$t('CONTEST_SETTING.TERMS_CONDITION')}}</a>
                        </p>
                    </label>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import Modal from '../../../Modal.vue';
import endPoints from '../../../../api/endPoints';
import AccountAPI from "../../../../api/account"
import alertMixin from 'shared/mixins/alertMixin';
import Spinner from 'shared/components/Spinner';


export default {
    components:{
        Modal,
        Spinner,
    },
    mixins:[alertMixin],
    data(){
        return {
            isOpen: false,
            isJoining:false,
            // isContestOpen: false,
            contest: {
                poster_url: "",
                landing_page_url: "",
                term_url: "",
                contest_name: "",
                account_joined:false,
            },
        }
    },
    computed:{},
    mounted(){
        this.getRecentContest();
    },
    watch:{
        isOpen(newValue) {
            // Emit an event to inform the parent whenever `isOpen` changes
            this.$emit('update:isOpen', newValue);
        },
    },
    methods:{
        async getRecentContest() {
            try {
                // check if recent login()
                const isRecentLogin = localStorage.getItem('recentLoginContestEvent');
                console.log({isRecentLogin});
                if (isRecentLogin === "true") {
                    const { data: contest } = await axios.get(endPoints('contestEvent').url());
                    if (contest && contest.poster_url) {
                        this.contest = contest;
                        this.isOpen = true;
                    }
                }
            } catch (error) {
                console.error("Failed to fetch contest data:", error);
            }
        },
        closeModal(){
            localStorage.clear('recentLoginContestEvent')
            this.isOpen = false;
        },
        async onCheckAccept(){
            if (this.contest.account_joined) {
                this.isOpen = false;
                return
            }
            try {
                this.isJoining = true;
                const payload = {
                    contest_name: this.contest.contest_name,
                }
                // Call your API here
                const { data: contestJoin } = await axios.post(endPoints('contestEvent').joinContest,payload);
                console.log({contestJoin});
                this.showAlert(`You have successfully join the contest`);
                this.isOpen = false;
                this.closeModal();
            } catch (error) {
                this.isOpen = false;
                this.showAlert(`Failed to join contest`);
                console.error("Failed to submit contest:", error);
            } finally{
                this.isJoining = false;
            }
        },
    },

}
</script>

<style lang="scss" scoped>
// @import '~dashboard/assets/scss/variables';

.event{
    position: relative;
    .join_btn{
        position: absolute;
        bottom: 2%;
        left: 50%;
        transform: translateX(-50%);
    }

    .enent_btn_wrap{
        padding: 3px;
        background: #A320FB;
        border-radius: 10px;
        .event_join_btn{
            color: #A320FB;
            background: #CCFF3C;
            font-weight: 900;
            border-radius: 10px !important;
        }
    }
    .term_condition{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        padding: 4px 14px;
        margin-top: 10px;
        border-radius: 4px;

        a{
            font-weight: 600;
            &:hover{
                text-decoration: underline;
            }
        }
        input{
            margin: 0;
        }
    }

}

</style>

<style lang="scss">
// global style to insert css into clild component
.event_wrapper{
    .event_close_btn{
        z-index: 100;
        background: #fff;
        &:hover{
            background: var(--s-50);
        }
    }
}
</style>