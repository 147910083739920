/* global axios */
import ApiClient from './ApiClient';

class CalendarAPI extends ApiClient {
  constructor() {
    super('', { accountScoped: true });
  }

  createCalendar(payload) {
    return axios.post(`${this.url}meet_calendars`, payload);
  }

  updateCalendar(payload) {
    return axios.patch(`${this.url}meet_calendars`, payload);
  }

  getCalendar() {
    return axios.get(`${this.url}meet_calendars`);
  }

  getAllMeetingSessions() {
    return axios.get(`${this.url}meeting_sessions`);
  }

  createMeetingSession(data) {
    return axios.post(`${this.url}meeting_sessions`, data);
  }

  updateMeetingSession(data) {
    return axios.patch(`${this.url}meeting_sessions`, data);
  }

  deleteMeetingSession(id) {
    return axios.delete(`${this.url}meeting_sessions?id=` + id);
  }
}

export default new CalendarAPI();
