import ApiClient from "../ApiClient";

class Hub360Channel extends ApiClient{
    constructor(){
        super('',{accountScoped: true});
    }

    createApi(body={}){
        // const url = this.url + `inboxes/generate_360_api_key?channel_id=${channel_id}`;
        const url = this.url + `inboxes/generate_apikey`;
        return axios.post(url,body);
    }
}

export default new Hub360Channel();