<template>
  <modal
  :show.sync="show"
  :on-close="onClose"
  :modalMainContainerClass="'notify_modal'"
  closeBtnClass="modal_box_close_btn"
  >
    <div class="notify_body">
        <aside class="logo">
            <img :src="clockIcon" alt="">
        </aside>
        <aside>
            <h3 class="title">{{$t('MESSAGE_MGMT.NOTIFY.TITLE')}}</h3>
            <p class="description">{{$t('MESSAGE_MGMT.NOTIFY.DESCRIPTION')}}</p>
            <woot-button
                class="okay_btn"
                @click="onOkay()"
            >
            {{$t('MESSAGE_MGMT.NOTIFY.BTN')}}
            </woot-button>
        </aside>
    </div>
  </modal>
</template>

<script>
import Modal from '../../../../components/Modal.vue';
// import clockIcon from "app\javascript\dashboard\assets\images\clock.png"
import clockIcon from "dashboard/assets/images/clock.png"

export default {
    components: {
        Modal,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        onClose: {
            type: Function,
            default: () => {},
        },
        onOkay:{
            type: Function,
            default: () => {},
        },
    },
    data(){
        return {
            clockIcon,
        }
    },
}
</script>

<style lang="scss" scoped>
.notify_body{
    color: #1F1F1F;
    text-align: center;
    .logo{
        // text-align: center;
        img{
            width: 100px;
        }
    }
    .title {
        margin-top: 24px;
        font-weight: 700;
        font-size: 20px;
    }
    .description{
        font-size: 14px;
        font-weight: 500;
    }
    .okay_btn{
        margin-top: 24px;
        width: 100%;
        background: #8D25F6;
    }
}
</style>

<style>
/*  global css  */
.notify_modal{
    max-width: 380px;
    padding: 32px 24px !important;
    border-radius: 20px;
}
.notify_modal .modal_box_close_btn{
    display: none;
}
</style>